import { computed, ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import type { PortalView } from 'dfx/edge/edge.did';

export const usePortalStore = defineStore('portal', () => {
  const allPortalsHash = ref<Map<bigint, PortalView>>(new Map());

  const joinedIds: Ref<bigint[]> = ref([]);
  const favoriteIds: Ref<bigint[]> = ref([]);
  const highlightedIds: Ref<bigint[]> = ref([]);
  const favoriteRecommendationsIds: Ref<bigint[]> = ref([]);

  const joinedPortals = computed(() => {
    return joinedIds.value.map((id) => allPortalsHash.value.get(id)!);
  });

  const favoritePortals = computed(() => {
    return favoriteIds.value.map((id) => allPortalsHash.value.get(id)!);
  });

  const highlightedPortals = computed(() => {
    return highlightedIds.value.map((id) => allPortalsHash.value.get(id)!);
  });

  const favoriteRecommendations = computed(() => {
    return favoriteRecommendationsIds.value.map(
      (id) => allPortalsHash.value.get(id)!,
    );
  });

  const updatePortalsHash = (portals: PortalView[]) => {
    portals.forEach((portal) => allPortalsHash.value.set(portal.id, portal));
  };

  const updatePortal = (portal: PortalView) => {
    allPortalsHash.value.set(portal.id, portal);

    if (portal.is_following) {
      joinedIds.value = [...joinedIds.value, portal.id];
      favoriteIds.value = highlightedPortals.value
        .filter((p) => p.is_following)
        .map((p) => p.id);
    } else {
      joinedIds.value = joinedIds.value.filter((id) => id !== portal.id);
      favoriteIds.value = favoriteIds.value.filter((id) => id !== portal.id);
    }
    return allPortalsHash.value.get(portal.id);
  };

  const setJoinedPortals = (portals: PortalView[]) => {
    joinedIds.value = portals.map((p) => p.id);
    updatePortalsHash(portals);
  };

  const setSortedFavoritePortals = (portals: PortalView[]) => {
    updatePortalsHash(portals);
    highlightedIds.value = portals.map((p) => p.id);
    favoriteIds.value = portals.filter((p) => p.is_following).map((p) => p.id);
  };

  const setFavoriteRecommendations = (portals: PortalView[]) => {
    favoriteRecommendationsIds.value = portals.map((p) => p.id);
    updatePortalsHash(portals);
  };

  return {
    allPortalsHash,
    joinedPortals,
    favoritePortals,
    highlightedPortals,
    favoriteRecommendations,
    setJoinedPortals,
    setSortedFavoritePortals,
    updatePortal,
    setFavoriteRecommendations,
  };
});
