<script lang="ts" setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import ContentDialog from '../../ui/Content.vue';
  import PortalCard from '../../ui/PortalCard.vue';
  import ContinueButton from '../ContinueButton.vue';
  import SkipButton from '../SkipButton.vue';
  import { OnboardingStep, OnboardingLootboxStep } from '@/shared/model';
  import { pluralize } from '@/shared/lib';
  import { useSetUserPortalJoinedMutation } from '../../api/use-set-user-portal-joined.mutation';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { useGetOnboardingPortalRecommendationsQuery } from '../../api/use-get-onboarding-portal-recommendations.query';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { usePortal } from '@/entities/portal';
  import { Loader } from '@/shared/ui/loader';
  import { useUser, useGetUserInterestsQuery } from '@/entities/user';

  defineProps<{
    isCompletingSteps: boolean;
  }>();

  const { t } = useI18n({
    useScope: 'global',
  });

  useGtm()?.trackEvent({ event: 'Join_Portals_Step_4' });
  const { getPortalIcon, getAbbreviatedMemberCount } = usePortal();
  const { currentUser } = useUser();
  const { data: userInterests, isLoading: isUserInterestsLoading } =
    useGetUserInterestsQuery(currentUser);
  const {
    data: portalRecommendations,
    isLoading: isPortalRecommendationsLoading,
  } = useGetOnboardingPortalRecommendationsQuery(currentUser);
  const { mutate: setUserPortalJoinedMutation } =
    useSetUserPortalJoinedMutation();

  const isLoading = computed(
    () => isPortalRecommendationsLoading.value || isUserInterestsLoading.value,
  );

  const selectedPortals = ref<bigint[]>();

  const totalSelectedPortals = computed(
    () => selectedPortals.value?.length ?? 0,
  );

  const isContinueDisabled = computed(() => totalSelectedPortals.value < 3);

  const onClickCard = (portalId: bigint) => {
    if (selectedPortals.value?.includes(portalId)) {
      selectedPortals.value.splice(selectedPortals.value.indexOf(portalId), 1);
    } else {
      if (!selectedPortals.value) {
        selectedPortals.value = [portalId];
      } else {
        selectedPortals.value?.push(portalId);
      }
    }
  };

  const onClickJoin = () => {
    setUserPortalJoinedMutation({ portalIds: selectedPortals.value! });
  };
</script>

<template>
  <div v-if="isLoading" class="relative p-40">
    <loader variant="rainbow" border-width="border" size="size-10 md:size-20" />
  </div>
  <content-dialog v-else class="pt-30 md:pt-20">
    <template #title>
      {{ t('onboarding.joinPortals.title') }}
    </template>
    <template #description>
      {{
        userInterests && userInterests.length > 0
          ? t('onboarding.joinPortals.description')
          : t('onboarding.joinPortals.descriptionNoInterests')
      }}
      <br />
      {{ t('onboarding.chooseAtLeast3') }}
    </template>
    <div
      class="grid grid-cols-1 md:grid-cols-[240px_240px_240px] max-h-100 overflow-y-auto thin-scrollbar gap-6 p-1"
    >
      <portal-card
        v-for="portal in portalRecommendations"
        :key="portal.id.toString()"
        :class="{
          'ring-2 ring-[#764BA2]': selectedPortals?.includes(portal.id),
          'ring-1 ring-gray-785 ring-opacity-50': !selectedPortals?.includes(
            portal.id,
          ),
        }"
        @click="onClickCard(portal.id)"
      >
        <template #cover>
          <img
            v-lazy="{
              src: portal.info[0]?.cover_photo[0],
              error: fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
              ),
            }"
            class="size-full object-cover rounded-xl"
          />
        </template>
        <template #avatar>
          <img
            v-lazy="{
              src: getPortalIcon(portal),
              error: fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
              ),
            }"
            class="size-16 object-cover rounded-xl"
          />
        </template>
        <template #name> {{ portal.name }} </template>
        <template #followers>
          {{ getAbbreviatedMemberCount(portal) }}
          {{ pluralize(Number(portal.member_count), 'follower') }}
        </template>
        <template #bio>
          {{ portal.description }}
        </template>
      </portal-card>
    </div>
    <template #actions>
      <div class="flex flex-col gap-1 w-full">
        <continue-button
          :current-step="
            isCompletingSteps
              ? OnboardingLootboxStep.MISSING_JOIN_PORTALS
              : OnboardingStep.JOIN_PORTALS
          "
          :disabled="isContinueDisabled"
          :on-custom-action="onClickJoin"
        >
          {{ t('onboarding.joinPortals.join') }}
          {{ totalSelectedPortals > 0 ? totalSelectedPortals : '' }}
        </continue-button>
        <skip-button
          :current-step="
            isCompletingSteps
              ? OnboardingLootboxStep.MISSING_JOIN_PORTALS
              : OnboardingStep.JOIN_PORTALS
          "
        />
      </div>
    </template>
  </content-dialog>
</template>
