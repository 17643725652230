<script setup lang="ts">
  import { useAsyncState } from '@vueuse/core';
  import MagicEdenNft from '@/features/marketplace/components/MagicEdenNft.vue';
  import TensorNft from '@/features/marketplace/components/TensorNft.vue';
  import { useToast } from '@/shared/model';
  import type { MagicEdenTokenMetadata } from '@/shared/api';
  import type { SolanaMarketplaceType } from '@/features/marketplace/types';
  import SolanaNftPlaceholder from '@/features/marketplace/ui/SolanaNftPlaceholder.vue';
  import { useMagicEdenNft } from '@/features/marketplace/model/composables/use-magic-eden-nft';
  import { useTensorNft } from '@/features/marketplace/model/composables/use-tensor-nft';

  // Pass marketplace type in the nftId after a hash we can split on
  const props = withDefaults(
    defineProps<{
      nftId: string;
      nftType?: SolanaMarketplaceType;
      preview?: boolean;
    }>(),
    {
      nftType: 'magic-eden' as SolanaMarketplaceType,
    },
  );

  const emit = defineEmits<{
    (e: 'deleteNode'): void;
  }>();

  const { showToast } = useToast();
  const { fetchNftDetails } = useMagicEdenNft(props.nftId);
  const { fetchNftDetails: fetchTensorNftDetails } = useTensorNft(props.nftId);

  // Fetch Nft details based on nftType
  const { isLoading, state } = useAsyncState(async () => {
    if (props.nftType === 'tensor') {
      const result = await fetchTensorNftDetails();
      if (!result) {
        return null;
      }
      return {
        tensorNft: result,
        type: 'tensor' as SolanaMarketplaceType,
      };
    }
    const result = await fetchNftDetails();
    if (!result) {
      if (props.preview) {
        showToast({
          title: 'NFT not found',
          type: 'error',
          durationSeconds: 5,
        });
        emit('deleteNode');
      }
      return null;
    }

    if (!result?.nft) {
      return null;
    }

    return {
      nft: result.nft as MagicEdenTokenMetadata,
      listing: result.listings[0],
      activities: result.activities,
      type: 'magic-eden' as SolanaMarketplaceType,
    };
  }, null);
</script>

<template>
  <magic-eden-nft
    v-if="state && state.nft && nftType === 'magic-eden'"
    :nft="state.nft"
    :listing="state.listing"
    :activity="state.activities"
  />
  <tensor-nft
    v-else-if="state && state.tensorNft && nftType === 'tensor'"
    :nft="state.tensorNft"
  />
  <solana-nft-placeholder :type="nftType" v-else-if="isLoading" />
</template>

<style scoped lang="scss">
  .dynamic-content[data-v-c58bc23a] .nft-embed img {
    margin: 1.5rem 0 !important;
  }
</style>
