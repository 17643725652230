<script setup lang="ts">
  import { CheckboxField } from '@/shared/ui/fields';
  import { useNotificationStore } from '@/entities/notification';
  import { IS_HIDDEN } from '@/common';

  const notificationStore = useNotificationStore();
</script>

<template>
  <div v-if="!IS_HIDDEN" class="flex items-center justify-end w-full">
    <div
      class="absolute top-0 right-0 flex items-center justify-end mt-4 mr-14 space-x-2"
    >
      <span> {{ $t('notifications.portalAdmin') }} </span>
      <div class="relative inline-flex items-center cursor-pointer">
        <checkbox-field
          v-model="notificationStore.isPortalAdmin"
          name="portal-admin"
          variant="toggle"
          @click.stop
        />
      </div>
    </div>
  </div>
</template>
