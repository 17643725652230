import { LITE_YOUTUBE_REGEX } from '@/common';

export const pluralize = (
  value: number | bigint,
  singular: string,
  plural = `${singular}s`,
) => {
  return isNaN(Number(value)) || Number(value) === 1 ? singular : plural;
};

export const shortenString = (inputString: string, maxLength = 10) =>
  inputString.length <= maxLength
    ? inputString
    : `${inputString.slice(0, 5)}...${inputString.slice(-4)}`;

const CHARACTERS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length: number): string => {
  let result = '';
  const charactersLength = CHARACTERS.length;
  for (let i = 0; i < length; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const generateRandomString = (length: number): string => {
  var charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';
  let result = '';

  while (length > 0) {
    var bytes = new Uint8Array(16);
    var random = window.crypto.getRandomValues(bytes);

    random.forEach(function (c) {
      if (length == 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
};

export const isValidHttpUrl = (url: string): boolean => {
  const pattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
  return pattern.test(url);
};

export const isFleekImage = (url: string) => {
  return url.match(/fleek/) !== null || url.match(/ipfs.dscvr.one/) !== null;
};

export const extractYoutubeIdFromUrl = (url: string) => {
  const match = LITE_YOUTUBE_REGEX.exec(url.trim());
  return match ? match[1] : null;
};
