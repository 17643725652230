<script lang="ts" setup>
  import { computed } from 'vue';
  import type { WalletName } from '@solana/wallet-adapter-base';
  import { shortenString, SOLANA_WALLET_NAMES } from '@/shared/lib';
  import { useUser } from '@/entities/user';
  import { PairButton } from '@/entities/user-setting';
  import {
    convertWalletNameToWalletType,
    useWalletPairing,
    usePairSolanaWallet,
  } from '@/entities/wallets';

  const props = defineProps<{
    walletName: string;
  }>();

  const emit = defineEmits<{
    (event: 'paired'): void;
    (event: 'unpaired'): void;
  }>();

  const { currentUser } = useUser();
  const { currentPairingWallet, pairedWallets, isFetchingPairedWallets } =
    useWalletPairing();
  const { pair, unpair } = usePairSolanaWallet();

  const walletName = computed(() => props.walletName as WalletName);

  const pairedWallet = computed(() =>
    pairedWallets.value.find(
      (wallet) =>
        wallet.walletType.slug ===
        convertWalletNameToWalletType(walletName.value),
    ),
  );

  const mainWalletAddress = computed(() => {
    return pairedWallet.value?.address;
  });

  const isWalletPairing = computed(
    () =>
      currentPairingWallet.value ===
      convertWalletNameToWalletType(walletName.value),
  );

  const togglePair = async () => {
    if (!pairedWallet.value) {
      const success = await pair(currentUser.value, walletName.value);
      if (success) {
        emit('paired');
      }
    } else {
      const success = await unpair(pairedWallet.value);
      if (success) {
        emit('unpaired');
      }
    }
  };
  const iconName = computed(() => {
    if (walletName.value === SOLANA_WALLET_NAMES.Solflare) {
      return 'multi-wallet-solflare';
    } else if (walletName.value === SOLANA_WALLET_NAMES.Backpack) {
      return 'multi-wallet-backpack';
    }
    return 'multi-wallet-phantom';
  });
</script>

<template>
  <pair-button
    :is-loading="isWalletPairing || isFetchingPairedWallets"
    :connected-id="mainWalletAddress"
    @toggle="togglePair"
  >
    <base-icon :name="iconName" size="size-10" />
    <template #name> {{ walletName }} (Solana) </template>
    <template #connection>
      {{ $t('userSettings.address') }}:
      {{ shortenString(mainWalletAddress || '') }}
    </template>
  </pair-button>
</template>
