<script setup lang="ts">
  import type { PortalView } from 'dfx/edge/edge.did';
  import { PORTAL_CONTENT } from '@/common/routeNames';
  import { formatToUnits } from '@/shared/lib';
  import { usePortal } from '../../model/composables/use-portal';
  import JoinPortalButton from '../JoinPortalButton.vue';

  defineProps<{
    portal: PortalView;
  }>();

  const { getPortalIcon } = usePortal();
</script>

<template>
  <td>
    <base-button
      variant="link"
      :to="{
        name: PORTAL_CONTENT,
        params: { portal: portal.slug },
      }"
      custom-classes="flex items-center gap-4"
    >
      <img
        alt="Portal icon"
        v-lazy="getPortalIcon(portal)"
        class="rounded-lg w-15 h-15"
      />
      <span
        class="text-lg font-semibold leading-5 text-white truncate"
        :title="portal.name"
        >{{ portal.name }}</span
      >
    </base-button>
  </td>
  <td>
    <div class="flex items-center">
      <p class="text-sm truncate" :title="portal.description">
        {{ portal.description }}
      </p>
    </div>
  </td>
  <td>
    <div class="flex items-center gap-2">
      <base-icon name="outlined-users2" size="w-4 h-4" />
      <span class="text-sm font-medium">
        {{ formatToUnits(portal.member_count) }}
      </span>
    </div>
  </td>
  <td>
    <div class="flex items-center gap-2">
      <base-icon name="vuesax-linear-notification-status" size="w-4 h-4" />
      <span class="text-sm font-medium">
        {{ formatToUnits(portal.content_count) }}
      </span>
    </div>
  </td>
  <td>
    <div class="flex items-center">
      <join-portal-button size="x-small" :portal="portal" />
    </div>
  </td>
</template>
