<script lang="ts" setup>
  import { Drawer } from '@/shared/ui/drawer';
  import { useVModel } from '@vueuse/core';
  import { useMainMenu } from '@/composables';
  import { FeaturedPortals } from '@/widgets/featured-portals';
  import { CreatePortalButton } from '@/widgets/create-portal-button';
  import TrendingPortals from '@/components/portals/TrendingPortals.vue';
  import { BetaBadge } from '@/components/widgets/beta-badge';
  import SearchBar from '@/components/bars/SearchBar.vue';
  import { useUser } from '@/entities/user';
  import { ReferralBlock } from '@/features/referral';

  const props = defineProps<{
    opened: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'update:opened', opened: boolean): void;
  }>();

  const isOpened = useVModel(props, 'opened', emit);

  const { mainMenuLinks } = useMainMenu();

  const { isLoggedIn } = useUser();
</script>

<template>
  <drawer
    v-model:opened="isOpened"
    direction="left"
    custom-classes="bg-gray-blue-900 border-r h-screen w-full border-white border-opacity-12"
  >
    <template #title>
      <span class="font-medium text-lg">Menu</span>
    </template>
    <div class="px-2">
      <search-bar class="mb-4 mt-2" @on-search="emit('update:opened', false)" />
      <template v-for="link in mainMenuLinks" :key="link.name">
        <base-button
          v-if="!link.isHidden"
          variant="link"
          :to="{ name: link.name }"
          custom-classes="flex items-center font-medium px-5 py-[15px] text-sm gap-3.5 rounded-l-xl hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785"
          :class="{
            'bg-gradient-to-r from-indigo-850 via-transparent to-transparent':
              link.isHighlighted,
          }"
          @click="emit('update:opened', false)"
        >
          <base-icon :name="link.icon" size="w-5 h-5" />
          <span class="relative text-sm pl-3">
            <template v-if="link.name === 'home'">
              <beta-badge />
            </template>
            {{ link.label }}
          </span>
        </base-button>
      </template>
    </div>
    <div class="mt-6 pl-8">
      <div class="font-medium text-lg">
        {{ isLoggedIn ? 'Favorites' : 'Featured Portals' }}
      </div>
      <div class="max-h-60 overflow-y-auto thin-scrollbar">
        <featured-portals @close-menu="isOpened = false" />
      </div>
    </div>
    <div class="px-4 mt-6">
      <create-portal-button
        variant="secondary"
        size="medium"
        class="w-full"
        @clicked="isOpened = false"
      >
        <span class="font-medium">{{ $t('createPortal') }}</span>
      </create-portal-button>
    </div>
    <div class="px-4 mt-6">
      <trending-portals in-rows @close-menu="isOpened = false" />
    </div>
    <div class="px-4 mt-2" @click="isOpened = false">
      <referral-block />
    </div>
  </drawer>
</template>
