<script setup lang="ts">
  import { onMounted, computed } from 'vue';
  import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
  import { useEmbeddedMention } from '@/composables';
  import EmbeddedPost from './EmbeddedPost.vue';
  import EmbeddedPortal from './EmbeddedPortal.vue';
  import EmbeddedNft from './EmbeddedNft.vue';

  const props = defineProps(nodeViewProps);
  const { portalsHash, fetchPortals } = useEmbeddedMention();

  const emits = defineEmits<{
    (e: 'loaded', loaded: boolean): void;
  }>();

  const id = computed(() => props.node.attrs.id);
  const type = computed(() => props.node.attrs.type);

  const isPost = computed(() => type.value === 'post');
  const portal = computed(() =>
    type.value === 'portal' ? portalsHash.value.get(id.value) : null,
  );
  const nftId = computed(() => (type.value === 'nft' ? id.value : null));

  onMounted(() => {
    if (type.value === 'portal') {
      fetchPortals([id.value]);
    }
  });
</script>

<template>
  <node-view-wrapper>
    <embedded-post v-if="isPost" :content-id="id" />
    <embedded-portal v-if="portal" :portal="portal" />
    <embedded-nft
      v-if="nftId"
      preview
      :nft-id="nftId"
      @delete-node="deleteNode"
    />
  </node-view-wrapper>
</template>
