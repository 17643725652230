import { client } from '@/utils/dfinity';
import TokenService from './token';
import { apiInstance } from './base';

const BASE_URL = '/dscvr/auth';

export const getOrCreateUser = async (): Promise<string | undefined> => {
  const principal = client.getPrincipal().toText();
  if (!principal) return;
  const response = await apiInstance.post(`${BASE_URL}/user`, {
    pk: principal,
  });
  return response.data.data.nonce;
};

export const verifyUser = async () => {
  if (TokenService.checkValidToken()) return;
  const principal = client.getPrincipal().toText();
  if (!principal) return;
  const nonce = await getOrCreateUser();
  const bufferNonce = Buffer.from(nonce!, 'base64');
  const req_and_headers = await client.signer!.createSignedQueryRequest(
    'verify_identity',
    [bufferNonce],
  );
  const payload = {
    request: req_and_headers.request,
    headers: req_and_headers.headers,
    pk: client.getPrincipal().toText(),
    nonce: nonce,
  };

  const response = await apiInstance.post(`${BASE_URL}/verify`, payload);
  TokenService.setUser(response.data.data);
};
