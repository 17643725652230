<script setup lang="ts">
  import { computed, ref, watch, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useBreakpoint } from '@/shared/model';
  import { useMetaUser, useUser, useUserRole } from '@/entities/user';
  import {
    USER_CONTENT,
    USER_PORTALS,
    USER_WALLETS,
    USER_SETTINGS_PORTALS,
  } from '@/common';
  import { UserAdminPanel } from '@/features/user/components';
  import { usePostDialog } from '@/features/post';
  import { UserProfileHeader } from '@/widgets/user-profile-header';
  import { Empty } from '@/shared/ui/empty';

  const props = defineProps<{
    username: string;
  }>();

  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n({ useScope: 'global' });
  const { loadUserProfileByUsername, userProfile, isCurrentUserProfile } =
    useUser();
  useMetaUser(userProfile);
  const { isAdmin } = useUserRole();
  const { isSmallerThanLg, isSmallerThanMd } = useBreakpoint();
  const { openCreatePostDialog } = usePostDialog();

  const selectedTab = ref('posts');

  const userTabs = computed(() => {
    const result = [
      {
        key: 'posts',
        label: t('posts'),
        route: {
          name: USER_CONTENT,
          params: {
            username: props.username,
          },
        },
        icon: 'notification-status',
      },
      {
        key: 'portals',
        label: t('portals'),
        route: {
          name: USER_PORTALS,
          params: {
            username: props.username,
          },
        },
        icon: 'outline-portal',
      },
    ];
    if (isCurrentUserProfile.value) {
      result.push({
        key: 'wallet',
        label: t('wallet'),
        route: {
          name: USER_WALLETS,
          params: {
            username: props.username,
          },
        },
        icon: 'wallet',
      });
    }

    return result;
  });

  watch(
    () => props.username,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        getUserProfile();
      }
    },
  );

  watch(
    () => userProfile.value?.username,
    (username) => {
      if (
        username &&
        route.query.post &&
        typeof route.query.post === 'string'
      ) {
        openCreatePostDialog(route.query.post);
        router.replace({ query: { ...route.query, post: undefined } });
      }
    },
  );

  const getUserProfile = () => {
    loadUserProfileByUsername(props.username);
  };

  const loadSelectedTabByRoute = () => {
    if (route.name === USER_CONTENT) {
      selectedTab.value = 'posts';
    } else if (route.name === USER_PORTALS) {
      selectedTab.value = 'portals';
    } else if (route.name === USER_WALLETS) {
      selectedTab.value = 'wallet';
    }
  };

  onMounted(() => {
    loadSelectedTabByRoute();
    getUserProfile();
  });
</script>

<template>
  <section v-if="userProfile !== undefined" class="w-full">
    <user-admin-panel v-if="userProfile && isAdmin" :user="userProfile" />

    <user-profile-header>
      <template #default="{ light }">
        <div
          class="flex justify-between items-start mb-1 pb-2 px-1 md:px-16 lg:px-0 bg-gray-975 pt-5 sm:pt-2"
        >
          <div
            class="flex gap-6 text-white justify-start flex-1 px-5 md:px-0"
            :class="{
              'pb-6': !light,
              'pb-0': light,
            }"
          >
            <base-button
              v-for="tab in userTabs"
              :key="tab.key"
              variant="custom"
              custom-classes="flex gap-2 items-center pb-3 px-4 md:px-0"
              :class="{
                'border-b-2': selectedTab === tab.key,
                'text-gray-400': selectedTab !== tab.key,
              }"
              :to="tab.route"
              @click.stop="selectedTab = tab.key"
            >
              <base-icon :name="tab.icon" />
              <span v-if="!isSmallerThanMd" class="text-base font-medium">
                {{ tab.label }}
              </span>
            </base-button>
          </div>
          <template v-if="selectedTab !== 'wallet'">
            <base-button
              v-if="isCurrentUserProfile"
              variant="secondary"
              :custom-classes="
                isSmallerThanLg ? undefined : 'flex gap-2 items-center'
              "
              size="x-small"
              class="hidden sm:flex text-gray-400 !p-2"
              :to="{ name: USER_SETTINGS_PORTALS }"
            >
              <base-icon name="preference" size="w-4 h-4" />
              <span class="hidden lg:block">{{ $t('managePortals') }}</span>
            </base-button>
          </template>
        </div>
      </template>
    </user-profile-header>
    <router-view />
  </section>
  <empty v-else>
    <template #title>
      {{ $t('userDoNotExist') }}
    </template>
  </empty>
</template>
