import { dscvrIcApi } from '@/shared/api';
import { useMutation } from '@tanstack/vue-query';

export const useAddPortalRoleToUserMutation = () => {
  return useMutation({
    mutationKey: ['add-portal-role-to-user-mutation'],
    mutationFn: (params: dscvrIcApi.user.AddMemberRoleMutationParams) =>
      dscvrIcApi.user.addMemberRole(params),
  });
};
