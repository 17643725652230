<script setup lang="ts">
  import { computed, ref, toRefs } from 'vue';
  import { PORTAL_CONTENT, PORTAL_SETTINGS_PAGE_OVERVIEW } from '@/common';
  import {
    usePortalDialog,
    usePortal,
    JoinPortalButton,
    useSinglePortal,
  } from '@/entities/portal';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { Badge } from '@/shared/ui/badge';
  import { useInvite } from '@/features/invite';

  const props = defineProps<{
    portal: PortalView;
    draggable?: boolean;
    animateUnfavorite?: boolean;
  }>();

  const animateDisappear = ref(false);
  const { portal } = toRefs(props);
  const { getPortalIcon, toggleFavoritePortal } = usePortal();
  const {
    canInvite,
    isCurrentUserOwner,
    isCurrentUserManager,
    isCurrentUserOwnerOrManager,
    isJoined,
    isFavorite,
  } = useSinglePortal(portal);
  const { openLeaveDialog } = usePortalDialog();
  const { openInvitePortalDialog } = useInvite();

  const favoriteClasses = computed(() => {
    return isFavorite.value ? '' : 'sm:opacity-0 sm:group-hover:opacity-100';
  });

  const toggleFavorite = () => {
    if (!props.animateUnfavorite) {
      toggleFavoritePortal(props.portal);
    } else {
      animateDisappear.value = true;
      setTimeout(() => {
        toggleFavoritePortal(props.portal);
      }, 500);
    }
  };
</script>

<template>
  <div
    class="relative mt-0 overflow-visible transition-all duration-500 ease-in-out"
    :class="{
      '!-mt-26': animateDisappear,
    }"
  >
    <div
      class="flex items-center w-full gap-2 px-4 py-5 border border-opacity-50 rounded-lg cursor-pointer bg-gray-975 border-gray-785 hover:border-gray-500 shadow-light group"
      :class="{
        'animate-fade-down animate-duration-500 animate-reverse':
          animateDisappear,
      }"
    >
      <base-icon
        v-if="draggable"
        name="drag"
        size="w-5 h-5"
        class="text-gray-500 drag-handle"
      />

      <base-button
        variant="custom"
        custom-classes="flex-1 flex items-center gap-2 min-w-0"
        :to="{
          name: PORTAL_CONTENT,
          params: { portal: portal.slug },
        }"
      >
        <img
          alt="Portal icon"
          v-lazy="getPortalIcon(portal)"
          class="mr-2 rounded-lg size-12 min-w-12 min-h-12"
        />
        <div class="flex-1 min-w-0">
          <p class="flex items-center gap-2 font-semibold truncate">
            <span>{{ portal.name }}</span>
            <badge
              v-if="isCurrentUserOwner"
              background="bg-gradient-to-r from-[#F43B47] to-[#453A94]"
              border="border border-white border-opacity-10"
              font="text-xs font-bold"
            >
              {{ $t('favoritePortals.owned') }}
            </badge>
            <badge
              v-else-if="isCurrentUserManager"
              background="bg-gradient-to-r from-[#B01EFF] to-[#E1467C]"
              border="border border-white border-opacity-10"
              font="text-xs font-bold"
            >
              {{ $t('managed') }}
            </badge>
          </p>
          <p class="text-sm font-medium text-gray-400 truncate">
            {{ portal.description }}
          </p>
        </div>
      </base-button>

      <template v-if="isJoined">
        <base-button
          variant="tertiary"
          size="x-small"
          :additional-classes="`!transition-all !duration-500 ${favoriteClasses}`"
          @click="toggleFavorite"
        >
          <base-icon
            :name="isFavorite ? 'star' : 'outline-star'"
            size="w-5 h-5"
          />
        </base-button>
        <base-button
          v-if="isCurrentUserOwnerOrManager"
          variant="light"
          size="x-small"
          :to="{
            name: PORTAL_SETTINGS_PAGE_OVERVIEW,
            params: { portal: portal.slug },
          }"
        >
          <base-icon name="settings" size="w-5 h-5" />
        </base-button>
        <base-dropdown
          v-else
          append-to-body
          custom-content-classes="bg-gray-900 border border-gray-785 border-opacity-50 transition-all duration-300 ease-in-out drop-shadow-heavy"
        >
          <template #button="{ toggle }">
            <base-button variant="secondary" size="x-small" @click="toggle">
              <base-icon name="horizontal-dots" size="w-5 h-5" />
            </base-button>
          </template>
          <template #content="{ hide }">
            <base-button
              v-if="canInvite"
              variant="custom"
              size="small"
              custom-classes="w-full px-4 py-3 text-left text-white hover:bg-gray-800"
              @click="hide(), openInvitePortalDialog(portal)"
            >
              {{ $t('inviteToPortal') }}
            </base-button>
            <base-button
              variant="custom"
              size="small"
              custom-classes="w-full px-4 py-3 text-left text-red-400 hover:bg-gray-800"
              @click="hide(), openLeaveDialog(portal)"
            >
              {{ $t('leave') }}
            </base-button>
          </template>
        </base-dropdown>
      </template>

      <join-portal-button v-else :portal="portal" />
    </div>
  </div>
</template>
