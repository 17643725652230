import { apiInstance } from '../base';
import type { CursorDataResponse, DataResponse } from './interfaces';

const BASE_URL = '/tensor/collection';

export interface GetListingParams {
  collectionId: string;
  limit: number;
  cursor?: string;
  sort?: string;
  mint?: string;
}

export interface GetStatsParams {
  collectionSlug: string;
  sortBy?: string;
}

export const getListings = (params: GetListingParams) => {
  return apiInstance
    .get(`${BASE_URL}/listings/${params.collectionId}`, {
      params,
    })
    .then((response) => response.data) as Promise<
    CursorDataResponse<TensorNft[]>
  >;
};

export const getStats = (params: GetStatsParams) => {
  return apiInstance
    .get(`${BASE_URL}/stats/${params.collectionSlug}`, {
      params,
    })
    .then((response) => response.data) as Promise<
    DataResponse<TensorCollection>
  >;
};

interface TensorLastSale {
  price: number;
  buyer: string;
  seller: string;
}

export interface TensorListing {
  price: string;
  txId: string;
  seller: string;
  source: string;
  blockNumber: string;
  priceUnit: string | null;
}

export interface TensorStats {
  buyNowPrice: string;
  buyNowPriceNetFees: string;
  floor7d: number;
  floor24h: number;
  marketCap: string;
  numBids: number;
  numListed: number;
  numListed7d: number;
  numListed24h: number;
  numMints: number;
  pctListed: number;
  sales1h: number;
  sales7d: number;
  sales24h: number;
  salesAll: number;
  volume1h: string;
  volume7d: string;
  volume24h: string;
  volumeAll: string;
}

export interface TensorCollection {
  name: string;
  collId: string;
  slugDisplay: string;
  slugMe: string;
  symbol: string;
  description: string;
  teamId: string;
  imageUri: string;
  tensorVerified: boolean;
  tensorWhitelisted: boolean;
  whitelistPda: string;
  tokenStandard: string;
  compressed: boolean;
  inscription: boolean;
  inscriptionMetaplex: boolean;
  spl20: boolean;
  sellRoyaltyFeeBPS: number;
  stats: TensorStats;
  createdAt: string;
  updatedAt: string;
  firstListDate: string;
}

interface TensorNftAttribute {
  value: string;
  trait_type: string;
}

// shown in response.mints
export interface TensorNft {
  mint: string;
  slug: string;
  frozen: boolean;
  attributes: Array<TensorNftAttribute>;
  imageUri: string;
  lastSale: TensorLastSale;
  metadataFetchedAt: number;
  metadataUri: string;
  animationUri: string;
  name: string;
  rarityRank: number;
  royaltyBps: number;
  tokenEdition: string;
  tokenStandard: string;
  hidden: boolean;
  compressed: boolean;
  verifiedCollection: string;
  updateAuthority: string;
  listing?: TensorListing;
  inscription: string;
  tokenProgram: string;
  metadataProgram: string;
  transferHookProgram: string;
  owner: string;
}
