<script setup lang="ts">
  import { computed, ref, toRefs, onUpdated, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { ActionTypes } from '@/store/action-types';
  import VRuntimeTemplate from 'vue3-runtime-template';
  import { hasAnchorAncestorOrSelf } from '@/utils';
  import { useEmbeddedMention } from '@/composables';
  import { useFormatDynamicContent } from './composables/use-format-dynamic-content';
  import type { PortalInfoView } from 'dfx/edge/edge.did';
  import { UserTooltip } from '@/entities/user';
  import BaseIcon from '@/shared/ui/base-icon/BaseIcon.vue';
  import {
    EmbeddedPortal,
    EmbeddedPost,
    EmbeddedNft,
    EmbeddedStreak,
    EmbeddedLiteYoutube,
  } from '@/components/tiptap';
  import {
    DynamicImage,
    DynamicVideo,
    DynamicIframe,
  } from '@/entities/content';
  import 'highlight.js/styles/github.css';
  import '@/shared/ui/styles/markdown.css';
  import '@/shared/ui/styles/tailwindExtended.css';
  import '@/shared/ui/styles/highlights.css';
  import { EmbeddedFrame } from '@/features/frame';
  import { EmbeddedCanvas } from '@/features/canvas';

  const props = defineProps<{
    body: string;
    portal: PortalInfoView;
  }>();

  const emits = defineEmits<{
    (e: 'read-more'): void;
  }>();

  const store = useStore();
  const container = ref(null);
  const { body, portal } = toRefs(props);
  const { usersHash, portalsHash } = useEmbeddedMention();
  const { computedHtml, highlightCode } = useFormatDynamicContent(body);

  const click = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (hasAnchorAncestorOrSelf(target)) {
      e.stopPropagation();
    }
  };

  const portalDataChanged = (portalData: PortalInfoView) => {
    store.dispatch(`portals/${ActionTypes.SET_MENTION}`, portalData);
  };

  const templateProps = computed(() => ({
    usersHash: usersHash.value,
    portalsHash: portalsHash.value,
    portal: portal.value,
    portalDataChanged,
    emits,
  }));

  const templateOptions = {
    $options: {
      components: {
        EmbeddedPortal,
        EmbeddedPost,
        EmbeddedNft,
        EmbeddedStreak,
        EmbeddedFrame,
        EmbeddedCanvas,
        EmbeddedLiteYoutube,
        UserTooltip,
        BaseIcon,
        DynamicImage,
        DynamicVideo,
        DynamicIframe,
      },
    },
  };

  function highlightPreCodeTags() {
    if (container.value) {
      highlightCode(container.value);
    }
  }

  onMounted(highlightPreCodeTags);
  onUpdated(highlightPreCodeTags);
</script>

<template>
  <div ref="container" class="text-white dynamic-content prose" @click="click">
    <v-runtime-template
      :template="computedHtml"
      :parent="templateOptions"
      :template-props="templateProps"
    />
  </div>
</template>

<style lang="postcss" scoped>
  .dynamic-content {
    overflow-wrap: anywhere;
  }

  .dynamic-content.prose:deep(*) {
    :where(.prose h1):not(:where([class~='not-prose'] *)) {
      color: white;
    }

    :where(.prose strong):not(:where([class~='not-prose'] *)) {
      color: white;
    }

    :where(.prose a):not(:where([class~='not-prose'] *)) strong {
      @apply text-indigo-600;
    }
    :where(.prose .post-header a) {
      @apply text-white;
    }
  }
</style>
