import { defineAsyncComponent } from 'vue';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
import { useDialog } from '@/shared/model';
import { fetchMedia } from '@/shared/lib';

export function useCreateRoomDialog() {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openCreateRoomDialog = () => {
    openConfiguredDialog({
      content: {
        component: defineAsyncComponent(
          () =>
            import('@/features/live/create-room/components/DialogContent.vue'),
        ),
        emits: {
          close: closeDialog,
        },
      },
      header: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/base-dialog/ImageHeader.vue'),
        ),
        slots: {
          media: {
            template: `<div class="min-h-28 -mx-6 bg-cover bg-center" style="background-image: url('${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/live/live-banner.png`,
            )}')" />`,
          },
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-screen-md overflow-hidden`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  return {
    openCreateRoomDialog,
    closeDialog,
  };
}
