<script setup lang="ts">
  import { computed, onBeforeMount, toRefs } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import InviteDialogContents from './InviteDialogContents.vue';
  import { useUser } from '@/entities/user';
  import { usePortal } from '@/entities/portal';
  import { formatToUnits } from '@/shared/lib';

  const props = defineProps<{
    portal: PortalView;
  }>();
  const { portal } = toRefs(props);
  const { getPortalIcon, getPortalChainType, portalChainType } = usePortal();
  const { getReferralCode, referralCode } = useUser();

  const inviteLink = computed(() => {
    const portalChain = portalChainType?.value?.length ? 'sol.' : '';
    return `https://${portalChain}dscvr.one/join/${props.portal.slug}?ur=${referralCode.value}`;
  });

  onBeforeMount(async () => {
    await getReferralCode();
    getPortalChainType(props.portal);
  });
</script>
<template>
  <div class="p-8 pt-4 md:p-0">
    <invite-dialog-contents
      :referent="portal"
      :invite-link="inviteLink"
      :name="portal.name"
    >
      <template #avatar>
        <img
          alt="Portal Icon"
          class="w-16 h-16 md:w-[148px] md:h-[148px] rounded-xl"
          v-lazy="getPortalIcon(portal)"
        />
      </template>
      <template #name>
        {{ portal.name }}
      </template>
      <template #description>
        {{ portal.description }}
      </template>
      <template #membersOrFollowers>
        {{ formatToUnits(portal?.member_count ?? 0n) }}
      </template>
      <template #posts>
        {{ formatToUnits(portal?.content_count ?? 0n) }}
      </template>
    </invite-dialog-contents>
  </div>
</template>
