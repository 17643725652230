<script setup lang="ts">
  import { Tippy } from 'vue-tippy';
  import { useVModel } from '@vueuse/core';
  import type { LeftSidebarContentType } from '@/store';

  const props = defineProps<{
    modelValue: LeftSidebarContentType;
  }>();

  const emits = defineEmits<{
    (e: 'update:modelValue', value: LeftSidebarContentType): void;
  }>();

  const contentType = useVModel(props, 'modelValue', emits);
</script>

<template>
  <tippy
    interactive
    trigger="click"
    theme="transparent"
    :arrow="false"
    placement="bottom-start"
  >
    <template #default="{ state }">
      <button class="flex items-center gap-1 group">
        <h3 class="text-lg font-semibold">
          <span v-if="contentType === 'HotSpots'">{{ $t('hotSpots') }}</span>
          <span v-else>{{ $t('favorites') }}</span>
        </h3>
        <base-icon
          name="chevron-down-filled"
          size="w-6 h-6"
          class="group-hover:text-white"
          :class="state.isVisible ? 'text-white' : 'text-gray-500'"
        />
      </button>
    </template>
    <template #content="{ hide }">
      <ul
        class="bg-gray-950 border border-gray-40 border-opacity-70 rounded-xl overflow-hidden min-w-48 text-gray-300 font-medium"
      >
        <li
          class="flex gap-3 items-center px-5 py-3 cursor-pointer group hover:bg-gradient-to-r hover:from-indigo-600 hover:via-transparent hover:to-transparent transition-all"
          @click="(contentType = 'HotSpots'), hide()"
        >
          <base-icon
            name="outline-fire"
            size="w-5 h-5"
            class="text-indigo-300 group-hover:text-white"
          />
          <span class="group-hover:text-white pb-1">{{ $t('hotSpots') }}</span>
        </li>
        <li
          class="flex gap-3 items-center px-5 py-3 cursor-pointer group hover:bg-gradient-to-r hover:from-indigo-600 hover:via-transparent hover:to-transparent transition-all"
          @click="(contentType = 'Favorites'), hide()"
        >
          <base-icon
            name="outline-star"
            size="w-5 h-5"
            class="text-indigo-300 group-hover:text-white"
          />
          <span class="group-hover:text-white pb-1">{{ $t('favorites') }}</span>
        </li>
      </ul>
    </template>
  </tippy>
</template>
