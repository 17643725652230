<script lang="ts" setup>
  import { ProgressBar } from '@/shared/ui/progress-bar';
  import { computed, ref, onBeforeMount } from 'vue';
  import PointsSummary from '../../ui/PointsSummary.vue';
  import ItemRow from '../../ui/ItemRow.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { fetchMedia, pluralize, roundify } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import type {
    ReferralRewardView,
    ReferralCapacitorView,
    UserView,
  } from 'dfx/edge/edge.did';
  import { useUser } from '@/entities/user';
  import { USER_CONTENT } from '@/common';
  import { useDialog } from '@/shared/model';
  import { useRouter } from 'vue-router';
  import ReferralButton from '../referral-button/ReferralButton.vue';

  const props = defineProps<{
    currentPage: number;
    loading: boolean;
    totalPages: bigint;
    referrals: ReferralRewardView[];
    capacitor: ReferralCapacitorView;
  }>();

  const router = useRouter();
  const { getFormattedTokens, getReferralLink, currentUser } = useUser();
  const { closeDialog } = useDialog();

  const referralLink = ref('');

  const tokenEarned = computed(() => {
    return Number(getFormattedTokens(props.capacitor.tokens_earned, true));
  });

  const nextTokenTier = computed(() => {
    return Number(getFormattedTokens(props.capacitor.next_token_tier, true));
  });

  const progress = computed(() => {
    return (tokenEarned.value / nextTokenTier.value) * 100;
  });

  const hasNoReferrals = computed(() => props.referrals.length === 0);
  const isProgressLessEqualToZero = computed(() => progress.value === 0);
  const innerBackgroundColor = computed(() =>
    isProgressLessEqualToZero.value
      ? 'bg-gradient-to-r from-red-500 to-[#B8429E]'
      : 'bg-gradient-to-r from-[#33E5FF] to-[#1A8ED8]',
  );

  const onClickUserRow = (user: UserView) => {
    closeDialog();
    router.push({ name: USER_CONTENT, params: { username: user.username } });
  };

  onBeforeMount(() => {
    getReferralLink(currentUser.value, true).then((link) => {
      referralLink.value = link;
    });
  });
</script>

<template>
  <div class="flex flex-col gap-8 mt-4">
    <div class="flex relative mx-auto w-full h-34 max-w-[493px] my-8">
      <div
        class="absolute bottom-4 left-20 z-0 overflow-hidden pr-4 flex justify-between items-center gap-2 rounded-[16.5px] w-[calc(100%-5rem)] h-[62px] pl-16 before:content-[''] before:absolute before:-z-1 before:top-1 before:rounded-[15px] before:left-1 before:w-[calc(100%-8px)] before:h-[54px] before:bg-[#323C67] after:content-[''] after:absolute after:-bottom-40 after:left-0 after:w-[500px] after:h-[500px] after:rounded-full after:bg-gradient-to-t after:to-[#00ECBC] after:from-[#007ADF] after:-z-2 after:animate-spin-slow"
      >
        <div class="w-full">
          <progress-bar
            :progress="progress"
            :inner-background-color="innerBackgroundColor"
            custom-inner-classes="min-w-5"
          />
        </div>
        <div
          class="text-center w-12 font-medium tracking-[0.16px]"
          :class="isProgressLessEqualToZero ? 'text-pink-400' : 'text-white'"
        >
          {{
            isProgressLessEqualToZero
              ? $t('referralFeature.community.referrals.empty')
              : roundify(nextTokenTier, 0, 'down')
          }}
        </div>
      </div>
      <div
        class="absolute top-5 left-20 flex items-center bg-no-repeat pl-16 text-base tracking-[0.18px] font-semibold h-[43px] w-[231px]"
        :style="{
          backgroundImage: `url('${fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/referral/capacitor-background.svg`,
          )}')`,
        }"
      >
        {{ $t('referralFeature.community.referrals.capacitor') }}
      </div>
      <div
        class="absolute top-0 left-0 z-0 size-34 rounded-full overflow-hidden flex justify-center items-center after:content-[''] after:absolute after:-top-[1px] after:-left-[1px] after:w-[138px] after:h-[138px] after:rounded-full after:bg-gradient-to-t after:to-[#00ECBC] after:from-[#007ADF] after:-z-[2] after:animate-spin-slow before:content-[''] before:absolute before:-z-1 before:top-0 before:left-0 before:w-34 before:h-34 before:rounded-full before:bg-[image:var(--bg)] before:bg-no-repeat"
        :style="`--bg: url('${decodeURIComponent(
          fetchMedia(
            `${DSCVR_STATIC_ASSETS_CDN_URL}/referral/black-hole-background.svg`,
          ),
        )}')`"
      >
        <img
          :src="
            hasNoReferrals
              ? fetchMedia(
                  `${DSCVR_STATIC_ASSETS_CDN_URL}/referral/black-hole.gif`,
                )
              : fetchMedia(
                  `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/streak-onboarding-meteor.gif`,
                )
          "
          alt="Black Hole"
          :class="hasNoReferrals ? 'w-full h-auto' : 'w-30 h-auto'"
        />
      </div>
      <template v-if="!hasNoReferrals && capacitor">
        <div class="absolute right-0 flex gap-4 -bottom-6">
          <points-summary>
            <template #icon>
              <base-icon name="filled-airdrop3" size="w-6 h-6" />
            </template>
            <template #points>
              {{ capacitor.multiplier_count }}
            </template>
            <template #label>
              {{
                pluralize(
                  1,
                  $t('referralFeature.multiplier'),
                  $t('referralFeature.multipliers'),
                )
              }}
            </template>
          </points-summary>
          <points-summary>
            <template #icon>
              <base-icon name="dscvr" size="w-6 h-6" />
            </template>
            <template #points>
              {{ tokenEarned }}
            </template>
            <template #label>
              {{ $t('earned') }}
            </template>
          </points-summary>
        </div>
      </template>
    </div>
    <div class="flex flex-col items-center gap-6">
      <div
        v-if="hasNoReferrals"
        class="flex flex-col gap-4 items-center max-w-[396px]"
      >
        <div class="font-bold -tracking-[0.12px] text-2xl">
          {{ $t('referralFeature.community.referrals.title') }}
        </div>
        <div class="tracking-[0.14px] text-sm text-gray-400 text-center">
          {{ $t('referralFeature.community.referrals.description') }}
        </div>
      </div>
      <div
        v-else
        class="flex flex-col gap-2 w-full max-h-[314px] overflow-y-auto scrollbar-none"
      >
        <template v-for="item in referrals" :key="item.referral.id.toString()">
          <base-button
            variant="link"
            size="full"
            @click="onClickUserRow(item.referral)"
          >
            <item-row>
              <template #avatar>
                <user-avatar :item="item.referral" size="w-10 h-10" />
              </template>
              <template #username>
                {{ item.referral.username }}
              </template>
              <template #points>
                {{ getFormattedTokens(item.points_earned_from) }}
              </template>
            </item-row>
          </base-button>
        </template>
      </div>
      <referral-button variant="primary" size="medium" :link="referralLink">
        <template #icon>
          <base-icon name="invite" size="w-5 h-5" />
        </template>
        {{ $t('referralFeature.community.referrals.copyReferralLink') }}
      </referral-button>
    </div>
  </div>
</template>
