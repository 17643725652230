import { computed } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import type { ArcadeProps } from '@/components/arcade/types';
import type { PortalView, UserView } from 'dfx/edge/edge.did';

export function useArcade() {
  const store = useStore();

  const games = computed<ArcadeProps[]>(() => store.getters['arcade/games']);
  const users = computed<UserView[]>(() => store.getters['arcade/users']);
  const portals = computed<PortalView[]>(() => store.getters['arcade/portals']);

  const fetchArcade = () => {
    store.dispatch(`arcade/${ActionTypes.GET_ARCADE_GAMES}`);
    store.dispatch(`arcade/${ActionTypes.GET_ARCADE_USERS}`);
    store.dispatch(`arcade/${ActionTypes.GET_ARCADE_PORTALS}`);
  };

  return {
    games,
    users,
    portals,
    fetchArcade,
  };
}
