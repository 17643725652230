<script setup lang="ts">
  import { onMounted } from 'vue';
  import type {
    MagicEdenAuctionData,
    MagicEdenTokenMetadata,
  } from '@/shared/api';
  import { useWalletPairing } from '@/entities/wallets';
  import { useUser } from '@/entities/user';
  import { usePairDialog } from '@/features/wallets';
  import { useMagicEdenNft } from '../model/composables/use-magic-eden-nft';
  import { useAuth } from '@/entities/auth';

  const props = defineProps<{
    nft: MagicEdenTokenMetadata;
    listing?: MagicEdenAuctionData;
  }>();

  const { firstPairedSolanaWallet, fetchPairedWallets } = useWalletPairing();
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { openAddWalletDialog } = usePairDialog();
  const { openBuyNftDialog } = useMagicEdenNft();

  const buyButtonClasses =
    'w-full whitespace-nowrap min-h-8 flex gap-x-2 items-center bg-gray-820 !rounded-full font-medium text-gray-300 hover:bg-gray-785 hover:text-white ease-transition-all';

  const openBuyDialogModal = async () => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    } else if (!props.listing) {
      window.open(
        `https://magiceden.io/item-details/${props.nft.mintAddress}`,
        '_blank',
      );
    } else if (!firstPairedSolanaWallet.value) {
      const added = await openAddWalletDialog();
      if (added) {
        openBuyNftDialog({ listing: props.listing! });
      }
    } else {
      openBuyNftDialog({ listing: props.listing! });
    }
  };

  onMounted(() => {
    if (isLoggedIn.value) fetchPairedWallets();
  });
</script>

<template>
  <base-button
    variant="custom"
    :custom-classes="buyButtonClasses"
    :class="[
      nft?.price
        ? 'bg-gray-820 px-4 py-2.5'
        : 'px-4 py-2.5 bg-pink-700 text-white hover:bg-pink-700',
    ]"
    :disabled="!nft?.price"
    @click="openBuyDialogModal"
  >
    <base-icon v-if="nft.price" name="shopping-cart" size="w-5 h-5" />
    {{
      nft?.price
        ? listing
          ? $t('nftMarketplace.buy')
          : $t('nftMarketplace.magicEden.buyOnMagicEden')
        : $t('nftMarketplace.sold')
    }}
  </base-button>
</template>
