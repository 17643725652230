<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import { useFile } from '@/composables';
  import { useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import storage from '@/services/storage';
  import { POST_IMAGE_ACCEPTED_MIME_TYPES } from '@/common';
  import { useFileUploadDialog } from '@/entities/file-upload';
  import { useProfileUpdate } from '@/features/user-settings';

  const coverPhoto = ref<string>();

  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { validateFile, fileToBase64 } = useFile();
  const { openFileUploadDialog, closeDialog } = useFileUploadDialog();
  const { currentUser, setIsLoading } = useUser();
  const { updateCoverPhoto } = useProfileUpdate();

  const uploadCover = async (files: FileList) => {
    setIsLoading(true);
    if (files.length) {
      const file = files[0];
      if (!validateFile(file, POST_IMAGE_ACCEPTED_MIME_TYPES)) {
        showToast({
          type: 'error',
          title: t('userSettings.invalidImageFileMessage'),
          durationSeconds: 5,
        });
        setIsLoading(false);
        return;
      }
      closeDialog();
      const base64 = await fileToBase64(file);
      const src = await storage.uploadBase64(base64);
      if (src.length > 0) {
        updateCoverPhoto(src);
        coverPhoto.value = src;
      } else {
        showToast({
          type: 'error',
          title: t('userSettings.errorUploadingFile'),
          durationSeconds: 5,
        });
      }
      setIsLoading(false);
    }
  };

  const handleCoverUpload = () => {
    openFileUploadDialog(uploadCover, {
      accept: POST_IMAGE_ACCEPTED_MIME_TYPES.join(','),
      multiple: false,
      dragMessage: t('dragImage'),
      title: t('uploadCoverPhoto'),
    });
  };

  watch(
    currentUser.value,
    () => {
      coverPhoto.value = currentUser.value?.cover_photo
        ? currentUser.value?.cover_photo[0]
        : undefined;
    },
    {
      immediate: true,
    },
  );
</script>

<template>
  <section
    class="min-h-40 md:min-h-45 bg-gray-950 border border-gray-785 border-opacity-50 rounded-2xl overflow-hidden relative"
  >
    <img
      v-if="coverPhoto"
      class="absolute inset-0 size-full object-cover after:absolute after:inset-0 after:z-1 after:bg-gradient-to-b after:from-black/0 after:to-black/70 after:z-2"
      v-lazy="coverPhoto"
    />

    <div class="absolute right-6 top-6 z-2">
      <base-tooltip
        append-to-body
        placement="left"
        :offset="[0, 18]"
        :content="$t('userSettings.coverPhotoRequirements')"
      >
        <template #default="{ hide }">
          <base-button
            variant="glass"
            size="small"
            additional-classes="gap-1.5 bg-gray-40 bg-opacity-40"
            @click="hide(), handleCoverUpload()"
          >
            <base-icon name="upload" size="size-5" />
            {{ $t('upload') }}
          </base-button>
        </template>
      </base-tooltip>
    </div>
  </section>
</template>
