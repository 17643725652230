import type { MultichainCollectionMarketPlacePage } from '@/shared/api';
import type { MarketplaceId } from '../types';

export const findMarketplacePage = (
  pages: MultichainCollectionMarketPlacePage[],
  filterId: MarketplaceId | MarketplaceId[],
) => {
  const ids: string[] = Array.isArray(filterId) ? filterId : [filterId];
  return pages.find((page) => ids.includes(page.marketplace_id));
};

// Magic eden
export const createMagicEdenUrl = (destination: string, tokenId: string) => {
  const baseUrl = 'https://magiceden.io';
  return `${baseUrl}/${destination}/${tokenId}`;
};

export const createMagicEdenNftUrl = (mintAddress: string) => {
  return createMagicEdenUrl('item-details', mintAddress);
};

// Tensor
export const createTensorUrl = (destination: string, tokenId: string) => {
  const baseUrl = 'https://tensor.trade';
  return `${baseUrl}/${destination}/${tokenId}`;
};

export const createTensorNftUrl = (mintAddress: string) => {
  return createTensorUrl('item', mintAddress);
};
