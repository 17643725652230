<script setup lang="ts">
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { type WalletName } from '@solana/wallet-adapter-base';
  import { useDevice, useToast } from '@/shared/model';
  import { Loader } from '@/shared/ui/loader';
  import { useConnectSolanaWallets } from '../../model/composables/use-connect-solana-wallets';
  import WalletIcon from '../WalletIcon.vue';

  const emit = defineEmits<{
    (event: 'close'): void;
    (event: 'connected', payload: WalletName): void;
  }>();

  const connectingWalletName = ref<WalletName>();

  const { isMobileOrTablet } = useDevice();
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const {
    installedWallets,
    // TODO: after implementing StandardAdapter (e.g Backpack), this will have a broader definition
    nonInstalledWallets: popularWallets,
    selectAndConnect,
  } = useConnectSolanaWallets();

  const selectWallet = async (name: WalletName) => {
    try {
      connectingWalletName.value = name;
      const connected = await selectAndConnect(name as WalletName);
      if (connected) {
        emit('connected', name);
      } else {
        emit('close');
      }
    } catch (error) {
      console.log('Error:', error);

      showToast({
        title: t('error'),
        description: `${t('wallets.connectWallet.errorConnectingWallet')}`,
        type: 'error',
        durationSeconds: 3,
      });
    } finally {
      connectingWalletName.value = undefined;
    }
  };
</script>

<template>
  <div
    v-if="connectingWalletName"
    class="flex flex-col items-center gap-6 p-6 pt-18"
  >
    <wallet-icon :id="connectingWalletName" size="h-32" />
    <span class="font-bold text-2xl">
      {{ $t('wallets.connectWallet.connecting') }} {{ connectingWalletName }}...
    </span>
    <div class="relative w-full h-10 my-6">
      <loader variant="rainbow" border-width="border" size="size-10" />
    </div>
  </div>
  <div v-else class="flex flex-col gap-4 p-6 pt-0 font-semibold">
    <h2 class="font-semibold text-lg text-center md:p-6">
      {{ $t('wallets.connectWallet.connectAWallet') }}
    </h2>
    <div
      v-if="isMobileOrTablet && !installedWallets.length"
      class="text-red-325 px-6"
    >
      {{ $t('wallets.connectWallet.mobileConnectError') }}
    </div>
    <template v-else>
      <div v-if="installedWallets.length">
        <h3 class="text-sm text-indigo-300 px-6 py-2">
          {{ $t('wallets.connectWallet.installed') }}
        </h3>
        <ul>
          <li v-for="item in installedWallets" :key="item.id">
            <base-button
              variant="tertiary"
              size="full"
              additional-classes="gap-2 w-full !justify-start !p-5"
              @click="selectWallet(item.id)"
            >
              <wallet-icon :id="item.id" />
              <span>{{ item.id }}</span>
            </base-button>
          </li>
        </ul>
      </div>
      <div v-if="popularWallets.length" class="mb-6">
        <h3 class="text-sm text-gray-400 px-6 py-2">
          {{ $t('wallets.connectWallet.popular') }}
        </h3>
        <ul>
          <li v-for="item in popularWallets" :key="item.id">
            <base-button
              variant="tertiary"
              size="full"
              additional-classes="gap-2 w-full !justify-start !p-5"
              @click="selectWallet(item.id)"
            >
              <wallet-icon :id="item.id" />
              <span>{{ item.id }}</span>
            </base-button>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
