<script setup lang="ts">
  import { computed, ref, watch, onBeforeUnmount } from 'vue';
  import { getSizedItemKey } from '../lib/size';
  import { useSizeStore } from '../model/store/size/store';
  import type { SizeItemType } from '../model/store/size/types';

  const props = withDefaults(
    defineProps<{
      id: string;
      type?: SizeItemType;
    }>(),
    {
      type: 'image',
    },
  );

  const itemRef = ref<HTMLDivElement>();
  const isReady = ref(false);
  const sizeStore = useSizeStore();

  const size = computed(() => {
    const key = getSizedItemKey(props.type, props.id);
    return sizeStore.sizeHash.get(key);
  });

  const sizeActive = computed(() => {
    return !isReady.value && size.value;
  });

  const storeSize = () => {
    if (itemRef.value) {
      const isNewSize =
        !size.value ||
        itemRef.value.clientWidth !== size.value.width ||
        itemRef.value.clientHeight !== size.value.height;
      if (isNewSize) {
        sizeStore.setSizeItem({
          id: props.id,
          type: props.type,
          width: itemRef.value.clientWidth,
          height: itemRef.value.clientHeight,
        });
      }
    }
  };

  const contentReady = () => {
    isReady.value = true;
    storeSize();
  };

  watch(
    () => props.id,
    () => {
      isReady.value = false;
    },
  );

  onBeforeUnmount(storeSize);
</script>

<template>
  <div
    ref="itemRef"
    class="flex"
    :style="sizeActive ? {
      minWidth: `${size!.width}px`,
      minHeight: `${size!.height}px`,
    }: undefined"
  >
    <slot :content-ready="contentReady" />
  </div>
</template>
