<script setup lang="ts">
  import { computed } from 'vue';
  import type { UserView } from 'dfx/edge/edge.did';
  import * as routeNames from '@/common/routeNames';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { formatToUnits } from '@/shared/lib';
  import { useUser, StreakCount } from '@/entities/user';

  const props = defineProps<{
    user: UserView;
  }>();

  const { getCreationDate } = useUser();

  const userTokens = computed(() =>
    formatToUnits(props.user.rights / 1000000n),
  );
</script>

<template>
  <td>
    <base-button
      variant="link"
      :to="{
        name: routeNames.USER_CONTENT,
        params: { username: user.username },
      }"
      custom-classes="flex items-center gap-4"
    >
      <user-avatar
        v-if="user"
        :item="user"
        size="w-15 h-15"
        class="flex-none"
      />
      <span
        class="text-lg font-semibold leading-5 truncate text-white"
        :title="user.username"
      >
        {{ user.username }}
      </span>
    </base-button>
  </td>
  <td>
    <div class="flex items-center">
      <p v-if="user.bio" class="truncate text-sm" :title="user.bio">
        {{ user.bio }}
      </p>
      <p v-else class="truncate text-sm">
        {{ $t('memberSince') }}
        {{ getCreationDate(user.created_at) }}
      </p>
    </div>
  </td>
  <td>
    <div class="flex items-center gap-2">
      <base-icon name="outlined-users2" size="w-4 h-4" />
      <span class="font-medium text-sm">
        {{ formatToUnits(user.followers) }}
      </span>
    </div>
  </td>
  <td>
    <div class="flex items-center gap-2">
      <span class="font-medium text-sm">
        {{ userTokens }}
      </span>
    </div>
  </td>
  <td>
    <streak-count :count="Number(user.active_streak)" />
  </td>
  <td>
    <div class="flex items-center">
      <follow-user-button size="x-small" :user="user" />
    </div>
  </td>
</template>
