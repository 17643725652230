<script setup lang="ts">
  import { computed, onMounted, watch } from 'vue';
  import { useBreakpoint } from '@/shared/model';
  import {
    usePeopleRecommendation,
    UserGridItemPortrait,
  } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';

  const props = withDefaults(
    defineProps<{
      count?: number;
    }>(),
    {
      count: 3,
    },
  );

  const { contentPreset } = useChainBehavior();
  const { peopleFeedRecommendations, fetchPeopleFeedRecommendations } =
    usePeopleRecommendation(contentPreset);
  const { isSmallerThanSm } = useBreakpoint();

  const list = computed(() => {
    return isSmallerThanSm.value
      ? peopleFeedRecommendations.value.slice(0, 2)
      : peopleFeedRecommendations.value;
  });

  watch(
    () => contentPreset.value,
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        fetchPeopleFeedRecommendations(props.count);
      }
    },
  );

  onMounted(() => {
    if (peopleFeedRecommendations.value.length === 0) {
      fetchPeopleFeedRecommendations(props.count);
    }
  });
</script>

<template>
  <ul class="grid grid-cols-2 sm:grid-cols-3 gap-4 py-0 mt-4">
    <li v-for="user in list" :key="user.username">
      <user-grid-item-portrait :user="user" />
    </li>
  </ul>
</template>
