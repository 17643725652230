<template>
  <!-- Portal Governance -->
  <div
    v-if="isPortal && currentPortalData"
    class="w-full px-4 pt-3 pb-4 mb-4 text-left text-white bg-gray-950 shadow-lg sidebar-portalGovernance height sm:rounded-xl"
  >
    <p class="my-3 text-xl font-medium">Portal Governance</p>

    <p class="p-0 m-0 mt-1 mb-2 font-light text-white truncate">Admin</p>

    <router-link
      :to="{
        name: USER_CONTENT,
        params: { username: currentPortalData.owner.username },
      }"
      class="inline-flex items-center gap-1 px-3 py-1 text-base bg-gray-700 rounded-lg cursor-pointer"
    >
      <user-avatar
        v-if="portalOwner"
        :item="portalOwner"
        size="w-4 h-4"
        class="inline mr-1"
      />
      <base-icon v-else name="user-circle" size="w-6 h-6" class="inline" />
      {{ currentPortalData.owner.username }}
    </router-link>

    <div v-if="currentPortalOwnerRoles.length > 0">
      <p class="p-0 m-0 mt-4 mb-2 font-light text-white truncate">Roles</p>

      <div
        v-for="(role, index) in currentPortalOwnerRoles"
        :key="index"
        class="inline-block mb-2 mr-2"
      >
        <div class="role">
          <diamond :color="role.color" size="size-3" class="mr-2" />

          <p class="ml-1 text-base">
            {{ role.name }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Portal Resources -->
  <div
    v-if="isPortal && currentPortalData.id == 5412741"
    class="w-full px-4 pt-3 pb-4 mb-4 text-left text-white bg-gray-950 shadow-lg sidebar-portalLinks height sm:rounded-xl"
  >
    <h1 class="my-3 text-xl font-medium">Resources</h1>
    <div>
      <div class="py-1 md:mb-0">
        <a href="https://dscvr.one/post/6958037/neurons-101" class="underline">
          Neurons 101
        </a>
        - <span class="text-gray-300">Learn about what a Neuron is.</span>
      </div>
      <div class="py-1 md:mb-0">
        <a href="https://dscvr.one/u/SNSProposalBot" class="underline">
          Proposals
        </a>
        - <span class="text-gray-300">List of proposals for the SNS-1.</span>
      </div>
    </div>
  </div>

  <!-- Portal Rules -->
  <div
    v-if="isPortal && rules && !!rules.length"
    class="w-full px-4 pt-3 pb-4 mb-16 text-left text-white bg-gray-950 shadow-lg sidebar-portalLinks height sm:rounded-xl sm:mb-4"
  >
    <h1 class="my-3 text-xl font-medium">Rules</h1>
    <div class="mt-3 overflow-y-auto thin-scrollbar">
      <div v-for="(rule, key) in rules" :key="key" class="py-2">
        <button
          class="flex items-center justify-between w-full"
          v-click-outside="() => (rule.expanded = false)"
          @click="rule.expanded = !rule.expanded"
        >
          <h4 class="p-0 m-0 text-gray-300 truncate">
            {{ key + 1 }}. {{ rule.name }}
          </h4>
          <svg
            :class="{ 'transform rotate-180': rule.expanded == true }"
            class="w-5 h-5 text-gray-300"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          v-show="rule.expanded"
          class="mt-3"
          :class="{ 'text-gray-600': rule.expanded == true }"
        >
          <p class="text-gray-400">
            {{ rule.desc }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ActionTypes } from '@/store/action-types';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { numberToColorHex } from '@/utils/numberColors';
  import {
    ALL,
    FOLLOW,
    HOME,
    PORTAL_SETTINGS_PAGE,
    PORTAL_SETTINGS_PAGE_ROLES,
    POST_DETAIL,
    SEARCH,
    USER_CONTENT,
  } from '@/common';
  import { useAuth } from '@/entities/auth';
  import { usePostDialog } from '@/features/post';
  import { Diamond } from '@/entities/role';

  export default {
    name: 'portal-info',
    components: {
      UserAvatar,
      Diamond,
    },
    setup() {
      const { showLoginSignUpDialog } = useAuth();
      const { openCreateEditPostDialog } = usePostDialog();
      return { showLoginSignUpDialog, openCreateEditPostDialog };
    },
    data() {
      return {
        USER_CONTENT,
        rules: null,
        currentPortal: null,
        portalOwner: null,
        currentRoute: null,
        noInfoPages: [
          HOME,
          ALL,
          SEARCH,
          FOLLOW,
          USER_CONTENT,
          POST_DETAIL,
          PORTAL_SETTINGS_PAGE,
          PORTAL_SETTINGS_PAGE_ROLES,
        ],
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
        currentPortalData: 'portals/currentPortal',
        currentPortalOwnerRoles: 'portals/currentPortalOwnerRoles',
      }),
      isPortal() {
        return this.currentPortalData;
      },
    },
    watch: {
      currentPortalData: {
        handler(portalData, oldPortalData) {
          if (portalData && portalData.info && portalData.info[0]) {
            this.rules = portalData.info[0].rules[0];
          } else {
            this.rules = null;
          }

          if (portalData === oldPortalData && portalData !== null) return;

          if (portalData && portalData.owner && portalData.owner.username) {
            this.getUser(portalData.owner.username);
          }

          if (
            this.portalData &&
            this.portalData.id &&
            this.portalData.owner_id
          ) {
            this.$store.dispatch(
              `portals/${ActionTypes.GET_PORTAL_USER_ROLES}`,
              {
                portal_id: this.portalData.id,
                user_id: this.portalData.owner_id,
                no_load: true,
                for_owner: true,
              },
            );
          }
        },
        immediate: true,
      },
      $route: {
        handler(data) {
          if (this.noInfoPages.includes(data.name)) {
            this.currentPortal = null;
          } else {
            this.$forceUpdate();
          }
        },
      },
    },
    methods: {
      isOwner(user, portal) {
        return user.id.toText() == portal.owner_id.toText();
      },

      isNoPortalDataPage(data) {
        return data.name !== 'portal-page';
      },
      async getUser(username) {
        await this.$store
          .dispatch(`auth/${ActionTypes.GET_USER}`, username)
          .then((result) => {
            if (result.result.length > 0) {
              this.portalOwner = result.result[0];
            }
          });
      },
      numberToHex(color) {
        return numberToColorHex(color);
      },
      createPost() {
        if (!this.currentUser) {
          this.showLoginSignUpDialog();
          return;
        }
        this.openCreateEditPostDialog();
      },
    },
  };
</script>

<style scoped>
  .label {
    @apply mt-3.5 mb-2 text-xs font-semibold uppercase text-white lg:text-gray-400;
  }

  .role {
    @apply bg-gray-700 py-1 px-3 inline-flex items-center rounded-lg text-xs;
  }

  .role-color {
    @apply h-2 w-2 mr-1 rounded-full;
  }
</style>
