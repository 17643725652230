<script setup lang="ts">
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { PermissionFlags, hasPermission } from '@/utils/permissions';
  import { useUserRole } from '@/entities/user';

  const props = defineProps<{
    contentId: bigint;
    permissions: bigint;
    isNSFW: boolean;
    canDelete: boolean;
    canEdit: boolean;
  }>();
  const { t } = useI18n({ useScope: 'global' });

  const emit = defineEmits<{
    (e: 'report'): void;
    (e: 'remove'): void;
    (e: 'delete'): void;
    (e: 'edit'): void;
    (e: 'set-nsfw', value: boolean): void;
  }>();

  const { isAdmin } = useUserRole();

  const isContentModerator = computed(() => {
    return hasPermission(props.permissions, PermissionFlags.MODERATE_CONTENT);
  });

  const deleteContent = () => {
    if (confirm(t('deleteConfirmationMessage'))) {
      emit('delete');
    }
  };
  const removeContent = () => {
    if (confirm(t('removeConfirmationMessage'))) {
      emit('remove');
    }
  };
</script>

<template>
  <base-dropdown
    append-to-body
    custom-content-classes="w-40 mt-2 origin-top-right bg-gray-950 rounded-md shadow-lg ring-1 ring-blue-500 focus:outline-none"
  >
    <template #button="{ toggle }">
      <span
        id="options-menu"
        class="btn-comment-actions group"
        aria-expanded="true"
        aria-haspopup="true"
        @click="toggle"
      >
        <base-icon
          name="ellipsis"
          class="text-gray-400 group-hover:text-white"
        />
      </span>
    </template>

    <template #content="{ hide, isVisible, isShown }">
      <div v-if="isVisible || isShown" class="py-1" role="none">
        <span
          class="block px-4 py-2 text-sm text-white cursor-pointer hover:bg-gray-600 hover:text-gray-200 lowercase"
          role="menuitem"
          @click="$emit('report'), hide()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="mr-2"
          >
            <path
              fill-rule="evenodd"
              d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
              clip-rule="evenodd"
            />
          </svg>

          {{ $t('report') }}
        </span>
        <!-- edit -->
        <span
          v-if="canEdit"
          class="block px-4 py-2 text-sm text-white cursor-pointer hover:bg-gray-600 hover:text-gray-200 lowercase"
          @click="$emit('edit'), hide()"
        >
          <base-icon name="pencil" class="mr-2" />
          {{ $t('edit') }}
        </span>

        <!-- delete -->
        <span
          v-if="canDelete"
          class="block px-4 py-2 text-sm text-white cursor-pointer hover:bg-gray-600 hover:text-gray-200 lowercase"
          @click="deleteContent(), hide()"
        >
          <base-icon name="trash" class="mr-2" />

          {{ $t('delete') }}
        </span>
        <span
          v-if="isAdmin"
          class="block px-4 py-2 text-sm text-white cursor-pointer hover:bg-gray-600 hover:text-gray-200 lowercase"
          role="menuitem"
        >
          <input
            :value="isNSFW"
            type="checkbox"
            class="mr-2 form-checkbox"
            @click="$emit('set-nsfw', !isNSFW), hide()"
          />

          {{ $t('markAsNSFW') }}
        </span>

        <span
          v-if="isAdmin || isContentModerator"
          class="block px-4 py-2 text-sm text-white cursor-pointer hover:bg-gray-600 hover:text-gray-200 lowercase"
          role="menuitem"
          @click="removeContent(), hide()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>

          {{ $t('remove') }}
        </span>
      </div>
    </template>
  </base-dropdown>
</template>

<style scoped>
  svg {
    display: inline;
    width: 17.5px;
    height: 17.5px;
  }
</style>
