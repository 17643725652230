<template>
  <form
    class="h-full text-gray-100 md:h-auto pt-6 pb-8 px-8"
    @submit.prevent="onSubmit"
  >
    <div class="flex flex-col h-full gap-1 md:h-auto">
      <label class="block">
        <input
          v-model="portal.name"
          type="text"
          class="block w-full mt-1 bg-gray-950 border-transparent rounded-md focus:bg-gray-700 focus:ring-0"
          placeholder="Portal Name"
        />
      </label>
      <span class="block mt-1 mb-2">
        {{ `https://dscvr.one/p/${slug}` }}
      </span>
      <label class="block">
        <textarea
          v-model="portal.description"
          placeholder="Description"
          class="block w-full bg-gray-950 border-transparent rounded-md focus:bg-gray-700 focus:ring-0"
          rows="3"
        ></textarea>
      </label>
      <label class="flex items-center p-1 mt-3 rounded-md w-max">
        <input
          v-model="portal.is_nsfw"
          type="checkbox"
          class="w-6 h-6 mr-2 bg-pink-600 rounded-md form-checkbox focus:ring-0 focus:outline-none focus:bg-pink-600"
        />
        NSFW?
      </label>

      <div>
        <error-display
          :errors="errors"
          :error-message="errorMessage"
          :local-errors="localErrors"
        />
      </div>

      <div class="p-4 mt-2 rounded-md bg-gray-785">
        <div class="flex justify-between text-base font-semibold text-white">
          <span>Costs</span>
          <div class="flex items-center gap-1">
            <base-icon name="dscvr" size="size-4" class="inline" />
            500
          </div>
        </div>

        <p class="mt-1 text-sm font-medium">
          Don't have 500 points?
          <a
            class="text-blue-400"
            href="https://dscvr.typeform.com/Portal-Proposal"
            target="_blank"
          >
            Propose a portal.
          </a>
        </p>
      </div>

      <div class="flex flex-col mt-auto md:flex-row md:justify-end md:mt-3">
        <form-button
          class="py-3 text-base md:text-sm md:py-2"
          text="Create Portal"
          :is-disabled="formDisabled"
          :form-ready="!formDisabled"
        />
      </div>
    </div>
  </form>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ActionTypes } from '@/store';
  import ErrorDisplay from '../ErrorDisplay.vue';
  import FormButton from '../buttons/FormButton.vue';

  export default {
    name: 'create-portal-form',
    components: { ErrorDisplay, FormButton },
    emits: ['create-portal'],
    data() {
      return {
        localErrors: [],
        errorMessage: '',
        errors: [],
        portal: {
          name: '',
          description: '',
          is_nsfw: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
      formDisabled() {
        return (
          this.currentUser && Number(this.currentUser.rights / 1000000n) < 500
        );
      },
      slug() {
        return this.portal.name
          .toLowerCase()
          .replace(/[^\w ]+/g, '')
          .replace(/ +/g, '-');
      },
    },
    methods: {
      isValid() {
        const portal = this.portal;
        this.localErrors = [];

        portal.name = portal.name.trim();
        portal.description = portal.description.trim();

        if (!portal.name.replace(/\s/g, '').length) {
          this.localErrors.push({
            field: 'Name',
            errors: ['the portal has to be named something'],
          });
        }

        if (!portal.description.replace(/\s/g, '').length) {
          this.localErrors.push({
            field: 'Description',
            errors: ['you need to describe it in at least one word'],
          });
        }

        return this.localErrors.length == 0;
      },
      transformError(data) {
        return data['field'] + ': ' + data['errors'].join(' ');
      },
      onSubmit() {
        if (this.isValid) {
          this.$store
            .dispatch(`portals/${ActionTypes.CREATE_PORTAL}`, {
              ...this.portal,
              slug: this.slug,
              icon_url: '',
            })
            .then((result) => {
              if (result?.status == 'happy') {
                const newPortal = result.result[0];
                this.portal = {
                  name: '',
                  description: '',
                  is_nsfw: false,
                };
                this.$emit('create-portal', newPortal);
              } else {
                this.errorMessage = result.message;
                this.errors = result.errors[0];
              }
            });
        }
      },
    },
  };
</script>

<style scoped>
  .form-checkbox:checked {
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .form-checkbox:hover,
  .form-checkbox:checked:focus,
  .form-checkbox:checked:hover {
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
</style>
