<template>
  <div
    class="z-0 overflow-hidden border border-gray-800 cursor-pointer bg-gray-950 h-52 rounded-xl group"
    @click="goToPortal"
  >
    <div class="relative h-full">
      <div
        class="absolute z-10 w-full h-full transition-all duration-300 bg-transparent opacity-25 group-hover:bg-black"
      ></div>
      <div class="absolute inset-0">
        <img
          v-if="portal?.info[0]?.cover_photo[0]?.length > 0"
          v-lazy="portal?.info[0]?.cover_photo[0]"
          class="object-cover w-full h-full transition-all duration-500 group-hover:scale-102"
        />
        <img v-else class="object-cover w-full h-full" :src="coverPhoto" />
      </div>
      <div
        class="absolute inset-0 opacity-80 bg-gradient-to-t from-black via-transparent to-transparent"
      />
      <div
        v-if="isMod"
        class="absolute flex items-center justify-center w-10 h-10 rounded-full top-2 right-2 bg-gradient-to-b from-indigo-800 to-red-500"
        @mouseover.stop="(e) => hoverTooltip(e, 'Moderator')"
      >
        <base-icon name="mod" class="pointer-events-none" />
      </div>
      <div
        class="absolute z-10 flex w-full transition-all duration-500 bottom-4 left-4 gap-x-4 group-hover:scale-102"
      >
        <img class="size-12 rounded-xl min-w-12" :src="getPortalIcon(portal)" />
        <div class="flex flex-col w-full font-semibold">
          <span class="w-3/4 text-lg truncate cursor-pointer">
            {{ portal?.name }}
          </span>
          <span class="text-sm text-gray-400">{{
            getDisplayAmount(portal?.member_count, true)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  import 'tippy.js/dist/svg-arrow.css';
  import { mapGetters } from 'vuex';
  import { COVER_PHOTOS } from '@/common/constants';
  import { getRandomInteger } from '@/utils/general';
  import { PORTAL_CONTENT } from '@/common';
  import { usePortal } from '@/entities/portal';
  import { getDisplayAmount } from '@/shared/lib';

  export default {
    props: {
      portal: {
        type: Object,
        default: () => ({}),
      },
    },
    setup() {
      const { getPortalIcon } = usePortal();
      return {
        getPortalIcon,
      };
    },
    data() {
      return {
        coverPhoto: null,
        getDisplayAmount,
      };
    },
    computed: {
      ...mapGetters({
        userProfile: 'users/userProfile',
      }),
      isMod() {
        return (
          this.portal?.is_mod ||
          this.portal?.owner?.username === this.userProfile?.username
        );
      },
    },
    mounted() {
      this.getRandomCoverPhoto();
    },
    methods: {
      getRandomCoverPhoto() {
        const photoIndex = getRandomInteger(0, COVER_PHOTOS.length - 1);
        this.coverPhoto =
          COVER_PHOTOS[photoIndex]?.length > 0
            ? COVER_PHOTOS[photoIndex]
            : this.getRandomCoverPhoto();
      },
      goToPortal() {
        this.$router.push({
          name: PORTAL_CONTENT,
          params: {
            portal: this.portal.slug,
          },
        });
      },
      hoverTooltip(e, text) {
        tippy(e.target, {
          animation: 'fade',
          placement: 'bottom',
          content: text,
          maxWidth: 500,
          offset: [0, 15],
        });
      },
    },
  };
</script>
