import { useFormatHTMLContent } from '@/composables';
import { dscvrIcApi } from '@/shared/api';
import type { CreateContent } from 'dfx/edge/edge.did';
import { makeDefaultCreateCommentPayload } from '@/entities/comment';
import { useMutation } from '@tanstack/vue-query';

export const useCreateCommentMutation = () => {
  return useMutation({
    mutationKey: ['create-comment-mutation'],
    mutationFn: async (params: {
      portalId: bigint;
      parentId: bigint;
      unstoredBody: string;
      partialComment?: Partial<CreateContent>;
    }) => {
      const { storeMediaSources } = useFormatHTMLContent();
      const body = await storeMediaSources(params.unstoredBody);
      const payload = makeDefaultCreateCommentPayload(
        params.portalId,
        params.parentId,
        body,
        params.partialComment ?? {},
      );
      return dscvrIcApi.comment.createComment(payload);
    },
  });
};
