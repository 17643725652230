<script lang="ts" setup>
  import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
  import { computed } from 'vue';
  import EmbeddedCanvas from './EmbeddedCanvas.vue';

  const props = defineProps(nodeViewProps);

  const url = computed(() => props.node.attrs.url);
</script>

<template>
  <node-view-wrapper>
    <embedded-canvas :url="url" />
  </node-view-wrapper>
</template>
