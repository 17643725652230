<script setup lang="ts">
  import { computed, watch, toRef } from 'vue';
  import {
    useBreakpoint,
    type FeedOptions,
    useFeed,
    portalFeedKey,
  } from '@/shared/model';
  import PortalInfo from '@/components/portals/PortalInfo.vue';
  import { LivePortalRoomPanel } from '@/widgets/live-portal-room-panel';
  import { Block as PwaBlock } from '@/widgets/pwa';
  import { CorporateLinks } from '@/widgets/corporate-links';
  import { FeedFilter } from '@/widgets/feed-filter';
  import CreatePostBar from '@/components/bars/CreatePostBar.vue';
  import { useUser } from '@/entities/user';
  import { usePortal } from '@/entities/portal';
  import { ContentRestrictedMessage } from './components';
  import { USER_SETTINGS_PREFERENCES } from '@/common';
  import { PostList } from '@/widgets/post-list';
  import { useFeedFilter } from '@/widgets/feed-filter';
  import { useRoute } from 'vue-router';

  const props = defineProps<{
    portal: string;
  }>();

  const portalSlug = toRef(() => props.portal);

  const route = useRoute();
  const { isLoggedIn, currentUser } = useUser();
  const { currentPortal, canViewContent } = usePortal();
  const { dateFilterQueryParameter, sortFilterQueryParameter } =
    useFeedFilter();
  const { isSmallerThanLg } = useBreakpoint();

  const feedOptions = computed<FeedOptions>(() => ({
    key: portalFeedKey,
    type: portalFeedKey,
    filter: 'hot',
    slug: portalSlug,
  }));

  const {
    isLoading,
    onComment,
    onDeleteContent,
    onEditContent,
    onFetchNextPage,
    onGoodbye,
    onPinContent,
    onPinContentToProfile,
    onPollVote,
    onRemoveContent,
    onSetNsfw,
    onSetRank,
    onTranslateContent,
    personalizedFeedV1Query,
    posts,
  } = useFeed(feedOptions);

  const nsfwRestricted = computed(
    () =>
      currentPortal.value?.is_nsfw &&
      !currentUser.value?.user_settings.allow_nsfw,
  );

  watch(
    [() => route.query.sort, () => route.query.sortDateRange],
    ([sortValue, _]) => {
      if (personalizedFeedV1Query.value) {
        personalizedFeedV1Query.value.query.sort =
          sortFilterQueryParameter.value;
        personalizedFeedV1Query.value.query.max_age =
          sortValue === 'top' && dateFilterQueryParameter.value
            ? [BigInt(dateFilterQueryParameter.value)]
            : [];
      }
    },
    { immediate: true },
  );
</script>

<template>
  <div v-if="currentPortal" class="flex-grow flex-shrink w-full">
    <div class="flex md:gap-6 md:px-16 lg:px-0">
      <main class="inline-flex w-0 flex-auto">
        <section id="posts" class="w-full">
          <div class="w-full text-md">
            <feed-filter v-if="canViewContent" />
            <create-post-bar v-if="isLoggedIn" />
            <template v-if="!canViewContent || nsfwRestricted">
              <content-restricted-message v-if="!canViewContent" />
              <div
                v-if="nsfwRestricted"
                class="flex justify-center items-center gap-4 p-5 text-lg font-medium text-center text-white bg-gray-950 shadow-lg sm:rounded-xl"
              >
                <span>{{ $t('portalNSFWRestrictedError') }}</span>
                <base-button
                  v-if="currentUser"
                  variant="secondary"
                  size="small"
                  :to="{
                    name: USER_SETTINGS_PREFERENCES,
                    params: {
                      username: currentUser.username,
                    },
                  }"
                >
                  <base-icon name="settings" size="w-4 h-4" />
                </base-button>
              </div>
            </template>
            <post-list
              v-else
              :posts="posts"
              :is-loading="isLoading"
              hide-feed-recommendations
              show-pinned-post
              @fetch-next-page="onFetchNextPage"
              @translate-content="onTranslateContent"
              @delete-content="onDeleteContent"
              @remove-content="onRemoveContent"
              @edit-content="onEditContent"
              @pin-content="onPinContent"
              @pin-content-to-profile="onPinContentToProfile"
              @set-nsfw="onSetNsfw"
              @goodbye="onGoodbye"
              @set-rank="onSetRank"
              @on-comment="onComment"
              @on-poll-vote="onPollVote"
            />
          </div>
        </section>
      </main>

      <div v-if="!isSmallerThanLg" class="w-full md:max-w-84">
        <live-portal-room-panel />
        <portal-info />
        <pwa-block />
        <corporate-links />
      </div>
    </div>
  </div>
</template>
