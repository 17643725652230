import { computed, onUnmounted, type Ref } from 'vue';
import type { WalletName } from '@solana/wallet-adapter-base';
import { useI18n } from 'vue-i18n';
import { trackEvent } from '@/utils/tracker';
import * as bs58 from 'bs58';
import { useDevice, useToast } from '@/shared/model';
import { dscvrApi, solApi } from '@/shared/api';
import { useGetUserByWalletAddressQuery } from '../../api/use-get-user-by-wallet-address.query';
import { useWalletDialog } from './use-wallet-dialog';
import { useConnectSolanaWallets } from './use-connect-solana-wallets';
import { useWalletPairing } from './use-wallet-pairing';
import {
  SOLANA_PAIR_ENABLED_WALLETS,
  convertWalletNameToWalletType,
} from '../../lib/solana/settings';
import { WalletNotFoundError, WalletPairError } from '../types/error';
import { prepareSignedMessage } from '../../lib/message-to-sign';
import type { PairDeeplinkParams } from '../types/pairing';
import type { UserView } from 'dfx/edge/edge.did';

export const usePairSolanaWallet = () => {
  const { t } = useI18n({ useScope: 'global' });
  const { showToast } = useToast();
  const { isMobileOrTablet } = useDevice();
  const {
    pairedWallets,
    setPairingWallet,
    clearPairingWallet,
    pairWallet,
    unpairWallet,
    waitForMobileWalletToPair,
    getNonce,
    finalizeWalletMobilePair,
  } = useWalletPairing();
  const {
    wallet,
    publicKey,
    signMessage,
    wallets,
    installedWallets,
    connectedWalletAddress,
    selectAndConnect,
    disconnect: cleanUpProvider,
  } = useConnectSolanaWallets();
  const {
    openSendingToWalletDialog,
    openSuccessfullyPairedDialog,
    openUnsuccessfulPairingDialog,
  } = useWalletDialog();
  const { refetch: fetchUserByWalletAddress } = useGetUserByWalletAddressQuery(
    connectedWalletAddress,
  );

  const pairEnabledWallets = computed(() =>
    SOLANA_PAIR_ENABLED_WALLETS.map((id) => {
      const wallet = wallets.value.find((w) => w.id === id);
      return {
        id,
        ...wallet,
      };
    }),
  );

  const isWalletPaired = (walletType?: dscvrApi.wallet.WalletType) => {
    return pairedWallets.value.some(
      (wallet) => wallet.walletType.slug === walletType,
    );
  };

  const pair = async (user: UserView, walletName: WalletName) => {
    const walletType = convertWalletNameToWalletType(walletName);
    if (!walletType) {
      throw new WalletNotFoundError();
    }
    let isPaired = isWalletPaired(walletType);
    if (isPaired) {
      return true;
    }

    const isInstalled = installedWallets.value.some(
      (wallet) => wallet.id === walletName,
    );

    if (isMobileOrTablet.value && !isInstalled) {
      const deepLinkWindow = await openSendingToWalletDialog(
        user.id.toText(),
        walletName,
      );
      const paired = await waitForMobileWalletToPair(walletType);
      // This closes a new tab left empty on IOS tablet
      if (deepLinkWindow) {
        deepLinkWindow.close();
      }
      if (paired) {
        openSuccessfullyPairedDialog(user.username);
      }
      return paired;
    }

    const result = await connectAndPair(walletName);
    if (result === 'pairingSuccess') {
      return true;
    }

    return false;
  };

  const unpair = async (wallet: dscvrApi.wallet.Wallet) => {
    const result = await unpairWallet(wallet.address);
    trackEvent('user_settings_action', 'wallet_unpair', wallet.walletType.slug);
    return result;
  };

  const connectAndPair = async (walletName: WalletName) => {
    try {
      const walletType = convertWalletNameToWalletType(walletName);
      if (!walletType) {
        throw new WalletNotFoundError();
      }

      setPairingWallet(walletType);
      const connected = await selectAndConnect(walletName);
      if (!connected) return 'connectionFailed';
      const { data: alreadyPairedWallet } = await fetchUserByWalletAddress();
      if (alreadyPairedWallet) {
        const doPair = await openUnsuccessfulPairingDialog(
          alreadyPairedWallet!.owner.username,
        );
        if (!doPair) return 'pairingFailed';
      }
      const paired = await pairWalletAndTrack(walletName);
      return paired ? 'pairingSuccess' : 'pairingFailed';
    } catch {
      return 'pairingFailed';
    } finally {
      clearPairingWallet();
    }
  };

  const getPairWalletPayload = async (
    walletName: WalletName,
    overrideNonce?: string,
  ) => {
    const walletType = convertWalletNameToWalletType(walletName);
    if (!walletType) {
      throw new WalletNotFoundError();
    }
    if (
      !publicKey.value ||
      !signMessage.value ||
      wallet.value?.id !== walletName
    ) {
      showToast({
        id: `${walletType}-wallet-link-error`,
        title: t('wallets.connectWallet.invalidWalletConnection'),
        type: 'error',
        durationSeconds: 3,
      });
      throw new WalletPairError();
    }

    const newNonce = overrideNonce ?? (await getNonce());
    if (!newNonce) {
      throw new Error('Failed to get nonce');
    }
    const { message, rawMessage } = prepareSignedMessage(
      'solana',
      solApi.rpc.SOLANA_MAINNET_CHAIN_ID,
      publicKey.value,
      newNonce,
    );
    const response = await signMessage.value(message);

    const payload: dscvrApi.wallet.LinkWalletDto = {
      signature: bs58.encode(response),
      message: rawMessage,
      address: publicKey.value,
      network: 'solana',
      walletType,
    };
    return payload;
  };

  const pairWalletAndTrack = async (walletName: WalletName) => {
    try {
      const walletType = convertWalletNameToWalletType(walletName);
      if (!walletType) throw new WalletNotFoundError();

      const pairPayload = await getPairWalletPayload(walletName);
      const paired = await pairWallet(walletType, pairPayload);
      if (paired) {
        trackEvent(
          'user_settings_action',
          'wallet_pair',
          walletName.toLowerCase(),
        );
        return true;
      }
    } catch {
      const walletPrefix =
        convertWalletNameToWalletType(walletName) ?? walletName.toLowerCase();
      showToast({
        id: `${walletPrefix}-wallet-link-error`,
        title: t('wallets.linkWalletFailed'),
        description: `${t('wallets.linkWalletFailedDescription')} ${
          connectedWalletAddress.value
        }`,
        type: 'error',
        durationSeconds: 3,
      });
    }
    return false;
  };

  const finalizeMobilePair = async (
    walletType: dscvrApi.wallet.WalletType,
    pairParams: PairDeeplinkParams,
  ) => {
    const walletName = (
      walletType === 'phantom' ? 'Phantom' : 'Solflare'
    ) as WalletName;

    const connected = await selectAndConnect(walletName);
    if (connected) {
      const pairPayload = await getPairWalletPayload(
        walletName,
        pairParams.nonce,
      );
      return await finalizeWalletMobilePair(walletType, pairPayload, {
        ...pairParams,
      });
    }
    return false;
  };

  onUnmounted(cleanUpProvider);

  return {
    publicKey,
    pairEnabledWallets,
    pair,
    unpair,
    finalizeMobilePair,
  };
};
