<script setup lang="ts">
  import { computed, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useSwipe } from '@vueuse/core';
  import * as routeNames from '@/common';

  const { direction } = useSwipe(window.document, {
    threshold: 200,
  });
  const route = useRoute();
  const router = useRouter();
  const isRunningStandalone = window.matchMedia(
    '(display-mode: fullscreen)',
  ).matches;

  const showBackButton = computed(() => {
    return isRunningStandalone && route.name !== routeNames.HOME;
  });

  watch(
    () => direction.value,
    (direction) => {
      if (direction === 'right') {
        router.go(-1);
      }
    },
  );
</script>

<template>
  <base-button
    v-if="showBackButton"
    variant="custom"
    class="flex lg:hidden items-center justify-center bg-blur bg-black bg-opacity-20 fixed z-40 left-6 bottom-6 rounded-2xl p-2 back-button"
    @click="$router.go(-1)"
  >
    <base-icon name="arrow-left" size="w-6 h-6" />
  </base-button>
</template>

<style scoped>
  .back-button {
    display: none;
  }
  @media all and (display-mode: fullscreen) {
    .back-button {
      display: block;
    }
  }
</style>
