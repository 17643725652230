import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';
import gatedService from '../../services/gated';
import { dscvrApi } from '@/shared/api';
import portalService from '../../services/portals';
import { NFT_SAURON_PRINCIPAL, NFT_SAURON_ROLE } from '../../common/constants';
import { PermissionFlags } from '@/utils/permissions';

// initial state
const state = () => ({
  isLoading: 0,
  portalSlug: null,
  icpNftCollections: [],
  multichainNftCollections: [],
  nftSauronPortalRoles: [],
  currentNftCollection: null,
});

// getters
const getters = {
  portalSlug: (state) => {
    return state.portalSlug;
  },
  icpNftCollections: (state) => {
    return state.icpNftCollections;
  },
  multichainNftCollections: (state) => {
    return state.multichainNftCollections;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  currentNftCollection: (state) => {
    return state.currentNftCollection;
  },
  nftSauronPortalRoles: (state) => {
    return state.nftSauronPortalRoles.length
      ? state.nftSauronPortalRoles[0].member.roles
      : [];
  },
  nftSauronUserId: (state) => {
    return state.nftSauronPortalRoles.length
      ? state.nftSauronPortalRoles[0].member.id
      : null;
  },
  nftRoleNames: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['portals/currentPortalRoles']
      ? rootGetters['portals/currentPortalRoles'].map((r) => r.name)
      : [];
  },
  doesNftWatcherRoleExist: (_state, getters) => {
    return getters.nftRoleNames.includes(NFT_SAURON_ROLE);
  },
  nftSauronRole: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['portals/currentPortalRoles'].find(
      (r) => r.name === NFT_SAURON_ROLE,
    );
  },
  nftSauronUser: (state) => {
    return state.nftSauronPortalRoles.length
      ? state.nftSauronPortalRoles[0].member
      : null;
  },
};

// actions
const actions = {
  async [ActionTypes.GET_GATING_NFT_COLLECTIONS]({ commit }, { slug, reset }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      if (reset) {
        commit(MutationTypes.SET_GATING_NFT_COLLECTIONS, {
          slug: null,
          icpNftCollections: [],
          multichainNftCollections: [],
        });
      }
      let icpNftCollections = [];
      let multichainNftCollections = [];
      try {
        icpNftCollections = (await gatedService.listNftCollections(slug)).data
          .data;
      } catch (e) {}
      try {
        multichainNftCollections = (
          await dscvrApi.multichainGating.listNftCollections({
            portalSlug: slug,
          })
        ).data.data;
      } catch (e) {}

      commit(MutationTypes.SET_GATING_NFT_COLLECTIONS, {
        slug,
        icpNftCollections,
        multichainNftCollections,
      });
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_GATING_NFT_COLLECTION]({ commit }, { id }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const nftCollection = (await gatedService.getNftCollection(id)).data.data;
      commit(MutationTypes.SET_GATING_NFT_COLLECTION, nftCollection);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_NFT_SAURON_PORTAL_ROLES]({ commit }, { id }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const response = await portalService.getPortalUserRoles(
        id,
        NFT_SAURON_PRINCIPAL,
      );
      commit(MutationTypes.SET_GATING_NFT_SAURON_ROLES, response);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GATING_SETUP_WATCHER]({
    dispatch,
    commit,
    getters,
    rootGetters,
  }) {
    BigInt.prototype.toJSON = function () {
      return Number(this);
    };

    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const currentPortalSlug = rootGetters[`portals/currentPortal`].slug;
      const joinNftPortal = async () => {
        if (getters.nftSauronPortalRoles.length) return;
        return gatedService.watcherJoinNftPortal(currentPortalSlug);
      };
      await Promise.all([
        dispatch(ActionTypes.GATING_CREATE_WATCHER_ROLES),
        joinNftPortal(),
      ]);

      // Reload Portal Roles
      const currentPortalId = rootGetters[`portals/currentPortal`].id;
      if (currentPortalId) {
        await Promise.all([
          dispatch(`portals/${ActionTypes.GET_PORTAL_ROLES}`, currentPortalId, {
            root: true,
          }),
          dispatch(
            `portals/${ActionTypes.GET_PORTAL_MEMBERS}`,
            currentPortalId,
            { root: true },
          ),
        ]);
      }
      const portalRoles = rootGetters[`portals/currentPortalRoles`];
      const nftSauronRoleIndex = portalRoles.findIndex(
        (r) => r.name === NFT_SAURON_ROLE,
      );
      const ordinals = portalRoles.map((r) => [r.id, r.ordinal]);
      console.log(`PORTAL ROLES`, portalRoles);
      console.log(`OLD ORDINALS`, ordinals);
      const oldSecond = JSON.parse(JSON.stringify(ordinals[1]));
      oldSecond[1] = nftSauronRoleIndex;
      ordinals[nftSauronRoleIndex][1] = 1;
      ordinals[1] = ordinals[nftSauronRoleIndex];
      ordinals[nftSauronRoleIndex] = oldSecond;
      console.log(`NEW ORDINALS`, ordinals);
      await dispatch(
        `portals/${ActionTypes.UPDATE_PORTAL_ROLES_ORDINALS}`,
        { portal_id: currentPortalId, ordinals: ordinals },
        { root: true },
      );

      // Assign NFT Sauron Role if not already assigned
      if (
        getters.nftSauronRole &&
        getters.nftSauronUser &&
        getters.nftSauronPortalRoles.find((r) => r.name === NFT_SAURON_ROLE) ===
          undefined
      ) {
        console.log(`Adding Portal Member Role`);
        await dispatch(
          `portals/${ActionTypes.ADD_PORTAL_MEMBER_ROLE}`,
          {
            portal_id: currentPortalId,
            members: [
              {
                memberId: getters.nftSauronUser.id,
                roleId: getters.nftSauronRole.id,
              },
            ],
          },
          { root: true },
        );
      }
      await dispatch(ActionTypes.GET_NFT_SAURON_PORTAL_ROLES, {
        id: currentPortalId,
      });
      return true;
    } catch (e) {
      console.log(e);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GATING_CREATE_WATCHER_ROLES]({
    dispatch,
    getters,
    rootGetters,
  }) {
    const roleObject = {
      color: 0,
      icon_url: '',
      kind: {
        Custom: null,
      },
      name: NFT_SAURON_ROLE,
      ordinal: rootGetters[`portals/currentPortalRoles`].length,
      permissions: PermissionFlags.MANAGE_MEMBERS,
    };
    const currentPortalId = rootGetters[`portals/currentPortal`].id;
    if (!getters.doesNftWatcherRoleExist && currentPortalId) {
      return dispatch(
        `portals/${ActionTypes.ADD_PORTAL_ROLE}`,
        {
          portal_id: currentPortalId,
          role: roleObject,
        },
        { root: true },
      );
    }
  },
  async [ActionTypes.GATING_UPDATE_NFT_COLLECTION](
    { commit },
    { id, payload },
  ) {
    try {
      commit(MutationTypes.SET_IS_LOADING, true);
      if ('nftCollectionAddress' in payload) {
        const response = await dscvrApi.multichainGating.updateNftCollection(
          id,
          payload,
        );
        commit(MutationTypes.UPDATE_GATING_NFT_COLLECTION, response.data.data);
        return response;
      } else {
        const response = await gatedService.updateNftCollection(id, payload);
        commit(MutationTypes.UPDATE_GATING_NFT_COLLECTION, response.data.data);
        return response;
      }
    } catch (e) {
      console.log(e);
      return;
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GATING_CREATE_NFT_COLLECTION]({ commit }, data) {
    try {
      if ('network' in data) {
        commit(MutationTypes.SET_IS_LOADING, true);
        const response = await dscvrApi.multichainGating.createNftCollection(
          data,
        );
        const newNft = response.data.data;
        commit(MutationTypes.ADD_GATING_NFT_COLLECTION, newNft);
        return response;
      } else {
        const response = await gatedService.createNftCollection(data);
        const newNft = response.data.data;
        commit(MutationTypes.ADD_GATING_NFT_COLLECTION, newNft);
        return response;
      }
    } catch (e) {
      console.log(e);
      return;
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GATING_DELETE_NFT_COLLECTION]({ commit }, data) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      if ('network' in data) {
        const response = await dscvrApi.multichainGating.deleteNftCollection(
          data.id,
        );
        commit(MutationTypes.REMOVE_GATING_NFT_COLLECTION, data);
        return response;
      } else {
        const response = await gatedService.deleteNftCollection(data.id);
        commit(MutationTypes.REMOVE_GATING_NFT_COLLECTION, data);
        return response;
      }
    } catch (e) {
      console.log(e);
      return;
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
};

// mutations
const mutations = {
  [MutationTypes.SET_GATING_NFT_COLLECTIONS](
    state,
    { slug, icpNftCollections, multichainNftCollections },
  ) {
    state.portalSlug = slug;
    state.icpNftCollections = icpNftCollections;
    state.multichainNftCollections = multichainNftCollections;
  },
  [MutationTypes.SET_GATING_NFT_COLLECTION](state, val) {
    state.currentNftCollection = val;
  },
  [MutationTypes.SET_IS_LOADING](state, val) {
    state.isLoading = val;
  },
  [MutationTypes.SET_GATING_NFT_SAURON_ROLES](state, val) {
    if (val) state.nftSauronPortalRoles = val;
  },
  [MutationTypes.REMOVE_GATING_NFT_COLLECTION](state, data) {
    if ('network' in data) {
      state.multichainNftCollections = state.multichainNftCollections.filter(
        (n) => n.id !== data.id,
      );
    } else {
      state.icpNftCollections = state.icpNftCollections.filter(
        (n) => n.id !== data.id,
      );
    }
  },
  [MutationTypes.ADD_GATING_NFT_COLLECTION](state, data) {
    if ('network' in data) {
      state.multichainNftCollections.push(data);
    } else {
      state.icpNftCollections.push(data);
    }
  },
  [MutationTypes.UPDATE_GATING_NFT_COLLECTION](state, data) {
    if ('network' in data) {
      state.multichainNftCollections = state.multichainNftCollections.map((c) =>
        c.id === data.id ? data : c,
      );
    } else {
      state.icpNftCollections = state.icpNftCollections.map((c) =>
        c.id === data.id ? data : c,
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
