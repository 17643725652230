import type { ResponseObject } from './models';
import { apiInstance } from './base';
import type { AxiosPromise } from 'axios';

const BASE_URL = '/sns';

export interface IICRCToken {
  cid: string;
  params: IICRCTokenParams;
}

export interface IICRCTokenParams {
  canTip: boolean;
  defaultAmounts: string[];
}

export const getICRCTokens = (): AxiosPromise<ResponseObject<IICRCToken[]>> => {
  return apiInstance.get(`${BASE_URL}/list/icrc`);
};
