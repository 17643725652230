import { apiInstance } from './base';
import type { ResponseObject } from './models';

export interface ITwitterProfile {
  username: string;
  followerCount: number;
}

const BASE_URL = '/twitter';

export const getMyTwitterProfile = (): Promise<
  ResponseObject<ITwitterProfile>
> => {
  return apiInstance.get(`${BASE_URL}/profile/me`).then((response) => {
    return response.data;
  });
};
