<script setup lang="ts">
  import {
    watch,
    onBeforeUnmount,
    ref,
    computed,
    onMounted,
    onActivated,
  } from 'vue';
  import { Editor, EditorContent } from '@tiptap/vue-3';
  import { useVModel } from '@vueuse/core';
  import { useFormatHTMLContent, useWysiwyg } from '@/composables';
  import { usePost } from '@/entities/post';
  import { createEditor } from '@/utils';
  import { DropArea } from '@/components/shared/drop-area';

  const props = defineProps<{
    context: 'feed' | 'comment';
    modelValue: string;
    placeholder?: string;
    singleLine?: boolean;
    autofocus?: boolean;
    editorCssClass?: string;
  }>();

  const emit = defineEmits<{
    (e: 'update:modelValue', body: string): void;
    (e: 'enter', body: string, empty: boolean): void;
    (e: 'error', value: string): void;
    (e: 'media-inserted'): void;
  }>();

  const animateEmptyMessage = ref(false);
  let animateEmptyMessageTimeoutId: NodeJS.Timeout | null = null;
  const content = useVModel(props, 'modelValue', emit);
  const { isHtmlEmpty } = useFormatHTMLContent();
  const editor: Editor = createEditor({
    context: props.context,
    initialContent: props.modelValue,
    initialPlaceholder: props.placeholder,
    onUpdate: (body) => {
      content.value = body;
    },
    onEnter,
  });

  const {
    uploadFilesToEditor,
    setPlaceHolder,
    resetContent,
    pasteImage,
    focus,
    ...wysiwygUtils
  } = useWysiwyg(editor);
  const { setIsLoading } = usePost();

  const isEmpty = computed(() => {
    return isHtmlEmpty(content.value);
  });

  const isFocused = computed(() => {
    return editor.isFocused;
  });

  watch(
    () => props.placeholder,
    (value) => setPlaceHolder(value || ''),
  );

  function onEnter(event: KeyboardEvent) {
    if (props.singleLine) {
      event.stopPropagation();
      event.preventDefault();
      emit('enter', content.value, isEmpty.value);
    }
  }

  function reset(autofocus?: boolean) {
    content.value = '';
    resetContent(autofocus);
  }

  function pasteImageAndEmit(url: string) {
    pasteImage(url);
    emit('media-inserted');
  }

  function uploadFiles(files?: FileList) {
    if (editor && files) {
      setIsLoading(true);
      uploadFilesToEditor(files)
        .then(() => emit('media-inserted'))
        .catch((error) => emit('error', error))
        .finally(() => setIsLoading(false));
    }
  }

  function executeEmptyMessageAnimation() {
    if (animateEmptyMessageTimeoutId) {
      clearTimeout(animateEmptyMessageTimeoutId);
    }
    animateEmptyMessage.value = true;
    animateEmptyMessageTimeoutId = setTimeout(() => {
      animateEmptyMessage.value = false;
    }, 1100);
  }

  onMounted(() => {
    if (props.autofocus) {
      focus();
    }
  });

  onActivated(() => {
    if (props.autofocus) {
      focus();
    }
  });

  onBeforeUnmount(() => {
    editor.destroy();
  });

  defineExpose({
    editor,
    isEmpty,
    isFocused,
    uploadFiles,
    reset,
    focus,
    pasteImage: pasteImageAndEmit,
    executeEmptyMessageAnimation,
    ...wysiwygUtils,
  });
</script>

<template>
  <drop-area
    @drop="uploadFiles($event.dataTransfer?.files)"
    class="drop-area"
    :drag-active-css-class="singleLine ? 'px-3' : 'p-4'"
  >
    <template #default="{ dragActive }">
      <editor-content
        class="editor-input prose w-full h-full"
        :editor="editor"
        :aria-placeholder="placeholder"
        :class="[
          editorCssClass || '',
          animateEmptyMessage ? 'empty-message-animation' : '',
          dragActive ? 'invisible' : '',
        ]"
      />
    </template>
    <template #message v-if="singleLine">{{ $t('dropFilesHere') }}</template>
  </drop-area>
</template>

<style lang="scss" scoped>
  .drop-area,
  .editor-input {
    min-height: inherit;
    height: inherit;
  }
  .empty-message-animation:deep(.ProseMirror)
    p.is-editor-empty:first-child::before {
    @apply animate-shake animate-duration-200 animate-twice;
  }

  .editor-input:deep(.ProseMirror) {
    & {
      color: white;
      min-height: inherit;
      height: inherit;
      margin: 0 !important;
    }
    & * {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    p.is-editor-empty:first-child::before {
      @apply opacity-50 transition duration-500;
    }
    &:hover p.is-editor-empty:first-child::before {
      @apply opacity-80;
    }
    .frame {
      max-width: 900px;
      background: white;
      margin: 0 auto;
    }
    .controls {
      padding: 10px;
      border-bottom: 3px solid #eee;
      display: flex;
      width: 100%;
      align-items: center;
    }
    .controls button {
      background: none;
      font-weight: bold;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      margin-right: 4px;
      align-items: center;
      color: #0d0d0d;
      padding: 7px;
      border: 1px solid #ccc;
    }
    .controls button.is-active {
      background: #ddd;
    }
    .video-wrapper {
      aspect-ratio: 16/9;
      width: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    &:focus,
    &:focus-visible,
    &:active {
      outline: none;
    }
    & > * + * {
      margin-top: 0.75em;
    }

    &:where(img):not(:where([class~='not-prose'] *)) {
      width: 100%;
      height: auto;
      display: block;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    img.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }

    .is-editor-empty:first-child::before {
      @apply text-gray-350;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    ol li::before {
      top: -0.5rem !important;
    }

    ul li::before {
      top: 0.65rem !important;
    }

    a {
      pointer-events: none;
    }

    ol > li::before {
      margin-top: -0.5rem;
    }

    ul > li::before {
      margin-top: 0.5rem;
    }

    &:where(p):not(:where([class~='not-prose'] *)) {
      padding-right: 4px;
    }

    &:where(p):not(:where([class~='not-prose'] *)) {
      display: block !important;
    }

    .iframe-wrapper {
      aspect-ratio: 16/9;
      width: 100%;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .mention {
      @apply text-indigo-500;
    }

    .code-block > pre {
      background: #0d0d0d !important;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 1.125rem 1rem;
      border-radius: 0.5rem;

      code {
        padding: 0;
        background: none;
        font-size: 0.8rem;
        background-color: rgba(#616161, 0.1);
        color: #e9e9e9;
      }
    }

    img,
    video {
      @apply max-h-160;
      width: auto;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
</style>
