<script setup lang="ts">
  import { ref, computed, onMounted } from 'vue';
  import LootboxDisplayText from './LootboxDisplayText.vue';
  import {
    CampaignMetaPageActionType,
    type CampaignMetaPage,
  } from '@/entities/lootbox';
  import { useFormatMarkdownContent } from '@/composables';
  import type { PortalView, UserView } from 'dfx/edge/edge.did';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { useUser, useMetaUser } from '@/entities/user';
  import { usePortal } from '@/entities/portal';
  import { JoinPortalButton } from '@/entities/portal';

  const props = defineProps<{
    campaignMetaPage: CampaignMetaPage;
    isAtEnd: boolean;
  }>();

  const emits = defineEmits<{
    (e: 'next'): void;
  }>();

  const userData = ref<UserView>();
  const portalData = ref<PortalView>();
  const hasAction = ref<boolean>(false);
  const isFollowing = ref<boolean | null>(null);

  const { getUserByUsername, checkIfFollowingUser } = useUser();
  const { loadPortalBySlug } = usePortal();
  const { compiledMarkdownAsHTML } = useFormatMarkdownContent();

  useMetaUser(userData);

  const campaignPageBody = computed(() => {
    return compiledMarkdownAsHTML(props.campaignMetaPage.body);
  });

  const hasCloseButton = computed(() => {
    return props.campaignMetaPage.action.closable || !hasAction.value;
  });

  const followTimeout = () => {
    setTimeout(() => {
      emits('next');
    }, 1000);
  };

  onMounted(async () => {
    const campaignAction = props.campaignMetaPage.action;
    if (campaignAction.type === CampaignMetaPageActionType.UserFollow) {
      userData.value = await getUserByUsername(campaignAction.target);
      if (userData.value) {
        isFollowing.value = await checkIfFollowingUser(userData.value?.id);
        hasAction.value = !isFollowing.value ?? false;
      }
    } else if (campaignAction.type === CampaignMetaPageActionType.PortalJoin) {
      loadPortalBySlug(campaignAction.target).then((portalView) => {
        if (portalView) {
          portalData.value = portalView;
          hasAction.value = !portalData.value.is_following;
        }
      });
    }
  });
</script>

<template>
  <div>
    <lootbox-display-text :body="campaignPageBody" />
  </div>
  <div>
    <div class="flex gap-2 items-center mt-4 justify-center">
      <base-button
        v-if="hasCloseButton"
        :variant="hasAction ? 'secondary' : 'primary'"
        rounded="rounded-xl"
        size="medium"
        class="backdrop-blur select-none"
        @click="emits('next')"
      >
        {{ props.isAtEnd ? 'Close' : 'Next' }}
      </base-button>

      <follow-user-button
        v-if="userData && !isFollowing"
        :user="userData"
        @update:is-following="followTimeout"
      />

      <join-portal-button
        v-if="portalData && !portalData.is_following"
        :portal="portalData"
        size="medium"
        join-class="group/join drop-shadow-light capitalize"
        @on-joined="followTimeout"
      />
    </div>
  </div>
</template>
