<script lang="ts" setup>
  import { type UserTooltipComponentsProps } from '@/entities/user';
  import type { RoleView } from 'dfx/edge/edge.did';
  import { Drawer } from '@/shared/ui/drawer';
  import { fromNow } from '@/utils';
  import { useUser } from '@/entities/user';
  import Roles from './Roles.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import FollowButton from '@/components/buttons/FollowButton.vue';
  import { useRouter } from 'vue-router';
  import { USER_CONTENT } from '@/common';
  import GoodbyeButton from './GoodbyeButton.vue';

  const props = withDefaults(defineProps<UserTooltipComponentsProps>(), {
    isAdmin: false,
  });

  const emits = defineEmits<{
    (e: 'update:isDrawerOpened', value: boolean): void;
    (e: 'goToProfile'): void;
    (e: 'onFollow', label: 'followers' | 'following'): void;
    (e: 'onAddNewRoleToUser', role: RoleView): void;
    (e: 'refetch'): void;
    (e: 'goodbye'): void;
  }>();

  const router = useRouter();
  const { getFormattedTokens } = useUser(props.user.id);
</script>

<template>
  <drawer
    direction="bottom"
    custom-classes="w-full max-h-full mt-2 overflow-y-auto thin-scrollbar shadow-lg bg-gray-950 rounded-t-md focus:outline-none"
    :show-header="false"
    :opened="isDrawerOpened"
    @update:opened="emits('update:isDrawerOpened', $event)"
  >
    <div class="relative p-4" role="none">
      <span
        class="absolute top-4 left-4"
        @click="emits('update:isDrawerOpened', false)"
      >
        <base-icon name="close" size="size-5" />
      </span>

      <div class="flex flex-col gap-2 items-center pt-9">
        <user-avatar :item="user" size="size-32" />

        <div class="flex flex-col items-center">
          <base-button
            variant="custom"
            custom-classes="flex items-center font-bold mt-4 text-gray-300 hover:text-indigo-300 text-2xl leading-7"
            @click="
              router.push({
                name: USER_CONTENT,
                params: {
                  username: user.username,
                },
              })
            "
          >
            <span v-if="isMention">@</span>{{ user.username }}
            <base-icon name="chevron-right" />
          </base-button>

          <div
            class="mt-3 text-base font-semibold leading-5 text-center text-gray-300"
          >
            {{ user.bio }}
          </div>

          <div class="flex gap-2 mt-3">
            <div
              class="flex items-center gap-1 px-2 py-1 text-sm rounded-md bg-gray-785"
            >
              <base-icon name="dscvr" size="size-4" />
              {{ getFormattedTokens(user.rights) }}
            </div>

            <div
              class="flex items-center gap-1 px-2 py-1 text-sm rounded-md bg-gray-785"
            >
              <base-icon name="clock" />
              Joined {{ fromNow(user.created_at) }}
            </div>
          </div>
        </div>

        <div class="flex justify-center gap-4 mt-3">
          <base-button
            variant="link"
            custom-classes="hover:underline follow-link"
            @click="emits('onFollow', 'following')"
          >
            {{ user.following }}

            <span class="text-blue-400">Following</span>
          </base-button>

          <base-button
            variant="link"
            custom-classes="hover:underline follow-link"
            @click="emits('onFollow', 'followers')"
          >
            {{ user.followers }}

            <span class="text-blue-400">Followers</span>
          </base-button>
        </div>
        <template v-if="isAdmin">
          <goodbye-button @goodbye="emits('goodbye')" />
        </template>
        <template v-if="'Portal' in portal.portal_type">
          <roles
            :is-owner="isOwner"
            :is-portal-manager="isPortalManager"
            :portal="portal"
            :user-portal-roles="userPortalRoles"
            :available-assignable-roles="availableAssignableRoles"
            @on-add-new-role-to-user="emits('onAddNewRoleToUser', $event)"
          />
        </template>
        <follow-button
          :is-following="isFollowing"
          :user-id="user.id"
          size="lg"
          class="mt-4"
          @follow-user="emits('refetch')"
          @login-modal-shown="tooltipRef?.hide()"
        />
      </div>
    </div>
  </drawer>
</template>
