import type { PortalInfoView, UserView } from 'dfx/edge/edge.did';
import type { MaybeRef, Ref } from 'vue';

export const userKey = 'user';
export const isFollowingKey = 'is-following';
export const userPortalRoleKey = 'user-portal-role';
export const assignableUserPortalRolesKey = 'assignable-user-portal-roles';

export const getUserPortalRolesByIdKey = (
  portal: Ref<PortalInfoView>,
  user: Ref<UserView>,
) => {
  return [
    userPortalRoleKey,
    'by-portal-id',
    portal.value.id.toString(),
    'by-user-id',
    user.value.id.toText(),
  ];
};

export const getAssignableUserPortalRolesByIdKey = (
  portal: Ref<PortalInfoView>,
) => {
  return [
    assignableUserPortalRolesKey,
    'by-portal-id',
    portal.value.id.toString(),
  ];
};

export const getUserByIdKey = (userId: MaybeRef<string>) => {
  return [userKey, 'get-user-by-id', userId];
};
