import type { AxiosResponse } from 'axios';
import { apiInstance } from '../base';
import type { RaffleEntryResponse, RaffleStatusResponse } from './types';

export type { RaffleEntryResponse, RaffleStatusResponse };

export const getRaffleStatus = (
  contentId: string,
): Promise<AxiosResponse<boolean | RaffleStatusResponse>> => {
  return apiInstance.get(`/raffle/check/${contentId}`).then((res) => res.data);
};
