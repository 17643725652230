<script setup lang="ts">
  import { toRefs } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { PORTAL_CONTENT } from '@/common';
  import { ClampLines } from '@/shared/ui/clamp-lines';
  import { usePortal } from '../model/composables/use-portal';
  import { useStoredPortal } from '../model/composables/use-stored-portal';
  import { useSinglePortal } from '../model/composables/use-single-portal';
  import ActionButton from './ActionButton.vue';
  import PortalStats from './PortalStats.vue';

  const props = defineProps<{
    portal: PortalView;
  }>();

  const { portal } = toRefs(props);
  const { storedPortal } = useStoredPortal(portal);
  const { hasCoverPhoto, coverPhoto } = useSinglePortal(storedPortal);
  const { getPortalIcon } = usePortal();
</script>

<template>
  <base-button
    v-if="storedPortal"
    variant="link"
    :to="{
      name: PORTAL_CONTENT,
      params: { portal: storedPortal.slug },
    }"
    custom-classes="flex w-full rounded-xl p-0.25 drop-shadow-light bg-gray-800 hover:bg-gradient-to-b from-[#764BA2] to-[#667EEA] group"
  >
    <div
      class="relative flex flex-col w-full gap-4 p-6 overflow-hidden sm:gap-1 rounded-xl"
    >
      <div
        class="absolute inset-0 transition-all duration-500 bg-center bg-cover blur-sm group-hover:brightness-50 group-hover:-inset-4 before:absolute before:inset-0 before:bg-black before:opacity-40"
        :class="{ 'group-hover:hue-rotate-180': !hasCoverPhoto }"
        :style="`background-image:url(${coverPhoto})`"
      />
      <div class="flex items-start z-1 md:hidden">
        <div class="flex-1">
          <img
            v-lazy="getPortalIcon(portal)"
            alt="Portal icon"
            class="rounded-lg min-w-15 size-15 shadow-light"
          />
        </div>

        <action-button :portal="storedPortal" />
      </div>
      <div class="flex items-center gap-4 z-1">
        <img
          v-lazy="getPortalIcon(portal)"
          alt="Portal icon"
          class="hidden min-w-30 w-30 h-30 md:block rounded-3xl shadow-light"
        />
        <div class="flex flex-col flex-1 min-w-0 gap-3 text-white">
          <div class="flex items-center justify-between gap-4">
            <h3
              class="flex-1 text-xl font-bold truncate"
              :title="storedPortal.name"
            >
              {{ storedPortal.name }}
            </h3>
            <div class="hidden md:block md:opacity-0 group-hover:opacity-100">
              <action-button :portal="storedPortal" />
            </div>
          </div>
          <portal-stats :portal="storedPortal" />
          <clamp-lines :text="storedPortal.description" class="min-h-10" />
        </div>
      </div>
    </div>
  </base-button>
</template>
