<script setup lang="ts">
  import type { ComputedRef } from 'vue';
  import { computed, ref, toRefs } from 'vue';
  import { useRoute } from 'vue-router';
  import { PORTAL_CONTENT } from '@/common';
  import { useDocumentScroll } from '@/shared/model';
  import FilterDropdown from './components/FilterDropdown.vue';
  import { useFeedFilter } from './composables/use-feed-filter';

  const route = useRoute();
  const {
    dateFilters,
    feedFilters,
    onUpdateDateFilter,
    onUpdateFeedFilter,
    onUpdateSortFilter,
    selectedDateFilter,
    selectedFeedFilter,
    selectedSortFilter,
    showSortRefinements,
    showTimeRefinements,
    sortFilters,
  } = useFeedFilter();

  defineEmits<{
    (e: 'update:sort', sort: string): void;
  }>();

  const { y, isScrolling } = useDocumentScroll();

  const scrollY = computed(() => parseFloat(y.value.toFixed(1)));
  const scrollOffset = computed(() => (isPortalPage.value ? 500 : 100));
  const scrollPositionReset = computed(() => (isPortalPage.value ? 425 : 1));

  const filterBarPositionClasses = computed(() => {
    return {
      'opacity-100 delay-500 duration-500 ':
        !isScrolling.value && scrollY.value > scrollPositionReset.value,
      'opacity-0': isScrolling.value && scrollY.value > scrollOffset.value,
    };
  });

  const isPortalPage: ComputedRef<boolean> = computed(
    () => route.name === PORTAL_CONTENT,
  );
</script>

<template>
  <div
    class="inline-flex mt-3 md:mt-0 mb-3 px-4 md:px-0 gap-x-2 z-30 transition-all duration-300 sticky top-24"
    :class="filterBarPositionClasses"
  >
    <filter-dropdown
      v-if="!isPortalPage"
      :model-value="selectedFeedFilter"
      :filters="feedFilters"
      :hide-mobile-label="true"
      button-classes="bg-gray-825 hover:bg-gray-850"
      extra-classes="w-84"
      @update:model-value="onUpdateFeedFilter"
    />
    <transition name="fade">
      <filter-dropdown
        v-if="showSortRefinements"
        :model-value="selectedSortFilter"
        :filters="sortFilters"
        button-classes="bg-gray-850 hover:gray-875"
        @update:model-value="onUpdateSortFilter"
      />
    </transition>
    <transition name="fade">
      <filter-dropdown
        v-if="showTimeRefinements"
        :filters="dateFilters"
        :model-value="selectedDateFilter"
        button-classes="bg-gray-850 hover:gray-875"
        @update:model-value="onUpdateDateFilter"
      />
    </transition>
  </div>
</template>
