import { computed, unref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { unescape, uniq } from 'lodash-es';
import { toHtml } from 'hast-util-to-html';
import { lowlight } from 'lowlight/lib/core';
import {
  useEmbeddedMention,
  useMapPlainText,
  type MapPlainTextState,
} from '@/composables';
import {
  isExternalLink,
  userMentionRegExp,
  portalMentionRegExp,
  postMentionRegExp,
  getUserMentionRegExp,
  getPostMentionRegExp,
  getPortalMentionRegExp,
  magicEdenRegExp,
  magicEdenPreviewRegExp,
  sanitizeSettings,
  lightUserMentionRegExp,
  getLightUserMentionRegExp,
  videoRegExp,
  imageRegExp,
  lazyImageRegExp,
  iframeRegExp,
} from '@/utils';
import sanitizeHtml from 'sanitize-html';
import { LITE_YOUTUBE_REGEX } from '@/common';

interface ComputedMatch {
  id: string;
  raw: string;
}

/**
 *
 * @param {string} html - Well formatted and sanitized html
 * @returns {string} html with external links with target blank
 */
function makeExternalAnchorTargetBlank(html: string): string {
  const linkRegExp = /<a.*?href="(?<href>[^"]*)"[^>]*>/g;
  const matches = [...html.matchAll(linkRegExp)];
  matches.forEach((match) => {
    const href = match.groups?.href;
    const linkRaw = match[0];
    if (href) {
      const hasTarget = /target=".*?"/.test(linkRaw);
      const hasTRel = /rel=".*?"/.test(linkRaw);
      if (isExternalLink(href) && !hasTarget && !hasTRel) {
        html = html.replace(
          linkRaw,
          linkRaw.replace(
            /^<a /,
            `<a target="_blank" rel="noreferrer noopener nofollow" `,
          ),
        );
      }
    }
  });

  return html;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @returns {ComputedMatch[]} a list with all the matches for user mentions. i.e <span data-type="mention" data-id="{username}">{username}<\/span>
 */
function findUserMatches(html: string): ComputedMatch[] {
  const userMentionMatches = html.matchAll(userMentionRegExp);
  const lightUserMentionMatches = html.matchAll(lightUserMentionRegExp);
  const matches = [...userMentionMatches, ...lightUserMentionMatches];
  const computed = matches.map((match) => ({
    id: match.groups?.id || '',
    raw: match[0],
  }));
  return computed;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @returns ComputedMatch[]
 */
function findPostMatches(html: string): ComputedMatch[] {
  const matches = [...html.matchAll(postMentionRegExp)];
  const computed = matches.map((match) => ({
    id: match.groups?.id || '',
    raw: match[0],
  }));
  return computed;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @returns ComputedMatch[]
 */
function findPortalMatches(html: string): ComputedMatch[] {
  const matches = [...html.matchAll(portalMentionRegExp)];
  const computed = matches.map((match) => ({
    id: match.groups?.id || '',
    raw: match[0],
  }));
  return computed;
}

/**
 *
 * @param matches
 * @returns {string[]} a list with all the unique ids from the matches (match.id)
 */
function getUniqueIds(matches: ComputedMatch[]): string[] {
  return uniq(matches.map((m) => m.id));
}

function enrichUsersReplacement(key: string) {
  return `<user-tooltip v-if="usersHash.has('${key.toLowerCase()}')"
            :user="usersHash.get('${key.toLowerCase()}')"
            :portal="portal"
            is-mention
            :show-pfp="false"
            :color="usersHash.get('${key.toLowerCase()}').color"
            avatar-size="md"
          />
          <span v-else>@${key}</span>`;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @param {string[]} matches a list of usernames
 * @returns {string} html with the user mentions replaced by the user-tooltip component
 */
function enrichUsers(html: string, matches: string[]): string {
  matches.forEach((key) => {
    const regExp = getUserMentionRegExp(key);
    html = html.replace(regExp, enrichUsersReplacement(key));
  });

  return html;
}

function enrichLightUsers(
  plainText: string,
  options: MapPlainTextState,
  matches: ComputedRef<{
    usernames: string[];
  }>,
): string {
  if (options.openTags.includes('a') || options.openTags.includes('code')) {
    return plainText;
  }

  // Its already a strong mention
  if (
    options.htmlResult.match(
      /<span data-type="mention"[^>]*?data-id="(?<id>[^"]+)"[^>]*>$/,
    )
  ) {
    return plainText;
  }

  matches.value.usernames.forEach((key) => {
    const regExp = getLightUserMentionRegExp(key);
    const replace = enrichUsersReplacement(key);
    plainText = plainText.replace(regExp, replace);
  });

  return plainText;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @param {string[]} matches - a list of postids (as strings)
 * @returns {string} html with the post mentions replaced by the item-inside component
 */
function enrichPosts(html: string, matches: string[]): string {
  matches.forEach((key) => {
    const regExp = getPostMentionRegExp(key);
    html = html.replace(regExp, `<embedded-post content-id="${key}" />`);
  });

  return html;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @param {string[]} matches - a list of portal slugs
 * @returns {string} html with the portal mentions replaced by the item-inside component
 */
function enrichPortals(html: string, matches: string[]): string {
  matches.forEach((key) => {
    const regExp = getPortalMentionRegExp(key);
    html = html.replace(
      regExp,
      `<embedded-portal v-if="portalsHash.has('${key}')" :portal="portalsHash.get('${key}')" />`,
    );
  });

  return html;
}

/**
 *
 * @param {string}  html - Well formatted and sanitized html
 * @returns {string} html with the iframe tags replaced by a new iframe container tag
 */
function enrichIframes(html: string): string {
  const matches = [...html.matchAll(iframeRegExp)];

  matches.forEach((match) => {
    const src = unescape(match.groups?.src || '');
    // backward compatibility for youtube video to use lite-youtube
    const isSrcFromYoutube = LITE_YOUTUBE_REGEX.test(src);
    html = html.replace(
      match[0],
      isSrcFromYoutube
        ? `<embedded-lite-youtube src="${src}"></embedded-lite-youtube>`
        : `<dynamic-iframe src="${src}" />`,
    );
  });

  return html;
}

function enrichMagicEden(html: string): string {
  const linkMatches = [...html.matchAll(magicEdenRegExp)];
  linkMatches.forEach((match) => {
    let key = match.groups?.id;

    if (!key) return;
    if (key.includes('?')) {
      key = key.split('?')[0];
    }

    html = html.replace(
      match[0],
      `<embedded-nft :nft-id="'${key}'"
    />`,
    );
  });

  const embedMatches = [...html.matchAll(magicEdenPreviewRegExp)];
  embedMatches.forEach((match) => {
    let key = match[1];

    if (!key) return;
    if (key.includes('?')) {
      key = key.split('?')[0];
    }

    html = html.replace(
      match[0],
      `<embedded-nft :nft-id="'${key}'"
    />`,
    );
  });

  return html;
}

function enrichImages(html: string): string {
  const matches = [
    ...html.matchAll(lazyImageRegExp),
    ...html.matchAll(imageRegExp),
  ];

  matches.forEach((match) => {
    if (!match.groups) return;
    const src = match.groups.src;
    html = html.replace(match[0], `<dynamic-image src="${src}" />`);
  });

  return html;
}

function enrichVideos(html: string): string {
  const matches = [...html.matchAll(videoRegExp)];

  matches.forEach((match) => {
    if (!match.groups) return;
    const src = match.groups.src;
    html = html.replace(match[0], `<dynamic-video src="${src}" />`);
  });

  return html;
}

function sanitizeVueTemplate(html: string): string {
  return html.replace(/\{\{/g, '{<!-- -->{');
}

function highlightCode(container: HTMLElement) {
  container.querySelectorAll('pre code').forEach((el) => {
    const content = el.innerHTML.replace(/<br>/g, '\n');
    const nodeTree = lowlight.highlightAuto(content);
    el.innerHTML = toHtml(nodeTree);
  });
}

/**
 *
 * @param {Ref<string>} body - the body of the post
 * @returns a computed property with the body of the post with the mentions replaced by the corresponding components
 */
export function useFormatDynamicContent(body: Ref<string>) {
  const { fetchUsers, fetchPortals } = useEmbeddedMention();

  const cleansedHtml = computed(() => {
    let html = unref(body);
    html = makeExternalAnchorTargetBlank(html);
    html = sanitizeHtml(html, sanitizeSettings);
    html = sanitizeVueTemplate(html);
    return html;
  });

  const matches = computed(() => {
    const html = unref(cleansedHtml);
    const usernames = getUniqueIds(findUserMatches(html));
    const postIds = getUniqueIds(findPostMatches(html));
    const portalSlugs = getUniqueIds(findPortalMatches(html));
    return { usernames, postIds, portalSlugs };
  });

  watch(
    () => matches.value,
    ({ usernames, portalSlugs }) => {
      fetchUsers(usernames);
      fetchPortals(portalSlugs);
    },
    {
      immediate: true,
    },
  );

  const { resultHtml: plainTextMappedHtml } = useMapPlainText(cleansedHtml, {
    replace: (plainText, options) =>
      enrichLightUsers(plainText, options, matches),
  });

  const computedHtml = computed(() => {
    let html = unref(plainTextMappedHtml);
    const { usernames, postIds, portalSlugs } = unref(matches);
    html = enrichUsers(html, usernames);
    html = enrichPosts(html, postIds);
    html = enrichPortals(html, portalSlugs);
    html = enrichIframes(html);
    html = enrichImages(html);
    html = enrichVideos(html);
    html = enrichMagicEden(html);
    return html;
  });

  return {
    computedHtml,
    highlightCode,
  };
}
