<template>
  <div v-if="currentPortal">
    <div class="text-lg font-bold text-white">Roles</div>

    <div class="text-sm text-gray-400">
      Use roles to organize your users and customize their permissions
    </div>

    <error-display
      class="mt-3"
      :errors="errors"
      :error-message="errorMessage"
      :local-errors="localErrors"
    />

    <router-link
      v-if="defaultRole"
      class="flex items-center justify-between p-3 bg-gray-700 rounded-lg min-w-max"
      :to="{
        name: PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
        params: { roleId: defaultRole.id },
      }"
    >
      <div>
        <div class="text-white text-medium">Default Permissions</div>

        <div class="text-xs text-white">
          {{ defaultRole.name }} • Applies to all members when they first join
        </div>
      </div>

      <base-icon name="chevron-right" size="w-5 h-5" />
    </router-link>

    <router-link
      v-if="everyoneRole"
      class="flex items-center justify-between p-3 mt-2 bg-gray-700 rounded-lg min-w-max"
      :to="{
        name: PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
        params: { roleId: everyoneRole.id },
      }"
    >
      <div>
        <div class="text-white text-medium">Everyone Permissions</div>

        <div class="text-xs text-white">
          Applies to all non-member DSCVR users.
        </div>
      </div>

      <base-icon name="chevron-right" size="w-5 h-5" />
    </router-link>

    <div
      class="flex items-center justify-between w-full my-4 flex-nowrap"
    ></div>

    <div class="my-4 text-white">
      <div class="flex items-center">
        <div class="w-1/2 text-lg font-bold">
          Roles - {{ roles ? otherRoles.length : 0 }}
        </div>

        <div class="w-1/2 text-lg font-bold">Members</div>

        <router-link
          :to="{ name: PORTAL_SETTINGS_PAGE_ROLES_DETAILS }"
          class="px-3 py-2 ml-2 text-xs font-medium bg-indigo-600 rounded-lg whitespace-nowrap"
        >
          Create Role
        </router-link>
      </div>
      <div
        class="flex items-center justify-between w-full py-1 my-4 bg-gray-900 rounded-lg flex-nowrap"
      >
        <base-icon name="search" size="w-5 h-5" class="ml-2 cursor-pointer" />
        <input
          v-model="searchedRole"
          type="text"
          class="block w-full text-white bg-gray-900 border-transparent border-none rounded-lg focus:ring-0"
          placeholder="Search Roles..."
        />
      </div>
      <div>
        <draggable
          v-if="localRoles && otherRoles.length"
          v-model="localRoles"
          item-key="id"
          class="mb-3"
          @change="ordinalsChanged = true"
        >
          <template #item="{ element }">
            <portal-setting-roles-item
              :role="element"
              v-show="
                element.id != defaultRole.id &&
                element.id != everyoneRole.id &&
                (searchedRole == null ||
                  element.name
                    .toLowerCase()
                    .includes(searchedRole.toLowerCase()))
              "
              @delete="roleToDelete = element"
            />
          </template>
        </draggable>

        <div
          class="fixed inset-0 top-0 left-0 z-40 flex items-center justify-center h-screen bg-gray-900 min-w-screen animated fadeIn faster o utline-none focus:outline-none bg-opacity-20"
          :class="{ hidden: !roleToDelete }"
        >
          <div class="absolute inset-0 z-0 bg-black opacity-80" />

          <div
            class="relative w-full max-w-lg p-5 mx-auto my-auto bg-gray-950 shadow-lg rounded-xl"
          >
            <!--content-->
            <div>
              <!--body-->
              <div class="justify-center flex-auto p-5 text-center">
                <div class="text-lg font-bold">
                  Are you sure you want to remove role
                  <b>{{ roleToDelete?.name }}</b
                  >?
                </div>
              </div>

              <!--footer-->
              <div class="flex justify-between p-3 mt-2">
                <div>
                  <button
                    class="p-3 ml-3 text-xs font-bold uppercase bg-gray-900 rounded-full whitespace-nowrap"
                    @click="roleToDelete = null"
                  >
                    Close
                  </button>
                </div>

                <div>
                  <button
                    class="p-2 ml-3 text-xs font-bold uppercase bg-red-800 rounded-full whitespace-nowrap"
                    @click="deletePortalRole"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-end justify-end">
          <button
            :disabled="ordinalsChanged == false"
            class="text-xs btn-submit whitespace-nowrap disabled:opacity-50"
            @click="updateOrdinals()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Draggable from 'vuedraggable';
  import { ActionTypes } from '../store/action-types';
  import ErrorDisplay from './ErrorDisplay.vue';
  import {
    PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
    ROLE_KIND_FIELD_DEFAULT,
    ROLE_KIND_FIELD_EVERYONE,
  } from '../common';
  import { useNFTGating } from '@/entities/nft-gate';
  import PortalSettingRolesItem from './PortalSettingsRolesItem.vue';

  export default {
    name: 'portal-settings-roles',
    components: {
      Draggable,
      PortalSettingRolesItem,
      ErrorDisplay,
    },
    props: {
      portal: {
        type: Object,
        default: () => null,
      },
      roles: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
        roleToDelete: null,
        searchedRole: null,
        localErrors: [],
        errorMessage: '',
        errors: [],
        ordinalsChanged: false,
        localRoles: [],
      };
    },
    setup() {
      const { loadSauronPortalRoles } = useNFTGating();

      return { loadSauronPortalRoles };
    },
    computed: {
      ...mapGetters({
        currentPortal: 'portals/currentPortal',
        currentPortalRoles: 'portals/currentPortalRoles',
      }),
      defaultRole() {
        if (!this.roles && this.roles.length == 0) return null;

        return this.roles.find((role) => ROLE_KIND_FIELD_DEFAULT in role.kind);
      },
      everyoneRole() {
        if (!this.roles && this.roles.length == 0) return null;

        return this.roles.find((role) => ROLE_KIND_FIELD_EVERYONE in role.kind);
      },
      otherRoles() {
        return this.roles.filter(
          (role) =>
            role != this.defaultRole &&
            role != this.everyoneRole &&
            (this.searchedRole == null ||
              role.name
                .toLowerCase()
                .includes(this.searchedRole.toLowerCase())),
        );
      },
    },
    watch: {
      roles: {
        handler(newVal) {
          this.localRoles = newVal;
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      if (this.currentPortal) {
        this.$store.dispatch(
          `portals/${ActionTypes.GET_PORTAL_ROLES}`,
          this.currentPortal.id,
        );
        this.loadSauronPortalRoles();
      }
    },
    methods: {
      deletePortalRole() {
        if (!this.roleToDelete || !this.roleToDelete.id) return;

        // update role
        this.$store
          .dispatch(
            `portals/${ActionTypes.DELETE_PORTAL_ROLE}`,
            this.roleToDelete.id,
          )
          .then(() => {
            // close popup
            this.roleToDelete = null;
            this.$store.dispatch(
              `portals/${ActionTypes.GET_PORTAL_ROLES}`,
              this.currentPortal.id,
            );
          });
      },
      updateOrdinals() {
        if (this.ordinalsChanged == false) return;

        if (this.currentPortal && this.currentPortal.id) {
          const ordinals = [];

          for (let i = 0; i < this.localRoles.length; i++) {
            ordinals.push([this.localRoles[i].id, i]);
          }

          this.$store
            .dispatch(`portals/${ActionTypes.UPDATE_PORTAL_ROLES_ORDINALS}`, {
              portal_id: this.currentPortal.id,
              ordinals: ordinals,
            })
            .then(() => {
              // refetching roles
              this.$store.dispatch(
                `portals/${ActionTypes.GET_PORTAL_ROLES}`,
                this.currentPortal.id,
              );
            });
        }
      },
    },
  };
</script>
