<script lang="ts" setup>
  import ItemRow from '../../ui/ItemRow.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import type { UserView } from 'dfx/edge/edge.did';
  import { useDialog, useToast } from '@/shared/model';
  import { useUser } from '@/entities/user';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { useI18n } from 'vue-i18n';
  import { Loader } from '@/shared/ui/loader';
  import { USER_CONTENT } from '@/common';
  import { useRouter } from 'vue-router';
  import type { SocialSphereTab } from '../..';
  import { computed } from 'vue';

  const MIN_ITEM_SIZE = 72;
  const BUFFER_PX = 360;

  const props = defineProps<{
    currentPage: number;
    tabValue: SocialSphereTab;
    loading: boolean;
    totalPages: bigint;
    users: UserView[];
  }>();

  const emit = defineEmits<{
    (e: 'loadUserList', page: number): void;
  }>();

  const router = useRouter();
  const { closeDialog } = useDialog();
  const { blockUserToggle, removeFollower, isCurrentUserProfile } = useUser();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });

  const usersList = computed(() => {
    return props.users.map((user) => {
      return {
        ...user,
        idAsString: user.id.toString(),
      };
    });
  });

  const block = async (user: UserView) => {
    const isBlocked = await blockUserToggle(user.id);
    showToast({
      title: isBlocked
        ? `${user.username} ${t('userBlocked')}`
        : `${user.username} ${t('userNoLongerBlocked')}`,
      type: 'success',
      durationSeconds: 3,
    });
    emit('loadUserList', props.currentPage);
  };

  const remove = async (user: UserView) => {
    await removeFollower(user.id);
    showToast({
      title: `${user.username} ${t('userNoLongerFollowsYou')}`,
      type: 'success',
      durationSeconds: 3,
    });
  };

  const next = () => {
    emit('loadUserList', props.currentPage + 1);
  };

  const prev = () => {
    emit('loadUserList', props.currentPage - 1);
  };

  const onClickUserRow = (user: UserView) => {
    closeDialog();
    router.push({ name: USER_CONTENT, params: { username: user.username } });
  };
</script>

<template>
  <loader
    v-if="loading"
    variant="rainbow"
    border-width="border"
    size="w-10 h-10"
    class="mt-8"
  />
  <template v-else>
    <recycle-scroller
      v-if="usersList.length > 0"
      page-mode
      :items="usersList"
      :min-item-size="MIN_ITEM_SIZE"
      :buffer="BUFFER_PX"
      v-slot="{ item }"
    >
      <base-button
        variant="custom"
        @click="onClickUserRow(item)"
        custom-classes="flex justify-between items-center w-full"
        :key="item.idAsString"
      >
        <item-row>
          <template #avatar>
            <user-avatar :item="item" size="w-10 h-10" />
          </template>
          <template #username>
            {{ item.username }}
          </template>
          <template #actions>
            <follow-user-button
              v-if="tabValue === 'followers' || tabValue === 'following'"
              size="x-small"
              :user="item"
              fully-elastic
            />
            <base-dropdown
              v-if="tabValue === 'followers' && isCurrentUserProfile"
              append-to-body
              placement="bottom-end"
              custom-content-classes="flex flex-col shadow-lg bg-gray-785 rounded-xl cursor-default"
            >
              <template #button="{ toggle }">
                <base-button variant="tertiary" size="small" @click="toggle">
                  <base-icon
                    name="ellipsis"
                    size="w-4 h-4"
                    class="block text-gray-400 group-hover:text-white"
                  />
                </base-button>
              </template>
              <template #content="{ hide }">
                <div
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div role="none">
                    <span
                      class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200 whitespace-nowrap"
                      @click.stop="hide(), block(item)"
                    >
                      <base-icon
                        name="outline-block"
                        size="min-w-6 w-6 h-6"
                        class="mr-3.5 m-1.5 text-indigo-300"
                      />
                      {{ $t('block') }} {{ item.username }}
                    </span>
                    <span
                      class="flex items-center px-4 py-2 text-base font-medium text-gray-300 cursor-pointer hover:bg-gray-600 hover:text-gray-200 whitespace-nowrap"
                      @click.stop="hide(), remove(item)"
                    >
                      <base-icon
                        name="remove"
                        size="min-w-6 w-6 h-6"
                        class="mr-3.5 m-1.5 text-indigo-300"
                      />
                      {{ $t('remove') }} {{ item.username }}
                    </span>
                  </div>
                </div>
              </template>
            </base-dropdown>
            <base-button
              v-if="!isCurrentUserProfile && tabValue === 'blocked'"
              variant="primary"
              size="small"
              @click="block(item)"
            >
              {{ $t('unblock') }}
            </base-button>
          </template>
        </item-row>
      </base-button>
    </recycle-scroller>
    <div
      v-if="users.length === 0"
      class="relative text-lg font-bold text-center text-white py-7 z-10"
    >
      Nobody here yet 🥺
    </div>
    <div
      v-if="currentPage >= 0"
      class="relative flex gap-4 justify-center items-center mt-4 w-full z-10"
    >
      <base-button
        v-if="currentPage >= 1"
        variant="primary"
        size="small"
        @click="prev"
      >
        {{ $t('prevPage') }}
      </base-button>
      <base-button
        v-if="Number(totalPages - 1n) - currentPage > 0"
        variant="primary"
        size="small"
        @click="next"
      >
        {{ $t('nextPage') }}
      </base-button>
    </div>
  </template>
</template>
