import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import type { AuthState } from './types';
import authService from '@/services/auth';
import betaFeatures from '@/services/beta-features';
import { ActionTypes } from '@/store/action-types';
import { MutationTypes } from '@/store/mutation-types';
import type {
  CreateICPUser,
  GlobalLiveData,
  SocialProfile,
  UpdateUsername,
  UserListPaged,
  UserPlatformPair,
  UserSearchQuery,
  UserSelfView,
  UserView,
  UserWallet,
} from 'dfx/edge/edge.did';
import { client } from '@/utils/dfinity';
import { setGAUserId } from '@/utils/tracker';

// state
const state = (): AuthState => ({
  isLoading: false,
  me: null,
  loginRoute: null,
  mentionsHash: new Map(),
  betaFeatures: null,
  unreadNotificationCount: BigInt(0),
  pairedWallets: [],
  pairedPlatforms: [],
  streakCount: null,
});

// getters
const getters: GetterTree<AuthState, unknown> = {
  me: (state: AuthState) => state.me,
  mentionsHash: (state: AuthState) => state.mentionsHash,
  isCurrentUserAdmin: (state: AuthState) =>
    state.me && (state.me.user_type as unknown as number) === 2,
  isLoading: (state: AuthState) => state.isLoading,
  loginRoute: (state: AuthState) => state.loginRoute,
  betaFeatures: (state: AuthState) => state.betaFeatures,
  unreadNotificationCount: (state: AuthState) => state.unreadNotificationCount,
  pairedWallets: (state: AuthState) => state.pairedWallets,
  pairedPlatforms: (state: AuthState) => state.pairedPlatforms,
  streakCount: (state: AuthState) => state.streakCount,
};

// actions
const actions: ActionTree<AuthState, unknown> = {
  [ActionTypes.SET_IS_LOADING]({ commit }, isLoading: boolean) {
    commit(MutationTypes.SET_IS_LOADING, isLoading);
  },
  async [ActionTypes.GET_SEARCHED_USERS_PAGINATED](
    { commit },
    query: UserSearchQuery,
  ) {
    try {
      const response = await authService.search_users(query);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_SEARCHED_USERS]({ commit }, query: [string]) {
    try {
      const response = await authService.user_search(query);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_USER]({ commit }, username: string) {
    try {
      const response = await authService.get_user(username);
      if (
        response &&
        response.status === 'happy' &&
        response.result.length > 0
      ) {
        commit(MutationTypes.SET_MENTION, response.result[0]);
      }
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_SELF](
    { dispatch, commit },
    payload = { hasLoading: false, hasNotification: false },
  ) {
    if (payload.hasLoading) {
      commit(MutationTypes.SET_IS_LOADING, true);
    }
    try {
      await dispatch(ActionTypes.UPDATE_BETA_FEATURES);
      const response = await authService.current_user();
      // If not a provisional user then set the user
      if (
        !payload.hasNotification &&
        response.result.length > 0 &&
        response.result[0] &&
        !response.result[0].is_provisional
      ) {
        commit(MutationTypes.SET_ME, response.result[0]);
      }
      return response;
    } catch (error) {
      const errorStr = `${error}`;
      console.error(`GET_SELF error ${errorStr}`);
      if (errorStr.indexOf('InvalidSignature') > 0) {
        // Workaround for DCP-984, where an invalid signature is returned
        // logging out should fix the problem.
        console.error('Invalid signature detected. Logging out.');
        client.logout();
      }
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.UPDATE_BETA_FEATURES]({ commit }) {
    try {
      const features = await betaFeatures.get_enabled_beta_features();
      commit(MutationTypes.SET_BETA_FEATURES, features);
    } catch (error) {
      console.error(error);
    }
  },
  async [ActionTypes.GET_USER_BLOCKED](_, query: { page: number }) {
    try {
      const response = await authService.get_user_blocked(query);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async [ActionTypes.GET_USER_FOLLOWERS](
    _,
    query: {
      username: string;
      page: UserListPaged;
    },
  ) {
    try {
      const response = await authService.get_user_following(query);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async [ActionTypes.GET_USER_FOLLOWING](
    _,
    query: {
      username: string;
      page: UserListPaged;
    },
  ) {
    try {
      const response = await authService.get_user_followers(query);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async [ActionTypes.UPDATE_USERNAME]({ commit }, username: UpdateUsername) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const response = await authService.update_username(username);
      if (response.result.length > 0) {
        commit(MutationTypes.SET_ME, response.result[0]);
      }
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.UNPAIR_PHONE]({ commit }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const response = await authService.unpair_phone();
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_MENTION]({ commit, state }, username: string) {
    try {
      if (state.mentionsHash.has(username.toLowerCase())) {
        return;
      }
      const response = await authService.get_user(username);
      if (
        response &&
        response.status === 'happy' &&
        response.result.length > 0
      ) {
        commit(MutationTypes.SET_MENTION, response.result[0]);
      }
    } catch (error) {
      console.log(error);
    }
  },
  [ActionTypes.SET_LOGIN_ROUTE](
    { commit },
    route: RouteLocationNormalizedLoaded | null,
  ) {
    commit(MutationTypes.SET_LOGIN_ROUTE, route);
  },
  async [ActionTypes.GET_GLOBAL_LIVE_DATA]({ commit }) {
    try {
      const response = await authService.getGlobalLiveData();
      if ('Err' in response) {
        console.error(response.Err);
      } else {
        commit(MutationTypes.SET_GLOBAL_LIVE_DATA, response.Ok);
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.SET_SETTINGS]({ commit }, settings) {
    try {
      const response = await authService.setSettings(settings);
      if ('Err' in response) {
        console.error(response.Err);
      } else {
        return response.Ok;
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  [ActionTypes.UPDATE_SOCIAL_PRESENCE](
    { commit, state },
    socialPresence: SocialProfile[],
  ) {
    const social_presence_2 = socialPresence.map((item) => [item]);
    commit(MutationTypes.SET_ME, {
      ...state.me,
      social_presence_2: [social_presence_2],
    });
    return authService.update_social_presence(socialPresence);
  },
  async [ActionTypes.GET_PAIRED_WALLETS]({ commit }) {
    const response = await authService.get_paired_wallets();
    commit(MutationTypes.SET_USER_PAIRED_PLATFORMS, response);
  },
  async [ActionTypes.GET_PAIRED_PLATFORMS]({ commit }) {
    const response = await authService.get_paired_platforms();
    commit(MutationTypes.SET_USER_PAIRED_PLATFORMS, response);
  },
  [ActionTypes.UNPAIR_PLATFORM](_, platform) {
    return authService.unpair_platform(platform);
  },
  [ActionTypes.SET_ME]: ({ commit }, me: UserSelfView | UserView | null) =>
    commit(MutationTypes.SET_ME, me),
};

// mutations
const mutations: MutationTree<AuthState> = {
  [MutationTypes.SET_ME](state, me: UserSelfView | null) {
    state.me = me;
    if (state.me) {
      setGAUserId(state.me.id.toString());
    }
  },
  [MutationTypes.SET_IS_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [MutationTypes.SET_LOGIN_ROUTE](
    state,
    loginRoute: RouteLocationNormalizedLoaded | null,
  ) {
    state.loginRoute = loginRoute;
  },
  [MutationTypes.SET_MENTION](state, user: UserView) {
    const mentionsHash = state.mentionsHash;
    mentionsHash.set(user.username.toLowerCase(), user);
  },
  [MutationTypes.SET_BETA_FEATURES](state, betaFeatures: string | null) {
    state.betaFeatures = betaFeatures;
  },
  [MutationTypes.SET_GLOBAL_LIVE_DATA](state, val: GlobalLiveData) {
    if (val) {
      state.unreadNotificationCount = val.unread_notification_count;
      if (val.tokens) {
        state.me!.rights = val.tokens;
      }
      state.streakCount = val.streak_count;
    }
  },
  [MutationTypes.SET_USER_PAIRED_WALLETS](state, wallets: UserWallet[]) {
    state.pairedWallets = wallets;
  },
  [MutationTypes.SET_USER_PAIRED_PLATFORMS](
    state,
    platforms: UserPlatformPair[],
  ) {
    state.pairedPlatforms = platforms;
  },
};

const module: Module<AuthState, unknown> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default module;
