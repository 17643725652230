import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useToast } from '@/shared/model';
import { useNotificationStore } from '@/entities/notification';
import { ActionTypes } from '@/store/action-types';
import { useUser } from '../../composables/use-user';
import type {
  ActionResultUserSelf,
  AppNotificationSettings,
  EmailMessagingSettings,
  GroupAppNotificationType,
  UserSettingsView,
} from './types';

export const useNotificationSettingStore = defineStore(
  'user-setting-notification',
  () => {
    const { currentUser } = useUser();
    const store = useStore();
    const notificationStore = useNotificationStore();

    const { t } = useI18n({ useScope: 'global' });
    const { showToast } = useToast();

    const isLoading = ref(true);

    const inAppNotifications = ref(
      undefined as Omit<AppNotificationSettings, 'shares_reposts'> | undefined,
    );
    const emailNotifications = ref(
      undefined as EmailMessagingSettings | undefined,
    );
    const setEmailNotifications = () => {
      emailNotifications.value!.marketing =
        !emailNotifications.value!.marketing;
    };

    const getSelfSettings = async () => {
      const { result }: ActionResultUserSelf = await store.dispatch(
        `auth/${ActionTypes.GET_SELF}`,
        { hasLoading: false, hasNotification: true },
      );
      return {
        appNotification: result[0]?.user_settings.app_notification[0],
        emailNotification: result[0]?.user_settings.email_messaging[0],
        groupNotification:
          result[0]?.user_settings.app_notification_grouping[0],
      };
    };

    const getUserSettings = async () => {
      try {
        isLoading.value = true;
        const { appNotification, emailNotification, groupNotification } =
          await getSelfSettings();
        inAppNotifications.value = {
          comments_replies: appNotification?.comments_replies!,
          new_followers: appNotification?.new_followers!,
          likes_reactions: appNotification?.likes_reactions!,
          mentions: appNotification?.mentions!,
          streak_reminders: appNotification?.streak_reminders!,
          // Note: Hidden as per discussion with Jay
          // shares_reposts: appNotification?.shares_reposts!,
        };
        emailNotifications.value = emailNotification;
        groupNotifications.value = groupNotification!.length === 4;
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    const setUserSettings = async (
      enabled: boolean,
      type: Exclude<keyof AppNotificationSettings, 'shares_reposts'>, // eg. comments_replies
    ) => {
      if (type === 'streak_reminders' && !currentUser.value.email_verified) {
        showToast({
          type: 'error',
          title: t('notifications.settings.streakRemindersErrorTitle'),
        });
        return;
      }
      try {
        isLoading.value = true;
        notificationStore.resetState();
        const inApp = Object.entries(inAppNotifications.value!);
        for (let [key] of inApp) {
          if (key === type) {
            if (enabled) {
              if (type === 'streak_reminders') {
                inAppNotifications.value![type] = [{ Email: null }];
              } else {
                inAppNotifications.value![type] = [{ App: null }];
              }
            } else {
              inAppNotifications.value![type] = [];
            }
          }
        }
        const settings: UserSettingsView = {
          ...currentUser.value.user_settings,
          app_notification: [
            {
              ...inAppNotifications.value,
              // @ts-ignore
              shares_reposts:
                currentUser.value.user_settings.app_notification[0]
                  ?.shares_reposts,
            },
          ],
          email_messaging: [emailNotifications.value!],
        };
        store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, true);
        await store.dispatch(`auth/${ActionTypes.SET_SETTINGS}`, settings);
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        console.error(error);
      } finally {
        store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, false);
        isLoading.value = false;
      }
    };

    const groupNotifications = ref(false);
    const setGroupNotifications = async (e: boolean) => {
      groupNotifications.value = e;
      try {
        isLoading.value = true;
        const appNotificationGrouping: GroupAppNotificationType[] =
          groupNotifications.value
            ? [
                { CommentReply: null },
                { PostReaction: null },
                { PostReply: null },
                { CommentReaction: null },
              ]
            : [];
        const settings: UserSettingsView = {
          ...currentUser.value.user_settings!,
          app_notification_grouping: [appNotificationGrouping],
        };
        store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, true);
        await store.dispatch(`auth/${ActionTypes.SET_SETTINGS}`, settings);
        await getSelfSettings();
        isLoading.value = false;
      } catch (error) {
        console.error(error);
        isLoading.value = false;
      } finally {
        store.dispatch(`auth/${ActionTypes.SET_IS_LOADING}`, false);
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      inAppNotifications,
      emailNotifications,
      setEmailNotifications,
      getUserSettings,
      setUserSettings,
      groupNotifications,
      setGroupNotifications,
    };
  },
);
