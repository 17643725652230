<script lang="ts" setup>
  import { useUser } from '@/entities/user';
  import { UserAvatar } from '@/shared/ui/user-avatar';

  withDefaults(
    defineProps<{
      showDetails?: boolean;
    }>(),
    {
      showDetails: true,
    },
  );

  const { currentUser, getFormattedTokens } = useUser();
</script>
<template>
  <div class="flex gap-2">
    <user-avatar
      v-if="currentUser"
      :item="currentUser"
      size="w-8 h-8 md:w-10 md:h-10"
    />
    <div v-if="showDetails" class="flex flex-col items-start">
      <div class="font-semibold text-sm">
        {{ currentUser.username }}
      </div>
      <div class="flex items-center gap-1">
        <base-icon name="dscvr" size="w-3 h-3" />
        <span class="text-xs text-gray-400">
          {{ getFormattedTokens(currentUser.rights) }}
        </span>
      </div>
    </div>
  </div>
</template>
