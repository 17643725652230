<script setup lang="ts">
  import { toRefs } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { PORTAL_CONTENT } from '@/common';
  import { usePortal } from '../model/composables/use-portal';
  import { useStoredPortal } from '../model/composables/use-stored-portal';
  import { useSinglePortal } from '../model/composables/use-single-portal';
  import ActionButton from './ActionButton.vue';

  const props = withDefaults(
    defineProps<{
      portal: PortalView;
      hideLabel?: boolean;
    }>(),
    {
      hideLabel: false,
    },
  );

  const { portal } = toRefs(props);
  const { storedPortal } = useStoredPortal(portal);
  const { hasCoverPhoto, coverPhoto, abbreviatedMemberCount } =
    useSinglePortal(storedPortal);
  const { getPortalIcon } = usePortal();
</script>

<template>
  <base-button
    v-if="storedPortal"
    variant="link"
    :to="{
      name: PORTAL_CONTENT,
      params: { portal: portal.slug },
    }"
    custom-classes="flex flex-col justify-between w-full rounded-xl p-4 drop-shadow-light border border-gray-800 relative overflow-hidden group before:absolute before:inset-0 before:bg-gradient-to-b before:from-black/10 before:to-black/90 before:z-1 p-4 portrait max-h-60"
  >
    <div
      class="absolute inset-0 transition-all duration-500 bg-center bg-cover group-hover:-inset-4"
      :class="{ 'group-hover:hue-rotate-180': !hasCoverPhoto }"
      :style="`background-image:url(${coverPhoto})`"
    />
    <div class="flex justify-end z-1">
      <action-button :portal="portal" :hide-label="hideLabel" />
    </div>
    <div class="flex items-center z-1 gap-3.5">
      <img
        alt="Portal icon"
        v-lazy="getPortalIcon(portal)"
        class="rounded-lg shadow-lg w-9 h-9 sm:w-11 sm:h-11"
      />
      <div class="flex flex-col min-w-0 gap-1 text-white">
        <span class="font-medium leading-5 truncate" :title="storedPortal.name">
          {{ storedPortal.name }}
        </span>
        <div class="flex items-center gap-4 text-sm">
          <div class="flex items-center gap-1">
            <base-icon
              name="outlined-users2"
              size="w-4 h-4"
              class="text-white"
            />
            <span class="text-xs text-white">
              {{ abbreviatedMemberCount }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </base-button>
</template>

<style scoped>
  .portrait {
    aspect-ratio: 1 / 1.5;
  }
</style>
