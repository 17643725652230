<script lang="ts" setup>
  import { CreatePostButton } from '@/features/post';
  import { useDocumentScroll } from '@/shared/model';

  const { isScrolling } = useDocumentScroll();
</script>

<template>
  <create-post-button
    variant="custom"
    custom-classes="fixed bottom-4 right-4 rounded-full bg-indigo-500 p-4 drop-shadow-[0_2px_3px_rgba(0,0,0,0.5)] md:hidden z-40"
    :class="{
      'bg-opacity-80 backdrop-blur-sm': isScrolling,
    }"
  >
    <base-icon name="plus" />
  </create-post-button>
</template>
