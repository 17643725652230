<script setup lang="ts">
  import { ref, onMounted, toRefs, reactive } from 'vue';
  import type { ContentView, PortalView } from 'dfx/edge/edge.did';
  import gatedService from '@/services/gated.js';
  import {
    SOLANA_PAIR_ENABLED_WALLETS,
    useWalletPairing,
  } from '@/entities/wallets';
  import { useUser } from '@/entities/user';
  import { usePortal, JoinPortalButton } from '@/entities/portal';
  import { PairingSolanaWallet } from '@/features/wallets';
  import RaffleEntryButton from './RaffleEntryButton.vue';
  import { useAuth } from '@/entities/auth';

  enum RaffleEntryResponse {
    READY = 1,
    ALREADY_ENTERED = 2,
    ERROR = 3,
    RAFFLE_OVER = 4,
    NOT_A_PORTAL_MEMBER = 5,
    NO_RAFFLE = 6,
    CONTENT_NOT_FOUND = 7,
    SUCCESS = 8,
    NO_WALLET = 9,
    RAFFLE_NOT_STARTED = 10,
    RAFFLE_MAX_ENTRIES = 11,
    NOT_LOGGED_IN = 12,
  }

  const { fetchPairedWallets } = useWalletPairing();
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { loadPortalBySlug } = usePortal();

  const props = defineProps<{
    content: ContentView;
  }>();

  const state = reactive({
    runningText: '',
    buttonText: '',
    raffleResults: null,
    canEnter: false,
    isOver: false,
  });

  const { content } = toRefs(props);

  const portal = ref<PortalView | undefined>();
  const raffleResults = ref(null);

  onMounted(async () => {
    getRaffleResults();
  });

  const computeState = async (status: RaffleEntryResponse) => {
    if (!status) {
      return;
    }

    state.canEnter = false;
    state.isOver = false;
    if (status === RaffleEntryResponse.READY) {
      state.runningText = 'Raffle ends in:';
      state.buttonText = 'Enter Raffle';
      state.canEnter = true;
    } else if (status === RaffleEntryResponse.ALREADY_ENTERED) {
      state.runningText = 'Raffle ends in:';
      state.buttonText = 'Entered!';
    } else if (status === RaffleEntryResponse.RAFFLE_OVER) {
      state.isOver = true;
      state.buttonText = 'Entries closed :(';
      state.runningText = 'Raffle is over!';
    } else if (status === RaffleEntryResponse.NOT_A_PORTAL_MEMBER) {
      state.runningText = 'You are not a portal member!';
      loadPortalBySlug(content.value.portal.slug).then((portalView) => {
        if (portalView) {
          portal.value = portalView;
        }
      });
    } else if (status === RaffleEntryResponse.RAFFLE_NOT_STARTED) {
      state.buttonText = 'Starts Soon :)';
      state.runningText = 'Starts in:';
    } else if (status === RaffleEntryResponse.RAFFLE_MAX_ENTRIES) {
      state.runningText = 'Raffle max entries!';
    } else if (status === RaffleEntryResponse.NOT_LOGGED_IN) {
      state.buttonText = 'Enter Raffle';
      state.runningText = 'Login to enter!';
      state.canEnter = true;
    } else if (status === RaffleEntryResponse.NO_WALLET) {
      await fetchPairedWallets();
    } else {
      state.runningText = 'Error!';
    }
  };

  const getRaffleStatus = async (contentId: bigint) => {
    gatedService
      .raffleStatus(contentId)
      .then((res) => {
        if (res?.data) {
          console.log('Raffle Debug:', res?.data);
        }

        raffleResults.value = res?.data;
        computeState(raffleResults?.value?.status);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getRaffleResults = async () => {
    if (!isLoggedIn.value) {
      getRaffleStatus(props.content.id);
    } else {
      gatedService
        .verifyUser()
        .then((_) => {
          getRaffleStatus(props.content.id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const enterRaffle = async () => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
    } else {
      let raffleId = raffleResults?.value?.result?.id;
      await gatedService.enterRaffle(raffleId);
      getRaffleResults();
    }
  };
</script>

<template>
  <div class="flex justify-center" v-if="raffleResults">
    <div
      v-if="
        raffleResults.status != RaffleEntryResponse.NOT_A_PORTAL_MEMBER &&
        raffleResults.status != RaffleEntryResponse.NO_WALLET
      "
    >
      <raffle-entry-button
        :raffle="raffleResults"
        :button-text="state.buttonText"
        button-variant="primary"
        :running-text="state.runningText"
        over-text="Raffle is over!"
        :is-over="state.isOver"
        :can-enter="state.canEnter"
        @enter="enterRaffle"
        @refresh="getRaffleResults"
      />
    </div>

    <div
      class="flex flex-col items-center w-full space-y-4 md:w-10/12"
      v-else-if="
        raffleResults.status === RaffleEntryResponse.NOT_A_PORTAL_MEMBER &&
        portal
      "
    >
      <div>
        <div class="text-lg">
          You must be a member of the <b>{{ content.portal.name }}</b> Portal to
          enter the raffle.
        </div>
        <div class="flex flex-col items-center w-full">
          <join-portal-button
            :portal="portal"
            :is-portal-list="false"
            :is-joined="false"
            :size="'medium'"
            @on-joined="getRaffleResults"
          />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col items-start w-full space-y-4 md:w-10/12"
      v-else-if="raffleResults.status === RaffleEntryResponse.NO_WALLET"
    >
      <div class="w-full font-bold text-center">
        Pair a Solana wallet to enter the raffle
      </div>
      <pairing-solana-wallet
        v-for="walletName in SOLANA_PAIR_ENABLED_WALLETS"
        class="w-full"
        :wallet-name="walletName"
        :key="walletName"
        @paired="getRaffleResults"
        @unpaired="getRaffleResults"
      />
    </div>
  </div>
</template>
