<script lang="ts" setup>
  import type { ContentView, UserView } from 'dfx/edge/edge.did';
  import { computed, toRef, ref, provide } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import {
    USER_CONTENT,
    POST_DETAIL,
    POST_DETAIL_COMMENT,
    PORTAL_CONTENT,
  } from '@/common';
  import PostCard from '../ui/PostCard.vue';
  import Content from './content/Content.vue';
  import FrameInteractionSummary from './content/FrameInteractionSummary.vue';
  import Links from './content/Links.vue';
  import NsfwTag from './content/NsfwTag.vue';
  import Poll from './content/Poll.vue';
  import PostTitle from './content/PostTitle.vue';
  import RaffleEntryPanel from './content/raffle/RaffleEntryPanel.vue';
  import Tags from './content/Tags.vue';
  import Actions from './footer/Actions.vue';
  import Admin from './footer/Admin.vue';
  import OpenCommentSummary from './footer/OpenCommentSummary.vue';
  import RelevantComments from './footer/RelevantComments.vue';
  import SelfHeader from './header/SelfHeader.vue';
  import PostHeader from './header/PostHeader.vue';
  import TipDisplay from './header/TipDisplay.vue';
  import PostMenu from './header/PostMenu.vue';
  import {
    ReactionForm,
    ReactionSummary,
    useReaction,
  } from '@/entities/reaction';
  import { PermissionFlags, hasPermission } from '@/utils';
  import { type TranslatedContentView } from '@/shared/model';
  import { useUserRole } from '@/entities/user';
  import { useGetRaffleStatusQuery } from '@/entities/post';
  import { getUrlTitle } from '@/entities/content';
  import { assign } from 'lodash-es';
  import { useFeedStore } from '@/shared/model';
  import { storeToRefs } from 'pinia';

  const props = withDefaults(
    defineProps<{
      post: ContentView;
      index?: number;
      isEmbedded?: boolean;
      variant?: 'default' | 'compact';
    }>(),
    {
      index: 0,
      isEmbedded: false,
      variant: 'default',
    },
  );

  const emits = defineEmits<{
    (
      e: 'translate-content',
      params: { content: TranslatedContentView; lang: string },
    ): void;
    (e: 'remove-content', content: ContentView): void;
    (e: 'delete-content', content: ContentView): void;
    (e: 'edit-content', content: ContentView): void;
    (
      e: 'pin-content',
      params: {
        content: ContentView;
        isPinned: boolean;
      },
    ): void;
    (
      e: 'pin-content-to-profile',
      params: {
        content: ContentView;
        isPinned: boolean;
      },
    ): void;
    (e: 'set-nsfw', params: { content: ContentView; isNsfw: boolean }): void;
    (e: 'set-rank', params: { content: ContentView; amount: bigint }): void;
    (e: 'goodbye', user: UserView): void;
    (e: 'on-comment', comment: ContentView): void;
    (e: 'on-poll-vote', content: ContentView): void;
  }>();

  const postContent = toRef(props, 'post');
  provide('content', postContent);
  const route = useRoute();
  const router = useRouter();
  const { contentReaction, reactionCount, react, reactionType, reactionEmoji } =
    useReaction(postContent);
  const { isAdmin } = useUserRole();
  const { data: raffleStatus } = useGetRaffleStatusQuery(postContent);
  const feedStore = useFeedStore();
  const { savedScrollPosition } = storeToRefs(feedStore);

  const isRelevantCommentsExpanded = ref(true);

  const isSelfPost = computed(() => {
    return 'User' in postContent.value.portal.portal_type;
  });

  const isPinned = computed(
    () =>
      (postContent.value.is_pinned || postContent.value.is_author_pinned) &&
      (route.name === USER_CONTENT || route.name === PORTAL_CONTENT) &&
      !props.isEmbedded,
  );

  const canReact = computed(() =>
    hasPermission(postContent.value.perm, PermissionFlags.REACT_CONTENT),
  );

  const canDislike = computed(() =>
    hasPermission(postContent.value.perm, PermissionFlags.DISLIKE_CONTENT),
  );

  const isThreadedContent = computed(
    () =>
      postContent.value.parent_content?.length > 0 ||
      postContent.value.root_parent_content?.length > 0,
  );

  const hasRelevantComments = computed(
    () => postContent.value.relevant_children.length > 0,
  );

  const relevantCommentsCssTag = computed(
    () => `post-relevant-comments-${route.name?.toString()}`,
  );

  const hasPoll = computed(
    () =>
      postContent.value.poll[0] &&
      postContent.value.poll[0].choices[0]?.text.length > 0,
  );

  const onClickPost = (content: ContentView, autofocus = false) => {
    savedScrollPosition.value.set(route.fullPath, props.index);
    const isComment = content.content_type === 'comment';
    const params = {
      id: postContent.value.id.toString(),
      title: getUrlTitle(postContent.value),
    };
    if (isComment && content.root_parent_content[0]) {
      const comment = content.root_parent_content[0];
      params.id = comment.id.toString();
      params.title = getUrlTitle(comment);
      assign(params, { comment: content.id.toString() });
    }
    router.push({
      name: isComment ? POST_DETAIL_COMMENT : POST_DETAIL,
      params,
      ...(autofocus && { query: { autofocus: 'true' } }),
    });
  };
</script>

<template>
  <post-card
    :variant="variant"
    :class="isPinned ? 'border-yellow-500' : 'border-gray-40 border-opacity-70'"
    @clicked-post="onClickPost(postContent)"
  >
    <template #header>
      <base-icon
        v-if="isPinned"
        name="paperclip"
        size="size-4"
        class="absolute text-yellow-500 -top-2 -right-2"
      />
      <div
        v-if="isAdmin && postContent.lang"
        class="absolute -top-3 right-0 text-sm"
      >
        {{ postContent.lang }}
      </div>
      <div class="flex justify-between items-start not-prose">
        <div class="flex gap-4 post-header">
          <component
            :is="isSelfPost ? SelfHeader : PostHeader"
            :content="postContent"
            :is-threaded="isThreadedContent"
            @clicked-user-tooltip="
              savedScrollPosition.set(route.fullPath, index)
            "
          />
        </div>
        <div class="flex items-center gap-2">
          <tip-display
            :content-id="postContent.id"
            data-id="header-tip-display-right"
          />
          <post-menu
            v-if="!isThreadedContent && variant === 'default'"
            :content="postContent"
            @delete-content="emits('delete-content', $event)"
            @remove-content="emits('remove-content', $event)"
            @translate-content="emits('translate-content', $event)"
            @edit-content="emits('edit-content', $event)"
            @pin-content="emits('pin-content', $event)"
            @pin-content-to-profile="emits('pin-content-to-profile', $event)"
            @set-nsfw="emits('set-nsfw', $event)"
          />
        </div>
      </div>
    </template>
    <template v-if="postContent.title.length" #title>
      <post-title :content="postContent" />
    </template>
    <template v-if="postContent.url" #links>
      <links :content="postContent" />
    </template>
    <template #content>
      <!--
        We have 3 levels of content (depth) root_parent, parent and content
        root_parent and content (if post does not have a parent content) are used main content in a post
        if content has a parent content, it becomes a threaded content
      -->
      <content
        :content="
          postContent.root_parent_content[0]
            ? postContent.root_parent_content[0]
            : postContent
        "
        :class="{
          'overflow-hidden max-h-68 preview-mask': isThreadedContent,
        }"
        @clicked-post="
          onClickPost(
            postContent.root_parent_content[0]
              ? postContent.root_parent_content[0]
              : postContent,
          )
        "
      />
      <template v-if="isThreadedContent">
        <template v-if="postContent.parent_content[0]">
          <div class="my-3 -mx-5 border-b border-gray-700" />
          <div class="flex flex-col mt-4">
            <post-header
              :content="postContent.parent_content[0]"
              :show-portal-tooltip="false"
            />
            <content
              :content="postContent.parent_content[0]"
              @clicked-post="onClickPost(postContent.parent_content[0])"
            />
          </div>
        </template>
        <div
          class="my-3 border-b border-gray-700"
          :class="postContent.parent_content[0] ? '-mx-2' : '-mx-5'"
        />
        <div class="flex flex-col mt-4">
          <post-header :content="postContent" :show-portal-tooltip="false" />
          <content
            :content="postContent"
            @clicked-post="onClickPost(postContent)"
          />
        </div>
      </template>
    </template>
    <template v-if="postContent.frame_interaction_count" #frame-interaction>
      <frame-interaction-summary :item="postContent" class="pt-4" />
    </template>
    <template v-if="raffleStatus" #raffle>
      <raffle-entry-panel :content="postContent" />
    </template>
    <template v-if="hasPoll" #poll>
      <poll
        :content="postContent"
        :poll="postContent.poll[0]"
        :portal="postContent.portal"
        :perm="postContent.perm"
        @on-poll-vote="emits('on-poll-vote', $event)"
      />
    </template>
    <template #tags v-if="postContent.tags.length || postContent.is_nsfw">
      <nsfw-tag v-if="postContent.is_nsfw" mode="view" />
      <tags v-if="postContent.tags.length" :tags="postContent.tags" />
    </template>
    <template v-if="!isThreadedContent && canReact" #reactions-form>
      <reaction-form
        data-id="footer-content-reaction-form"
        :content-reaction="contentReaction"
        :reaction-type="reactionType"
        :reaction-emoji="reactionEmoji"
        :remove-dislike="!canDislike"
        @reacted="react('post_action', $event)"
      />
    </template>
    <template #reactions-summary>
      <reaction-summary
        :reaction-counts="reactionCount"
        data-id="footer-content-reaction-summary"
      />
    </template>
    <template #actions>
      <div
        class="order-2 md:order-3"
        :class="{
          'col-span-2': isThreadedContent,
        }"
      >
        <actions
          :content="postContent"
          :is-threaded="isThreadedContent"
          :is-comment="postContent.content_type === 'comment'"
          @clicked-post="onClickPost(postContent, true)"
        />
      </div>
    </template>
    <template #comment-summary>
      <div class="order-4 md:col-span-full">
        <open-comment-summary
          :content="postContent"
          v-model="isRelevantCommentsExpanded"
          :enabled="hasRelevantComments"
        />
      </div>
    </template>
    <template v-if="isAdmin" #admin>
      <admin
        :content="postContent"
        @remove-content="emits('remove-content', $event)"
        @goodbye="emits('goodbye', $event)"
        @set-rank="emits('set-rank', $event)"
      />
    </template>
    <template #relevant-comment>
      <relevant-comments
        v-if="isRelevantCommentsExpanded"
        :comments="postContent.relevant_children"
        :parent-post="postContent"
        :class="relevantCommentsCssTag"
        @clicked-comment="onClickPost($event)"
        @on-comment="emits('on-comment', $event)"
      />
    </template>
  </post-card>
</template>
