import { actor } from '../base';
import type { ActionResultUserSelf, CreateICPUser } from 'dfx/edge/edge.did';

export const createReferredUser = (params: CreateICPUser) => {
  return actor()
    .create_referred_user(params)
    .then((res) => res) as Promise<ActionResultUserSelf>;
};

export const createIcpUser = (params: CreateICPUser) => {
  return actor()
    .create_icp_user(params)
    .then((res) => res) as Promise<ActionResultUserSelf>;
};
