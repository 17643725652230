import { apiInstance } from '../base';
import type { TensorNft } from './collection';
import type { DataResponse } from './interfaces';
const BASE_URL = '/tensor/token';

export const getToken = (nftId: string) => {
  return apiInstance
    .get(`${BASE_URL}/${nftId}`)
    .then((response) => response.data) as Promise<DataResponse<TensorNft>>;
};

export interface BuyNowRequest {
  buyer: string;
  // mint address
  mint: string;
  owner: string;
  maxPrice: number;
}

type BufferType = 'Buffer';

export interface TxData {
  data: Uint8Array;
  type: BufferType;
}

export interface TensorBuyTx {
  tx: TxData;
  txV0: TxData;
  lastValidBlockHeight: number;
  metadata: any;
}

export interface BuyNowResponse {
  txs: TensorBuyTx[];
}

export const buyNFT = (payload: BuyNowRequest) => {
  return apiInstance
    .post(`${BASE_URL}/buy`, payload)
    .then((response) => response.data) as Promise<DataResponse<BuyNowResponse>>;
};
