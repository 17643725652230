const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

const rgbToHex = (r, g, b) => '#' + [r, g, b].map(componentToHex).join('');

/**
 *
 * @param r
 * @param g
 * @param b
 */
function rgbColorToNumber(r, g, b) {
  return (r << 16) + (g << 8) + b;
}

/**
 *
 * @param hex
 */
export function colorHexToNumber(hex) {
  return rgbColorToNumber(...colorHexToRGB(hex));
}

/**
 *
 * @param hex
 */
function colorHexToRGB(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

/**
 *
 * @param number
 */
export function numberToColorHex(number) {
  const r = (number & 0xff0000) >> 16;
  const g = (number & 0x00ff00) >> 8;
  const b = number & 0x0000ff;

  return rgbToHex(...[r, g, b]);
}
