<script setup lang="ts">
  import { ref, watch, computed, onBeforeUnmount } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useBreakpoint } from '@/shared/model';
  import { usePortal, useMetaPortal } from '@/entities/portal';
  import { useNFTGating } from '@/entities/nft-gate';
  import { ListenLiveRoomModal } from '@/widgets/listen-live-room-modal';
  import { PORTAL_ABOUT, PORTAL_MARKETPLACE, PORTAL_CONTENT } from '@/common';
  import { config } from '@/shared/lib';
  import { useUser } from '@/entities/user';
  import { liveChatModel } from '@/entities/live';
  import { usePostDialog } from '@/features/post';
  import { PortalProfileHeader } from '@/widgets/portal-profile-header';
  import { Empty } from '@/shared/ui/empty';

  const props = defineProps<{
    portal: string;
  }>();

  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n({ useScope: 'global' });
  const { isLoggedIn } = useUser();
  const {
    marketplaceNftCollections,
    fetchNftGatedCollections,
    fetchEntrepotCollections,
  } = useNFTGating();
  const {
    currentPortal,
    loadPortalProfile,
    fetchCurrentPortalUserRoles,
    clearCurrentPortal,
    updateCurrentPortal,
  } = usePortal();
  useMetaPortal(currentPortal);
  const { openCreatePostDialog } = usePostDialog();

  const { isSmallerThanLg, isSmallerThanMd } = useBreakpoint();
  const { showLiveModal } = liveChatModel.use();

  const selectedTab = ref('posts');
  const isPortalEmpty = ref(false);
  const portalTabs = computed(() => {
    const tabs = [
      {
        key: 'posts',
        label: t('posts'),
        route: {
          name: PORTAL_CONTENT,
          params: {
            portal: props.portal,
          },
        },
        icon: 'vuesax-linear-notification-status',
      },
    ];
    if (
      config.ENABLE_MARKETPLACE_TAB &&
      marketplaceNftCollections.value.length > 0
    ) {
      tabs.push({
        key: 'marketplace',
        label: t('marketplace'),
        route: {
          name: PORTAL_MARKETPLACE,
          params: {
            portal: props.portal,
          },
        },
        icon: 'vuesax-linear-shop',
      });
    }
    if (isSmallerThanLg.value) {
      tabs.push({
        key: 'about',
        label: t('about'),
        route: {
          name: PORTAL_ABOUT,
          params: {
            portal: props.portal,
          },
        },
        icon: 'outlined-user-circle',
      });
    }
    return tabs;
  });

  const getPortalProfile = async () => {
    const portal = await loadPortalProfile(props.portal);
    if (portal === null) {
      isPortalEmpty.value = true;
      return;
    }
    isPortalEmpty.value = false;
    await fetchCurrentPortalUserRoles(false);
    if (config.ENABLE_MARKETPLACE_TAB) {
      await fetchEntrepotCollections();
      await fetchNftGatedCollections(props.portal);
    }
  };

  const loadSelectedTabByRoute = () => {
    if (route.name === PORTAL_CONTENT) {
      selectedTab.value = 'posts';
    } else if (route.name === PORTAL_MARKETPLACE) {
      selectedTab.value = 'marketplace';
    } else if (route.name === PORTAL_ABOUT) {
      selectedTab.value = 'about';
    }
  };

  watch(
    () => props.portal,
    () => getPortalProfile(),
    {
      immediate: true,
    },
  );

  watch(
    () => isSmallerThanLg.value,
    (value) => {
      if (!value && route.name === PORTAL_ABOUT) {
        router.push({
          name: PORTAL_CONTENT,
          params: {
            portal: props.portal,
          },
        });
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    () => route.name,
    () => loadSelectedTabByRoute(),
    {
      immediate: true,
    },
  );

  watch(isLoggedIn, () => {
    if (currentPortal.value) {
      updateCurrentPortal();
    }
  });

  watch(
    () => currentPortal.value?.slug,
    (slug) => {
      if (slug && route.query.post && typeof route.query.post === 'string') {
        openCreatePostDialog(route.query.post);
        router.replace({ query: { ...route.query, post: undefined } });
      }
    },
  );

  onBeforeUnmount(() => {
    clearCurrentPortal();
  });
</script>

<template>
  <section v-if="!isPortalEmpty" class="w-full">
    <portal-profile-header />
    <div
      class="flex justify-center md:justify-start items-start mb-1 pb-2 px-5 md:px-16 lg:px-0 bg-gray-975 pt-5 sm:pt-2"
    >
      <div
        v-if="portalTabs.length > 1"
        class="flex w-full gap-10 pb-6 text-white justify-start"
      >
        <base-button
          v-for="tab in portalTabs"
          :key="tab.key"
          variant="custom"
          custom-classes="flex gap-2 items-center pb-3 px-4 md:px-0"
          :class="{
            'border-b-2': selectedTab === tab.key,
            'text-gray-400': selectedTab !== tab.key,
          }"
          :to="tab.route"
          @click.stop="selectedTab = tab.key"
        >
          <base-icon :name="tab.icon" />
          <span v-if="!isSmallerThanMd" class="text-base font-medium">
            {{ tab.label }}
          </span>
        </base-button>
      </div>
    </div>
    <router-view />
  </section>
  <empty v-else>
    <template #title>
      {{ $t('portalDoNotExist') }}
    </template>
  </empty>
  <listen-live-room-modal v-if="showLiveModal" />
</template>
