<script setup lang="ts">
  import {
    onMounted,
    ref,
    watch,
    type Ref,
    onUnmounted,
    onActivated,
    onDeactivated,
    computed,
  } from 'vue';
  import { DynamicScroller } from 'vue-virtual-scroller';
  import {
    FeedItem,
    useCommentFeed,
    type CommentViewNode,
  } from '@/entities/comment';
  import { Loader } from '@/shared/ui/loader';
  import { CustomDynamicScrollerItem } from '@/shared/ui/virtual-scroller';
  import type { ContentSort } from 'dfx/edge/edge.did';

  const PAGE_SIZE = BigInt(100);
  const MIN_ITEM_SIZE = 265;
  const BUFFER_PX = 4000;
  const REFRESH_MS = 4000;

  const props = defineProps<{
    postId: bigint;
    sortBy: ContentSort;
    targetCommentId?: bigint;
  }>();

  const isLoadingContent = ref(false);
  const endOfComments = ref(false);
  const page = ref(BigInt(0));
  const currentHighlighted = ref<CommentViewNode>();
  const observerTimer: Ref<ReturnType<typeof setInterval> | undefined> = ref();
  const scrollerRef: Ref<InstanceType<typeof DynamicScroller> | undefined> =
    ref();

  const {
    flattenComments,
    getCommentById,
    loadFeed,
    loadFeedPage,
    refreshFeedRecentEntries,
  } = useCommentFeed();

  const flattenVisibleComments = computed(() =>
    flattenComments.value.filter((n) => !n.hidden),
  );

  const scrollToComment = (id?: bigint) => {
    if (currentHighlighted.value !== undefined) {
      currentHighlighted.value.highlighted = false;
      currentHighlighted.value = undefined;
    }
    if (id) {
      currentHighlighted.value = getCommentById(id);

      if (currentHighlighted.value) {
        const commentIndex = flattenVisibleComments.value.indexOf(
          currentHighlighted.value,
        );
        if (commentIndex >= 0) {
          currentHighlighted.value.highlighted = true;
          // For some reason, scrollToItem doesn't work well without a timeout
          scrollerRef.value?.scrollToItem(commentIndex);
        }
      }
    }
  };

  const loadNextPage = async () => {
    if (isLoadingContent.value || endOfComments.value) {
      return;
    }
    isLoadingContent.value = true;
    page.value++;
    const response = await loadFeedPage(
      props.postId,
      page.value,
      PAGE_SIZE,
      props.sortBy,
    );
    endOfComments.value = response?.length < PAGE_SIZE;
    isLoadingContent.value = false;
  };

  const resetFeed = async () => {
    isLoadingContent.value = true;
    page.value = BigInt(0);
    const response = await loadFeed(props.postId, PAGE_SIZE, props.sortBy);
    isLoadingContent.value = false;
    endOfComments.value = response?.length < PAGE_SIZE;
    scrollToComment(props.targetCommentId);
  };

  const startObserver = () => {
    stopObserver();
    observerTimer.value = setInterval(() => {
      if (!isLoadingContent.value) {
        refreshFeedRecentEntries(props.postId, PAGE_SIZE, props.sortBy);
      }
    }, REFRESH_MS);
  };

  const stopObserver = () => {
    if (observerTimer.value) {
      clearInterval(observerTimer.value);
      observerTimer.value = undefined;
    }
  };

  watch(() => props.postId, resetFeed);
  watch(() => props.sortBy, resetFeed);
  watch(() => props.targetCommentId, scrollToComment);

  onMounted(async () => {
    await resetFeed();
    startObserver();
  });

  onActivated(startObserver);

  onUnmounted(stopObserver);

  onDeactivated(stopObserver);

  defineExpose({
    resetFeed,
  });
</script>

<template>
  <dynamic-scroller
    ref="scrollerRef"
    page-mode
    list-class="!overflow-visible"
    :items="flattenVisibleComments"
    :min-item-size="MIN_ITEM_SIZE"
    :buffer="BUFFER_PX"
    key-field="idAsString"
    @scroll-end="loadNextPage"
  >
    <template #default="{ item: comment, active }">
      <custom-dynamic-scroller-item
        :id="comment.idAsString"
        :item="comment"
        :active="active"
      >
        <feed-item :item="comment" :key="comment.idAsString" />
      </custom-dynamic-scroller-item>
    </template>
  </dynamic-scroller>
  <div class="relative">
    <div
      v-if="isLoadingContent"
      class="flex items-center justify-center h-20 my-4 relative"
    >
      <loader variant="rainbow" size="w-8 h-8" border-width="border" />
    </div>
  </div>
</template>
