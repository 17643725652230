<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { trackEvent } from '@/utils/tracker';
  import { useArcade } from '@/composables';
  import { useDialog } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useUser } from '@/entities/user';
  import { usePortal } from '@/entities/portal';
  import {
    ArcadePlayer,
    ArcadeButton,
    ArcadeCard,
    ArcadeHeader,
    ArcadeListItem,
    CreatorCard,
    OwnerBadge,
  } from './components';
  import { BaseToggle } from '@/components/shared/base-toggle';
  import { BaseCarousel } from '@/components/shared/base-carousel';
  import { JoinPortalButton } from '@/entities/portal';
  import { trackSeconds } from '@/utils';
  import type { ArcadeProps } from './types';

  const { t } = useI18n({ useScope: 'global' });
  const { currentUser } = useUser();
  const { isMemberPortalOwner } = usePortal();
  const { fetchArcade, games, users, portals } = useArcade();
  const { dialogDefaults, openConfiguredDialog } = useDialog();

  const currentGame = ref('');
  const isListView = ref(false);
  const slides = ref([
    {
      title: t('arcade.slideTitle'),
      primaryButton: {
        text: t('arcade.slidePrimaryButtonText'),
        link: 'https://dscvr.typeform.com/DSCVR-Arcade',
        icon: 'nav-boxed',
      },
      secondaryButton: {
        text: t('arcade.slideSecondaryButtonText'),
        link: 'https://www.notion.so/dscvr/DSCVR-Arcade-FAQ-5a42a72f7b7f41a9bb741fd2d4497226',
      },
      background: fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/arcade/spaceship.png`,
      ),
    },
  ]);

  const timer = trackSeconds();

  const openGame = (game: ArcadeProps) => {
    const { title, link, external } = game;

    if (external) {
      window.open(link, '_blank')!.focus();
      trackEvent('click', 'arcade_external_link', link);
      return;
    }

    if (link) {
      currentGame.value = link;
    }

    openPlayerDialog();
    trackEvent('start_play', 'play_game', title);
    timer.startTracking();

    document.documentElement.style.overflow = 'hidden';
  };

  const closePlayer = () => {
    document.documentElement.style.overflow = '';
    const elapsedSeconds = timer.getSecondsElapsed();
    trackEvent(
      'end_play',
      'play_game',
      `${currentGame.value}__seconds_played: ${elapsedSeconds}`,
    );
    timer.stopTracking();
  };

  const slideLinkClicked = (link: string) => {
    window.open(link, '_blank')!.focus();
    trackEvent('click', 'arcade_slide_link', link);
  };

  const openPlayerDialog = () => {
    openConfiguredDialog({
      content: {
        component: ArcadePlayer,
        props: {
          gameUrl: currentGame,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-screen min-h-screen lg:max-w-[85vw] lg:min-h-[85vh] ease-transition-all bg-transparent`,
      },
      drawer: {
        contentClasses: 'flex-1 relative',
        customClasses: `${dialogDefaults.drawer.customClasses} !rounded-none h-full`,
      },
      emit: closePlayer,
    });
  };

  onMounted(() => {
    fetchArcade();
  });
</script>

<template>
  <div class="pt-18">
    <arcade-header />

    <div
      class="mt-6 mb-12 mx-2 sm:mx-4 md:mx-12 xl:mx-20 xxl:mx-56 ease-transition-all"
    >
      <div class="flex justify-end">
        <base-toggle
          v-model:toggled="isListView"
          active-toggle-classes="text-indigo-300"
          inactive-toggle-classes="hover:text-indigo-500 text-gray-400"
          toggle-classes="bg-gray-850"
        >
          <template #primary>
            <base-icon name="grid" size="h-4 w-4" />
          </template>
          <template #secondary>
            <base-icon v-if="isListView" name="list" size="h-4 w-4" />
            <base-icon v-else name="list-outline" size="h-4 w-4" />
          </template>
        </base-toggle>
      </div>

      <!-- New -->
      <section class="w-full mt-8 xl:mt-16">
        <h2 class="hidden md:block text-[32px] font-semibold mb-6">
          {{ t('arcade.newToArcade') }}
        </h2>
        <div
          v-if="!isListView"
          class="grid grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6 max-w-screen-3xl mx-auto"
        >
          <arcade-card
            :key="index"
            v-for="(game, index) in games"
            :item="game"
            spacing="between"
            is-game
            :class="[game.mobileHide ? 'hidden lg:block' : '']"
            @play-game="openGame(game)"
          >
            <template #button>
              <arcade-button variant="play" @play="openGame(game)" />
            </template>
          </arcade-card>
        </div>
        <div v-else class="grid grid-cols-1 gap-y-8 max-w-screen-3xl mx-auto">
          <arcade-list-item
            :key="index"
            v-for="(game, index) in games"
            :item="game"
          >
            <template #button>
              <arcade-button variant="play" @play="openGame(game)" />
            </template>
          </arcade-list-item>
        </div>
      </section>

      <base-carousel
        overlay
        :slides="slides"
        class="my-15 -mx-2 sm:-mx-4 md:-mx-12 lg:-mx-20 xl:-mx-56"
        @slide-interaction="slideLinkClicked"
      />

      <!-- Portals -->
      <section class="text-center">
        <div class="flex justify-center items-center mb-7">
          <base-icon name="pacman" class="mr-2" size="size-10" />
          <h2 class="text-[32px] font-semibold">
            {{ t('arcade.discoverGamingPortals') }}
          </h2>
        </div>
        <div
          v-if="portals.length"
          class="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-screen-3xl mx-auto"
        >
          <arcade-card
            :key="index"
            v-for="(portal, index) in portals"
            :item="portal"
          >
            <template #button>
              <join-portal-button
                v-if="!isMemberPortalOwner(portal, currentUser)"
                :portal="portal"
              />
              <owner-badge v-else />
            </template>
          </arcade-card>
        </div>
      </section>

      <!-- creators -->
      <section class="text-center mt-15">
        <div class="flex justify-center items-center mb-7">
          <base-icon name="spotlight" class="mr-2" size="size-10" />
          <h2 class="text-[32px] font-semibold">
            {{ t('arcade.creatorSpotlight') }}
          </h2>
        </div>
        <div
          v-if="users.length"
          class="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-screen-3xl mx-auto"
        >
          <creator-card
            :key="index"
            v-for="(user, index) in users"
            :user="user"
          />
        </div>
      </section>
    </div>
  </div>
</template>
