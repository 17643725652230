import { computed, reactive } from 'vue';
import {
  selectDominantSpeaker,
  selectIsConnectedToRoom,
  selectIsLocalAudioEnabled,
  selectPeers,
  selectPeerCount,
  selectBroadcastMessages,
  selectSessionStore,
  selectRecordingState,
  selectMessagesByRole,
  selectLocalMediaSettings,
  selectDevices,
  type HMSMessage,
  type HMSTrackNotification,
  type HMSPeer,
  type HMSRoleChangeRequest,
} from '@100mslive/hms-video-store';
import { DateTime } from 'luxon';
import { type ChatUserRole, liveRoomsModel } from '@/entities/live';
import { type LiveRoom, dscvrApi } from '@/shared/api';
import { PROD_URL_100MS } from '@/common/constants';
import { useTimeElapsedCounter, DEFAULT_EMOJI_LIST } from '../lib';
import type { CreateRoomDto } from '@/shared/api/dscvr-api/rooms';
import type {
  LiveChatState,
  LiveChatUser,
  AdminActionPayload,
  Emoji,
  PeerMetadata,
} from './types';
import { useUser } from '@/entities/user';
import { hmsActions, hmsStore, hmsNotifications } from './hms-store';
import { getAvatarIcon } from '@/shared/lib';
import { getAvatarUrl } from '@/utils';
const SPOTLIGHT_LINKS_SESSION_STORE_KEY = 'spotlight-links';

const getDefaultPeerMetaData = (pfpMeta: string): PeerMetadata => {
  return { pfpMeta: pfpMeta, isHandRaised: false, emoji: null };
};

// Singleton State Shared across all uses of useLiveChat()
// TODO: move to pinia once we decide to migrate
const state = reactive<LiveChatState>({
  isLoading: false,
  isConnected: false,
  showLiveModal: false,
  roleChangeRequests: [],
  spotlightLinks: [],
  users: [],
  userMicStatus: {},
  speakingUser: null,
  peerCount: 0,
  isAudioEnabled: hmsStore.getState(selectIsLocalAudioEnabled),
  isRecording: false,
  liveMessages: [],
  greenRoomMessages: [],
  selectedLocalAudioInputDevice: null,
  selectedLocalAudioOutputDevice: null,
  localAudioInputDevices: [],
  localAudioOutputDevices: [],
  emojis: DEFAULT_EMOJI_LIST,
  emojiTimeoutId: null,
});

const userToRoleValue = (user: LiveChatUser) => {
  if (user.roleName === 'moderator') {
    return 2;
  } else if (user.roleName === 'speaker') {
    return 1;
  }
  return 0;
};

// Todo: Extract useCreateLiveChat & store modal state in it's own composable
export const use = () => {
  // const audioLevelSubscriptions = ref({});

  const { currentUserAvatarUrl, currentUser } = useUser();
  const {
    selectedRoom,
    loadAndSelectRoom,
    fetchPortalRooms,
    setSelectedRoom,
    removeLiveRoom,
    decreaseLocalRoomUserCount,
    increaseLocalRoomUserCount,
  } = liveRoomsModel.use();

  const { timeElapsed, setTimeElapsedCounter, cleanupTimeElapsedCounter } =
    useTimeElapsedCounter();

  const currentUserPfpMeta = computed(() => currentUserAvatarUrl.value || '');

  const computedCurrentUsername = computed(() =>
    currentUser.value ? currentUser.value.username : '',
  );

  const hostPrincipal = computed(() =>
    selectedRoom.value && selectedRoom.value.creator
      ? selectedRoom.value.creator.dscvrPk
      : '',
  );

  const hostUsername = computed(() =>
    selectedRoom.value && selectedRoom.value.creator
      ? selectedRoom.value.creator.username
      : '',
  );

  const peerMe = computed(() =>
    state.users.find((user) => user.username === computedCurrentUsername.value),
  );

  const currentUserEmoji = computed(() => {
    return peerMe.value?.metadata?.emoji as Emoji;
  });

  const startedAtTime = computed(() =>
    selectedRoom.value && selectedRoom.value.startAt
      ? selectedRoom.value.startAt
      : '',
  );

  /**
   *
   * @param root0
   * @param root0.data
   */
  function updatePeerMic({ data }: HMSTrackNotification) {
    if (!data.peerId) return;
    state.userMicStatus[data.peerId] = data.enabled;
    const foundUser = state.users.find((user) => user.peerId === data.peerId);
    if (foundUser) {
      foundUser.isAudioEnabled = data.enabled;

      if (foundUser.username === computedCurrentUsername.value) {
        state.isAudioEnabled = data.enabled;
      }
    }
  }

  /**
   *
   */
  async function sendRaisedHandMessage() {
    const message = `🎤 @${computedCurrentUsername.value} wants to speak`;
    await hmsActions.sendBroadcastMessage(message);
  }

  /**
   *
   */
  async function toggleRaiseHand() {
    // store the data in metadata & users will be rendered based on this
    if (!peerMe.value) return;
    const metadata = peerMe.value.metadata;
    const newIsHandRaised = !metadata.isHandRaised;

    // Send Raised Hand to Chat
    if (newIsHandRaised) {
      sendRaisedHandMessage();
    }
    metadata.isHandRaised = newIsHandRaised;
    hmsActions.changeMetadata(metadata);
  }

  /**
   *
   * @param peer
   */
  async function setSpeakingUser(peer: HMSPeer | null) {
    // console.log("LOUDEST SPEAKER", peer);
    if (!peer) {
      state.speakingUser = null;
      return;
    }
    state.speakingUser = state.users.find((user) => user.peerId === peer.id);
  }

  /**
   *
   * @param peer
   */
  async function setModerator(peer: LiveChatUser) {
    console.log('setModerator', peer);
    if (!peer) return;
    await hmsActions.changeRoleOfPeer(peer.peerId, 'moderator');
  }

  /**
   *
   * @param peer
   */
  async function setListener(peer: LiveChatUser) {
    console.log('setListener', peer);
    if (!peer) return;
    await hmsActions.changeRoleOfPeer(peer.peerId, 'listener', true);
  }

  /**
   *
   * @param emoji
   */
  async function chooseEmoji(emoji: Emoji) {
    if (!peerMe.value) return;
    const metadata = peerMe.value.metadata;
    metadata['emoji'] =
      currentUserEmoji?.value?.name !== emoji.name ? emoji : null;

    if (metadata['emoji']) {
      if (state.emojiTimeoutId) {
        window.clearTimeout(state.emojiTimeoutId);
      }

      state.emojiTimeoutId = window.setTimeout(() => {
        chooseEmoji(emoji);
      }, 3000);
    }

    hmsActions.changeMetadata(metadata);
  }

  /**
   *
   * @param peer
   */
  async function kickUser(peer: LiveChatUser) {
    console.log('kickUser', peer);
    if (!peer) return;
    await hmsActions.removePeer(peer.peerId, 'Kicked by moderator');
  }

  /**
   *
   * @param username
   * @param meta
   */
  function getUserPFP(username: string, meta: string | object) {
    if (meta && typeof meta === 'string') {
      return getAvatarUrl(meta);
    }
    return getAvatarIcon(username);
  }

  /**
   *
   * @param request
   */
  async function acceptRoleChangeRequest(request: HMSRoleChangeRequest) {
    await hmsActions.acceptChangeRole(request);
  }

  /**
   *
   */
  function getLocalAudioDevices() {
    const options = hmsStore.getState(selectDevices);
    const selected = hmsStore.getState(selectLocalMediaSettings);
    state.localAudioInputDevices = options.audioInput;
    state.localAudioOutputDevices = options.audioOutput;
    state.selectedLocalAudioInputDevice = selected.audioInputDeviceId;
    state.selectedLocalAudioOutputDevice = selected.audioOutputDeviceId;
    return { options, selected };
  }

  /**
   *
   * @param deviceId
   */
  function selectAudioInputDevice(deviceId: string) {
    hmsActions.setAudioSettings({ deviceId });
    getLocalAudioDevices();
  }

  /**
   *
   * @param deviceId
   */
  function selectAudioOutputDevice(deviceId: string) {
    hmsActions.setAudioSettings({ deviceId });
    getLocalAudioDevices();
  }

  /**
   *
   */
  async function toggleMic() {
    await hmsActions.setLocalAudioEnabled(
      !hmsStore.getState(selectIsLocalAudioEnabled),
    );
  }

  /**
   *
   * @param peers
   */
  async function updatePeers(peers: HMSPeer[]) {
    console.log('Updating Peers', peers);
    state.peerCount = hmsStore.getState(selectPeerCount);
    state.users = peers.map((peer) => {
      const metadata = peer.metadata ? JSON.parse(peer.metadata) : {};
      const pfpMeta = metadata['pfpMeta'];
      const isHandRaised = metadata['isHandRaised'] ?? false;
      return {
        isAudioEnabled: state.userMicStatus[peer.id] || false,
        username: peer.name,
        icon: getUserPFP(peer.name, pfpMeta),
        peerId: peer.id,
        roleName: peer.roleName as ChatUserRole,
        isHandRaised: isHandRaised,
        metadata: metadata,
        // Todo: Need to fix because the hostusername is set to pk in nest api...
        isHost: peer.name === hostUsername.value,
      };
    });
  }

  /**
   *
   */
  async function toggleRecording() {
    if (!selectedRoom.value) return;
    if (!isModerator.value) return;
    if (state.isRecording) {
      await dscvrApi.rooms.endRecording(selectedRoom.value.roomId);
    }
    await dscvrApi.rooms.startRecording(selectedRoom.value.roomId);
  }

  /**
   *
   */
  async function endRoom() {
    _setIsLoading(true);
    try {
      if (selectedRoom.value == null)
        throw new Error('No room selected to end');
      const roomToEndId = selectedRoom.value.roomId;
      state.isConnected = false;
      await dscvrApi.rooms.endRoom(roomToEndId);
      removeLiveRoom(roomToEndId);
    } catch (e) {
      state.isConnected = true;
    } finally {
      _setIsLoading(false);
    }
  }

  /**
   *
   * @param room
   */
  async function joinRoom(room: LiveRoom) {
    try {
      await dscvrApi.verify.verifyUser();
      _setIsLoading(true);
      setShowLiveModal(true);
      setSelectedRoom(room);
      const connection = await dscvrApi.rooms.connect(room.roomId);
      // console.log(connection);
      const token = connection.data.token;
      // console.log("Joining room...", token);
      const metadata = getDefaultPeerMetaData(currentUserPfpMeta.value);
      await hmsActions.join({
        metaData: JSON.stringify(metadata),
        userName: connection.data.username,
        authToken: token,
        settings: {
          isAudioMuted: !state.isAudioEnabled,
        },
        initEndpoint: PROD_URL_100MS,
      });
      state.isAudioEnabled = hmsStore.getState(selectIsLocalAudioEnabled);
      const recordingState = hmsStore.getState(selectRecordingState);
      state.isRecording = recordingState.server.running;
      // update the roomCount in database
      await dscvrApi.rooms.joinRoom(room.roomId);
      // unblock audio for chrome users since this functions is called on user interaction
      unblockAudio();
      increaseLocalRoomUserCount(room.roomId);
    } catch (e) {
      console.log(e);
      setShowLiveModal(false);
      // Todo: show login modal if user is not logged in (main reason for this to fail)
    } finally {
      _setIsLoading(false);
    }
  }

  /**
   *
   * @param createRoomData
   */
  async function createRoom(
    createRoomData: CreateRoomDto,
    onCreated: () => void,
  ) {
    _setIsLoading(true);
    try {
      await dscvrApi.verify.verifyUser();
      const response = await dscvrApi.rooms.createRoom({ ...createRoomData });
      console.log('creating room...');
      console.log(response);
      setSelectedRoom(response.data.data!);
      onCreated();
      _setIsLoading(false);
      if (createRoomData['startNow']) {
        await joinRoom(response.data.data!);
      }
    } catch (e) {
      console.log(e);
      // Todo: show error message somewhere
    } finally {
      _setIsLoading(false);
    }
  }

  /**
   *
   * @param value
   */
  function _setIsLoading(value: boolean) {
    state.isLoading = value;
  }

  /**
   *
   * @param value
   */
  function setShowLiveModal(value: boolean) {
    state.showLiveModal = value;
  }

  // Share links to stage
  /**
   *
   * @param link
   */
  async function shareLinkToStage(link: string) {
    // Share link to the room
    const existingLinks =
      hmsStore.getState(
        selectSessionStore(SPOTLIGHT_LINKS_SESSION_STORE_KEY),
      ) || [];
    existingLinks.push(link);
    hmsActions.sessionStore.set(
      SPOTLIGHT_LINKS_SESSION_STORE_KEY,
      existingLinks,
    );
  }

  /**
   *
   * @param link
   */
  async function removeLinkFromStage(link: string) {
    // Remove link from the room
    let links =
      hmsStore.getState(
        selectSessionStore(SPOTLIGHT_LINKS_SESSION_STORE_KEY),
      ) || [];
    links = links.filter((l: string) => l !== link);
    hmsActions.sessionStore.set(SPOTLIGHT_LINKS_SESSION_STORE_KEY, links);
  }

  /**
   *
   */
  async function clearLinksFromStage() {
    // Clear all links from the room
    hmsActions.sessionStore.set(SPOTLIGHT_LINKS_SESSION_STORE_KEY, []);
  }

  /**
   *
   * @param links
   */
  function updateSpotlightLinks(links: string[]) {
    state.spotlightLinks = links;
  }

  // use isConnected to change UI of the panel
  /**
   *
   * @param isConnected
   */
  function onConnection(isConnected: boolean | undefined) {
    state.isConnected = isConnected as boolean;
    setTimeElapsedCounter(startedAtTime.value);
  }

  /**
   *
   * @param root0
   * @param root0.peer
   * @param root0.action
   */
  async function moderatorAction({ peer, action }: AdminActionPayload) {
    if (!selectedRoom.value) return;
    switch (action) {
      case 'make-listener':
        await dscvrApi.rooms.changePeerRole(
          selectedRoom.value.roomId,
          peer.peerId,
          'listener',
        );
        break;
      case 'make-speaker':
        await dscvrApi.rooms.changePeerRole(
          selectedRoom.value.roomId,
          peer.peerId,
          'speaker',
        );
        break;
      case 'make-moderator':
        await dscvrApi.rooms.changePeerRole(
          selectedRoom.value.roomId,
          peer.peerId,
          'moderator',
        );
        break;
      case 'ban-user':
        await dscvrApi.rooms.banPeer(selectedRoom.value.roomId, peer.peerId);
        break;
    }
  }
  /**
   * Chat Related Stuff
   */

  /**
   *
   * @param messages
   */
  function renderMessages(messages: HMSMessage[]) {
    console.log('Rendering Messages', messages);
    if (!messages) return;
    state.liveMessages = messages.map((message) => {
      return {
        ...message,
        sender: resolvePeerFromPeerId(message.sender!),
        formattedTime: DateTime.fromJSDate(message.time)
          .diff(DateTime.fromISO(startedAtTime.value), [
            'hours',
            'minutes',
            'seconds',
          ])
          .toFormat('hh:mm:ss'),
      };
    });
  }

  /**
   *
   * @param peerId
   */
  function resolvePeerFromPeerId(peerId: string) {
    return state.users.find((user) => user.peerId === peerId);
  }

  /**
   *
   * @param messages
   */
  function renderGreenRoomMessages(messages: HMSMessage[] | undefined) {
    if (!messages) return;
    state.greenRoomMessages = messages.map((message) => {
      return {
        ...message,
        sender: resolvePeerFromPeerId(message.sender!),
        formattedTime: DateTime.fromJSDate(message.time)
          .diff(DateTime.fromISO(startedAtTime.value), [
            'hours',
            'minutes',
            'seconds',
          ])
          .toFormat('hh:mm:ss'),
      };
    });
  }

  /**
   *
   * @param message
   */
  function sendGeneralMessage(message: string) {
    hmsActions.sendBroadcastMessage(message);
  }

  /**
   *
   * @param message
   */
  function sendGreenroomMessage(message: string) {
    hmsActions.sendGroupMessage(message, ['moderator', 'speaker']);
  }

  /**
   *
   */
  function subscribeToActions() {
    hmsStore.subscribe(onConnection, selectIsConnectedToRoom);
    hmsStore.subscribe(updatePeers, selectPeers);
    hmsStore.subscribe(setSpeakingUser, selectDominantSpeaker);
    hmsStore.subscribe(renderMessages, selectBroadcastMessages);
    hmsStore.subscribe(
      renderGreenRoomMessages,
      selectMessagesByRole('moderator'),
    );
    hmsStore.subscribe(
      updateSpotlightLinks,
      selectSessionStore(SPOTLIGHT_LINKS_SESSION_STORE_KEY),
    );
    // hmsStore.subscribe(updateAudioLevel, selectPeerAudioByID(peerId));
    // hmsStore.subscribe(setSpeaker, selectSpeakers);
    hmsNotifications.onNotification((notification) => {
      // console.log("notification", notification);
      switch (notification.type) {
        case 'TRACK_UNMUTED':
          console.log('Called TRACK_UNMUTED', notification);
          updatePeerMic(notification);
          break;
        case 'TRACK_MUTED':
          console.log('Called TRACK_MUTED', notification);
          updatePeerMic(notification);
          break;
        case 'TRACK_ADDED':
          console.log('Called TRACK_ADDED');
          updatePeerMic(notification);
          break;
        case 'ROOM_ENDED':
          console.log('Called ROOM_ENDED');
          // Todo: figure out state when room ends from design / show a message "Room was ended by host."
          leaveRoom();
          break;
        default:
          break;
      }
    });

    // leave room if user closes the tab or refreshes
    window.addEventListener('beforeunload', () => leaveRoom());
    window.addEventListener('onunload', () => leaveRoom());
  }

  /**
   *
   * @param isUserGuest
   */
  async function leaveRoom(isUserGuest = true) {
    cleanupTimeElapsedCounter();
    if (!selectedRoom.value) return;
    if (isUserGuest) await hmsActions.leave();
    // update the roomCount in database
    state.isConnected = false;
    const roomId = selectedRoom.value.roomId;
    await dscvrApi.rooms.leaveRoom(roomId);
    decreaseLocalRoomUserCount(roomId);
    state.users = [];
    state.isAudioEnabled = false;
    setSelectedRoom(null);
    setShowLiveModal(false);
  }

  /**
   *
   */
  async function unblockAudio() {
    await hmsActions.unblockAudio();
  }

  const sortedUsers = computed(() => {
    return state.users.sort((a, b) => {
      return userToRoleValue(a) - userToRoleValue(b);
    });
  });

  const speakers = computed(() => {
    return sortedUsers.value.filter(
      (user) => user.roleName === 'speaker' || user.roleName === 'moderator',
    );
  });

  const listeners = computed(() => {
    return sortedUsers.value.filter((user) => user.roleName === 'listener');
  });

  const isAudioEnabled = computed(() => {
    return state.isAudioEnabled;
  });

  const isConnected = computed(() => {
    return state.isConnected;
  });

  const isLoading = computed(() => {
    return state.isLoading;
  });

  const isRecording = computed(() => {
    return state.isRecording;
  });

  const showLiveModal = computed(() => {
    return state.showLiveModal;
  });

  const peerCount = computed(() => {
    return state.peerCount;
  });

  const emojis = computed(() => {
    return state.emojis;
  });

  const speakingUserUsername = computed(() => {
    // if (state.speakingUser && state.speakingUser.username === peerMe.value.username) return "You";

    return state.speakingUser ? state.speakingUser.username : '';
  });

  const isModerator = computed(() => {
    return (peerMe.value?.roleName === 'moderator') as boolean;
  });

  const isSpeaker = computed(() =>
    ['speaker', 'moderator'].includes(peerMe.value?.roleName ?? ''),
  );

  const liveMessagesCount = computed(() => {
    return state.liveMessages.length;
  });

  const isCurrentUserHandRaised = computed(() => {
    return peerMe.value?.metadata?.isHandRaised as boolean;
  });

  const liveMessages = computed(() => {
    return state.liveMessages;
  });

  const liveGreenRoomMessages = computed(() => {
    return state.greenRoomMessages;
  });

  const selectedLocalAudioInputDevice = computed(() => {
    return state.selectedLocalAudioInputDevice;
  });

  const selectedLocalAudioOutputDevice = computed(() => {
    return state.selectedLocalAudioOutputDevice;
  });

  const localAudioInputDevices = computed(() => {
    return state.localAudioInputDevices;
  });
  const localAudioOutputDevices = computed(() => {
    return state.localAudioOutputDevices;
  });

  return {
    selectedLocalAudioInputDevice,
    selectedLocalAudioOutputDevice,
    localAudioInputDevices,
    localAudioOutputDevices,
    isLoading,
    isConnected,
    isRecording,
    isAudioEnabled,
    isSpeaker,
    isModerator,
    isCurrentUserHandRaised,
    currentUserEmoji,
    selectedRoom,
    sortedUsers,
    speakers,
    listeners,
    speakingUserUsername,
    showLiveModal,
    peerCount,
    liveMessagesCount,
    liveMessages,
    liveGreenRoomMessages,
    timeElapsed,
    emojis,
    hostPrincipal,
    loadAndSelectRoom,
    sendGeneralMessage,
    sendGreenroomMessage,
    chooseEmoji,
    moderatorAction,
    selectAudioInputDevice,
    selectAudioOutputDevice,
    getLocalAudioDevices,
    toggleRaiseHand,
    toggleRecording,
    createRoom,
    kickUser,
    unblockAudio,
    toggleMic,
    joinRoom,
    leaveRoom,
    endRoom,
    setListener,
    setModerator,
    fetchPortalRooms,
    subscribeToActions,
    acceptRoleChangeRequest,
    setShowLiveModal,
    removeLinkFromStage,
    clearLinksFromStage,
  };
};
