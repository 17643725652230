<script setup lang="ts">
  import { computed, onMounted, ref, watch, type Ref } from 'vue';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import {
    useGetClaimableLootboxQuery,
    useGetLootboxTargetQuery,
  } from '@/entities/lootbox';
  import { useVerifyUserMutation } from '@/entities/wallets';
  import { useUser } from '@/entities/user';
  import { useI18n } from 'vue-i18n';
  import { dscvrApi } from '@/shared/api';
  import { usePortal, JoinPortalButton } from '@/entities/portal';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import type { PortalView, UserView } from 'dfx/edge/edge.did';
  import { useLootbox } from '../model/composables/use-lootbox';
  import { useBreakpoint } from '@/shared/model';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';

  //enum for buttonState
  enum ButtonState {
    READY = 0,
    LOADING = 1,
    OPENED = 2,
  }

  const userData = ref<UserView>();
  const portalData = ref<PortalView>();
  const lootbox: Ref<dscvrApi.lootbox.LootboxAvailable | null> = ref(null);
  const targetLootboxId = ref<string>();
  const buttonState = ref<ButtonState>(ButtonState.READY);
  const openText = ref('Open');

  const { isSmallerThanMd } = useBreakpoint();
  const { getUserByUsername } = useUser();
  const { openLootbox } = useLootbox();
  const { isLoggedIn } = useUser();
  const { loadPortalBySlug } = usePortal();
  const { t } = useI18n({ useScope: 'global' });
  const { mutateAsync: verifyUser, isSuccess: isUserVerified } =
    useVerifyUserMutation();

  const enableClaimableLootboxQuery = computed(
    () => isUserVerified.value && isLoggedIn.value,
  );

  const { data: claimableLootbox } = useGetClaimableLootboxQuery(
    enableClaimableLootboxQuery,
  );
  const { data: lootboxTarget } = useGetLootboxTargetQuery(targetLootboxId);

  const computeState = async (openResult: dscvrApi.lootbox.ILootboxOpen) => {
    switch (openResult.status) {
      case dscvrApi.lootbox.LootboxOpenStatus.OPENED:
        buttonState.value = ButtonState.OPENED;
        openText.value = t('opened');
        break;
      case dscvrApi.lootbox.LootboxOpenStatus.NO_WALLET:
        setTimeout(() => {
          buttonState.value = ButtonState.READY;
          openText.value = t('open');
        }, 5000);
        break;
      default:
        break;
    }
  };

  const computeLootbox = (
    availableLootbox: dscvrApi.lootbox.LootboxAvailable | undefined,
  ) => {
    if (availableLootbox?.id) {
      lootbox.value = availableLootbox;
      if (availableLootbox.target) {
        computeTargetResult(availableLootbox.target);
      }
    }
  };

  const computeTargetResult = async (
    targetResult: dscvrApi.lootbox.LootboxTargetResult,
  ) => {
    if (!targetResult.result) {
      for (const rule of targetResult.data.rules) {
        if (rule.type === 'user-followers') {
          const user = await getUserByUsername(rule.target);
          if (user) {
            userData.value = user;
          }
        } else if (rule.type === 'portal-members') {
          const portal = await loadPortalBySlug(rule.target);
          if (portal) {
            portalData.value = portal;
          }
        }
      }
    }
  };

  const clickOpenLootbox = async (id: string | undefined) => {
    if (id) {
      buttonState.value = ButtonState.LOADING;
      openText.value = `${t('opening')}...`;
      const result = await openLootbox(id);
      if (result) {
        computeState(result);
      }
    }
  };

  const followTimeout = () => {
    setTimeout(async () => (targetLootboxId.value = lootbox.value?.id), 1500);
  };

  watch(
    () => ({ claimable: claimableLootbox.value, target: lootboxTarget.value }),
    ({ claimable, target }) => {
      computeLootbox(target ?? claimable);
    },
  );

  onMounted(async () => {
    if (isLoggedIn.value) {
      await verifyUser();
    }
  });
</script>

<template>
  <div v-if="lootbox">
    <div
      class="relative flex justify-between w-full p-5 text-white border border-gray-700 shadow-lg border-opacity-70 bg-gray-950 post-item rounded-xl"
    >
      <div class="flex items-center justify-center">
        <div
          class="flex-none bg-gray-800 border border-gray-600 shadow-lg border-opacity-70 rounded-xl"
        >
          <img
            v-lazy="
              fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/lootbox/dscvr-chest.gif`,
              )
            "
            class="self-center size-20 md:size-40"
          />
        </div>
        <div class="flex items-center justify-start ml-6">
          <div v-if="lootbox.target === null || lootbox.target === undefined">
            <div class="text-base font-bold md:text-xl">
              {{ t('luckyDay') }}
            </div>
            <div class="text-sm text-gray-300 md:text-base">
              You DSCVRed a loot box.
            </div>
          </div>
        </div>
        <div v-if="lootbox.target" class="flex flex-col gap-2 md:gap-4">
          <div class="text-base font-bold md:text-xl">
            <template v-if="isSmallerThanMd">
              {{ t('lootbox.creatorLootboxFound') }}
            </template>
            <template v-else>
              {{ t('lootbox.luckyYouCreator') }}
            </template>
          </div>
          <base-tooltip
            theme="lootbox"
            append-to-body
            tag="div"
            class="w-full max-w-max"
          >
            <template #default>
              <base-button
                variant="custom"
                custom-classes="flex items-center gap-1 text-indigo-300"
              >
                <base-icon name="info" size="size-4" />
                {{ t('lootbox.criteria') }}
              </base-button>
            </template>
            <template #content>
              <template
                v-for="rule in lootbox.target.data.rules"
                :key="rule.type"
              >
                <div v-if="rule.type === 'user-followers'" class="flex gap-1">
                  <span>{{ t('lootbox.mustFollow') }}</span>
                  <span class="font-bold">{{ rule.target }}</span>
                </div>
                <div v-else-if="rule.type === 'portal-members'">
                  <span>{{ t('lootbox.mustBeMemberOf') }}</span>
                  <span class="pl-1 font-bold">{{ rule.target }}</span>
                </div>
              </template>
            </template>
          </base-tooltip>
        </div>
      </div>
      <div class="flex items-center justify-center ml-2">
        <base-button
          v-if="
            lootbox.target === null ||
            lootbox.target == undefined ||
            (lootbox.target && lootbox.target.result)
          "
          variant="primary"
          :size="isSmallerThanMd ? 'small' : 'medium'"
          class="h-10 text-sm md:text-base"
          :class="{
            'animate-pulse': buttonState !== ButtonState.READY,
          }"
          :disabled="buttonState > 0"
          @click="clickOpenLootbox(lootbox.id)"
        >
          <div>{{ openText }}</div>
        </base-button>
        <follow-user-button
          v-else-if="userData"
          :user="userData"
          :size="isSmallerThanMd ? 'small' : 'medium'"
          @update:is-following="followTimeout"
        />
        <join-portal-button
          v-else-if="portalData"
          :portal="portalData"
          :size="isSmallerThanMd ? 'small' : 'medium'"
          join-class="capitalize group/join drop-shadow-light"
          @on-joined="followTimeout"
        />
      </div>
    </div>
  </div>
</template>
