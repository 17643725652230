<script setup lang="ts">
  import { useNotificationStore } from '@/entities/notification';

  const notificationStore = useNotificationStore();
</script>

<template>
  <base-button
    variant="link"
    @click="notificationStore.markAllNotificationsAsRead"
  >
    <base-icon name="chevron-check" size="w-4 h-4" class="mr-2" />
    {{ $t('notifications.markAllAsRead') }}
  </base-button>
</template>
