<template>
  <div v-if="currentPortal">
    <div class="text-lg font-bold text-white">Bans</div>

    <div class="text-sm text-white">
      You can see banned members and revoke bans
    </div>

    <error-display
      class="mt-3"
      :errors="errors"
      :error-message="errorMessage"
      :local-errors="localErrors"
    />

    <div
      class="flex items-center justify-between w-full py-1 my-4 bg-gray-900 rounded-lg flex-nowrap"
    >
      <base-icon name="search" size="w-5 h-5" class="ml-2 cursor-pointer" />
      <input
        v-model="searchedMember"
        type="text"
        class="block w-full text-white bg-gray-900 border-transparent border-none rounded-lg focus:ring-0"
        placeholder="Search Members..."
      />
    </div>

    <div class="my-4 text-white">
      <div class="flex">
        <div class="w-1/2 my-2 text-lg font-bold">
          Bans - {{ filteredMembers ? filteredMembers.length : 0 }}
        </div>
      </div>

      <p v-show="!filteredMembers" class="p-3 mt-3 text-center text-small">
        No banned members
      </p>

      <div class="my-5 overflow-y-auto thin-scrollbar h-80">
        <div
          class="fixed inset-0 top-0 left-0 z-40 flex items-center justify-center h-screen bg-gray-900 min-w-screen animated fadeIn faster o utline-none focus:outline-none bg-opacity-20"
          :class="{ hidden: !selectedMember }"
        >
          <div class="absolute inset-0 z-0 bg-black opacity-80" />

          <div
            class="relative w-full max-w-lg p-5 mx-auto my-auto bg-gray-950 shadow-lg rounded-xl"
          >
            <div class="">
              <!--body-->
              <div class="justify-center flex-auto p-5 text-center">
                <div
                  class="h-32 mt-3 overflow-y-auto thin-scrollbar text-white"
                >
                  <div class="text-lg font-bold">Ban Reason:</div>

                  <div
                    v-for="(memo, index) in currentPortalMemberMemos"
                    :key="index"
                    class="mb-2 text-left"
                  >
                    {{ memo }}
                  </div>
                </div>
              </div>

              <!--footer-->
              <div class="flex justify-between p-3 mt-2">
                <div>
                  <button
                    class="p-3 ml-3 text-xs font-bold uppercase bg-gray-900 rounded-full whitespace-nowrap"
                    @click="selectedMember = null"
                  >
                    Close
                  </button>
                </div>

                <div>
                  <button
                    class="p-2 ml-3 text-xs font-bold uppercase bg-red-800 rounded-full whitespace-nowrap"
                    @click="revokeBanForSelectedMember"
                  >
                    Revoke
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="member in filteredMembers"
          :key="member.id"
          class="relative flex items-center py-3 px-1 text-white border-b border-gray-600"
        >
          <user-avatar
            v-if="member.user"
            :item="member.user"
            size="w-8 h-8"
            class="mr-3"
          />

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-8 h-8 mr-3"
          >
            <path
              class="text-white fill-current"
              d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z"
            />
          </svg>

          <div class="font-semibold text-base">
            {{ member.user.username }}
          </div>

          <div
            class="ml-auto mr-2 cursor-pointer"
            @click="selectedMember = member"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-4 h-4"
            >
              <path
                class="text-white fill-current"
                d="M13.427 3.021h-7.427v-3.021l-6 5.39 6 5.61v-3h7.427c3.071 0 5.561 2.356 5.561 5.427 0 3.071-2.489 5.573-5.561 5.573h-7.427v5h7.427c5.84 0 10.573-4.734 10.573-10.573s-4.733-10.406-10.573-10.406z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="flex items-end justify-end">
        <button
          class="text-xs btn-submit whitespace-nowrap disabled:opacity-50"
        >
          Save
        </button>
      </div>
    </div>

    <div
      class="fixed p-2 text-lg text-white transition-all duration-500 transform -translate-x-1/2 bg-indigo-600 rounded-lg bg-opacity-80 bottom-5 left-1/2"
      :class="{ 'opacity-0': !shouldShowToast, 'opacity-100': shouldShowToast }"
    >
      Member updated successfully!
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ActionTypes } from '../store/action-types';
  import ErrorDisplay from './ErrorDisplay.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';

  export default {
    name: 'portal-settings-bans',
    components: { ErrorDisplay, UserAvatar },
    props: {
      portal: {
        type: Object,
        default: () => null,
      },
      roles: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        searchedMember: null,
        localErrors: [],
        errorMessage: '',
        errors: [],
        shouldShowToast: false,
        hideToastTimeout: null,
        selectedMember: null,
      };
    },
    computed: {
      ...mapGetters({
        currentPortal: 'portals/currentPortal',
        currentPortalMembersByStatus: 'portals/currentPortalMembersByStatus',
        currentPortalMemberMemos: 'portals/currentPortalMemberMemos',
      }),
      filteredMembers() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedMember = null;

        if (this.currentPortalMembersByStatus == null) return [];

        return this.currentPortalMembersByStatus.filter(
          (member) =>
            //(member.status && member.status.hasOwnProperty("Banned")) &&
            !this.searchedMember ||
            member.user.username
              .toLowerCase()
              .includes(this.searchedMember.toLowerCase()),
        );
      },
    },
    watch: {
      selectedMember: {
        handler: function () {
          if (this.selectedMember && this.selectedMember.id) {
            this.$store.dispatch(
              `portals/${ActionTypes.GET_CURRENT_PORTAL_MEMBER_MEMOS}`,
              { portal_id: this.portal.id, member_id: this.selectedMember.id },
            );
          }
        },
      },
    },
    created() {
      this.$store.dispatch(
        `portals/${ActionTypes.GET_PORTAL_MEMBERS_BY_STATUS}`,
        { portal_id: this.portal.id, status: { Banned: null } },
      );
    },
    methods: {
      revokeBanForSelectedMember() {
        if (this.selectedMember && this.selectedMember.id) {
          /*console.log("BANNING MEMBER", {
          portal_id: this.portal.id,
          member_id: this.selectedMember.id,
          kind: { Approved: null },
          reason: "Revoked ban",
        });*/
          this.$store
            .dispatch(`portals/${ActionTypes.SET_PORTAL_MEMBER_STATUS}`, {
              portal_id: this.portal.id,
              member_id: this.selectedMember.id,
              kind: { Left: null },
              reason: 'Revoked ban',
            })
            .then(() => {
              // refresh members
              this.$store
                .dispatch(
                  `portals/${ActionTypes.GET_PORTAL_MEMBERS_BY_STATUS}`,
                  {
                    portal_id: this.portal.id,
                    status: { Banned: null },
                  },
                )
                .then(() => {
                  this.selectedMember = null;
                });
            });
        }
      },
      showToastForSeconds(seconds = 2000) {
        if (this.hideToastTimeout) clearTimeout(this.hideToastTimeout);

        this.shouldShowToast = true;
        this.hideToastTimeout = setTimeout(() => {
          this.shouldShowToast = false;
        }, seconds);
      },
    },
  };
</script>
