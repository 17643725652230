<template>
  <div
    class="relative flex flex-col items-center w-full bg-gray-700 rounded-lg"
  >
    <router-link
      :to="{
        name: PORTAL_CONTENT,
        params: { portal: portal.slug },
      }"
    >
      <div
        class="w-full overflow-hidden rounded-t-lg h-22 sm:h-28 lg:h-22 xl:h-28"
      >
        <img
          class="object-cover w-full h-full"
          alt="Portal cover image"
          :src="getCoverPhoto(portal)"
        />
      </div>
    </router-link>
    <div class="relative">
      <router-link
        class="absolute transform -translate-x-1/2 -translate-y-1/2 rounded-lg size-12"
        :to="{
          name: PORTAL_CONTENT,
          params: { portal: portal.slug },
        }"
      >
        <img
          class="w-full h-full rounded-lg"
          alt="Portal cover image"
          :src="getPortalIcon(portal)"
        />
      </router-link>
    </div>
    <div class="h-6"></div>
    <div class="w-full p-3 rounded-b-xl">
      <div class="flex flex-col items-center justify-between w-full h-full">
        <router-link
          :to="{
            name: PORTAL_CONTENT,
            params: { portal: portal.slug },
          }"
          class="w-full overflow-hidden text-lg text-center text-ellipsis whitespace-nowrap"
        >
          {{ portal.name }}
        </router-link>
        <router-link
          :to="{
            name: PORTAL_CONTENT,
            params: { portal: portal.slug },
          }"
          class="ml-3 mr-3 text-xs text-gray-300 min-w-max"
        >
          {{ portal.member_count }} Members
        </router-link>
        <div class="mt-3 w-full [&>button]:w-full">
          <join-portal-button :portal="portal" is-portal-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Prando from 'prando';
  import { mapGetters } from 'vuex';
  import { COVER_PHOTOS, PORTAL_CONTENT } from '@/common';
  import { JoinPortalButton, usePortal } from '@/entities/portal';

  export default {
    name: 'featured-portal',
    components: {
      JoinPortalButton,
    },
    props: {
      portal: {
        type: Object,
        default: () => ({}),
      },
    },
    setup() {
      const { getPortalIcon } = usePortal();
      return { getPortalIcon };
    },
    data() {
      return {
        PORTAL_CONTENT,
        coverPhotos: COVER_PHOTOS,
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
      }),
    },
    methods: {
      getCoverPhoto(portal) {
        if (!portal) {
          return '';
        } else if (!portal.info[0]) {
          return this.getRandomCoverPhoto(portal.name);
        }
        if (portal.info[0].cover_photo.length > 0) {
          const cp = portal.info[0].cover_photo[0];
          return cp == '' ? this.getRandomCoverPhoto(portal.name) : cp;
        } else {
          return this.getRandomCoverPhoto(portal.name);
        }
      },
      getRandomCoverPhoto(portalName) {
        const rng = new Prando(portalName);
        return this.coverPhotos[rng.nextInt(0, this.coverPhotos.length - 1)];
      },
    },
  };
</script>
<style scoped>
  .coverPhoto {
    min-height: 70px;
    /* height: 82px; */
  }
</style>
