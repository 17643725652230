import { colors } from '@/shared/ui/custom-tailwind-styles';
/*
 * This map is used to determine the gradient colors for the role badges.
 * The key is the color of the role (as a number) coming from the BE
 * The value is an object with two keys: from and to representing the gradient colors
 */
export const roleGradients = new Map<
  number,
  {
    from: `from-${keyof typeof colors}`;
    to: `to-${keyof typeof colors}`;
    via?: `via-${keyof typeof colors}`;
  }
>([
  [15079755, { from: 'from-red-650', to: 'to-fuchsia-625' }], // #e6194b
  [3978315, { from: 'from-green-550', to: 'to-sky-750' }], // #3cb44b
  [16769305, { from: 'from-lime-350', to: 'to-green-350' }], // #ffe119
  [4416472, { from: 'from-blue-250', to: 'to-blue-550' }], // #4363d8
  [16089649, { from: 'from-red-450', to: 'to-amber-550' }], // #f58231
  [9510580, { from: 'from-pink-650', to: 'to-violet-750' }], // #911eb4
  [4649200, { from: 'from-teal-320', to: 'to-green-420' }], // #46f0f0
  [
    15741670,
    { from: 'from-pink-450', via: 'via-pink-350', to: 'to-purple-450' },
  ], // #f032e6
  [12383756, { from: 'from-yellow-220', to: 'to-emerald-250' }], // #bcf60c
  [16432830, { from: 'from-violet-55', to: 'to-violet-350' }], // #fabebe
  [32896, { from: 'from-emerald-350', to: 'to-sky-550' }], // #008080
  [15122175, { from: 'from-purple-425', to: 'to-pink-350' }], // #e6beff
  [10117924, { from: 'from-cyan-325', to: 'to-orange-450' }], // #9a6324
  [16775880, { from: 'from-amber-250', to: 'to-cyan-125' }], // #fffac8
  [8388608, { from: 'from-red-575', to: 'to-red-525' }], // #800000
  [
    11206595,
    { from: 'from-teal-325', via: 'via-purple-225', to: 'to-indigo-550' },
  ], // #aaffc3
  [8421376, { from: 'from-pink-575', to: 'to-blue-775' }], // #808000
  [16767153, { from: 'from-amber-110', to: 'to-fuchsia-350' }], // #ffd8b1
  [117, { from: 'from-cyan-250', to: 'to-indigo-625' }], // #000075
  [8421504, { from: 'from-pink-625', to: 'to-amber-350' }], // #808080
  [16777215, { from: 'from-emerald-50', to: 'to-fuchsia-75' }], // #ffffff
  [0, { from: 'from-purple-650', via: 'via-violet-725', to: 'to-violet-850' }],
]);
