<script setup lang="ts">
  import { onMounted } from 'vue';
  import type { TensorNft } from '@/shared/api/sol-api/tensor/collection';
  import { useUser } from '@/entities/user';
  import { useWalletPairing } from '@/entities/wallets';
  import { createTensorNftUrl } from '@/entities/token';
  import { usePairDialog } from '@/features/wallets';
  import { useTensorNft } from '../model/composables/use-tensor-nft';
  import { useAuth } from '@/entities/auth';

  const props = defineProps<{
    nft: TensorNft;
  }>();

  const { firstPairedSolanaWallet, fetchPairedWallets } = useWalletPairing();
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { openAddWalletDialog } = usePairDialog();
  const { openBuyNftDialog } = useTensorNft();

  const buyButtonClasses =
    'w-full whitespace-nowrap min-h-8 flex gap-x-2 items-center bg-gray-820 !rounded-full font-medium text-gray-300 hover:bg-gray-785 hover:text-white ease-transition-all';

  const openBuyDialogModal = async () => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    } else if (!props.nft.listing?.price) {
      window.open(createTensorNftUrl(props.nft.mint), '_blank');
    } else if (!firstPairedSolanaWallet.value) {
      const added = await openAddWalletDialog();
      if (added) {
        openBuyNftDialog({ nft: props.nft });
      }
    } else {
      openBuyNftDialog({ nft: props.nft });
    }
  };

  onMounted(() => {
    if (isLoggedIn.value) fetchPairedWallets();
  });
</script>

<template>
  <base-button
    variant="custom"
    :custom-classes="buyButtonClasses"
    :class="[
      nft?.listing?.price
        ? 'bg-gray-820 px-4 py-2.5'
        : 'px-4 py-2.5 bg-pink-700 text-white hover:bg-pink-700',
    ]"
    :disabled="!nft?.listing?.price"
    @click="openBuyDialogModal"
  >
    <base-icon v-if="nft.listing?.price" name="shopping-cart" size="w-5 h-5" />
    {{
      nft?.listing?.price
        ? nft
          ? $t('nftMarketplace.buy')
          : $t('nftMarketplace.tensor.buyOnTensor')
        : $t('nftMarketplace.sold')
    }}
  </base-button>
</template>
