import type { PersonalizedFeedV1Parameters } from 'dfx/edge/edge.did';

export const followingFeedParameters: PersonalizedFeedV1Parameters = {
  upvote_weight: 1,
  following_upvote_weight: 100,
  following_comment_weight: 1000,
  max_follower_degrees: 1n,
  max_comment_depth: 2n,
  following_upvote_degree_penalty: 5,
  following_comment_degree_penalty: 5,
  relevant_comment_count: 3,
  include_portals: false,
  profile_posts_weight: [],
  freshness_weight: [],
  portal_admin_posts_weight: [],
  show_caller_posts: [false],
  include_top_users: [false],
};

export const forYouFeedParameters: PersonalizedFeedV1Parameters = {
  upvote_weight: 5,
  following_upvote_weight: 100,
  following_comment_weight: 100,
  max_follower_degrees: 2n,
  max_comment_depth: 4n,
  following_upvote_degree_penalty: 0.5,
  following_comment_degree_penalty: 0.5,
  include_portals: true,
  relevant_comment_count: 0,
  profile_posts_weight: [3],
  freshness_weight: [0.005],
  portal_admin_posts_weight: [3],
  show_caller_posts: [true],
  include_top_users: [false],
};
