<script setup lang="ts">
  import { onMounted, onUnmounted, watch } from 'vue';
  import { liveChatLib } from '@/entities/live';

  const { timeElapsed, setTimeElapsedCounterEnds, cleanupTimeElapsedCounter } =
    liveChatLib.useTimeElapsedCounter();

  const props = defineProps<{
    raffle: any;
    buttonVariant: string;
    buttonText: string;
    runningText: string;
    overText: string;
    isOver: boolean;
    canEnter: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'enter'): void;
    (e: 'refresh'): void;
  }>();

  watch(
    () => props.raffle.result,
    (value) => setupTimer(),
  );

  const setupTimer = () => {
    cleanupTimeElapsedCounter();
    let startDate = props.raffle?.result?.startsAt;
    if (Date.parse(startDate) > new Date().getTime()) {
      let timeUntilStart = Date.parse(startDate) - new Date().getTime();

      setTimeout(() => {
        emit('refresh');
      }, timeUntilStart);

      setTimeElapsedCounterEnds(startDate as string);
    } else if (!props.isOver) {
      let timeUntilEnd =
        Date.parse(props.raffle.result.endsAt) - new Date().getTime();

      setTimeout(() => {
        emit('refresh');
      }, timeUntilEnd);

      setTimeElapsedCounterEnds(props.raffle.result.endsAt as string);
    }
  };

  onMounted(() => {
    setupTimer();
  });

  onUnmounted(() => {
    cleanupTimeElapsedCounter();
  });
</script>

<template>
  <div class="flex flex-col items-center gap-3">
    <base-button
      :variant="props.buttonVariant"
      size="medium"
      class="backdrop-blur"
      @click="$emit('enter')"
      :disabled="props.isOver || !props.canEnter"
    >
      <div>{{ props.buttonText }}</div>
    </base-button>
    <div class="text-gray-200">
      <base-icon
        name="outlined-clock"
        size="w-4 h-4"
        class="text-white inline mb-1 mr-1"
      />
      <span v-if="!props.isOver">
        {{ props.runningText }}
        <span class="text-pink-300">{{ timeElapsed }}</span>
      </span>
      <span v-else-if="props.isOver">
        {{ props.overText }}
      </span>
    </div>
  </div>
</template>
