<script setup lang="ts">
  import { computed, inject, onMounted, ref, toRef, type Ref } from 'vue';
  import { refDebounced, useElementBounding } from '@vueuse/core';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { DSCVR_STATIC_ASSETS_CDN_URL, POST_DETAIL } from '@/common';
  import { fetchMedia, isValidHttpUrl } from '@/shared/lib';
  import { ReportSummary } from '@/entities/wallet-transaction';
  import { useUser } from '@/entities/user';
  import { getUrlTitle } from '@/entities/content';
  import { useGetCanvasQuery } from '../api/use-get-canvas.query';
  import CanvasApp from './CanvasApp.vue';
  import { useLogInteractionMutation } from '../api/use-log-interaction.mutation';
  import { useRoute } from 'vue-router';
  import { trackEvent } from '@/utils/tracker';
  import { useAuth } from '@/entities/auth';
  import { useBreakpoint } from '@/shared/model';

  const props = defineProps<{
    url: string;
    inCarousel?: boolean;
  }>();

  const MIN_ASPECT_RATIO_WIDTH = 1.91;
  const MAX_HEIGHT = 1200;
  const backgrounds = [
    'bg-gradient-to-l from-[#6A11CB] to-[#2575FC]',
    'bg-gradient-to-r from-[#7873F5] to-[#EC77AB]',
    'bg-gradient-to-br from-[#16D9E3] to-[#46AEF7]',
    'bg-gradient-to-l from-[#96E6A1] to-[#D4FC79]',
    'bg-gradient-to-t from-[#37ECBA] to-[#72AFD3]',
    'bg-gradient-to-br from-[#B39FFF] to-[#6A1ED2]',
    'bg-gradient-to-t from-[#FE5196] to-[#F77062]',
    'bg-gradient-to-r from-[#B01EFF] to-[#E1467C]',
  ];
  const backgroundIndex = ref(0);
  const containerRef = ref<HTMLElement>();
  const contentSize = ref<{ width: number; height: number }>();
  const showIframe = ref(false);
  const isPreviewLoaded = ref(false);
  const isLoadingIframe = ref(false);
  const iframeLoadingError = ref(false);
  const content = inject<Ref<ContentView | undefined> | undefined>(
    'content',
    undefined,
  );
  const route = useRoute();
  const { isSmallerThanSm } = useBreakpoint();
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { width: containerWidth } = useElementBounding(containerRef);

  const { data: canvasAppInfo, isFetching: isLoadingCanvasInfo } =
    useGetCanvasQuery(toRef(props, 'url'));
  const { mutate: logInteractionMutation } = useLogInteractionMutation();

  const reportCount = computed(() => {
    return canvasAppInfo.value?.reportCount || 0;
  });

  const transactedCount = computed(() => {
    return canvasAppInfo.value?.transactedCount || 0;
  });

  const isLoading = computed(
    () => isLoadingCanvasInfo.value || isLoadingIframe.value,
  );

  const loadingError = computed(
    () =>
      !isLoading.value && (!canvasAppInfo.value || iframeLoadingError.value),
  );

  const previewImageUrl = computed(() => {
    const previewImageUrl = canvasAppInfo.value?.previewImageUrl;
    if (!previewImageUrl) return;
    if (isValidHttpUrl(previewImageUrl)) {
      return previewImageUrl;
    }
    return `${props.url}${previewImageUrl}`;
  });

  const postDetailNavigation = computed(() => {
    if (!props.inCarousel || !content?.value) return;
    return {
      name: POST_DETAIL,
      params: {
        id: content.value.id.toString(),
        title: getUrlTitle(content.value),
      },
      query: {
        autoStart: encodeURIComponent(canvasAppInfo.value?.originalUrl || ''),
      },
    };
  });

  const autoStartByQueryParam = computed(
    () =>
      typeof route.query.autoStart === 'string' &&
      decodeURIComponent(route.query.autoStart) ===
        canvasAppInfo.value?.originalUrl,
  );

  const shouldShowIframe = computed(() => {
    if (props.inCarousel || !isLoggedIn.value || !canvasAppInfo.value) {
      return false;
    }

    if (canvasAppInfo.value.previewImageUrl && !isPreviewLoaded.value) {
      return false;
    }

    return (
      autoStartByQueryParam.value ||
      canvasAppInfo.value.autoStart ||
      showIframe.value
    );
  });

  const aspectRatio = computed(() => {
    if (!contentSize.value) {
      return `${MIN_ASPECT_RATIO_WIDTH}/1`;
    }
    const { width, height } = contentSize.value;
    if (width / height > MIN_ASPECT_RATIO_WIDTH) {
      return `${MIN_ASPECT_RATIO_WIDTH}/1`;
    }
    return `${width}/${height}`;
  });

  const background = computed(() => {
    return backgrounds[backgroundIndex.value];
  });

  const showBackground = computed(() => {
    return isSmallerThanSm.value && !props.inCarousel && !props.inEditor;
  });

  const isLoadingDebounced = refDebounced(isLoading, 100);

  // for now, we only support full width
  const setContentSize = (width: number, height: number) => {
    const newHeight = Math.min(
      Math.ceil((height / width) * containerWidth.value),
      MAX_HEIGHT,
    );
    contentSize.value = { width: containerWidth.value, height: newHeight };
  };

  const previewImageLoaded = (imageElement: HTMLImageElement) => {
    if (imageElement.naturalWidth && imageElement.naturalHeight) {
      setContentSize(imageElement.naturalWidth, imageElement.naturalHeight);
    }
    isPreviewLoaded.value = true;
  };

  const loadIframe = () => {
    if (props.inCarousel) {
      return;
    }
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
      return;
    }
    showIframe.value = true;

    if (props.url && content?.value?.id) {
      trackEvent('canvas_play', content.value.id, props.url);
      logInteractionMutation({ url: props.url, contentId: content.value.id });
    }
  };

  const iframeLoadError = () => {
    iframeLoadingError.value = true;
    isLoadingIframe.value = false;
  };

  onMounted(() => {
    backgroundIndex.value = Math.floor(Math.random() * backgrounds.length);
  });
</script>
<template>
  <div
    ref="containerRef"
    class="flex flex-col gap-2 relative"
    :class="
      inCarousel
        ? 'size-full p-0.5'
        : showBackground
        ? ` -mx-4 px-4 py-12.5 ${background} animated-background before:absolute before:inset-0 before:bg-black before:bg-opacity-20`
        : ''
    "
  >
    <report-summary
      v-if="!inCarousel && (reportCount || transactedCount)"
      :report-count="reportCount"
      :transacted-count="transactedCount"
    />

    <div
      class="not-prose rounded-xl relative flex items-center justify-center bg-gray-990 z-0 shadow-lighter"
      :style="{
        aspectRatio: inCarousel ? undefined : aspectRatio,
        'max-height': `${MAX_HEIGHT}px`,
      }"
      :class="inCarousel ? 'size-full' : 'w-full'"
    >
      <div
        v-if="isLoadingDebounced"
        class="absolute -inset-1 overflow-hidden rounded-xl -z-1"
      >
        <div
          class="absolute -inset-[150%] animate-spin animate-duration-[3000ms] conic-gradient"
        />
      </div>
      <div
        v-else-if="!showIframe"
        class="absolute -inset-px overflow-hidden rounded-xl -z-1"
      >
        <div
          class="absolute size-full bg-gradient-to-r from-[#6722be] to-[#58296f]"
        />
        <div
          class="absolute size-full bg-gradient-to-r from-[#6722be] to-[#146FDA] animate-fade animate-infinite animate-duration-2000 animate-alternate-reverse"
        />
      </div>
      <div class="relative bg-gray-990 size-full overflow-hidden rounded-xl">
        <div
          v-if="loadingError"
          class="error size-full flex flex-col items-center justify-center gap-2 text-slade-500 text-white text-opacity-70 p-4 md:p-7"
        >
          <div class="flex-1 min-h-0 w-full">
            <base-icon name="error-bot" class="opacity-60" size="size-full" />
          </div>
          <span
            class="relative"
            :class="inCarousel ? 'text-sm lg:text-xl' : 'text-xl'"
          >
            {{ $t('canvas.errorLoading') }}
          </span>
        </div>
        <base-button
          v-else-if="!shouldShowIframe || isLoadingIframe"
          variant="custom"
          custom-classes="size-full flex flex-col items-center justify-center group"
          :disabled="isLoadingIframe"
          :to="postDetailNavigation"
          @click="loadIframe"
        >
          <div
            class="absolute inset-0 transition-all duration-500 bg-center bg-cover"
            :class="{ 'group-hover:-inset-4': !inCarousel }"
          >
            <img
              v-if="previewImageUrl"
              v-lazy="{
                src: previewImageUrl,
                error: fetchMedia(
                  `${DSCVR_STATIC_ASSETS_CDN_URL}/canvas/placeholder.png`,
                ),
                lifecycle: {
                  loaded: previewImageLoaded,
                  error: () => (isPreviewLoaded = true),
                },
              }"
              class="!size-full object-cover"
            />
            <img
              v-else
              v-lazy="
                fetchMedia(
                  `${DSCVR_STATIC_ASSETS_CDN_URL}/canvas/placeholder.png`,
                )
              "
              class="!size-full object-cover"
            />
          </div>
          <div class="absolute size-full bg-black bg-opacity-45" />
          <img
            v-lazy="`${DSCVR_STATIC_ASSETS_CDN_URL}/canvas/corner-logo.png`"
            class="absolute top-6 right-6 md:top-8 md:right-8 w-auto h-5"
          />
          <span
            v-if="!isLoadingIframe"
            class="relative flex items-center justify-center gap-2 h-12 md:h-auto p-3.5 text-white md:text-xl rounded-2.5xl bg-black bg-opacity-24 backdrop-blur-lg overflow-hidden before:absolute before:inset-0 before:bg-white before:bg-opacity-8 group-hover:before:bg-opacity-12"
          >
            <base-icon
              name="play-arrow"
              size="size-4.5 md:size-6"
              class="opacity-80"
            />
            Canvas
          </span>
        </base-button>
        <canvas-app
          v-if="shouldShowIframe && canvasAppInfo"
          :app-info="canvasAppInfo"
          :content="content"
          :max-height="MAX_HEIGHT"
          @loading="isLoadingIframe = $event"
          @loading-error="iframeLoadError"
          @resize="setContentSize"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .error {
    background: linear-gradient(
      161.15deg,
      rgba(103, 20, 204, 0.2) 12.73%,
      rgba(46, 104, 245, 0.2) 72.95%
    );
  }

  .conic-gradient {
    background-image: conic-gradient(#3b82f6 20deg, transparent 120deg);
  }

  .animated-background {
    @apply animate-[background-animation_8s_ease_infinite];
  }

  @keyframes background-animation {
    0%,
    100% {
      background-size: 100%;
    }

    50% {
      background-size: 150%;
    }
  }
</style>
