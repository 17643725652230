<template>
  <div
    class="text-white height"
    :class="{
      'mt-5': !!!portalsFilteredAndClipped?.length,
    }"
  >
    <div v-if="!hideHeader" class="flex items-center mx-4 mb-4 md:mx-0">
      <div class="text-lg font-bold">Portals</div>
      <div
        class="flex items-center justify-center mt-0.5 ml-2 text-sm text-indigo-300 cursor-pointer"
        @click="setActiveTab"
      >
        <span>View More</span>
        <base-icon name="chevron-right" size="size-4" />
      </div>
    </div>
    <div
      class="relative w-full p-4 mb-4 text-white bg-gray-950 shadow-lg post-item md:rounded-xl"
    >
      <div
        v-if="isLoading && !!!portalsFilteredAndClipped?.length"
        class="flex items-center justify-center h-56 space-x-4 no-results"
      >
        <div class="relative pt-2 text-center text-white w-14 h-14">
          <div
            class="transform translate-x-1.5 translate-y-6 spinner-big"
          ></div>
        </div>
      </div>
      <div
        v-else-if="portalsFiltered == null || portalsFiltered.length == 0"
        class="flex items-center justify-center space-x-4 min-h-56"
      >
        <div class="flex flex-col items-center justify-center no-results">
          <div class="text-xl text-gray-300">
            <a
              href="https://www.youtube.com/watch?v=DtFhreFhSfU"
              target="_blank"
            >
              Oops </a
            >, no portals found
          </div>
          <div class="flex flex-col items-center mt-3 text-base text-gray-400">
            <div class="min-w-max">Try searching for something else, or</div>
            <div>create the portal yourself!</div>
            <create-portal-button
              variant="primary"
              size="small"
              rounded="rounded-lg"
              additional-classes="mt-3 px-5 py-2.5"
            >
              <base-icon name="outlined-plus" size="size-4" />
              <span class="text-white font-medium">
                {{ $t('createPortal') }}
              </span>
            </create-portal-button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2 2xl:grid-cols-4">
        <div
          v-for="portal in portalsFilteredAndClipped"
          :key="portal.name"
          class="relative w-full"
        >
          <featured-portal :portal="portal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ActionTypes } from '@/store/action-types';
  import FeaturedPortal from './FeaturedPortal.vue';
  import { mapGetters } from 'vuex';
  import { SEARCH } from '@/common';
  import { CreatePortalButton } from '@/widgets/create-portal-button';

  export default {
    name: 'searched-portals-list',
    components: {
      FeaturedPortal,
      CreatePortalButton,
    },
    props: {
      hideHeader: {
        type: Boolean,
        default: false,
      },
      shownOffset: {
        type: Number,
        default: 0,
      },
      shownCount: {
        type: Number,
        default: 4,
      },
      search: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        step: 4,
        count: this.shownCount,
        isLoading: false,
        portalsSlugs: ['dscvr', 'general', 'bugs', 'feature-requests'],
      };
    },
    computed: {
      ...mapGetters({
        portals: `portals/portals`,
      }),
      portalsFiltered() {
        let portals = this.portals;
        const searchPortalNameLowerCase = this.search.toLowerCase();
        if (this.search) {
          portals = portals.filter((portal) => {
            return portal.name
              .toLowerCase()
              .includes(searchPortalNameLowerCase);
          });
        }
        return portals;
      },
      portalsFilteredAndClipped() {
        return this.portalsFiltered.slice(this.shownOffset, this.count);
      },
    },
    watch: {
      shownCount() {
        this.count = this.shownCount;
      },
    },
    mounted() {
      this.fetchPortals();
      window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
      // reposition second popup if visible, on scroll
      window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
      onScroll() {
        if (!this.hideHeader) return;
        const bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop,
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          if (this.count < this.portalsFiltered.length)
            this.count = Math.min(
              this.portalsFiltered.length,
              this.count + this.step,
            );
        }
      },
      setActiveTab() {
        const query = JSON.parse(JSON.stringify(this.$route.query));
        query.tab = 'Portals';
        this.$router.push({
          name: SEARCH,
          query: query,
        });
      },
      fetchPortals() {
        this.isLoading = true;
        this.$store.dispatch(`portals/${ActionTypes.LIST_PORTALS}`).then(() => {
          this.isLoading = false;
        });
      },
    },
  };
</script>
<style scoped>
  .overlay {
    background: rgba(31, 41, 55, 0.64);
    backdrop-filter: blur(20px);
  }
  .no-results {
    min-height: 180px;
  }
</style>
