import axios from 'axios';
import TokenService from './token.service';
import { client } from '@/utils/dfinity';
import { config } from '@/shared/lib';

const gatedAxios = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${config.GATED_API_BASE}/api`,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

gatedAxios.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (config.headers && token)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default {
  async getOrCreateUser() {
    const principal = client.getPrincipal().toText();
    if (!principal) return;
    const response = await gatedAxios.post('dscvr/auth/user', {
      pk: principal,
    });
    return response.data.data.nonce;
  },
  async verifyUser() {
    if (TokenService.checkValidToken()) return;
    const principal = client.getPrincipal().toText();
    if (!principal) return;
    const nonce = await this.getOrCreateUser();
    const bufferNonce = Buffer.from(nonce, 'base64');
    const req_and_headers = await client.signer.createSignedQueryRequest(
      'verify_identity',
      [bufferNonce],
    );
    const payload = {
      request: req_and_headers.request,
      headers: req_and_headers.headers,
      pk: client.getPrincipal().toText(),
      nonce: nonce,
    };

    const response = await gatedAxios.post('dscvr/auth/verify', payload);
    TokenService.setUser(response.data.data);
  },
  listNftCollections(portalSlug) {
    return gatedAxios.get('nft-collections', {
      params: { portalSlug: portalSlug },
    });
  },
  createNftCollection(payload) {
    return gatedAxios.post('nft-collections', payload);
  },
  updateNftCollection(id, data) {
    return gatedAxios.put(`nft-collections/${id}`, data);
  },
  getNftCollection(id) {
    return gatedAxios.get(`nft-collections/${id}`);
  },
  deleteNftCollection(id) {
    return gatedAxios.delete(`nft-collections/${id}`);
  },
  watcherJoinNftPortal(slug) {
    return gatedAxios.post(`portals/${slug}/invite-watcher`);
  },
  sendPhoneVerify(phone) {
    try {
      return gatedAxios.post('verify/phone', {
        phone: phone,
      });
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },
  confirmPhone(code, sid) {
    try {
      return gatedAxios.post('verify/phone/confirm', {
        code: code,
        sid: sid,
      });
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },

  tip(data) {
    try {
      return gatedAxios.post('sns/tip', data);
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },

  raffleStatus(contentId) {
    try {
      return gatedAxios.get(`raffle/check/${contentId}`);
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },

  enterRaffle(raffleId) {
    try {
      return gatedAxios.post(`raffle/enter/${raffleId}`);
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },

  getTips(contentIds) {
    try {
      return gatedAxios.post('sns/tips', contentIds);
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },

  getMyEvmWallets() {
    try {
      return gatedAxios.get('evm/my-wallets');
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },

  linkEvmWallet(payload) {
    return gatedAxios.post('evm/my-wallets/link', payload);
  },

  unlinkEvmWallet(evmWalletAddress) {
    try {
      return gatedAxios.delete(`evm/my-wallets/${evmWalletAddress}`);
    } catch (error) {
      return {
        data: { status: 'error', msg: error.response.data['message'] || '' },
      };
    }
  },
};
