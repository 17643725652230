import { computed, type Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import type { ContentView, ContentViewPage } from 'dfx/edge/edge.did';
import { ActionTypes } from '@/store/action-types';
import { POST_DETAIL } from '@/common';
import { useContentTitle } from '@/entities/content';
import { trackEvent } from '@/utils/tracker';

export function usePost(content?: Ref<ContentView>) {
  const store = useStore();
  const router = useRouter();
  const { title } = useContentTitle(content);

  const revealedNSFWContent = computed<bigint[]>(() => {
    return store.getters['posts/revealedNSFWContent'];
  });

  const isLoadingContent = computed<boolean>(
    () => store.state.posts.isLoadingContent,
  );

  const currentPost = computed<ContentView>(() => store.getters['posts/post']);

  const pages = computed<Omit<ContentViewPage, 'contents'>>(
    () => store.getters['posts/pages'],
  );

  const revealNSFWContent = (id: bigint) => {
    trackEvent('click', 'reveal_nsfw', id.toString());
    store.dispatch(`posts/${ActionTypes.REVEAL_NSFW_POST}`, id);
  };

  const postId = computed(() => {
    return content?.value.id?.toString() ?? null;
  });

  const postDetailRouteParams = computed(() => {
    return { id: postId.value, title: title.value };
  });

  const postDetailAbsoluteUrl = computed(() => {
    const route = router.resolve({
      name: POST_DETAIL,
      params: postDetailRouteParams.value,
    });

    return new URL(route.href, window.location.origin).href;
  });

  const setIsLoading = (isLoading: boolean) => {
    store.commit(`posts/${ActionTypes.SET_IS_LOADING}`, isLoading);
  };

  return {
    isLoadingContent,
    pages,
    postDetailAbsoluteUrl,
    postDetailRouteParams,
    postId,
    currentPost,
    revealedNSFWContent,
    revealNSFWContent,
    setIsLoading,
  };
}
