<script setup lang="ts">
  import type { PortalView } from 'dfx/edge/edge.did';
  import { PORTAL_CONTENT, USER_CONTENT } from '@/common';
  import { ClampLines } from '@/shared/ui/clamp-lines';
  import { usePortal } from '../model/composables/use-portal';
  import JoinPortalButton from './JoinPortalButton.vue';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { formatToUnits } from '@/shared/lib';

  defineProps<{
    portal: PortalView;
  }>();

  const { getPortalIcon } = usePortal();
</script>

<template>
  <base-button
    variant="link"
    :to="{
      name: PORTAL_CONTENT,
      params: { portal: portal.slug },
    }"
    custom-classes="flex flex-col md:flex-row items-center gap-6 md:gap-10 w-full group rounded-xl p-6 sm:p-0 border border-gray-800 sm:border-none overflow-hidden"
  >
    <div class="flex w-full gap-10 md:w-auto">
      <img
        alt="Portal icon"
        v-lazy="getPortalIcon(portal)"
        class="rounded-lg size-16 md:size-52"
      />
      <div
        class="flex md:flex-col justify-start sm:justify-center gap-3.5 w-full md:w-40 flex-wrap"
      >
        <div
          class="flex flex-col justify-center gap-3.5 flex-1 md:flex-initial md:w-40"
        >
          <span class="text-lg font-semibold leading-5 text-white truncate">
            {{ portal.name }}
          </span>
          <div class="flex justify-start gap-4">
            <div class="flex items-center gap-1">
              <base-icon name="outlined-users2" size="w-4 h-4" />
              <span class="text-sm font-medium">
                {{ formatToUnits(portal.member_count) }}
              </span>
            </div>
            <div class="flex items-center gap-1">
              <base-icon name="vuesax-linear-simcard2" size="w-4 h-4" />
              <span class="text-sm font-medium">
                {{ formatToUnits(portal.content_count) }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex self-start justify-start">
          <join-portal-button size="x-small" :portal="portal" />
        </div>
      </div>
    </div>
    <div class="flex items-center w-full md:w-72">
      <clamp-lines :text="portal.description" :lines="4" />
    </div>
    <div class="flex md:flex-col gap-3.5 flex-1 w-full justify-start">
      <span class="text-sm text-gray-400 whitespace-nowrap">{{
        $t('createdBy')
      }}</span>

      <base-button
        variant="link"
        :to="{
          name: USER_CONTENT,
          params: { username: portal.owner.username },
        }"
        custom-classes="flex gap-2"
      >
        <user-avatar
          v-if="portal.owner"
          :item="portal.owner"
          size="w-6 h-6"
          class="z-1"
        />
        <span
          class="text-sm font-medium leading-5 truncate"
          :title="portal.owner.username"
        >
          {{ portal.owner.username }}
        </span>
      </base-button>
    </div>
  </base-button>
</template>
