import type { AxiosPromise } from 'axios';
import { apiInstance } from './base';
import type { ResponseObject } from './models';

export interface ILinkStatus {
  status: boolean;
}

const BASE_URL = '/matrica';

export const getMatricaLinkStatus = (): AxiosPromise<
  ResponseObject<ILinkStatus>
> => {
  return apiInstance.get(`${BASE_URL}/link-status`);
};

export const refreshMatricaWallets = (): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/refresh-wallets`);
};

export const unlinkMatricaWallet = (): AxiosPromise<void> => {
  return apiInstance.delete(`${BASE_URL}/unlink`);
};
