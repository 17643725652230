<script setup lang="ts">
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { usePeopleRecommendation } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import { usePortalRecommendation } from '@/entities/portal';

  const props = defineProps<{
    mode: 'portals' | 'people';
  }>();

  const { contentPreset } = useChainBehavior();
  const { openRandomUser } = usePeopleRecommendation(contentPreset);
  const { openRandomPortal } = usePortalRecommendation(contentPreset);

  const openRandom = () => {
    if (props.mode === 'portals') {
      openRandomPortal();
    } else {
      openRandomUser();
    }
  };
</script>

<template>
  <div
    class="flex items-center justify-center w-full bg-center bg-cover h-80 pt-18"
    :style="{
      backgroundImage: `url('${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/explore/explore-footer-bg.png`,
      )}')`,
    }"
  >
    <div class="flex flex-col justify-center gap-6">
      <h1 class="font-bold text-center text-white text-xxxl">
        {{ $t('rollTheDice') }}
      </h1>
      <base-button
        variant="glass"
        size="medium"
        class="backdrop-blur"
        @click="openRandom"
      >
        <base-icon name="dice" size="w-7 h-7" />
        <span>{{ $t('takeMeToTheUnknown') }}</span>
      </base-button>
    </div>
  </div>
</template>
