import axios from 'axios';
import TokenService from './token.service';
import { config } from '@/shared/lib';

const portalHuntAxios = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: `${config.GATED_API_BASE}/api`,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

portalHuntAxios.interceptors.request.use(
  (config) => {
    /**
     * you can do something here
     */

    const token = TokenService.getLocalAccessToken();
    if (config.headers && token)
      config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default {
  submitAnswer(gameAnswer) {
    return portalHuntAxios
      .post(`portal-hunt/answer`, gameAnswer)
      .catch((exc) => {
        console.log(exc);
        return exc.response;
      });
  },
  getStatus(gameStatus) {
    return portalHuntAxios.get(
      `portal-hunt/status/${gameStatus.gameId}/${gameStatus.stageId}`,
    );
  },
  getByPostId(postId) {
    return portalHuntAxios.get(`portal-hunt/post/${postId}`);
  },
};
