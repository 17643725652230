<script setup lang="ts">
  import { onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { InviteBackground, PortalInviteSignUp } from '@/features/invite';
  import { usePortal, useMetaPortal } from '@/entities/portal';

  const props = defineProps<{
    portal: string;
  }>();

  const route = useRoute();
  const { loadPortalProfile, currentPortal } = usePortal();

  onMounted(() => {
    loadPortalProfile(props.portal);
  });

  useMetaPortal(currentPortal);
</script>

<template>
  <div class="h-screen">
    <invite-background />
    <portal-invite-sign-up
      v-if="currentPortal"
      :portal="currentPortal"
      :code="typeof route.query.ur === 'string' ? route.query.ur : undefined"
    />
  </div>
</template>
