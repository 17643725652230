import { useUser } from '@/entities/user';
import { computed } from 'vue';
import { USER_WALLETS, USER_CONTENT, USER_SETTINGS_PROFILE } from '@/common';
import TokenService from '@/services/token.service';
import { client } from '@/utils/dfinity';
import { type RouteLocationNamedRaw, useRouter } from 'vue-router';
import { useVModel } from '@vueuse/core';
import type { UserMenuProps, UserEmit, MenuLink } from '@/widgets/app-header';

export const useUserLinks = (props: UserMenuProps, emit: UserEmit) => {
  const { currentUser } = useUser();
  const router = useRouter();

  const isOpened = useVModel(props, 'opened', emit);

  const defaultParams = {
    username: currentUser.value?.username,
  };

  const userLinks = computed<MenuLink[]>(() => [
    {
      label: 'Profile',
      icon: 'filled-user',
      route: {
        name: USER_CONTENT,
        params: defaultParams,
      },
    },
    {
      label: 'Wallet',
      icon: 'wallet-filled',
      route: {
        name: USER_WALLETS,
        params: defaultParams,
      },
    },
    {
      label: 'Settings',
      icon: 'cog',
      route: {
        name: USER_SETTINGS_PROFILE,
        params: defaultParams,
      },
    },
    {
      label: 'Logout',
      icon: 'logout',
    },
  ]);

  const otherLinks = computed<Omit<MenuLink, 'icon'>[]>(() => [
    {
      label: 'Join the community',
      route: 'https://discord.gg/hZChhYVzYF',
    },
    {
      label: 'Help & support',
      route: 'https://dscvr.notion.site/',
    },
  ]);

  const logout = () => {
    TokenService.removeUser();
    client.logout();
  };

  const onClickRoute = (route?: RouteLocationNamedRaw | string) => {
    emit('update:opened', false);
    return route ? router.push(route) : logout();
  };

  const customLinksClasses =
    'flex items-center font-medium px-5 py-[15px] text-sm gap-3.5 hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785';

  return {
    userLinks,
    otherLinks,
    onClickRoute,
    customLinksClasses,
    emit,
    isOpened,
  };
};
