<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue';
  import { EXPLORE_PORTALS } from '@/common';
  import { useChainBehavior } from '@/entities/user-setting';
  import {
    usePortalRecommendation,
    GridItemLandscape,
  } from '@/entities/portal';
  import { FeaturedFooter, SearchForm } from '.';
  import { BaseCollectionView } from '@/shared/ui/base-collection-view';
  import { useSticky } from '@/composables';

  const props = withDefaults(
    defineProps<{
      showSearch: boolean;
      count?: number;
    }>(),
    {
      count: 50,
    },
  );

  const loading = ref(false);
  const stickyRef = ref<HTMLElement | null>(null);
  const contentRef = ref<HTMLElement | null>(null);
  const { contentPreset } = useChainBehavior();
  const { isStickyActive } = useSticky(stickyRef, contentRef);
  const { portalExploreRecommendations, fetchPortalExploreRecommendations } =
    usePortalRecommendation(contentPreset);

  watch(
    () => contentPreset.value,
    async (newValue, oldValue) => {
      if (oldValue !== newValue) {
        loading.value = true;
        await fetchPortalExploreRecommendations(props.count);
        loading.value = false;
      }
    },
  );

  onMounted(async () => {
    if (portalExploreRecommendations.value.length === 0) {
      loading.value = true;
      fetchPortalExploreRecommendations(props.count);
      loading.value = false;
    }
  });
</script>

<template>
  <div class="flex flex-col items-center">
    <div
      ref="stickyRef"
      class="flex justify-center sticky top-47.5 bg-gray-975 z-40 w-full"
    >
      <div
        class="flex justify-between items-end w-full max-w-screen-lg mx-4 pb-4"
        :class="{
          'shadow-bottom': isStickyActive,
        }"
      >
        <h2 class="text-2xl md:text-xxxl font-bold text-white">
          {{ $t('featured') }}
        </h2>
        <div class="flex gap-4">
          <transition
            enter-active-class="animate-fade-left"
            leave-active-class="animate-fade animate-reverse animate-duration-200"
          >
            <search-form v-if="showSearch" small class="hidden md:flex" />
          </transition>
          <base-button
            variant="secondary"
            size="small"
            :to="{ name: EXPLORE_PORTALS }"
            >{{ $t('viewAll') }}</base-button
          >
        </div>
      </div>
    </div>
    <div ref="contentRef" class="flex flex-col w-full max-w-screen-lg mx-4">
      <base-collection-view
        :items="portalExploreRecommendations"
        :loading="loading"
        type="grid"
      >
        <template #item="{ item }">
          <grid-item-landscape :portal="item" />
        </template>
      </base-collection-view>
    </div>
  </div>
  <featured-footer mode="portals" />
</template>
