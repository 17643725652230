import { computed, type Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes } from '@/store/action-types';
import type { ContentPreset } from '@/shared/model';
import { useFetchRandomizedRecommendedPortalsQuery } from '../../api/use-fetch-randomized-recommended-portals.query';
import { usePortal } from './use-portal';
import { usePortalStore } from '../store/portal-store';
import portalService from '@/services/portals';
import type { PortalView } from 'dfx/edge/edge.did';
import type { RandomizedRecommendationQuery } from 'dfx/edge/edge.did';
import { PORTAL_CONTENT } from '@/common';

export const usePortalRecommendation = (contentPreset: Ref<ContentPreset>) => {
  const store = useStore();
  const portalStore = usePortalStore();
  const router = useRouter();
  const { favoritePortals } = usePortal();

  const portalHotspots = computed<PortalView[]>(
    () => store.getters['portals/hotspots'],
  );

  const portalFavoriteRecommendations = computed<PortalView[]>(
    () => portalStore.favoriteRecommendations,
  );

  const portalFeedRecommendations = computed<PortalView[]>(
    () => store.getters['portals/feedRecommendations'],
  );

  const portalExploreRecommendations = computed<PortalView[]>(
    () => store.getters['portals/exploreRecommendations'],
  );

  const featuredPortals = computed<PortalView[]>(
    () => store.getters['portals/featuredPortals'],
  );

  const fetchPortalHotspots = (count: number) => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['hotspot']],
      count,
      content_preset: contentPreset.value,
    };
    return store.dispatch(`portals/${ActionTypes.GET_HOTSPOTS}`, query);
  };

  const fetchPortalFavoriteRecommendations = async (
    count: number,
  ): Promise<PortalView[]> => {
    // TODO: since we need only portals that are not highlighted
    // we fetch the count plus the number of highlighted portals
    // so we ensure we can get at leat ${count} portals that are not highlighted
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['hotspot']],
      count: count + favoritePortals.value.length,
      content_preset: contentPreset.value,
    };
    const response = await useFetchRandomizedRecommendedPortalsQuery(query);
    const portals = response
      .filter(
        (portal) =>
          !favoritePortals.value.some((item) => item.slug === portal.slug),
      )
      .slice(0, count);
    portalStore.setFavoriteRecommendations(portals);
    return response;
  };

  const replacePortalFavoriteRecommendations = async (
    slug: string,
  ): Promise<PortalView[]> => {
    // TODO: since we need only portals that are not highlighted and that are not already in the list
    // we fetch the 1 plus the number of highlighted portals and the number of portals already in the list
    // so we ensure we can get at leat 1 portal that is not highlighted nor already in the list
    const count =
      1 +
      favoritePortals.value.length +
      portalFavoriteRecommendations.value.length;
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['hotspot']],
      count,
      content_preset: contentPreset.value,
    };
    const response = await useFetchRandomizedRecommendedPortalsQuery(query);
    const portals = response.filter(
      ({ slug }) =>
        !favoritePortals.value.some((item) => item.slug === slug) &&
        !portalFavoriteRecommendations.value.some((item) => item.slug === slug),
    );
    const newRecommendations = portalFavoriteRecommendations.value.map(
      (portal) => {
        if (portal.slug === slug) {
          return portals[0];
        }
        return portal;
      },
    );
    portalStore.setFavoriteRecommendations(newRecommendations);
    return response;
  };

  const fetchPortalFeedRecommendations = (count: number) => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['feed-recs']],
      count,
      content_preset: contentPreset.value,
    };
    return store.dispatch(
      `portals/${ActionTypes.GET_FEED_RECOMENDATIONS}`,
      query,
    );
  };

  const fetchPortalExploreRecommendations = (count: number) => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['explore-featured']],
      count,
      content_preset: contentPreset.value,
    };
    return store.dispatch(
      `portals/${ActionTypes.GET_EXPLORE_RECOMENDATIONS}`,
      query,
    );
  };

  const fetchFeaturedPortals = (count: number) => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['sidebar-featured']],
      count,
      content_preset: contentPreset.value,
    };
    return store.dispatch(`portals/${ActionTypes.GET_FEATURED_PORTALS}`, query);
  };

  const openRandomPortal = async () => {
    const query: RandomizedRecommendationQuery = {
      filter_tags: [['explore-featured']],
      count: 1,
      content_preset: contentPreset.value,
    };
    const result = (await portalService.get_randomized_recommended_portals(
      query,
    )) as PortalView[];
    if (result.length > 0) {
      router.push({
        name: PORTAL_CONTENT,
        params: { portal: result[0].slug },
      });
    }
  };

  return {
    portalHotspots,
    portalFeedRecommendations,
    portalFavoriteRecommendations,
    portalExploreRecommendations,
    featuredPortals,
    fetchPortalHotspots,
    fetchPortalFeedRecommendations,
    fetchPortalFavoriteRecommendations,
    replacePortalFavoriteRecommendations,
    fetchPortalExploreRecommendations,
    fetchFeaturedPortals,
    openRandomPortal,
  };
};
