<script setup lang="ts">
  // TODO: fix FSD
  import { toRefs } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import {
    PHONE_VERIFICATION_REQUIRED_ERROR,
    PORTAL_SETTINGS_PAGE_OVERVIEW,
  } from '@/common';
  import { useUser } from '@/entities/user';
  import { usePortal } from '../model/composables/use-portal';
  import { usePortalDialog } from '../model/composables/use-portal-dialog';
  import { useSinglePortal } from '../model/composables/use-single-portal';
  import { ElasticLine } from '@/shared/ui/elastic-line';
  import { useAuth } from '@/entities/auth';

  const props = withDefaults(
    defineProps<{
      portal: PortalView;
      hideLabel?: boolean;
    }>(),
    {
      hideLabel: false,
    },
  );

  const { portal } = toRefs(props);
  const { isJoined, isFavorite, isCurrentUserOwnerOrManager } =
    useSinglePortal(portal);
  const { showLoginSignUpDialog } = useAuth();
  const { isLoggedIn } = useUser();
  const { toggleFavoritePortal, togglePortalFollow } = usePortal();
  const { openVerifyPhoneDialog } = usePortalDialog();

  const join = () => {
    if (!isLoggedIn.value) {
      showLoginSignUpDialog();
    } else {
      try {
        togglePortalFollow(props.portal.id);
      } catch (error: unknown) {
        const err = error as Error;
        if (err.message === PHONE_VERIFICATION_REQUIRED_ERROR) {
          openVerifyPhoneDialog();
        }
      }
    }
  };
</script>

<template>
  <base-button
    v-if="isCurrentUserOwnerOrManager"
    variant="secondary"
    size="x-small"
    additional-classes="drop-shadow-light"
    :to="{
      name: PORTAL_SETTINGS_PAGE_OVERVIEW,
      params: { portal: portal.slug },
    }"
  >
    <base-icon name="settings" size="w-4 h-4" />
  </base-button>
  <base-button
    v-else-if="!isJoined"
    variant="primary"
    size="x-small"
    rounded="rounded-xl"
    additional-classes="drop-shadow-light"
    @click.prevent="join"
  >
    <base-icon name="plus-circle" size="w-4 h-4" class="flex-none" />
    <span>{{ $t('join') }}</span>
  </base-button>
  <base-button
    v-else
    variant="glass"
    size="x-small"
    additional-classes="drop-shadow-light"
    @click.prevent="toggleFavoritePortal(portal)"
  >
    <base-icon :name="isFavorite ? 'star' : 'outline-star'" size="w-4 h-4" />
    <elastic-line>
      {{
        hideLabel
          ? ''
          : isFavorite
          ? $t('favoritePortals.faved')
          : $t('favoritePortals.fave')
      }}
    </elastic-line>
  </base-button>
</template>
