import { computed } from 'vue';
import { getUrlTitle } from '../../lib/title';

import type { Ref } from 'vue';
import type { ContentTreeView, ContentView } from 'dfx/edge/edge.did';

export function useContentTitle(
  content?: Ref<ContentTreeView | ContentView> | Ref<ContentView>,
) {
  const title = computed(() => content?.value && getUrlTitle(content.value));

  return {
    title,
  };
}
