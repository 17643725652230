<script setup lang="ts">
  import { toRef, computed } from 'vue';
  import type { PortalInfoView } from 'dfx/edge/edge.did';
  import {
    useFormatHTMLContent,
    useFormatMarkdownContent,
  } from '@/composables';
  import { DynamicContent } from '@/components/dynamic-content';

  const props = defineProps<{
    body: string;
    portal: PortalInfoView;
  }>();

  const body = toRef(props, 'body');
  const { isHTML, computedBody } = useFormatHTMLContent(body);
  const { compiledMarkdownAsHTML } = useFormatMarkdownContent();
  const finalBody = computed(() => {
    if (isHTML.value) {
      return computedBody.value;
    }
    return compiledMarkdownAsHTML(computedBody.value);
  });
</script>

<template>
  <dynamic-content :body="finalBody" :portal="portal" />
</template>
