<script lang="ts" setup>
  import { usePortal } from '@/entities/portal';
  import type { PortalView } from 'dfx/edge/edge.did';

  withDefaults(
    defineProps<{
      portal: PortalView | null;
      selected?: boolean;
    }>(),
    {
      portal: null,
      selected: false,
    },
  );

  const emit = defineEmits<{
    (e: 'set-portal', value: PortalView): void;
  }>();

  const { getPortalIcon } = usePortal();
</script>

<template>
  <div
    class="flex items-center justify-between mb-3.5 cursor-pointer"
    @click="emit('set-portal', portal!)"
  >
    <div class="flex items-center">
      <div v-if="!portal" class="flex items-center justify-center mr-2 size-8">
        <base-icon name="user-group" />
      </div>
      <img
        v-else
        alt="icon"
        class="inline mr-2 rounded-lg size-8"
        :src="getPortalIcon(portal)"
      />
      <span class="text-base leading-5 font-medium text-[#D1D5DB]">
        {{ portal ? portal.name : 'Your Feed' }}
      </span>
    </div>
  </div>
</template>
