<script setup lang="ts">
  import { onMounted, computed, watch } from 'vue';
  import { useBreakpoint } from '@/shared/model';
  import { usePortalRecommendation, GridItemPortrait } from '@/entities/portal';
  import { useChainBehavior } from '@/entities/user-setting';

  const props = withDefaults(
    defineProps<{
      count?: number;
    }>(),
    {
      count: 3,
    },
  );

  const { contentPreset } = useChainBehavior();
  const { portalFeedRecommendations, fetchPortalFeedRecommendations } =
    usePortalRecommendation(contentPreset);
  const { isSmallerThanSm } = useBreakpoint();

  const list = computed(() => {
    return isSmallerThanSm.value
      ? portalFeedRecommendations.value.slice(0, 2)
      : portalFeedRecommendations.value;
  });

  watch(
    () => contentPreset.value,
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        fetchPortalFeedRecommendations(props.count);
      }
    },
  );

  onMounted(() => {
    if (portalFeedRecommendations.value.length === 0) {
      fetchPortalFeedRecommendations(props.count);
    }
  });
</script>

<template>
  <ul class="grid grid-cols-2 sm:grid-cols-3 gap-4 py-0 mt-4">
    <li v-for="portal in list" :key="portal.slug">
      <grid-item-portrait :portal="portal" hide-label />
    </li>
  </ul>
</template>
