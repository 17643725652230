<script setup lang="ts">
  import { toRefs } from 'vue';
  import InviteSignUpContents from './InviteSignUpContents.vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { useSinglePortal, usePortal } from '@/entities/portal';

  const props = defineProps<{
    portal: PortalView;
    code?: string;
  }>();

  const { portal } = toRefs(props);
  const { coverPhoto, abbreviatedContentCount, abbreviatedMemberCount } =
    useSinglePortal(portal);
  const { getPortalIcon } = usePortal();
</script>
<template>
  <invite-sign-up-contents
    v-if="portal"
    :referent="portal"
    :code="code"
    :banner-bg-url="coverPhoto"
  >
    <template #mobile-avatar>
      <img
        v-lazy="getPortalIcon(portal)"
        alt="Portal Icon"
        class="border border-gray-700 rounded-full shadow-xl size-6 min-h-6 min-w-6"
      />
    </template>
    <template #avatar>
      <img
        v-lazy="getPortalIcon(portal)"
        alt="Portal Icon"
        class="border border-gray-500 rounded-xl min-h-19 min-w-19 size-19"
      />
    </template>
    <template #name>
      {{ portal.name }}
    </template>
    <template #contentCount>
      {{ abbreviatedContentCount }}
    </template>
    <template #membersOrFollowers>
      {{ abbreviatedMemberCount }}
    </template>
  </invite-sign-up-contents>
</template>
