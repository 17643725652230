<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';
  import type { PortalView, UserView } from 'dfx/edge/edge.did';
  import { usePortal } from '@/entities/portal';
  import { SOCIAL_LINKS } from '../lib/social-links';
  import type { SocialLink } from '../types';
  import { useInvite } from '../model/composables/use-invite';
  import { RANDOM_GRADIENTS } from '@/common';
  import VueQrcode from '@chenfengyuan/vue-qrcode';
  import { ReferralButton, useUser } from '@/entities/user';

  const props = defineProps<{
    referent: PortalView | UserView;
    name: string;
    inviteLink: string;
  }>();

  const emit = defineEmits<{
    (e: 'social-share', link: string): void;
    (e: 'download-share-card'): void;
  }>();

  const { downloadDivAsImage, setGradient } = useInvite();
  const { getPortalChainType } = usePortal();
  const { isCurrentUserProfile } = useUser();

  const shareCard = ref<HTMLElement | null>(null);

  const coverPhoto = computed(() => {
    if ('cover_photo' in props.referent) {
      return props.referent.cover_photo[0];
    }
    if ('info' in props.referent) {
      return props.referent?.info[0]?.cover_photo[0];
    }
    return null;
  });

  const isProfile = computed(() => {
    return 'username' in props.referent;
  });

  const randomGradientBg = computed(() => {
    const randomIndex = Math.floor(Math.random() * RANDOM_GRADIENTS.length);
    return RANDOM_GRADIENTS[randomIndex];
  });

  const openSocialShare = (link: SocialLink) => {
    const linkUrl = link.url(props.name, props.inviteLink, isProfile.value);
    window.open(linkUrl, '_blank');
  };

  const downloadShareCard = () => {
    downloadDivAsImage(shareCard.value, props.name);
  };

  onMounted(() => {
    setGradient(coverPhoto.value);
  });

  watch(props.referent, (nV, oV) => {
    if (nV !== oV) {
      setGradient(coverPhoto.value);
      if (!isProfile.value) getPortalChainType(props.referent as PortalView);
    }
  });
</script>

<template>
  <div
    ref="shareCard"
    class="p-6 rounded-2xl relative overflow-hidden"
    :class="
      !coverPhoto
        ? 'bg-gradient-to-br from-gray-700 to-gray-900'
        : 'gradient-bg'
    "
  >
    <div
      v-if="referent"
      class="rounded-2xl overflow-hidden border border-gray-785 border-opacity-50 shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]"
    >
      <div
        class="relative min-h-[350px] bg-cover bg-center"
        :style="{ backgroundImage: coverPhoto ? `url(${coverPhoto})` : '' }"
        :class="[coverPhoto ? '' : `${randomGradientBg} `]"
      >
        <div
          class="flex flex-col items-center justify-center gap-4 h-[350px] rounded-t-2xl backdrop-blur-sm bg-black/20"
        >
          <slot name="avatar" />
          <span class="font-semibold">
            <slot name="name" />
          </span>
          <div class="flex gap-x-4 text-sm font-medium">
            <div
              v-if="isProfile"
              class="flex justify-center items-center gap-1.5"
            >
              <img
                src="/icons/dscvr.svg"
                class="size-4"
                :alt="isProfile ? 'Total portals' : 'Total users'"
              />
              <span class="text-gray-200 font-medium">
                <slot name="points" />
              </span>
            </div>
            <div class="flex justify-center items-center gap-1.5">
              <img
                :src="
                  isProfile ? '/icons/outline-portal.svg' : '/icons/users.svg'
                "
                class="size-4"
                :alt="isProfile ? 'Total portals' : 'Total users'"
              />
              <span class="text-gray-200 font-medium">
                <slot name="membersOrFollowers" />
              </span>
            </div>
            <div class="flex justify-center items-center gap-1.5">
              <img src="/icons/posts.svg" class="size-4" alt="Total posts" />
              <span class="text-gray-200 font-medium">
                <slot name="posts" />
              </span>
            </div>
          </div>
          <div class="text-sm line-clamp-2 px-6">
            <slot name="description" />
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center p-6 bg-gray-975">
        <img
          src="@/assets/icons/dscvr-with-label.svg"
          alt="DSCVR"
          class="w-[8.2rem] h-[2.5rem]"
        />
        <div class="bg-black/50 rounded-xl p-2 border border-gray-600">
          <vue-qrcode :value="inviteLink" :options="{ width: 60, margin: 1 }" />
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-between mt-8">
    <div class="flex p-2 gap-4 rounded-full border border-gray-700">
      <base-button
        v-for="(link, index) in SOCIAL_LINKS"
        :key="index"
        variant="link"
        custom-classes="rounded-full text-gray-200 p-1 text-gray-200"
        @click="openSocialShare(link)"
      >
        <base-icon :name="link.icon" size="w-[22.5px] h-[22.5px]" />
      </base-button>
    </div>
    <referral-button
      variant="custom"
      custom-classes="flex justify-center items-center rounded-full border border-white border-opacity-8 gap-3.5 py-2.5 px-4 bg-white bg-opacity-8 hover:bg-opacity-10"
      rounded="rounded-full"
      :link="inviteLink"
    >
      <template #icon>
        <base-icon
          :name="isCurrentUserProfile ? 'invite' : 'outlined-share'"
          size="size-5"
        />
      </template>
      {{ isCurrentUserProfile ? $t('refer') : $t('share') }}
    </referral-button>
    <base-button
      variant="custom"
      custom-classes="flex justify-center items-center rounded-full border border-white border-opacity-8  p-3 bg-white bg-opacity-8 hover:bg-opacity-10"
      rounded="rounded-full"
      @click="downloadShareCard"
    >
      <base-icon name="download" size="size-5" />
    </base-button>
  </div>
</template>

<style scoped lang="scss">
  .gradient-bg {
    @apply bg-gradient-to-br;
    background-color: var(--color-from);
    background-image: linear-gradient(
      to bottom right,
      var(--color-from),
      var(--color-to)
    );
  }
</style>
