import { apiInstance } from './base';
import type {
  ListingSortType,
  MagicEdenAuctionData,
  MagicEdenCollectionsStats,
} from './models';

const BASE_URL = '/collection';

export const getListings = (
  collectionId: string,
  page: number,
  pageSize: number,
  sort?: ListingSortType,
  sortDirection?: 'asc' | 'desc',
  search: string = '',
  aggregateMode: string = 'false',
) => {
  const params = {
    page,
    pageSize,
    sort,
    sortDirection,
    search,
    aggregateMode,
  };

  return apiInstance
    .get(`${BASE_URL}/listings/${collectionId}`, {
      params,
    })
    .then((response) => response.data) as Promise<MagicEdenAuctionData[]>;
};

export const getStats = (
  collectionId: string,
  aggregateMode: string = 'false',
) => {
  const params = {
    aggregateMode,
  };
  return apiInstance
    .get(`${BASE_URL}/stats/${collectionId}`, {
      params,
    })
    .then((response) => response.data) as Promise<MagicEdenCollectionsStats>;
};
