<script lang="ts" setup>
  // TODO: fix FSD
  import { MainMenu } from '@/widgets/main-menu';
  import { Notifications } from '@/features/notifications';
  import { useUser } from '@/entities/user';
  import SearchBar from '@/components/bars/SearchBar.vue';
  import { HOME } from '@/common';
  import { useBreakpoint } from '@/shared/model';
  import { CreatePostButton } from '@/features/post';
  import {
    UserDrawer,
    SidebarMenu,
    UserDropdown,
    SolanaBanner,
  } from '@/widgets/app-header/components';
  import { UserSummary } from '@/widgets/user-summary';
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { ActiveStreakButton } from '@/entities/streak';
  import { useAuth } from '@/entities/auth';

  const emit = defineEmits<{
    (e: 'update:height', height: string): void;
  }>();

  const { showLoginSignUpDialog } = useAuth();
  const { currentUser, isLoggedIn } = useUser();
  const route = useRoute();
  const { isSmallerThanLg, isSmallerThanMd, isSmallerThanSm } = useBreakpoint();

  const userDrawerOpened = ref(false);
  const menuDrawerOpened = ref(false);
  const isHomePage = computed(() => route.name === HOME);

  const hasSolanaBanner = computed(() => isHomePage.value && !isLoggedIn.value);

  const isSolanaBannerMinimized = ref(false);
</script>

<template>
  <header
    class="fixed z-40 w-full h-18"
    :class="{ 'blur-effect': !hasSolanaBanner }"
  >
    <div
      v-if="hasSolanaBanner"
      class="absolute top-0 left-0 w-full h-[72px] blur-effect -z-1"
      :class="[isSolanaBannerMinimized ? 'opacity-100' : 'opacity-0']"
      :style="{
        transition: 'opacity 0.5s',
      }"
    ></div>
    <div class="mx-auto my-auto flex max-w-home py-1 px-2 gap-2 h-18">
      <div class="flex items-center gap-3 flex-1">
        <base-button
          v-if="isSmallerThanLg"
          variant="link"
          @click="menuDrawerOpened = !menuDrawerOpened"
        >
          <base-icon name="hamburger" size="w-8 h-8" />
        </base-button>
        <sidebar-menu
          v-if="isSmallerThanLg"
          v-model:opened="menuDrawerOpened"
        />
        <base-button variant="link" :to="{ name: HOME }">
          <base-icon
            :name="isSmallerThanMd ? 'dscvr' : 'dscvr-with-label'"
            :size="isSmallerThanMd ? 'w-7 h-7' : 'w-[91px] h-7'"
          />
        </base-button>
        <base-button
          v-if="isSmallerThanSm"
          variant="link"
          custom-classes="flex justify-center items-center rounded-full bg-black bg-opacity-80 border border-white border-opacity-12 p-3"
          @click="menuDrawerOpened = !menuDrawerOpened"
        >
          <base-icon name="search" size="h-5 w-5" class="text-gray-400" />
        </base-button>
        <search-bar v-if="!isSmallerThanSm" />
      </div>
      <div
        v-if="!isSmallerThanLg"
        class="flex items-center justify-center flex-1"
      >
        <main-menu />
      </div>
      <div class="flex justify-end items-center gap-4 lg:flex-1">
        <create-post-button v-if="isLoggedIn" variant="primary" size="x-small">
          <base-icon name="plus" size="w-4 h-4" />
        </create-post-button>
        <div v-else class="flex gap-3">
          <base-button
            variant="tertiary"
            :size="isSmallerThanLg ? 'x-small' : 'small'"
            @click="showLoginSignUpDialog()"
          >
            Log in
          </base-button>
          <base-button
            variant="primary"
            :size="isSmallerThanLg ? 'x-small' : 'small'"
            @click="showLoginSignUpDialog('signup')"
          >
            {{ $t('signup') }}
          </base-button>
        </div>
        <active-streak-button v-if="currentUser" />
        <notifications v-if="!isSmallerThanMd && currentUser" />
        <base-button
          v-if="currentUser"
          variant="link"
          :class="{
            'border border-gray-785 border-opacity-40 p-2': !isSmallerThanMd,
          }"
          custom-classes="rounded-full w-max"
          @click="userDrawerOpened = !userDrawerOpened"
        >
          <user-summary :show-details="!isSmallerThanMd" />
          <user-dropdown
            v-if="!isSmallerThanLg && currentUser"
            v-model:opened="userDrawerOpened"
          />
        </base-button>

        <user-drawer
          v-if="isSmallerThanLg && currentUser"
          v-model:opened="userDrawerOpened"
        />
      </div>
    </div>
  </header>
  <solana-banner
    v-if="hasSolanaBanner"
    @minimized="isSolanaBannerMinimized = $event"
  />
</template>
