<script setup lang="ts">
  import { onBeforeMount } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useNotificationSettingStore } from '@/entities/user';
  import { CheckboxField } from '@/shared/ui/fields';
  import type { UserSelfView } from 'dfx/edge/edge.did';

  defineProps<{
    user: UserSelfView;
    username: UserSelfView['username'];
  }>();

  const notificationSettingStore = useNotificationSettingStore();
  const { inAppNotifications, groupNotifications } = storeToRefs(
    notificationSettingStore,
  );

  onBeforeMount(async () => {
    await notificationSettingStore.getUserSettings();
  });
</script>

<template>
  <section class="flex flex-col gap-8">
    <div>
      <p class="text-2xl font-bold leading-7">
        {{ $t('notifications.title') }}
      </p>
    </div>
    <div class="flex flex-col gap-4">
      <p class="text-xl font-bold tracking-[0.2px]">
        {{ $t('notifications.options.title') }}
      </p>
      <div
        class="flex justify-between gap-y-6 bg-gray-950 border border-gray-785 border-opacity-80 rounded-2xl p-6"
      >
        <div>
          <p class="pb-2 font-semibold leading-5 tracking-[0.16px]">
            {{ $t('notifications.options.grouping.title') }}
          </p>
          <p class="text-gray-400 text-sm leading-4.5 tracking-[0.14px]">
            {{ $t('notifications.options.grouping.subtitle') }}
          </p>
        </div>
        <div>
          <checkbox-field
            name="group-notifications"
            variant="toggle"
            :model-value="groupNotifications"
            @update:model-value="notificationSettingStore.setGroupNotifications"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <p class="text-xl font-bold tracking-[0.2px]">
        {{ $t('notifications.preferences.title') }}
      </p>
      <div
        class="flex flex-col gap-y-6 bg-gray-950 border border-gray-785 border-opacity-80 rounded-2xl p-6"
      >
        <template
          v-for="(notifications, index) in inAppNotifications"
          :key="index"
        >
          <div class="flex items-center justify-between">
            <p class="font-semibold leading-5 tracking-[0.16px]">
              <span v-if="index === 'comments_replies'">
                {{ $t('notifications.preferences.commentsAndReplies') }}
              </span>
              <!-- @TODO: Add this feature once we have this feature: -->
              <!-- <span v-else-if="index === 'shares_reposts'">
                Shares &amp; reposts
              </span> -->
              <span v-else-if="index === 'mentions'">
                {{ $t('notifications.preferences.mentions') }}
              </span>
              <span v-else-if="index === 'likes_reactions'">
                {{ $t('notifications.preferences.likesAndReactions') }}
              </span>
              <span v-else-if="index === 'new_followers'">
                {{ $t('notifications.preferences.newFollowers') }}
              </span>
              <span v-else-if="index === 'streak_reminders'">
                {{ $t('notifications.preferences.streakReminders') }}
              </span>
            </p>
            <div>
              <checkbox-field
                :name="`${index}-notifications`"
                variant="toggle"
                :class="{
                  'opacity-50':
                    index === 'streak_reminders' && !user.email_verified,
                }"
                :model-value="
                  notifications.some((e) => 'App' in e || 'Email' in e)
                "
                @update:model-value="
                  notificationSettingStore.setUserSettings($event, index)
                "
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
