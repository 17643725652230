<script setup lang="ts">
  import { ref } from 'vue';
  import { client } from '@/utils/dfinity';
  import { token } from '@/shared/api/dscvr-api';
  import TestReplicaSettingsForm from '../components/forms/TestReplicaSettingsForm.vue';
  import FormButton from '../components/buttons/FormButton.vue';
  import { config } from '@/shared/lib';
  import CMS from '@/services/cms';
  import { CheckboxField } from '@/shared/ui/fields';
  import { useStorage } from '@vueuse/core';
  import { Principal } from '@dfinity/principal';

  const passKey = ref('');
  const resetStreak = ref(false);
  const nextClaimInSecs = ref('0');
  const streakCount = ref('0');
  const rewardPoints = ref('0');
  const rewardUser = ref('');
  const users = ref(config.DEBUG_USERS);
  const enableBackendSettings = ref(config.ENABLE_DEBUG);
  const isStagingBackdoorAvailable =
    config.EDGE_URL.includes('stg') || config.EDGE_URL.includes('localhost');

  const setUser = async (user: { name: string; keys: string[] }) => {
    token.default.removeUser();
    await client.createDebugActorFromJSON(user.keys);
    window.location.reload();
  };

  const onSubmit = async () => {
    const response = await CMS.getSqrls(passKey.value);
    users.value = JSON.parse(response);
    passKey.value = '';
  };

  const onSubmitStreak = async () => {
    if (resetStreak.value) {
      await client.actor?.backdoor_reset_streak();
    } else {
      let params = {
        streak_count: parseInt(streakCount.value),
        streak_claimable_in_secs: parseInt(nextClaimInSecs.value),
      };
      await client.actor?.backdoor_streak_setup_data(params);
    }
  };

  const onSubmitRewards = async () => {
    let principal =
      rewardUser.value && rewardUser.value.length > 0
        ? Principal.fromText(rewardUser.value)
        : client.getPrincipal();
    await client.actor?.backdoor_token_reward_user(
      principal,
      BigInt(parseInt(rewardPoints.value)) * 1000000n,
      [{ DailyStreak: null }],
    );
  };

  const onSubmitSetSelfAsAdmin = async () => {
    await client.actor?.backdoor_set_self_as_admin();
  };

  const onSubmitClearSelfAsAdmin = async () => {
    await client.actor?.backdoor_clear_self_as_admin();
  };
</script>

<template>
  <section class="text-white w-full text-md">
    <h2 class="text-2xl">Debug Users</h2>
    <div v-for="user in users" :key="user.name" class="mt-4">
      <div class="text-xl underline cursor-pointer" @click="setUser(user)">
        {{ user.name }}
      </div>
      <div v-if="user.description">{{ user.description }}</div>
    </div>
    <template v-if="enableBackendSettings">
      <div>
        <h2 class="text-2xl mt-12">Test Replica Settings</h2>
        <test-replica-settings-form />
      </div>
      <div class="mt-10">
        <form @submit.prevent="onSubmit">
          <div class="max-w-md">
            <div class="pt-4 pb-2">SQRL Loader</div>
            <input
              v-model="passKey"
              type="password"
              class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="SQRL Key"
            />

            <div class="flex items-center pt-4">
              <form-button text="Submit" form-ready />
            </div>
          </div>
        </form>
      </div>
      <div v-if="isStagingBackdoorAvailable">
        <h2 class="text-2xl mt-12">Streaks Backdoor</h2>
        <form @submit.prevent="onSubmitStreak">
          <div class="max-w-md">
            <span class="pt-4 pb-2">
              <input v-model="resetStreak" type="checkbox" />
              <span class="pl-2">Reset Streak</span>
            </span>
            <div class="pt-4 pb-2">Streak Count</div>
            <input
              v-model="streakCount"
              type="number"
              min="0"
              class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="Streak Count URL"
              :disabled="resetStreak"
            />
            <div class="pt-4 pb-2">Next Claim In Secs</div>
            <input
              v-model="nextClaimInSecs"
              type="number"
              class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="Next Claim In Secs"
              :disabled="resetStreak"
            />
            <div class="flex items-center pt-4">
              <form-button text="Submit" form-ready />
            </div>
          </div>
        </form>
      </div>
      <div v-if="isStagingBackdoorAvailable">
        <h2 class="text-2xl mt-12">Rewards Backdoor</h2>
        <form @submit.prevent="onSubmitRewards">
          <div class="max-w-md">
            <div class="pt-4 pb-2">Reward Points</div>
            <input
              v-model="rewardPoints"
              type="number"
              min="0"
              class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="Reward Points"
            />
            <div class="pt-4 pb-2">Reward User</div>
            <input
              v-model="rewardUser"
              type="text"
              class="block w-full bg-gray-900 border-transparent rounded-md ring-1 focus:border-gray-600 focus:bg-gray-700 focus:ring-0"
              placeholder="Reward User"
            />
            <div class="flex items-center pt-4">
              <form-button text="Submit" form-ready />
            </div>
          </div>
        </form>
      </div>
      <div v-if="isStagingBackdoorAvailable">
        <h2 class="text-2xl mt-12">Superadmin Backdoor</h2>
        <div class="flex flex-row justify-start space-x-2">
          <form @submit.prevent="onSubmitSetSelfAsAdmin">
            <div class="pt-4">
              <form-button text="Set Self As Admin" form-ready />
            </div>
          </form>
          <form @submit.prevent="onSubmitClearSelfAsAdmin">
            <div class="pt-4">
              <form-button text="Clear Self As Admin" form-ready />
            </div>
          </form>
        </div>
      </div>
    </template>
  </section>
</template>
