<template>
  <div>
    <content-loader v-if="isLoading" />
    <no-content v-else-if="!isLoading && sortedPortals?.length === 0" portals />
    <div
      v-else
      class="grid grid-cols-1 gap-4 px-4 md:grid-cols-2 md:px-8 lg:gap-6 lg:px-0"
    >
      <profile-portal-card
        v-for="portal in sortedPortals"
        :key="portal.id"
        :portal="portal"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ProfilePortalCard } from './components';
  import ContentLoader from '@/components/loaders/ContentLoader.vue';
  import NoContent from '@/components/empty-messages/NoContent.vue';

  export default {
    components: {
      ContentLoader,
      NoContent,
      ProfilePortalCard,
    },
    props: {
      username: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        sortedPortals: [],
      };
    },
    computed: {
      ...mapGetters({
        isLoading: 'users/isLoading',
        userProfile: 'users/userProfile',
        userPortals: 'users/userPortals',
      }),
    },
    watch: {
      userPortals: {
        immediate: true,
        handler(newPortals, oldPortals) {
          if (newPortals?.length && newPortals !== oldPortals) {
            this.sortByCoverPhoto(newPortals);
          }
        },
      },
    },
    methods: {
      sortByCoverPhoto(userPortals) {
        const withCoverPhotos = userPortals.filter((portal) => {
          return (
            portal.info[0]?.cover_photo &&
            portal.info[0]?.cover_photo[0]?.length > 0
          );
        });
        const randomOrderCoverPhotos = withCoverPhotos
          .sort(() => Math.random() - 0.5)
          .concat(
            userPortals.filter((portal) => {
              return (
                !portal.info[0]?.cover_photo ||
                !portal.info[0]?.cover_photo[0]?.length > 0
              );
            }),
          );
        const moddedPortals = randomOrderCoverPhotos.filter((portal) => {
          return (
            portal?.is_mod ||
            portal?.owner?.username === this.userProfile?.username
          );
        });

        const filteredPortals = moddedPortals.filter(
          (portal) =>
            !(portal.portal_type.length && 'User' in portal.portal_type[0]),
        );

        this.sortedPortals = filteredPortals.concat(
          randomOrderCoverPhotos.filter((portal) => {
            return (
              !portal?.is_mod &&
              portal?.owner?.username !== this.userProfile?.username
            );
          }),
        );
      },
    },
  };
</script>
