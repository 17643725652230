import type { PublicKey } from '@solana/web3.js';
import type { MultichainCollectionMarketPlacePage } from '../models';

export const WALLET_TYPES = {
  phantom: 'phantom',
  solflare: 'solflare',
  metamask: 'metamask',
  matrica: 'matrica',
  backpack: 'backpack',
} as const;

export type WalletType = (typeof WALLET_TYPES)[keyof typeof WALLET_TYPES];

export type NetworkType = 'polygon' | 'solana' | 'ethereum';

export interface BlockchainNetwork {
  id: string;
  name: string;
  slug: string;
  chainType: string;
  crossChainId: string;
}
export type BaseWallet = {
  id: string;
  address: string;
  isPrimary: boolean;
  isPublic: boolean;
  isIdentityWallet: boolean;
  walletChainType: string;
  walletType: {
    id: string;
  };
  thirdPartyLinked?: string[];
  createdAt: string;
  updatedAt: string;
  owner:
    | {
        id: string;
      }
    | string;
  networks: BlockchainNetwork[];
};

export type WalletOwner = {
  id: string;
  username: string;
  dscvrPk: string;
  createdAt: string;
  updatedAt: string;
};

export type Wallet = BaseWallet & {
  walletType: {
    id: string;
    name: string;
    slug: WalletType;
    walletChainType: string;
  };
  owner: WalletOwner | string;
};

export type UserWallet = Wallet & {
  thirdPartyLinked: any[];
  owner: WalletOwner;
};

export interface LinkWalletDto {
  signature: string;
  address: string | PublicKey;
  message: string;
  network: NetworkType;
  walletType: WalletType;
}

export interface LinkWalletMobileDto extends LinkWalletDto {
  otpCode: string;
  dscvrPk: string;
}

export interface GetMyWalletsDto {
  network?: NetworkType;
  walletType?: WalletType;
}

export type SolanaFungiblesResponse = {
  data: SolanaFungiblesResponseWallet[];
  meta: {
    aggregation: Record<string, number>;
  };
};

export type SolanaFungiblesResponseWallet = BaseWallet & {
  tokens: SolanaFungiblesResponseWalletToken[];
};

export type SolanaFungiblesResponseWalletToken = {
  address: string;
  balance: number;
  associated_account: string;
  info: {
    decimals: number;
    name: string;
    symbol: string;
    image: string;
  };
};

export type SolanaNonFungiblesResponse = {
  data: SolanaNonFungiblesResponseWallet;
};

export type SolanaNonFungiblesResponseWallet = BaseWallet & {
  nfts: SolanaNonFungiblesResponseWalletToken[];
};

export type SolanaNonFungiblesResponseWalletToken = {
  nft_id: string;
  contract_address: string;
  token_id: null | string;
  name: string;
  description: string;
  previews: {
    image_small_url: string;
    image_medium_url: string;
    image_large_url: string;
    image_opengraph_url: string;
    blurhash: string;
    predominant_color: string;
  };
  image_url: string;
  image_properties: {
    width: number;
    height: number;
    size: number;
    mime_type: string;
  };
  video_url: null | string;
  video_properties: null | string;
  audio_url: null | string;
  audio_properties: null | string;
  model_url: null | string;
  model_properties: null | string;
  other_url: null | string;
  other_properties: null | string;
  background_color: null | string;
  external_url: string;
  created_date: string;
  status: string;
  token_count: number;
  owner_count: number;
  owners: {
    owner_address: string;
    quantity: number;
    quantity_string: string;
    first_acquired_date: string;
    last_acquired_date: string;
  }[];
  contract: {
    type: string;
    name: string;
    symbol: string;
    deployed_by: null | string;
    deployed_via_contract: null | string;
    owned_by: null | string;
  };
  collection: {
    collection_id: string;
    name: string;
    description: null | string;
    image_url: null | string;
    banner_image_url: null | string;
    category: null | string;
    is_nsfw: null | string;
    external_url: null | string;
    twitter_username: null | string;
    discord_url: null | string;
    instagram_username: null | string;
    medium_username: null | string;
    telegram_url: null | string;
    marketplace_pages: MultichainCollectionMarketPlacePage[];
    metaplex_mint: null | string;
    metaplex_first_verified_creator: null | string;
    floor_prices: any[]; // You can specify a more detailed type if needed
    top_bids: any[]; // You can specify a more detailed type if needed
    distinct_owner_count: number;
    distinct_nft_count: number;
    total_quantity: number;
    chains: string[];
    top_contracts: any[]; // You can specify a more detailed type if needed
  };
  last_sale: null | string;
  first_created: {
    minted_to: string;
    quantity: number;
    quantity_string: string;
    timestamp: string;
    block_number: number;
    transaction: string;
    transaction_initiator: string;
  };
  rarity: {
    rank: null | string;
    score: null | string;
    unique_attributes: null | string;
  };
  royalty: {
    source: string;
    total_creator_fee_basis_points: number;
    recipients: any[]; // You can specify a more detailed type if needed
  }[];
  extra_metadata: {
    attributes: any[]; // You can specify a more detailed type if needed
    is_mutable: boolean;
    seller_fee_basis_points: number;
    creators: any[]; // You can specify a more detailed type if needed
    image_original_url: string;
    animation_original_url: null | string;
    metadata_original_url: string;
  };
  address: string;
};

export type PatchWalletStatus = {
  isPublic?: boolean;
  isPrimary?: boolean;
};
