import type {
  PortalHiglights,
  PortalView,
  UserPortalHighlight,
} from 'dfx/edge/edge.did';

export const sortFavoritePortals = (
  response: PortalHiglights[],
): PortalView[] => {
  if (!response.length) {
    return [];
  }
  const portals = response[0]?.portals ?? [];
  const ordinals =
    response[0]?.ordinals.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1)) ??
    [];
  const sortedPortals = ordinals.map(({ portal_id }) => {
    const found = portals.find((item) => item.id === portal_id);
    return found!;
  });
  return sortedPortals;
};

export const getPortalsOrdinals = (
  portals: PortalView[],
): UserPortalHighlight[] => {
  const ordinals = portals.map((portal, index) => ({
    portal_id: portal.id,
    ordinal: BigInt(index),
  }));

  return ordinals;
};
