<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useNotificationStore } from '@/entities/notification';

  const notificationStore = useNotificationStore();
  const { unreadNotificationCount } = storeToRefs(notificationStore);
</script>

<template>
  <base-button
    variant="link"
    class="relative text-gray-400 hover:text-white"
    @click.stop="notificationStore.openDrawer"
  >
    <base-icon
      name="bell"
      size="w-5 h-5"
      class="hover:text-white transition-colors duration-300 ease-in-out"
    />
    <div
      v-if="unreadNotificationCount > 0n"
      class="w-3 h-3 flex items-center justify-center rounded-full border border-pink-500 font-semibold bg-pink-500 text-xxs text-white top-2 right-2 absolute"
    >
      {{ unreadNotificationCount }}
    </div>
  </base-button>
</template>
