<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useIntersectionObserver } from '@vueuse/core';
  import { IntroductionFull, IntroductionLight } from '@/widgets/introduction';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { USER_SETTINGS_PROFILE } from '@/common';
  import ReadMore from '@/components/ReadMore.vue';
  import { FollowUserButton } from '@/widgets/follow-user-button';
  import { useShareDialog } from '@/shared/model';
  import { SocialLinksSummary } from '@/features/social-links';
  import { useUser, StreakCount, useSocialSphere } from '@/entities/user';
  import SkeletonLoader from './components/SkeletonLoader.vue';
  import InviteUserButton from './components/InviteUserButton.vue';
  import { useBreakpoint } from '@/shared/model';

  const {
    getSocialPresenceLinks,
    copyPrincipalId,
    coverImageGradient,
    currentUser,
    getCreationDate,
    portalCount,
    userProfile,
    getFormattedTokens,
    isCurrentUserProfile,
  } = useUser();

  const { openShareUserProfile } = useShareDialog();
  const { showReferralCommunityDialog } = useSocialSphere();
  const { isSmallerThanMd } = useBreakpoint();

  const customButtonClasses =
    'flex gap-3 p-3 w-[200px] hover:bg-gray-785 active:bg-gray-785 focus:bg-gray-700';

  const coverImage = computed(() =>
    userProfile.value?.cover_photo ? userProfile.value.cover_photo[0] : null,
  );

  const followers = computed(() =>
    Number(userProfile.value?.followers).toLocaleString('en-US'),
  );

  const following = computed(() =>
    Number(userProfile.value?.following).toLocaleString('en-US'),
  );

  const postsCount = computed(() =>
    Number(userProfile.value?.num_posts).toLocaleString('en-US'),
  );

  const tokensCount = computed(() =>
    getFormattedTokens(userProfile.value.rights),
  );

  const introductionFullRef = ref(null);
  const introductionFullVisible = ref(true);
  useIntersectionObserver(introductionFullRef, ([{ isIntersecting }]) => {
    introductionFullVisible.value = isIntersecting;
  });
  const socialLinks = computed(() => getSocialPresenceLinks(userProfile.value));
</script>

<template>
  <template v-if="userProfile">
    <div
      v-if="!introductionFullVisible"
      class="sticky top-16 h-0 overflow-visible z-40 animate-fade animate-duration-500 md:hidden"
    >
      <introduction-light>
        <template #cover-image>
          <div
            :class="coverImageGradient"
            :style="coverImage ? `background-image:url(${coverImage})` : ''"
            class="bg-cover w-full h-[72px] bg-black bg-opacity-50"
          />
          <div
            class="absolute inset-0 w-full h-full bg-black bg-opacity-50"
          ></div>
        </template>
        <template #photo>
          <user-avatar
            v-if="userProfile"
            :item="userProfile"
            size="w-10 h-10"
          />
        </template>
        <template #name>
          {{ userProfile.username }}
        </template>
        <template #actions>
          <base-button
            variant="glass"
            size="small"
            @click="openShareUserProfile(userProfile.username)"
          >
            <base-icon name="share" size="w-5 h-5" />
          </base-button>
          <base-button
            v-if="isCurrentUserProfile"
            variant="primary"
            size="small"
            :to="{
              name: USER_SETTINGS_PROFILE,
              params: {
                username: userProfile.username,
              },
            }"
          >
            <base-icon name="settings" size="w-5 h-5" />
          </base-button>
          <follow-user-button
            v-if="!isCurrentUserProfile"
            size="x-small"
            :user="userProfile"
          />
          <base-dropdown placement="bottom-end" :distance="-5">
            <template #button="{ toggle }">
              <base-button variant="glass" class="py-2.5 px-3" @click="toggle">
                <base-icon name="vertical-dots" size="w-5 h-5" />
              </base-button>
            </template>
            <template #content="{ hide }">
              <div
                class="rounded-xl overflow-hidden bg-gray-725 border border-gray-785 border-opacity-50 right-0 top-full mt-4"
              >
                <base-button
                  variant="custom"
                  size="medium"
                  @click="hide(), openShareUserProfile(userProfile.username)"
                  :custom-classes="customButtonClasses"
                >
                  <base-icon
                    name="outlined-share"
                    size="w-5 h-5"
                    class="text-indigo-300"
                  />
                  {{ $t('shareProfile') }}
                </base-button>

                <base-button
                  variant="custom"
                  size="medium"
                  @click="hide(), copyPrincipalId(userProfile)"
                  :custom-classes="customButtonClasses"
                >
                  <base-icon
                    name="copy-documents"
                    size="w-5 h-5"
                    class="text-indigo-300"
                  />
                  {{ $t('copyPrincipal') }}
                </base-button>
              </div>
            </template>
          </base-dropdown>
        </template>
      </introduction-light>
      <slot light></slot>
    </div>
    <introduction-full ref="introductionFullRef">
      <template #cover-image>
        <div
          :class="coverImageGradient"
          :style="coverImage ? `background-image:url(${coverImage})` : ''"
          class="bg-cover w-full h-42 md:h-72 lg:rounded-t-xl relative"
        >
          <div
            class="absolute inset-0 w-full h-full bg-gradient-to-t from-black/74 via-transparent to-transparent"
          ></div>
        </div>
      </template>
      <template #followers>
        <span class="block text-center">{{ followers }}</span>
        <base-button
          variant="link"
          custom-classes="text-indigo-400 block text-center"
          @click="showReferralCommunityDialog('followers')"
        >
          Followers
        </base-button>
      </template>
      <template #photo>
        <user-avatar
          v-if="userProfile"
          :item="userProfile"
          ring-size="ring-2 md:ring-4"
          size="w-full h-full"
        />
      </template>
      <template #following>
        <span class="block text-center">{{ following }}</span>
        <base-button
          variant="link"
          custom-classes="text-indigo-400 block text-center"
          @click="showReferralCommunityDialog('following')"
        >
          Following
        </base-button>
      </template>
      <template #streak>
        <streak-count :count="Number(userProfile.active_streak)" />
      </template>
      <template #name>
        {{ userProfile.username }}
      </template>
      <template #tokens-count>
        {{ tokensCount }}
      </template>
      <template #portals-count>
        {{ portalCount }}
      </template>
      <template #content-count>
        {{ postsCount }}
      </template>
      <template #creation-date>
        <template v-if="!isSmallerThanMd">
          {{ $t('memberSince') }}
        </template>
        {{ getCreationDate(userProfile.created_at) }}
      </template>
      <template #bio>
        <read-more
          class="text-sm text-gray-300 px-4 md:place-self-start w-full break-words"
          :show-length="isSmallerThanMd ? 70 : 1200"
          :context="userProfile.bio"
        />
      </template>
      <template #actions>
        <div
          class="absolute top-20 right-2 md:relative md:top-auto md:right-auto md:self-start md:mt-15 justify-end flex items-center gap-3 w-full md:max-w-[190px] lg:max-w-[300px]"
        >
          <invite-user-button :user="userProfile" />
          <base-button
            v-if="isCurrentUserProfile"
            variant="primary"
            size="small"
            :to="{
              name: USER_SETTINGS_PROFILE,
              params: {
                username: userProfile.username,
              },
            }"
          >
            <base-icon name="settings" size="w-5 h-5" />
            <span class="hidden md:block">{{ $t('edit') }}</span>
          </base-button>
          <follow-user-button
            v-if="!isCurrentUserProfile"
            size="x-small"
            :user="userProfile"
          />
          <base-dropdown placement="bottom-end" :distance="-5">
            <template #button="{ toggle, isVisible }">
              <base-button
                :variant="isSmallerThanMd ? 'glass' : 'tertiary'"
                :class="{
                  '!bg-white !bg-opacity-6': !isSmallerThanMd && isVisible,
                }"
                class="py-2.5 px-3"
                @click="toggle"
              >
                <base-icon name="vertical-dots" size="w-5 h-5" />
              </base-button>
            </template>
            <template #content="{ hide }">
              <div
                class="rounded-xl overflow-hidden bg-gray-725 border border-gray-785 border-opacity-50 right-0 top-full mt-4"
              >
                <base-button
                  variant="custom"
                  size="medium"
                  @click="hide(), copyPrincipalId(userProfile)"
                  :custom-classes="customButtonClasses"
                >
                  <base-icon
                    name="copy-documents"
                    size="w-5 h-5"
                    class="text-indigo-300"
                  />
                  {{ $t('copyPrincipal') }}
                </base-button>
              </div>
            </template>
          </base-dropdown>
        </div>
      </template>
      <template #social-links>
        <social-links-summary
          :links="socialLinks"
          :for-user-id="userProfile.id"
        />
      </template>
    </introduction-full>
    <slot :light="false"></slot>
  </template>
  <skeleton-loader v-else />
</template>
