import type { ContentView } from 'dfx/edge/edge.did';
import { useQuery } from '@tanstack/vue-query';
import type { Ref } from 'vue';
import { dscvrApi } from '@/shared/api';

export const useGetRaffleStatusQuery = (content: Ref<ContentView>) => {
  return useQuery({
    queryKey: ['raffle-status', 'by-content-id', content.value.id.toString()],
    queryFn: () => dscvrApi.raffle.getRaffleStatus(content.value.id.toString()),
  });
};
