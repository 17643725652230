import { computed, type MaybeRef, toValue, toRef } from 'vue';
import { useStore } from 'vuex';
import userService from '@/services/users';
import authService from '@/services/auth';
import type { Principal } from '@dfinity/principal';
import { ActionTypes } from '@/store/action-types';
import type {
  UserPlatformPair,
  UserView,
  UserSelfView,
  SocialProfile,
  ActionResultUserSearch,
  UserSearchQuery,
  ReferralCommunityQuery,
} from 'dfx/edge/edge.did';
import { getAvatarUrl } from '@/utils';
import { getAvatarIcon, pluralize, formatToUnits } from '@/shared/lib';
import { useSocialPresence } from '@/composables';
import { useClipboardToast, useToast } from '@/shared/model';
import { useReferralCommunityQuery } from '../../api/use-referral-community.query';
import { useSetUserRankMutation } from '../../api/use-set-user-rank.mutation';
import { useBanUserMutation } from '../../api/use-ban-user.mutation';
import { useUnbanUserMutation } from '../../api/use-unban-user.mutation';
import { useTokenGiftUserMutation } from '../../api/use-token-gift-user.mutation';
import { getUserByIdKey } from '../../api/keys';
import { useQueryClient } from '@tanstack/vue-query';
import type { BanFormFields, TokenGiftFormFields } from '@/features/user/types';
import { user } from '@/shared/api/dscvr-ic';

export function useUser(userId?: MaybeRef<Principal | undefined>) {
  const store = useStore();
  const { showToast } = useToast();
  const { copyToClipboard } = useClipboardToast();
  const { matchLinks } = useSocialPresence();
  const { mutate: setUserRankMutation } = useSetUserRankMutation();
  const { mutate: banUserMutation } = useBanUserMutation();
  const { mutate: unbanUserMutation } = useUnbanUserMutation();
  const { mutate: tokenGiftUserMutation } = useTokenGiftUserMutation();
  const queryClient = useQueryClient();

  const colors = [
    'bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500',
    'bg-gradient-to-br from-green-200 via-green-300 to-blue-500',
    'bg-gradient-to-br from-rose-500 via-pink-500 to-indigo-400',
    'bg-gradient-to-br from-blue-300 via-green-200 to-yellow-300',
    'bg-gradient-to-tl from-green-300 via-blue-500 to-purple-600',
    'bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500',
    'bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-green-300 to-purple-400',
    'bg-[conic-gradient(at_left,_var(--tw-gradient-stops))] from-green-300 via-yellow-300 to-pink-300',
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);

  const coverImageGradient = computed(() => colors[randomIndex]);

  const currentUser = computed<UserSelfView>(() => store.getters['auth/me']);

  const isLoggedIn = computed(() => !!currentUser.value?.id);

  const currentUserAvatarUrl = computed<string | Error>(() => {
    return currentUser.value?.display_nft.length > 0
      ? getAvatarUrl(currentUser.value.display_nft[0]?.meta)
      : currentUser.value?.icon_url.length > 0
      ? currentUser.value?.icon_url
      : new Error('no avatar');
  });

  const getFormattedTokens = (value: bigint, compact = false): string => {
    const tokens = value / 1000000n;
    if (compact) {
      return formatToUnits(tokens);
    }

    return Number(tokens).toLocaleString('en-US');
  };

  const pairedPlatformsHash = computed<Map<string, UserPlatformPair[]>>(
    () => store.getters['users/pairedPlatformsHash'],
  );

  const userPairedPlatforms = computed<UserPlatformPair[]>(() => {
    const id = userId ? toValue(userId) : undefined;
    return id ? pairedPlatformsHash.value.get(id.toString()) || [] : [];
  });

  const discordUsername = computed(() => {
    const platforms = userPairedPlatforms.value;
    for (let i = 0; i < platforms.length; i++) {
      const p = platforms[i];
      if ('Discord' in p.kind && p.is_paired) {
        return p.platform_username;
      }
    }
    return undefined;
  });

  const portalCount = computed(() => {
    const userPortals = store.getters['users/userPortals'];
    return userPortals?.length ?? 0;
  });

  const userProfile = computed(() => store.getters['users/userProfile']);

  const referralCode = computed(() => {
    const referralObject = store.getters['users/userReferralCode'];
    return referralObject?.Ok ?? '';
  });

  const isCurrentUserProfile = computed(
    () =>
      currentUser.value &&
      userProfile.value &&
      currentUser.value.id.toText() === userProfile.value.id.toText(),
  );

  const allUsersPaginated = computed<UserView[]>(
    () => store.state.users.allUsersPaginated.items,
  );

  const allUsersPaginatedCurrentPage = computed<bigint>(
    () => store.state.users.allUsersPaginated.currentPage,
  );

  const coverImage = computed(() =>
    userProfile.value?.cover_photo ? userProfile.value.cover_photo[0] : null,
  );

  const followers = computed(() =>
    Number(userProfile.value?.followers).toLocaleString('en-US'),
  );

  const following = computed(() =>
    Number(userProfile.value?.following).toLocaleString('en-US'),
  );

  const postsCount = computed(() =>
    Number(userProfile.value?.num_posts).toLocaleString('en-US'),
  );

  const tokensCount = computed(() =>
    getFormattedTokens(userProfile.value.rights),
  );

  const getCreationDate = (date: bigint) => {
    const newDate = Number(String(date).slice(0, 13));
    return new Date(newDate).toLocaleString('default', {
      year: 'numeric',
      month: 'short',
    });
  };

  const loadPairedPlatforms = (userId: Principal) => {
    store.dispatch(`users/${ActionTypes.GET_PAIRED_PLATFORMS}`, userId);
  };

  const getUserDataByUsername = (usernames: string[]) => {
    const userPromises = usernames.map((user) => {
      return getUserByUsername(user);
    });

    const userResults = Promise.all(userPromises).then((results) => {
      if (results.length) {
        return results;
      } else {
        return [];
      }
    });

    return userResults;
  };

  const getUserByUsername = (username: string) => {
    return store
      .dispatch(`auth/${ActionTypes.GET_USER}`, username)
      .then((result: { result: unknown[] }) => {
        if (result.result.length > 0) {
          return result.result[0] as UserView;
        }
      });
  };

  const getSelf = async (hasLoading = true): Promise<UserSelfView> => {
    const result = await store.dispatch(`auth/${ActionTypes.GET_SELF}`, {
      hasLoading,
    });
    return result.result[0] as UserSelfView;
  };

  const checkIfFollowingUser = async (userId: Principal) => {
    if (currentUser.value) {
      const result = await store.dispatch(
        `users/${ActionTypes.IS_USER_FOLLOWING}`,
        userId,
      );
      return result;
    }
    return false;
  };

  const copyPrincipalId = (user: UserView) => {
    copyToClipboard(user.id.toText(), 'Address copied to clipboard');
  };

  const currentUserPrincipal = computed(() => {
    return currentUser.value?.id;
  });

  const getUserPortals = (userId: Principal) => {
    return store.dispatch(`users/${ActionTypes.GET_USER_PORTALS}`, userId);
  };

  const loadUserProfileByUsername = async (username: string) => {
    if (!username || username === userProfile.value?.username) {
      return;
    }

    const user = await getUserByUsername(username);
    await store.dispatch(`users/${ActionTypes.SET_USER}`, user);
    if (user) {
      await getUserPortals(user.id);
    }

    return user;
  };

  const getSocialPresenceLinks = (user?: UserSelfView): string[] => {
    const socialPresence =
      user && user.social_presence_2 && user.social_presence_2.length > 0
        ? user.social_presence_2[0] ?? []
        : [];
    return socialPresence
      .filter((linkObject) => linkObject.length === 1)
      .map((linkObject) => {
        return Object.entries(linkObject[0] as SocialProfile).map(
          ([_, value]) => value,
        );
      })
      .reduce((acc, val) => [...acc, ...val], []);
  };

  const currentUserSocialPresence = computed<string[]>(() =>
    getSocialPresenceLinks(currentUser.value),
  );

  const updateCurrentUserSocialPresence = (links: string[]) => {
    const socialPresence = matchLinks(links).map(
      ({ link, socialNetwork }) =>
        ({ [socialNetwork.type]: link } as SocialProfile),
    );

    return store.dispatch(
      `auth/${ActionTypes.UPDATE_SOCIAL_PRESENCE}`,
      socialPresence,
    );
  };

  const fetchAllUsersPaginated = (
    request: Omit<UserSearchQuery, 'query'>,
    pagesCount?: bigint,
  ): Promise<ActionResultUserSearch> => {
    return store.dispatch(`users/${ActionTypes.GET_ALL_PAGINATED}`, {
      request: { ...request, query: '' },
      pagesCount,
    });
  };

  const getReferralCode = async () => {
    await store.dispatch(`users/${ActionTypes.GET_USER_REFERRAL_CODE}`);
  };

  const getReferralLink = async (user: UserView, addReferralCode = false) => {
    await getReferralCode();
    return isCurrentUserProfile.value || addReferralCode
      ? `${window.location.origin}/invite/${user.username}?ur=${referralCode.value}`
      : `${window.location.origin}/u/${user.username}`;
  };

  const setIsLoading = (isLoading: boolean) => {
    store.commit(`users/${ActionTypes.SET_IS_LOADING}`, isLoading);
  };

  const refreshCurrentUser = async () => {
    const user = await getUserByUsername(currentUser.value.username);
    await store.dispatch(`users/${ActionTypes.SET_USER}`, user);
  };

  const blockUserToggle = async (userId: Principal) => {
    const result = await userService.blockUserToggle(userId);
    return result[0];
  };

  const removeFollower = (userId: Principal) => {
    return authService.remove_follower(userId);
  };

  const getFollowers = (user: UserView, currentPage = 0) => {
    return store.dispatch(`auth/${ActionTypes.GET_USER_FOLLOWERS}`, {
      username: user.username,
      page: {
        page: BigInt(currentPage),
        page_size: BigInt(500),
      },
    });
  };

  const getFollowing = (user: UserView, currentPage = 0) => {
    return store.dispatch(`auth/${ActionTypes.GET_USER_FOLLOWING}`, {
      username: user.username,
      page: {
        page: BigInt(currentPage),
        page_size: BigInt(500),
      },
    });
  };

  const getBlocked = (currentPage = 0) => {
    return store.dispatch(`auth/${ActionTypes.GET_USER_BLOCKED}`, {
      page: {
        page: BigInt(currentPage),
        page_size: BigInt(500),
      },
    });
  };

  const getReferralCommunity = (params: ReferralCommunityQuery) => {
    return useReferralCommunityQuery(params);
  };

  const onSetUserRank = (user: UserView, amount: bigint) => {
    setUserRankMutation(
      { id: user.id, rank: amount },
      {
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: [
              ...getUserByIdKey(toRef(user, 'id').value.toText()),
              'rank',
            ],
          });
        },
        onSuccess: () => {
          showToast({
            description: `${user.username} rank has been set to ${Number(
              amount,
            )}`,
            type: 'success',
          });
        },
        onError: () => {
          showToast({
            description: `Failed to set ${user.username} rank to ${Number(
              amount,
            )}`,
            type: 'error',
          });
        },
      },
    );
  };

  const onBan = (user: UserView, values: BanFormFields) => {
    const days = values.days;
    const daysToMilliseconds = days * 24 * 60 * 60 * 1000;
    banUserMutation(
      {
        userId: user.id,
        length: daysToMilliseconds,
        why: values.why,
      },
      {
        onSuccess: () => {
          showToast({
            description: `${
              user.username
            } has been banned for ${days} ${pluralize(days, 'day')}`,
            type: 'success',
          });
        },
        onError: () => {
          showToast({
            description: `Failed to ban ${user.username}`,
            type: 'error',
          });
        },
      },
    );
  };

  const onUnban = (user: UserView) => {
    unbanUserMutation(user.id, {
      onSuccess: () => {
        showToast({
          description: `${user.username} has been unbanned`,
          type: 'success',
        });
      },
      onError: () => {
        showToast({
          description: `Failed to unban ${user.username}`,
          type: 'error',
        });
      },
    });
  };

  const onTokenGift = (user: UserView, values: TokenGiftFormFields) => {
    const amount = values.amount;
    tokenGiftUserMutation(
      {
        userId: user.id,
        amount: BigInt(values.amount) * 1000000n,
      },
      {
        onSuccess: () => {
          showToast({
            description: `${amount} ${pluralize(
              amount,
              'token',
            )} have been gifted to ${user.username}`,
            type: 'success',
          });
        },
        onError: () => {
          showToast({
            description: `Failed to gift ${values.amount} tokens to ${user.username}`,
            type: 'error',
          });
        },
      },
    );
  };

  return {
    allUsersPaginated,
    allUsersPaginatedCurrentPage,
    blockUserToggle,
    checkIfFollowingUser,
    copyPrincipalId,
    coverImage,
    coverImageGradient,
    currentUser,
    currentUserAvatarUrl,
    currentUserPrincipal,
    currentUserSocialPresence,
    discordUsername,
    fetchAllUsersPaginated,
    followers,
    following,
    getAvatarIcon,
    getBlocked,
    getCreationDate,
    getFollowers,
    getFollowing,
    getFormattedTokens,
    getReferralCommunity,
    getReferralCode,
    getReferralLink,
    getSocialPresenceLinks,
    getSelf,
    getUserByUsername,
    getUserDataByUsername,
    getUserPortals,
    isCurrentUserProfile,
    isLoggedIn,
    loadPairedPlatforms,
    loadUserProfileByUsername,
    onBan,
    onSetUserRank,
    onTokenGift,
    onUnban,
    pairedPlatformsHash,
    portalCount,
    postsCount,
    referralCode,
    removeFollower,
    setIsLoading,
    tokensCount,
    refreshCurrentUser,
    updateCurrentUserSocialPresence,
    userPairedPlatforms,
    userProfile,
  };
}
