import { signer } from '../base';

export const createSignedQuery = (body: string) => {
  return signer().createSignedQuery('log_frame_interaction', [body]);
};

export const acceptFrameTransaction = (
  frameUrl: string,
  transactionId: string,
) => {
  // TODO (JM): return icClientInstance.log_accepted(request);
  return new Promise<void>((resolve) => resolve());
};

export const rejectFrameTransaction = (
  frameUrl: string,
  transactionId?: string,
) => {
  // TODO (JM): return icClientInstance.log_rejected(request);
  return new Promise<void>((resolve) => resolve());
};

export const reportFrameTransaction = (
  frameUrl: string,
  transactionId: string,
  reason: string,
  comments?: string,
) => {
  // TODO (JM): return icClientInstance.log_report(request);
  return new Promise<void>((resolve) => resolve());
};
