<script setup lang="ts">
  import { toRefs } from 'vue';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { usePortal } from '../../model/composables/use-portal';
  import { useSinglePortal } from '../../model/composables/use-single-portal';
  import { usePortalDialog } from '../../model/composables/use-portal-dialog';
  import type { PortalView } from 'dfx/edge/edge.did';

  const props = defineProps<{
    portal: PortalView;
  }>();

  defineEmits<{
    (e: 'share'): void;
  }>();

  const { portal } = toRefs(props);
  const { isJoined, isFavorite } = useSinglePortal(portal);
  const { toggleFavoritePortal } = usePortal();
  const { openLeaveDialog } = usePortalDialog();
</script>
<template>
  <base-dropdown
    :distance="5"
    placement="bottom-end"
    append-to-body
    custom-content-classes="bg-gray-900 border border-gray-785 border-opacity-50 transition-all duration-300 ease-in-out drop-shadow-heavy py-2 min-w-52"
  >
    <template #button="{ toggle }">
      <base-button variant="glass" size="x-small" @click="toggle">
        <base-icon name="vertical-dots" size="w-5 h-5" />
      </base-button>
    </template>
    <template #content="{ hide }">
      <base-button
        variant="custom"
        size="small"
        custom-classes="flex gap-2 w-full px-4 py-3 text-left text-white hover:bg-gray-800 capitalize"
        @click="hide(), $emit('share')"
      >
        <base-icon name="outlined-share" size="w-5 h-5" />
        <span>{{ $t('sharePortal') }}</span>
      </base-button>
      <base-button
        v-if="isJoined && !isFavorite"
        variant="custom"
        size="small"
        custom-classes="flex gap-2 w-full px-4 py-3 text-left text-white hover:bg-gray-800 capitalize"
        @click="hide(), toggleFavoritePortal(portal)"
      >
        <base-icon name="outline-star" size="w-5 h-5" />
        <span>{{ $t('favoritePortals.addToFavorites') }}</span>
      </base-button>

      <div
        v-if="isJoined"
        class="border-t border-white border-opacity-12 mx-5 my-2"
      />
      <base-button
        v-if="isJoined"
        variant="custom"
        size="small"
        custom-classes="flex gap-2 w-full px-4 pt-3 pb-5 text-left text-red-400 hover:bg-gray-800 capitalize"
        @click="hide(), openLeaveDialog(portal)"
      >
        {{ $t('leavePortal') }}
      </base-button>
    </template>
  </base-dropdown>
</template>
