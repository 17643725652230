<script setup lang="ts">
  import { computed, onMounted, onUnmounted, ref, toRef, watch } from 'vue';
  import type { ContentView } from 'dfx/edge/edge.did';
  import { useCanvasHost } from '../model/composables/use-canvas-host';
  import type { CanvasAppInfo } from '../types';

  const props = defineProps<{
    appInfo: CanvasAppInfo;
    content?: ContentView;
    maxHeight?: number;
  }>();

  const emit = defineEmits<{
    (e: 'loading', value: boolean): void;
    (e: 'loading-error'): void;
    (e: 'resize', width: number, height: number): void;
  }>();

  const isLoadingIframe = ref(true);
  const canvasIframeRef = ref<HTMLIFrameElement>();
  const { iframeSize, endCanvasLifecycle } = useCanvasHost(
    canvasIframeRef,
    toRef(props, 'appInfo'),
    toRef(props, 'content'),
  );

  const maxHeight = computed(() => {
    return props.maxHeight ? `${Math.ceil(props.maxHeight)}px` : undefined;
  });

  // for now, we only support full width
  const iframeHeight = computed(() => {
    return !iframeSize.value?.height
      ? '100%'
      : `${Math.ceil(iframeSize.value.height)}px`;
  });

  const onIframeLoaded = () => {
    isLoadingIframe.value = false;
    emit('loading', isLoadingIframe.value);
  };

  const onIframeError = () => {
    isLoadingIframe.value = false;
    emit('loading-error');
  };

  watch(iframeSize, (size) => {
    emit('resize', size?.width || 0, size?.height || 0);
  });

  onMounted(() => {
    emit('loading', isLoadingIframe.value);
  });

  onUnmounted(endCanvasLifecycle);
</script>
<template>
  <div
    class="size-full scrollbar-thin scrollbar-thumb-gray-785 scrollbar-track-gray-950 overflow-auto"
    :class="{ hidden: isLoadingIframe }"
    :style="{ 'max-height': maxHeight }"
  >
    <iframe
      ref="canvasIframeRef"
      class="w-full min-h-full"
      style="color-scheme: dark"
      allow="autoplay; encrypted-media"
      referrerpolicy="origin"
      sandbox="allow-pointer-lock allow-scripts allow-same-origin allow-forms"
      :src="appInfo.proxyUrl"
      :style="{ height: iframeHeight }"
      @load="onIframeLoaded"
      @error="onIframeError"
    />
  </div>
</template>
