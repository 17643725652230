<script setup lang="ts">
  import { computed } from 'vue';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import { ResponsiveOverlayCard } from '@/shared/ui/responsive-overlay-card';
  import type { TensorNft } from '@/shared/api/sol-api/tensor/collection';
  import { useShareDialog } from '@/shared/model';
  import { fetchMedia } from '@/shared/lib';
  import {
    convertLamportToSol,
    createTensorNftUrl,
    parseNftName,
  } from '@/entities/token';

  const props = defineProps<{
    item: TensorNft;
    purchased?: boolean;
  }>();

  const emit = defineEmits<{
    (e: 'buy'): void;
  }>();

  const { openShareDialog } = useShareDialog();

  const rarity = computed(() => {
    return props.item.rarityRank;
  });

  const buyOnTensor = computed(
    () =>
      !props.item?.listing?.seller ||
      !props.item?.listing.source ||
      !props.item?.mint ||
      !props.item?.listing.price,
  );

  const share = () => {
    openShareDialog(createTensorNftUrl(props.item.mint));
  };
</script>

<template>
  <responsive-overlay-card class="h-98">
    <div class="size-full">
      <img
        v-lazy="fetchMedia(item.imageUri)"
        class="size-full object-cover !rounded-none"
      />
    </div>
    <template #footer>
      <footer class="flex justify-between w-full p-6">
        <div class="flex gap-2 items-center">
          <span class="font-medium">
            {{ parseNftName(item.name).numberOrName }}
          </span>
          <span v-if="rarity" class="text-gray-400 text-sm">{{ rarity }}</span>
        </div>

        <div class="flex gap-2 items-center">
          <base-icon name="solana" size="size-5" />
          <span>
            {{ convertLamportToSol(BigInt(item.listing?.price || 0)) }}
          </span>
        </div>
      </footer>
    </template>
    <template #overlay>
      <div class="flex justify-end items-center w-full">
        <base-button
          v-if="purchased"
          size="small"
          rounded="rounded-full"
          variant="glass"
          class="text-green-500"
          disabled
        >
          {{ $t('purchased') }}
        </base-button>
        <base-button
          v-else
          size="small"
          rounded="rounded-full drop-shadow-heavy"
          target="_blank"
          :variant="item.listing?.price ? 'glass' : 'destructive'"
          :disabled="!item.listing?.price"
          :to="buyOnTensor ? createTensorNftUrl(item.mint) : undefined"
          @click="buyOnTensor ? undefined : $emit('buy')"
        >
          <base-icon
            v-if="item.listing?.price"
            name="vuesax-linear-shopping-cart"
            size="size-5"
          />
          <span>{{
            item.listing?.price
              ? buyOnTensor
                ? 'Buy on Tensor'
                : 'Buy'
              : 'Sold'
          }}</span>
        </base-button>
        <base-dropdown
          placement="left-start"
          custom-content-classes="border border-gray-785 border-opacity-50 bg-gray-725 rounded-2xl overflow-hidden drop-shadow-light"
          :distance="-5"
        >
          <template #button="{ toggle }">
            <base-button
              variant="custom"
              size="small"
              additional-classes="drop-shadow-heavy"
              @click="toggle"
            >
              <base-icon
                name="vertical-dots"
                size="size-5"
                class="text-white"
              />
            </base-button>
          </template>
          <template #content="{ hide }">
            <ul class="flex flex-col">
              <li
                class="flex cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide()"
              >
                <base-button
                  variant="custom"
                  custom-classes="px-5 py-4 w-full text-left"
                  target="_blank"
                  :to="createTensorNftUrl(item.mint)"
                >
                  <span class="whitespace-nowrap">
                    {{ $t('nftMarketplace.tensor.viewOnTensor') }}
                  </span>
                </base-button>
              </li>
              <li
                class="flex px-5 py-4 cursor-pointer hover:bg-gradient-to-r hover:from-indigo-850 hover:via-transparent hover:to-transparent transition-all"
                @click="hide(), share()"
              >
                <span class="whitespace-nowrap capitalize">
                  {{ $t('share') }}
                </span>
              </li>
            </ul>
          </template>
        </base-dropdown>
      </div>
      <base-button
        variant="glass"
        size="small"
        rounded="rounded-full"
        additional-classes="ml-3.5 drop-shadow-heavy"
        target="_blank"
        :to="createTensorNftUrl(item.mint)"
      >
        <base-icon name="tensor" size="size-5" />
        <span>tensor.trade</span>
      </base-button>
    </template>
  </responsive-overlay-card>
</template>
