<script setup lang="ts">
  import { toRefs } from 'vue';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { useSinglePortal } from '../model/composables/use-single-portal';

  const props = defineProps<{
    portal: PortalView;
    hideLabels?: boolean;
  }>();

  const { portal } = toRefs(props);
  const { abbreviatedMemberCount, abbreviatedContentCount } =
    useSinglePortal(portal);
</script>
<template>
  <div class="flex items-center gap-4 text-sm">
    <div class="flex items-center gap-1">
      <base-icon name="outlined-users2" size="w-4 h-4" class="text-white" />
      <span class="text-white">
        {{ abbreviatedMemberCount }}
      </span>
      <span v-if="!hideLabels" class="lowercase">{{ $t('members') }}</span>
    </div>
    <div class="flex items-center gap-1">
      <base-icon
        name="vuesax-linear-simcard2"
        size="w-4 h-4"
        class="text-white"
      />
      <span class="text-white">
        {{ abbreviatedContentCount }}
      </span>
      <span v-if="!hideLabels" class="lowercase">{{ $t('posts') }}</span>
    </div>
  </div>
</template>
