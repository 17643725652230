<script setup lang="ts">
  import { computed } from 'vue';
  import type {
    SolanaCollectionStats,
    MultichainCollectionDetailDto,
    MultichainGateDto,
  } from '@/shared/api';
  import { formatToUnits } from '@/shared/lib';
  import { convertLamportToSol } from '@/entities/token';
  import type { SolanaMarketplaceType } from '@/features/marketplace/types';

  const props = defineProps<{
    type: SolanaMarketplaceType;
    collection: MultichainGateDto;
    detail: MultichainCollectionDetailDto;
    stats: SolanaCollectionStats;
    noListings?: boolean;
    openExternalUrl?: string;
  }>();

  const emit = defineEmits<{
    (e: 'open'): void;
  }>();

  const nftUrlStyle = computed<string>(() => {
    const url = props.detail.image_url;
    return `background-image:url(${url})`;
  });

  const floorPrice = computed(() => {
    if (props.detail?.floor_prices.length) {
      return convertLamportToSol(props.detail.floor_prices[0].value);
    }

    return undefined;
  });

  const marketplaceIcon = computed(() => {
    return props.type === 'magic-eden' ? 'branded-magic-eden' : 'tensor';
  });

  const marketplaceText = computed(() => {
    return props.type === 'magic-eden' ? 'magiceden.io' : 'tensor.trade';
  });

  const getExternalUrl = computed(() => {
    return props.noListings ? props.openExternalUrl : undefined;
  });
</script>

<template>
  <base-button
    variant="custom"
    custom-classes="h-full rounded-2xl p-6 md:p-10 lg:p-6 shadow-light border border-gray-800 overflow-hidden group relative"
    target="_blank"
    :to="getExternalUrl"
    @click="noListings ? undefined : $emit('open')"
  >
    <div
      class="absolute inset-0 bg-gradient-to-b from-[#4138CA]/0 via-[#4138CA]/0 to-[#4138CA]/5 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-all duration-500 z-1"
    />
    <article class="flex flex-col gap-6 relative z-10">
      <section
        class="flex flex-col w-full gap-4 squared rounded-xl relative overflow-hidden content-between place-content-between"
      >
        <div
          class="absolute bg-cover bg-center inset-0"
          :style="nftUrlStyle"
        ></div>
        <div
          class="absolute bg-cover bg-center inset-0 opacity-0 group-hover:-inset-4 group-hover:opacity-100 transition-all duration-500"
          :style="nftUrlStyle"
        ></div>
        <div
          class="absolute inset-0 bg-gradient-to-t from-black/0 to-black/70"
        ></div>
        <div class="flex justify-end w-full p-4">
          <base-button
            variant="glass"
            size="small"
            rounded="rounded-2xl"
            class="drop-shadow-light"
            disabled
          >
            <base-icon name="vuesax-linear-key" size="w-5 h-5" />
            <span>{{ $t('key') }}</span>
          </base-button>
        </div>
        <div class="flex pb-4">
          <base-button
            variant="glass"
            size="small"
            rounded="rounded-full"
            class="ml-3.5"
            target="_blank"
            :to="openExternalUrl"
          >
            <base-icon :name="marketplaceIcon" size="w-5 h-5" />
            <span>{{ marketplaceText }}</span>
          </base-button>
        </div>
      </section>
      <section class="min-h-11 font-semibold text-indigo-300 text-lg text-left">
        <span>{{ collection.name }}</span>
        <base-icon
          name="filled-account-verified"
          size="w-4 h-4"
          class="inline-block ml-1 -mt-1"
        />
      </section>
      <footer class="flex gap-6">
        <div v-if="floorPrice" class="flex flex-col items-start gap-4">
          <span class="uppercase text-xs text-gray-400">{{
            $t('floorPrice')
          }}</span>
          <div class="flex gap-2 items-center">
            <base-icon name="solana" size="w-5 h-5" />
            <span>{{ floorPrice }}</span>
          </div>
        </div>
        <div class="flex flex-col items-start gap-4">
          <span class="uppercase text-xs text-gray-400">{{
            $t('listedSupply')
          }}</span>
          <span>
            {{ formatToUnits(props.stats?.listedCount || 0) }} /
            {{ formatToUnits(props.detail.total_quantity) }}
          </span>
        </div>
        <div
          v-if="props.stats?.volumeAll"
          class="flex flex-col items-start gap-4"
        >
          <span class="uppercase text-xs text-gray-400">{{
            $t('allVolume')
          }}</span>
          <span>{{
            formatToUnits(
              Number(convertLamportToSol(props.stats.volumeAll).toFixed(2)),
            )
          }}</span>
        </div>
      </footer>
    </article>
  </base-button>
</template>
