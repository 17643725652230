<template>
  <div v-if="currentPortal" class="flex flex-col items-center sm:block">
    <span class="text-lg font-bold text-white">Portal Gates</span>
    <error-display
      class="mt-3"
      :errors="errors"
      :error-message="errorMessage"
      :local-errors="localErrors"
    />

    <div class="font-caption sub-title">preferences</div>

    <div class="w-full my-4">
      <div class="relative flex justify-between items-center">
        <div class="max-w-lg">
          <div class="font-label-medium font-medium">Phone Verified</div>

          <div class="text-neutral-300 font-paragraph-small">
            Require new members who joined to have a verified phone number.
            Existing users will not be impacted
          </div>
        </div>

        <div>
          <toggle-button :checked="requiresPhone" @changed="phoneVerify" />
        </div>
      </div>
    </div>
    <portal-gates-setup />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import ErrorDisplay from './ErrorDisplay.vue';
  import { ActionTypes } from '../store/action-types';
  import ToggleButton from './buttons/ToggleButton.vue';
  import { PortalGatesSetup } from '@/features/gating';

  export default {
    name: 'portal-settings-gates',
    components: {
      ErrorDisplay,
      ToggleButton,
      PortalGatesSetup,
    },
    props: {
      portal: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    data() {
      return {
        editedNft: {
          id: null,
          name: '',
          portalSlug: '',
          roleId: '',
          canisterId: '',
          type: 'ext',
        },
        showEditModal: false,
        errorMessage: '',
        errors: [],
        isCanisterPrincipalInvalid: false,
        localErrors: [],
        requiresPhone: false,
        searchedNft: null,
        nftToDelete: null,
      };
    },
    computed: {
      ...mapGetters({
        currentPortal: 'portals/currentPortal',
        isCurrentUserAdmin: 'auth/isCurrentUserAdmin',
        currentPortalRoles: 'portals/currentPortalRoles',
        isLoading: 'isLoading',
      }),
      statusTextFromShouldSyncRegistry() {
        return (shouldSyncRegistry) => {
          return shouldSyncRegistry ? 'Active' : 'Verifying At DSCVR';
        };
      },
    },
    async mounted() {
      this.requiresPhone = this.currentPortal.requires_phone;
      if (this.currentPortal) {
        await this.$store.dispatch(
          `portals/${ActionTypes.GET_PORTAL_ROLES}`,
          this.currentPortal.id,
        );
      }
    },

    methods: {
      phoneVerify(val) {
        if (val == this.requiresPhone) return;

        this.requiresPhone = val;

        this.$store.dispatch(`portals/${ActionTypes.SET_PORTAL_PHONE_VERIFY}`, {
          id: this.currentPortal.id,
          requiresPhone: this.requiresPhone,
        });
      },
    },
  };
</script>
<style scoped>
  .sub-title {
    @apply mt-7_5 mb-4 py-1.5 font-semibold uppercase text-gray-400 border-b border-gray-700;
  }

  .mt-7_5 {
    margin-top: 30px;
  }
</style>
