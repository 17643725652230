import { getICRCTokens } from '@/shared/api/dscvr-api/sns';
import { ICRC1 } from './standards/token/icrc1';
import { fetchMedia } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

const defaultParams = {
  canGift: false,
  canTip: false,
  canTransfer: true,
  defaultAmounts: [100, 250, 500, 1000],
  ordinal: 10,
};

const CUSTOM_TIP_AMOUNTS = 'CUSTOM_TIP_AMOUNTS';

/**
 *
 */
export function getCustomTipAmounts() {
  const customTipAmounts = localStorage.getItem(CUSTOM_TIP_AMOUNTS);
  if (customTipAmounts) {
    return JSON.parse(customTipAmounts);
  }
  return [];
}

/**
 *
 * @param amounts
 */
export function setCustomTipAmounts(amounts) {
  localStorage.setItem(CUSTOM_TIP_AMOUNTS, JSON.stringify(amounts));
}

/**
 *
 * @param config
 */
async function getICRC1MetaData(config) {
  try {
    const icrc1 = new ICRC1(config.cid);
    await icrc1.create();
    const data = await icrc1.getMetadata();
    return {
      ...data,
      icpTicker: null,
      ...defaultParams,
      ...config.params,
      ordinal: 0,
    };
  } catch (err) {
    console.log(err);
  }
  return null;
}

class TokenRegistry {
  constructor() {
    this.tokenList = null;
    this.debug = import.meta.env.DEV;
  }

  async list() {
    if (!this.tokenList) {
      console.log('rebuild token registry');
      const icrc1List = await this.ICRC1List();
      this.tokenList = [...icrc1List, ...this.extList()];

      const customTipAmounts = getCustomTipAmounts();
      customTipAmounts.forEach((x) => {
        const token = this.tokenList.find((y) => y.cid === x.cid);
        if (token) {
          token.defaultAmounts = x.customAmounts;
        }
      });
    }
    return this.tokenList;
  }

  async ICRC1List() {
    try {
      const icrcTokens = await getICRCTokens();

      const tokenList = icrcTokens.data.map((token) => {
        return {
          cid: token.cid,
          params: {
            canTip: token.params.canTip,
            defaultAmounts: token.params.defaultAmounts.map((amount) =>
              Number(amount),
            ),
          },
        };
      });

      const results = await Promise.all(
        tokenList.map((x) => getICRC1MetaData(x)),
      );
      return results.filter((x) => x != null);
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  extList() {
    return [
      {
        cid: 'fjbi2-fyaaa-aaaan-qanjq-cai',
        name: 'IC Ghost Token (EXT)',
        symbol: 'GHOST (EXT)',
        standard: 'ext',
        fee: 100000000n,
        decimals: 8n,
        icpTicker: '',
        icon: fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/tokens/ghost.png`),
        ...defaultParams,
        canTip: false,
        defaultAmounts: [100, 250, 500, 1000],
      },
    ];
  }
}

export const tokenRegistry = new TokenRegistry();
