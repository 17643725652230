import type { SocialSphereTab } from '@/entities/user';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useReferralStore = defineStore('referral', () => {
  const selectedSocialSphereTab = ref<SocialSphereTab>('referrals');

  return {
    selectedSocialSphereTab,
  };
});
