import type {
  ActionResultPortal,
  PagedQuery,
  PortalHiglights,
  PortalView,
  RandomizedRecommendationQuery,
  UserPortalHighlight,
  ActionResultGetContentPaged,
} from 'dfx/edge/edge.did';
import type { ContentPreset } from '../../../model/edge';
import { actor } from '../base';

export const getPortal = (slug: string) => {
  return actor().get_portal(slug) as Promise<ActionResultPortal>;
};

export const fetchJoinedPortals = () => {
  return actor()
    .get_user_follows()
    .then((res) => res) as Promise<PortalView[]>;
};

export const fetchFavoritePortals = (contentPreset: ContentPreset) => {
  return actor()
    .list_highlighed_portals(contentPreset)
    .then((res) => res) as Promise<PortalHiglights[]>;
};

export const fetchRandomizedRecommendedPortals = (
  query: RandomizedRecommendationQuery,
) => {
  return actor()
    .get_randomized_recommended_portals(query)
    .then((res) => res) as Promise<PortalView[]>;
};

export const setFavoritePortals = (portalOrdinals: UserPortalHighlight[]) => {
  return actor().set_highlighted_portals(portalOrdinals) as Promise<
    PortalHiglights[]
  >;
};

export const getPortalContent = (slug: string, query: PagedQuery) => {
  return actor()
    .get_portal_content(slug, query)
    .then((res) => res) as Promise<ActionResultGetContentPaged>;
};
