<script setup lang="ts">
  import { EntrepotNftCollectionCard } from '@/entities/nft-gate';
  import MultichainNftCollectionCard from '@/features/marketplace/components/MultichainNftCollectionCard.vue';
  import MagicEdenNftCollectionListings from '@/features/marketplace/components/MagicEdenNftCollectionListings.vue';
  import TensorNftCollectionListings from '@/features/marketplace/components/TensorNftCollectionListings.vue';
  import { Loader } from '@/shared/ui/loader';
  import {
    useNFTMarketplace,
    type GeneralMarketplaceCollection,
  } from '@/features/marketplace/model/composables/use-nft-marketplace';
  import type { SolanaCollectionStats } from '@/shared/api/models';

  const props = defineProps<{
    portal: string;
  }>();

  const {
    selectedCollection,
    multichainCollections,
    icpCollections,
    isLoadingDetails,
    selectCollection,
    clearCollection,
    hasGatedCollections,
  } = useNFTMarketplace(props.portal);

  const getStats = (multichainCollection: GeneralMarketplaceCollection) => {
    if (multichainCollection.type === 'magic-eden') {
      return multichainCollection.statsMagicEden as SolanaCollectionStats;
    }

    // map tensor stats to magic eden structure for consistency
    return {
      symbol: multichainCollection.detail.name,
      floorPrice: parseInt(
        multichainCollection.statsTensor?.buyNowPrice as string,
      ) as number,
      listedCount: multichainCollection.statsTensor?.numListed as number,
      avgPrice24hr: multichainCollection.statsTensor?.floor24h as number,
      volumeAll: parseInt(
        multichainCollection.statsTensor?.volumeAll as string,
      ) as number,
    } as SolanaCollectionStats;
  };
  const getOpenExternalUrl = (collection: GeneralMarketplaceCollection) => {
    if (collection.type === 'magic-eden') {
      return collection.openExternalUrlMagicEden;
    }

    return collection.openExternalUrlTensor;
  };
  const getNoListingsStatus = (collection: GeneralMarketplaceCollection) => {
    if (collection.type === 'magic-eden') {
      return collection.noListingsMagicEden;
    }

    return collection.noListingsTensor;
  };
</script>

<template>
  <div v-if="hasGatedCollections" v-show="!selectedCollection">
    <div class="px-4 lg:p-0">
      <h2 class="text-xl mb-2">{{ $t('collections') }}</h2>
      <p class="text-sm mb-8 text-gray-300">
        {{ $t('marketplaceCollectionsLegend') }}
      </p>
    </div>
    <div v-if="isLoadingDetails" class="relative w-full min-h-32">
      <loader variant="rainbow" border-width="border" size="w-10 h-10" />
    </div>
    <div
      v-else
      class="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3"
    >
      <multichain-nft-collection-card
        v-for="multichainCollection in multichainCollections"
        :type="multichainCollection.type"
        :key="`${multichainCollection.detail.collection_id}-${multichainCollection.type}`"
        :collection="multichainCollection.collection"
        :detail="multichainCollection.detail"
        :stats="getStats(multichainCollection)"
        :no-listings="getNoListingsStatus(multichainCollection)"
        :open-external-url="getOpenExternalUrl(multichainCollection)"
        @open="selectCollection(multichainCollection)"
      />
      <entrepot-nft-collection-card
        v-for="{ collection, entrepotCollection } in icpCollections"
        :key="collection.id"
        :collection="collection"
        :entrepot-collection="entrepotCollection"
      />
    </div>
  </div>
  <div v-if="selectedCollection" class="flex flex-col gap-4 items-start">
    <magic-eden-nft-collection-listings
      v-if="selectedCollection.type === 'magic-eden'"
      :collection-gate="selectedCollection.collection"
      :collection-detail="selectedCollection.detail"
      :stats="getStats(selectedCollection)"
      @back="clearCollection"
    />
    <tensor-nft-collection-listings
      v-else-if="selectedCollection.type === 'tensor'"
      :collection-gate="selectedCollection.collection"
      :collection-detail="selectedCollection.detail"
      :stats="getStats(selectedCollection)"
      @back="clearCollection"
    />
  </div>
</template>
