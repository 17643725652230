<script lang="ts" setup>
  import { computed } from 'vue';
  import type { TranslatedContentView } from '@/shared/model';

  const props = defineProps<{
    content: TranslatedContentView;
  }>();

  const title = computed(() =>
    props.content.translatedTitle
      ? props.content.translatedTitle
      : props.content.title,
  );
</script>

<template>
  <div class="flex flex-wrap items-baseline mb-2">
    <h3
      class="px-4 mt-2 text-base font-bold break-words cursor-pointer sm:text-2xl lg:px-0"
      :title="title"
    >
      {{ title }}
    </h3>
  </div>
</template>
