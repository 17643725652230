<template>
  <div
    v-if="currentPortal"
    class="flex flex-col justify-center w-full gap-6 md:flex-row"
  >
    <div class="flex flex-col px-2 pt-4 md:hidden">
      <base-button
        variant="link"
        custom-classes=" flex gap-2 w-full pb-4 transition-all duration-300 cursor-pointer hover:text-white"
        :to="{
          name: PORTAL_CONTENT,
          params: { portal },
        }"
      >
        <base-icon name="arrow-left" />
        Back to {{ portal }}
      </base-button>
      <drop-down-button
        class="mt-2 mb-6"
        :items="mobileDropdownSettingsMenu"
        @set-tab="(tab) => setTab(tab)"
      />
    </div>
    <aside
      class="flex-col justify-between flex-grow-0 flex-shrink-0 order-1 hidden w-68 transform md:flex"
    >
      <div class="text-sm bg-gray-950 shadow-lg md:rounded-lg">
        <router-link
          :to="{ name: PORTAL_CONTENT, params: { portal: portal } }"
          class="inline-flex items-center px-6 pt-4 mb-5 mr-2 text-lg font-bold text-white cursor-pointer portalSettingsHeading"
        >
          <base-icon name="back" size="w-5 h-5" />
          <span class="ml-2 capitalize">{{ portal }} </span>
        </router-link>
        <router-link
          v-for="(setting, index) in filteredSettings"
          :key="setting.routeName"
          :to="{ name: setting.routeName, query: $route.query }"
          class="block py-3 cursor-pointer hover:bg-gray-700"
          :class="{
            'is-selected pl-1 text-white':
              setting.routeName === currentRouteName ||
              setting.childRouteNames.includes(currentRouteName),
            'pl-2 text-gray-300':
              setting.routeName !== currentRouteName &&
              !setting.childRouteNames.includes(currentRouteName),
            'rounded-b-xl': index === settings.length - 1,
          }"
        >
          <div class="flex items-center">
            <div class="inline-flex ml-4 leading-5">
              <base-icon
                v-if="
                  setting.routeName === currentRouteName ||
                  setting.childRouteNames.includes(currentRouteName)
                "
                :name="setting.activeIcon"
                size="w-5 h-6"
                class="mr-2"
              />
              <base-icon
                v-else
                :name="setting.icon"
                size="w-5 h-6"
                class="mr-2"
              />

              <span class="flex items-center font-medium">{{
                setting.label
              }}</span>
            </div>
          </div>
        </router-link>
      </div>
    </aside>

    <area-loader
      v-if="!currentPortal || !Array.isArray(currentPortalRoles)"
      :rounded="false"
      class="rounded-full"
    />

    <section v-if="currentPortal" class="order-2 grow max-w-[932px]">
      <div class="w-full text-md">
        <div class="w-full">
          <div class="w-full p-4 mb-8 bg-gray-950 shadow-md md:rounded-lg">
            <router-view
              :key="$route.path"
              :portal="currentPortal"
              :roles="currentPortalRoles ?? []"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { ActionTypes } from '../store/action-types';
  import { mapGetters, mapActions } from 'vuex';
  import AreaLoader from '../components/AreaLoader.vue';
  import DropDownButton from '@/components/buttons/DropDownButton.vue';
  import { reduce } from 'lodash-es';

  import {
    PermissionFlags,
    hasPermissionGroup,
    PermissionGroups,
  } from '../utils/permissions';
  import {
    HOME,
    PORTAL_CONTENT,
    PORTAL_SETTINGS_PAGE_BANS,
    PORTAL_SETTINGS_PAGE_GATES,
    PORTAL_SETTINGS_PAGE_MEMBERS,
    PORTAL_SETTINGS_PAGE_OVERVIEW,
    PORTAL_SETTINGS_PAGE_ROLES,
    PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
  } from '../common';

  export default {
    name: 'portal-settings-page',
    components: {
      AreaLoader,
      DropDownButton,
    },
    props: {
      portal: {
        type: String,
        default() {
          return '';
        },
      },
    },
    data() {
      return {
        PORTAL_CONTENT,
        lastPortalId: -1,
        settings: [
          {
            label: 'Settings',
            routeName: PORTAL_SETTINGS_PAGE_OVERVIEW,
            childRouteNames: [],
            isDefault: true,
            permission: PermissionFlags.MODERATE_CONTENT,
            icon: 'settings',
            activeIcon: 'cog',
          },
          {
            label: 'Roles',
            routeName: PORTAL_SETTINGS_PAGE_ROLES,
            childRouteNames: [PORTAL_SETTINGS_PAGE_ROLES_DETAILS],
            isDefault: false,
            permission: PermissionFlags.MANAGE_ROLES,
            icon: 'outline-crown',
            activeIcon: 'crown',
          },
          {
            label: 'Members',
            routeName: PORTAL_SETTINGS_PAGE_MEMBERS,
            childRouteNames: [],
            isDefault: false,
            permission: PermissionFlags.MANAGE_MEMBERS,
            icon: 'outline-follows',
            activeIcon: 'follows',
          },
          {
            label: 'Bans',
            routeName: PORTAL_SETTINGS_PAGE_BANS,
            childRouteNames: [],
            isDefault: false,
            permission:
              PermissionFlags.MANAGE_MEMBERS | PermissionFlags.BAN_MEMBER,
            icon: 'outline-block',
            activeIcon: 'block',
          },
          {
            label: 'Gates',
            routeName: PORTAL_SETTINGS_PAGE_GATES,
            childRouteNames: [],
            isDefault: false,
            permission: PermissionFlags.MANAGE_MEMBERS,
            icon: 'outline-lock',
            activeIcon: 'lock',
          },
          /*{
          label: "Settings",
          routeName: "portal-settings-page.details",
          childRouteNames: [],
          isDefault: false,
          permission: PermissionFlags.MANAGE_MEMBERS,
          icon: "SettingsIcon",
          activeIcon: "CogIcon",
        },*/
          // {
          //   label: "Invites",
          //   routeName: "portal-settings-page.invites",
          //   childRouteNames: [],
          //   isDefault: false,
          //   permission:
          //     PermissionFlags.MANAGE_MEMBERS,
          // },
        ],
        currentTab: PORTAL_SETTINGS_PAGE_OVERVIEW,
      };
    },
    computed: {
      ...mapGetters({
        currentPortal: 'portals/currentPortal',
        currentPortalRoles: 'portals/currentPortalRoles',
        currentUser: 'auth/getUser',
      }),
      currentRouteName() {
        return this.$route.name;
      },
      filteredSettings() {
        return this.settings.filter(
          (setting) =>
            (setting.permission & Number(this.currentPortal.perm)) > 0,
        );
      },
      mobileDropdownSettingsMenu() {
        return reduce(
          this.filteredSettings,
          (result, value) => ({
            ...result,
            [value.label.toLowerCase()]: {
              short: value.label,
              icon: value.icon,
              name: value.routeName,
            },
          }),
          {},
        );
      },
    },
    async created() {
      this.showHidePostsNavbar({ enabled: false });
      await this.$store
        .dispatch(`portals/${ActionTypes.GET_PORTAL}`, { name: this.portal })
        .then(() => {
          //console.log(this.currentPortal);
          if (this.currentPortal == null) {
            this.$router.replace({ name: HOME });
          } else if (
            hasPermissionGroup(
              this.currentPortal.perm,
              PermissionGroups.PORTAL_MANAGER,
            ) == false
          ) {
            this.$router.replace({
              name: PORTAL_CONTENT,
              params: { portal: this.portal },
            });
          } else {
            // refetching roles
            this.$store.dispatch(
              `portals/${ActionTypes.GET_PORTAL_ROLES}`,
              this.currentPortal.id,
            );
          }
        });
    },
    beforeUnmount() {
      this.showHidePostsNavbar({ enabled: true });
    },
    methods: {
      ...mapActions({
        showHidePostsNavbar: `posts/${ActionTypes.SHOW_HIDE_POSTS_NAVBAR}`,
      }),
      setTab(tab) {
        this.currentTab = tab;

        this.$router.push({ name: tab });
      },
    },
  };
</script>
