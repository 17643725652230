<script lang="ts" setup>
  import type { UserMenuProps, UserEmit } from '@/widgets/app-header';
  import { Drawer } from '@/shared/ui/drawer';
  import { UserSummary } from '@/widgets/user-summary';
  import { useUserLinks } from '@/widgets/app-header';
  import { UserMenu, OtherMenu } from '@/widgets/app-header/components';
  import { Notifications } from '@/features/notifications';
  import { SolanaBanner } from '@/widgets/solana-banner';

  const props = defineProps<UserMenuProps>();

  const emit = defineEmits<UserEmit>();

  const { isOpened } = useUserLinks(props, emit);
</script>

<template>
  <drawer
    v-model:opened="isOpened"
    direction="right"
    :custom-classes="`bg-gray-blue-900 border-l h-screen border-white border-opacity-12 ${
      isOpened ? 'w-full' : 'w-0'
    }`"
  >
    <template #title>
      <span class="font-medium text-lg">Profile</span>
    </template>
    <div class="flex flex-col h-full">
      <div class="flex justify-between p-4">
        <user-summary class="self-start" />
        <div class="flex gap-4">
          <notifications />
        </div>
      </div>
      <div class="h-px w-full bg-white bg-opacity-12" />
      <div class="flex-grow">
        <user-menu v-model:opened="isOpened" />
      </div>
      <div class="flex flex-col items-start pb-6">
        <solana-banner />
        <other-menu v-model:opened="isOpened" />
      </div>
    </div>
  </drawer>
</template>
