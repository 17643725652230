<script setup lang="ts">
  import { ref, toRefs, watch, type Ref } from 'vue';
  import { useBreakpoint, useDialog } from '@/shared/model';
  import { PORTAL_CONTENT } from '@/common';
  import { Drawer } from '@/shared/ui/drawer';
  import { BaseTooltip } from '@/shared/ui/base-tooltip';
  import type { PortalInfoView, PortalView } from 'dfx/edge/edge.did';
  import { default as TooltipContent } from './TooltipContent.vue';
  import { useStoredPortal } from '../../model/composables/use-stored-portal';

  const props = defineProps<{
    portal: PortalView | PortalInfoView;
  }>();

  defineEmits<{
    (e: 'share', portal: PortalView): void;
  }>();

  const { portal } = toRefs(props);
  const isDrawerOpen = ref(false);
  const tooltipRef: Ref<InstanceType<typeof BaseTooltip> | undefined> = ref();
  const { storedPortal } = useStoredPortal(portal);
  const { isSmallerThanSm } = useBreakpoint();
  const { dialogProps } = useDialog();

  const openMobileDrawer = () => {
    if (isSmallerThanSm.value) {
      isDrawerOpen.value = true;
    }
  };

  watch(
    () => dialogProps.value.opened,
    (open) => {
      if (open) {
        tooltipRef.value?.hide();
        isDrawerOpen.value = false;
      }
    },
  );
</script>

<template>
  <base-tooltip
    ref="tooltipRef"
    placement="bottom-start"
    theme="transparent"
    interactive
    :arrow="false"
    append-to-body
    :offset="[0, 10]"
    :max-width="420"
    :delay="200"
  >
    <template #default>
      <slot :open-mobile-drawer="openMobileDrawer">
        <base-button
          variant="custom"
          custom-classes="text-left truncate text-lg font-bold hover:underline"
          :to="
            isSmallerThanSm
              ? undefined
              : {
                  name: PORTAL_CONTENT,
                  params: { portal: props.portal.slug },
                }
          "
          @click="openMobileDrawer()"
        >
          {{ portal.name }}
        </base-button>
      </slot>
    </template>
    <template #content="{ isVisible, isShown }">
      <tooltip-content
        v-if="!isSmallerThanSm && storedPortal && (isVisible || isShown)"
        :portal="storedPortal"
        @share="$emit('share', storedPortal)"
      />
    </template>
  </base-tooltip>
  <drawer
    v-if="isSmallerThanSm"
    direction="bottom"
    custom-classes="w-full max-h-full mt-2 overflow-y-auto thin-scrollbar shadow-lg bg-gray-950 rounded-t-md focus:outline-none"
    :show-header="false"
    v-model:opened="isDrawerOpen"
  >
    <tooltip-content
      v-if="storedPortal"
      is-drawer
      :portal="storedPortal"
      @share="$emit('share', storedPortal)"
    />
  </drawer>
</template>
