<script setup lang="ts">
  import { ref } from 'vue';
  import { useIntersectionObserver, type MaybeElement } from '@vueuse/core';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { SearchForm } from '.';

  const emit = defineEmits<{
    (e: 'search-visible', value: boolean): void;
  }>();

  const searchRef = ref<MaybeElement>();
  useIntersectionObserver(
    searchRef,
    ([{ isIntersecting }]) => {
      emit('search-visible', isIntersecting);
    },
    {
      rootMargin: '-72px',
    },
  );
</script>

<template>
  <div
    class="flex items-center justify-center bg-center bg-cover h-100 pt-18"
    :style="{
      backgroundImage: `url('${fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/explore/explore-header-bg.png`,
      )}')`,
    }"
  >
    <div class="flex flex-col justify-center gap-6">
      <h1 class="text-2xl font-bold text-center text-white sm:text-xxxl">
        {{ $t('exploreAndDiscover') }}
      </h1>
      <search-form ref="searchRef" />
    </div>
  </div>
</template>
