<script setup lang="ts">
  import { computed } from 'vue';
  import type { RoleView } from 'dfx/edge/edge.did';
  import { useNFTGating } from '@/entities/nft-gate';
  import { numberToColorHex } from '@/utils/numberColors';
  import { PORTAL_SETTINGS_PAGE_ROLES_DETAILS } from '@/common';
  import { Diamond } from '@/entities/role';

  const props = defineProps<{
    role: RoleView;
  }>();

  defineEmits<{
    (e: 'delete'): void;
  }>();

  const { nftSauronCustomRole } = useNFTGating();

  const isSauronRole = computed(
    () =>
      nftSauronCustomRole.value?.id &&
      nftSauronCustomRole.value?.id === props.role.id,
  );
</script>
<template>
  <div
    class="flex py-2 border-b border-transparent border-gray-600"
    :class="{
      'opacity-50': role.is_locked,
      'pointer-events-none': role.is_locked,
    }"
  >
    <div class="flex items-center justify-start w-1/2 my-2">
      <diamond :color="role.color" size="size-3" class="mr-2" />

      <span
        :class="{
          'text-gray-400': isSauronRole,
        }"
        >{{ role.name }}</span
      >
    </div>

    <div class="flex items-center justify-start w-1/2 my-2">
      <div
        class="flex items-center justify-start"
        :class="{ 'text-gray-400': isSauronRole }"
      >
        <base-icon name="outline-follows" size="w-5 h-5" />
        <span class="ml-2">{{ role.member_count }}</span>
      </div>
      <div class="flex gap-2 ml-auto">
        <base-button
          variant="secondary"
          size="small"
          v-show="!role.is_locked"
          :disabled="isSauronRole"
          @click="$emit('delete')"
        >
          <base-icon name="trash" size="w-4 h-4" />
        </base-button>

        <base-button
          variant="secondary"
          size="small"
          class="w-24"
          :to="{
            name: PORTAL_SETTINGS_PAGE_ROLES_DETAILS,
            params: { roleId: role.id.toString() },
          }"
          :class="{ 'pointer-events-none opacity-50': isSauronRole }"
        >
          <base-icon name="pencil" size="w-4 h-4" class="mr-2" />
          <span>{{ $t('edit') }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>
