import type { ContentTreeView, ContentView } from 'dfx/edge/edge.did';

const MAX_TITLE_CHARS = 64;

const formatUrlTitle = (title: string) => {
  if (!title) {
    return '';
  }

  const result = title
    .substring(0, MAX_TITLE_CHARS)
    .toLowerCase()
    .replace(/[^\sA-Za-z0-9]/g, ' ')
    .trim()
    .replace(/ +/g, '-');

  if (!result || result.charAt(0) === '-') {
    return 'comments';
  }
  if (result[result.length - 1] === '-') {
    return result.substring(0, result.length - 1);
  }

  return result;
};

export const extractItemTitle = (item: ContentTreeView | ContentView) => {
  const imgIndex = item.body.indexOf('<img');
  const videoIndex = item.body.indexOf('<video');
  const audioIndex = item.body.indexOf('<audio');
  const iframeIndex = item.body.indexOf('<iframe');
  const linkIndex = item.body.indexOf('<link');
  const aIndex = item.body.indexOf('<a');

  // The title ends, either at the end of the body or the start of the first media element.
  const titleEnd = [
    imgIndex,
    videoIndex,
    audioIndex,
    iframeIndex,
    linkIndex,
    aIndex,
  ].reduce((acc, current) => {
    return current > -1 ? Math.min(acc, current) : acc;
  }, item.body.length);

  const span = document.createElement('span');
  // Note: This will process any HTML in the body and also load any referenced
  // assets. We mitigate this by cutting off the body at the first media element.
  span.innerHTML = item.body.slice(5, titleEnd);
  const h1 = span.querySelector('h1');
  if (h1 && h1.innerText) {
    const title = formatUrlTitle(h1.innerText);

    if (title) {
      return title;
    }
  }

  const spanTitle = span.textContent || span.innerText || '';
  if (spanTitle === '') {
    if (imgIndex > -1) {
      return 'image';
    }
    if (videoIndex > -1) {
      return 'video';
    }
    if (audioIndex > -1) {
      return 'audio';
    }
    if (iframeIndex > -1) {
      return 'embed';
    }
    if (linkIndex > -1 || aIndex > -1) {
      return 'link';
    }
    return '__';
  }
  return formatUrlTitle(spanTitle);
};

export const getUrlTitle = (item: ContentTreeView | ContentView) => {
  if (item.title) {
    return formatUrlTitle(item.title);
  }
  return extractItemTitle(item);
};
