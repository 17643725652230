import { apiInstance } from './base';

const BASE_URL = '/multichain-pfps';

export type VerifyDto = {
  network: 'solana';
  nftId: string;
  collectionAddress: string;
};

export const verify = (params: VerifyDto) => {
  return apiInstance.post(`${BASE_URL}/verify`, params);
};
