import sanitize from 'sanitize-html';

export const sanitizeSettings: sanitize.IOptions = {
  allowedTags: [
    'img',
    'a',
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'figure',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'video',
    'thead',
    'iframe',
    'tr',
    'user-tooltip',
    'preview',
    'embedded-portal',
    'embedded-post',
    'embedded-nft',
    'embedded-streak',
    'embedded-frame',
    'embedded-canvas',
    'embedded-lite-youtube',
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: [
      'href',
      'name',
      'target',
      'data-*',
      'data-embed',
      'v-on:click',
      'class',
    ],
    video: ['src', 'data-src', 'controls', 'autoplay', 'loop', 'muted'],
    span: ['data-*'],
    img: [
      'src',
      'srcset',
      'alt',
      'title',
      'width',
      'height',
      'loading',
      'v-lazy',
    ],
    iframe: ['src', 'style', 'allowfullscreen', 'scrolling', 'width', 'height'],
    div: ['style', 'class', 'id'],
    ['user-tooltip']: [
      'user',
      'portal',
      'avatar-size',
      'is-mention',
      'is-self-post',
      'show-pfp',
      'class',
    ],
    preview: ['type', 'id', 'data-embed'],
    ['embedded-streak']: ['days', 'multiplier', 'points', 'username'],
    ['embedded-frame']: ['url'],
    ['embedded-canvas']: ['url'],
    ['embedded-portal']: ['portal'],
    ['embedded-post']: ['content-id'],
    ['embedded-lite-youtube']: ['src'],
  },
  selfClosing: [
    'img',
    'br',
    'hr',
    'area',
    'base',
    'basefont',
    'input',
    'link',
    'meta',
    'user-tooltip',
    'embedded-portal',
    'embedded-post',
  ],
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
  allowedSchemesByTag: {
    img: ['data:image/', 'https', 'http'],
    span: ['data'],
    a: ['https'],
  },
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite', 'target'],
  allowedClasses: {
    code: ['language-*', 'lang-*'],
    span: ['hljs-*', 'mention', '*'],
    a: ['read-more'],
  },
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
};
