import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Preview from '@/components/tiptap/PreviewExtension';
import {
  Iframe,
  Video,
  MagicEden,
  StreakExtension,
  LiteYoutubeExtension,
} from './extensions';
import { createOpengraphExtension } from './extensions/opengraph';
import { StreakNode, FrameNode, CanvasNode, LiteYoutubeNode } from './nodes';
import {
  configureCodeBlock,
  configureDropCursor,
  configureImage,
  configureLink,
  configureMention,
} from './configurations';
import { Editor, type EditorOptions } from '@tiptap/vue-3';
import sanitize from 'sanitize-html';
import { sanitizeSettings } from '@/utils';
import { config as appConfig } from '@/shared/lib';
import { compact } from 'lodash-es';

interface EditorConfig {
  context: 'feed' | 'comment';
  initialContent?: string;
  initialPlaceholder?: string;
  onUpdate?: (body: string) => void;
  onEnter?: (event: KeyboardEvent) => void;
  editorOptions?: Partial<EditorOptions>;
}

export function createEditor(config: EditorConfig) {
  const isFeedContext = config.context === 'feed';
  const enableFrames = appConfig.ENABLE_FRAMES && isFeedContext;

  const extensions = compact([
    StarterKit.configure({
      dropcursor: false,
      codeBlock: false,
    }),
    LiteYoutubeExtension,
    LiteYoutubeNode,
    Underline,
    Preview,
    Iframe,
    Video,
    MagicEden,
    StreakExtension,
    StreakNode,
    isFeedContext && createOpengraphExtension(),
    enableFrames && FrameNode,
    isFeedContext && CanvasNode,
    Placeholder.configure({
      placeholder: config.initialPlaceholder || '',
    }),
    configureDropCursor(),
    configureImage(),
    configureLink(),
    configureCodeBlock(),
    configureMention(),
  ]);

  const editor = new Editor({
    extensions,
    content: config.initialContent || '',
    onUpdate: () => {
      const sanitizedHTML = sanitize(editor.getHTML(), sanitizeSettings);
      config.onUpdate && config.onUpdate(sanitizedHTML);
    },
    editorProps: {
      handleDOMEvents: {
        keydown: (_, event) => {
          if (event.key === 'Enter') {
            config.onEnter && config.onEnter(event);
          }
          return false;
        },
      },
    },
    ...config.editorOptions,
  });

  return editor;
}
