import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store/action-types';
import {
  useCommentStore,
  type CommentVote,
  useCreateCommentMutation,
  useUpdateCommentMutation,
  useFetchCommentsQuery,
  useReactToCommentMutation,
  useSetNsfwCommentMutation,
  useDeleteCommentMutation,
  useRemoveCommentMutation,
} from '@/entities/comment';
import type {
  ActionResultContent,
  ContentReaction,
  ContentSort,
  ContentTreeView,
  ContentView,
  CreateContent,
} from 'dfx/edge/edge.did';
import { trackEvent } from '@/utils';
import { hasPermission, PermissionFlags } from '@/utils/permissions';
import { useToast } from '@/shared/model';

export function useCommentFeed() {
  const store = useStore();
  const commentStore = useCommentStore();
  const { showToast } = useToast();
  const { t } = useI18n({ useScope: 'global' });
  const { mutateAsync: createCommentMutation, data: newCreatedComment } =
    useCreateCommentMutation();
  const { flattenComments, isUpdating } = storeToRefs(commentStore);
  const isLoadingFeed = ref(false);

  const loadFeed = async (
    postId: bigint,
    pageSize: bigint,
    sortBy: ContentSort,
  ) => {
    isLoadingFeed.value = true;
    commentStore.clearFeed();

    const response = await useFetchCommentsQuery({
      max_grand_child_depth: [],
      max_grand_children_per_level: [],
      content_id: postId,
      thread_start: BigInt(0),
      thread_size: pageSize,
      since: [],
      sort: sortBy,
    });
    commentStore.setFeed(response);
    isLoadingFeed.value = false;
    return response;
  };

  const loadFeedPage = async (
    postId: bigint,
    threadStart: bigint,
    pageSize: bigint,
    sortBy: ContentSort,
    since?: bigint,
  ) => {
    const response = await useFetchCommentsQuery({
      max_grand_child_depth: [],
      max_grand_children_per_level: [],
      content_id: postId,
      thread_start: threadStart,
      thread_size: pageSize,
      since: since ? [since] : [],
      sort: sortBy,
    });
    commentStore.appendPage(response);
    return response;
  };

  const sinceNanoseconds = (seconds: number) => {
    return Date.now() * 1000000 - seconds * 10 ** 9;
  };

  const refreshFeedRecentEntries = async (
    postId: bigint,
    pageSize: bigint,
    sortBy: ContentSort,
  ) => {
    if (isUpdating.value) {
      return;
    }
    const since = BigInt(sinceNanoseconds(60));
    const response = await useFetchCommentsQuery({
      max_grand_child_depth: [],
      max_grand_children_per_level: [],
      content_id: postId,
      thread_start: BigInt(0),
      thread_size: pageSize,
      since: since ? [since] : [],
      sort: sortBy,
    });

    if (!isUpdating.value && !isLoadingFeed.value && response?.length) {
      commentStore.appendRecentComments(response);
    }
    return response;
  };

  const createFeedComment = async (
    portalId: bigint,
    parentId: bigint,
    unstoredBody: string,
    partialComment?: Partial<CreateContent>,
    trackAction?: string,
  ) => {
    isUpdating.value = true;
    await createCommentMutation(
      {
        portalId,
        parentId,
        unstoredBody,
        partialComment,
      },
      {
        onSuccess: (response: ActionResultContent) => {
          if (response?.status === 'happy' && response.result[0]) {
            trackEvent('comment_action', trackAction);
            commentStore.appendNewComment(response.result[0]);
          }
        },
        onSettled: () => {
          isUpdating.value = false;
        },
      },
    );
    return newCreatedComment.value;
  };

  const updateFeedComment = async (id: bigint, unstoredBody: string) => {
    isUpdating.value = true;
    try {
      const response = await useUpdateCommentMutation(id, unstoredBody);
      if (response?.status === 'happy' && response.result[0]) {
        trackEvent('comment_action', 'edited');
        commentStore.updateComment(response.result[0]);
      }
      return response;
    } finally {
      isUpdating.value = false;
    }
  };

  const deleteFeedComment = async (id: bigint) => {
    isUpdating.value = true;
    try {
      const response = await useDeleteCommentMutation(id);
      if (response?.status === 'happy' && response.result[0]) {
        if (response.result[0].children_count > 0) {
          commentStore.updateComment(response.result[0]);
        } else {
          commentStore.removeComment(response.result[0].id);
        }
      }
      return response;
    } finally {
      isUpdating.value = false;
    }
  };

  const removeFeedComment = async (id: bigint) => {
    isUpdating.value = true;
    try {
      const response = await useRemoveCommentMutation(id);
      if (response?.status === 'happy' && response.result[0]) {
        if (response.result[0].children_count > 0) {
          commentStore.updateComment(response.result[0]);
        } else {
          commentStore.removeComment(response.result[0].id);
        }
      }
      return response;
    } finally {
      isUpdating.value = false;
    }
  };

  const optimisticVoteUpdate = (
    comment: ContentView | ContentTreeView,
    vote: CommentVote,
  ) => {
    if (comment.is_upvoter) {
      comment.upvotes -= 1n;
    }
    if (comment.is_downvoter) {
      comment.downvotes -= 1n;
    }

    comment.is_upvoter = vote === 'upvote';
    comment.is_downvoter = vote === 'downvote';

    if (comment.is_upvoter) {
      comment.upvotes += 1n;
    } else if (comment.is_downvoter) {
      comment.downvotes += 1n;
    }
  };

  const voteFeedComment = async (
    comment: ContentView | ContentTreeView,
    isUpvote: boolean,
    beforeVote: (vote: CommentVote) => void,
  ) => {
    isUpdating.value = true;
    try {
      if (
        isUpvote &&
        !hasPermission(comment.perm, PermissionFlags.REACT_CONTENT)
      ) {
        showToast({
          title: t('voteCommentPermissionError'),
          type: 'error',
          durationSeconds: 5,
        });
        return false;
      }
      if (
        !isUpvote &&
        !hasPermission(comment.perm, PermissionFlags.DISLIKE_CONTENT)
      ) {
        showToast({
          title: t('voteCommentPermissionError'),
          type: 'error',
          durationSeconds: 5,
        });
        return false;
      }

      let vote: CommentVote = 'reset';
      if (isUpvote) {
        vote = comment.is_upvoter ? 'reset' : 'upvote';
      } else {
        vote = comment.is_downvoter ? 'reset' : 'downvote';
      }

      if (beforeVote) {
        beforeVote(vote);
      }
      let reaction: ContentReaction | undefined = undefined;
      if (vote === 'upvote') {
        reaction = { Like: null };
        trackEvent('comment_action', 'upvote');
      } else if (vote === 'downvote') {
        reaction = { Dislike: null };
        trackEvent('comment_action', 'downvote');
      } else {
        trackEvent('comment_action', 'reset');
      }

      const voteBackup = {
        upvotes: comment.upvotes,
        downvotes: comment.downvotes,
        is_upvoter: comment.is_upvoter,
        is_downvoter: comment.is_downvoter,
      };

      optimisticVoteUpdate(comment, vote);

      const response = await useReactToCommentMutation(comment.id, reaction);
      if (response?.status === 'happy') {
        return true;
      }

      comment.upvotes = voteBackup.upvotes;
      comment.downvotes = voteBackup.downvotes;
      comment.is_upvoter = voteBackup.is_upvoter;
      comment.is_downvoter = voteBackup.is_downvoter;

      if (response?.message.startsWith('PERMISSION_ERROR_CONTENT_UPVOTE')) {
        showToast({
          title: t('voteCommentPermissionError'),
          type: 'error',
          durationSeconds: 5,
        });
        return false;
      }

      showToast({
        title: t('voteCommentError'),
        type: 'error',
        durationSeconds: 5,
      });
      return false;
    } finally {
      isUpdating.value = false;
    }
  };

  const setFeedNSFW = async (id: bigint, isNSFW: boolean) => {
    const response = await useSetNsfwCommentMutation(id, isNSFW);
    if (response?.status === 'happy' && response.result[0]) {
      commentStore.setNSFWComment(id, isNSFW);
    }
  };

  const report = (contentId: bigint) => {
    store.dispatch(ActionTypes.SHOW_REPORT_MODAL, {
      value: true,
      contentId,
    });
  };

  return {
    flattenComments,
    loadFeed,
    loadFeedPage,
    refreshFeedRecentEntries,
    createFeedComment,
    updateFeedComment,
    deleteFeedComment,
    removeFeedComment,
    voteFeedComment,
    setFeedNSFW,
    report,
    toggleChildren: commentStore.toggleChildren,
    getCommentById: commentStore.getCommentById,
  };
}
