import { client } from '../utils/dfinity';
import { callWithRetry } from '../utils/general';

export default {
  listPortals() {
    return callWithRetry(() => {
      return client.edgeActor.list_portals({
        page_size: 10_000,
        page: 0,
        sort: { NameAscending: null },
        highlighted: false,
      });
    });
  }, //portal_requires_phone
  requiresPhone(update) {
    return client.actor.portal_requires_phone(update.id, update.requiresPhone);
  },
  createPortal(portal) {
    return client.actor.create_portal(portal);
  },
  updatePortal(portal) {
    return client.actor.update_portal(portal);
  },
  updatePortalIcon(portalIcon) {
    return client.actor.update_portal_icon(portalIcon);
  },
  updatePortalInfoRules(portal_id, rules) {
    return client.actor.update_portal_info_rules(portal_id, rules);
  },
  updatePortalInfoLinks(portal_id, links) {
    return client.actor.update_portal_info_links(portal_id, links);
  },
  updatePortalInfoCoverPhoto(portal_id, icon_url) {
    return client.actor.update_portal_info_cover_photo(portal_id, icon_url);
  },
  nsfwTogglePortal(id) {
    return client.actor.portal_nsfw_toggle(id);
  },
  rootTogglePortal(id) {
    return client.actor.portal_root_toggle(id);
  },
  removePortal(id) {
    return client.actor.remove_portal(id);
  },
  getPortal(name) {
    return client.actor.get_portal(name);
  },
  getPortalChainType(portal_id) {
    return client.actor.get_portal_chain_type(portal_id);
  },
  getPortals(slugs) {
    return callWithRetry(() => {
      return client.edgeActor.get_portals(slugs);
    });
  },
  getPortalRoles(portal_id) {
    return client.actor.get_portal_roles(portal_id);
  },
  getPortalUserRoles(portal_id, user_id) {
    return client.actor.get_user_portal_roles(portal_id, user_id);
  },
  getPortalUserAssignableRoles(portal_id) {
    return client.actor.get_assignable_portal_roles(portal_id);
  },
  getPortalMembers(portal_id, page_size, page_start) {
    return client.actor.get_portal_members({
      includes_roles: false,
      page_size: page_size,
      role_ids: [],
      portal_id: portal_id,
      page_start: page_start,
    });
  },
  getPortalMembersQuery(portal_id, page_size, page_start) {
    return client.actor.get_portal_members({
      includes_roles: false,
      page_size: page_size,
      role_ids: [],
      portal_id: portal_id,
      page_start: page_start,
    });
  },
  getPortalMembersByStatus(portal_id, status) {
    return client.actor.get_portal_members_by_status(portal_id, status);
  },
  getCurrentPortalMemberMemos(portal_id, member_id) {
    return client.actor.get_portal_member_memos(portal_id, member_id);
  },
  getPortalRoleMembers(role_id) {
    return client.actor.get_role_members(role_id);
  },
  addPortalRole(portal_id, role) {
    return client.actor.add_portal_role(portal_id, role);
  },
  updatePortalRole(role) {
    return client.actor.update_portal_role(role);
  },
  deletePortalRole(role_id) {
    return client.actor.delete_portal_role(role_id);
  },
  updatePortalRolesOrdinals(portal_id, ordinals) {
    return client.actor.update_portal_role_ordinals(portal_id, ordinals);
  },
  addPortalMemberRole(portal_id, members) {
    return client.actor.add_member_role(portal_id, members);
  },
  removePortalMemberRole(portal_id, members) {
    return client.actor.remove_member_role(portal_id, members);
  },
  setPortalMemberStatus(portal_id, member_id, kind, reason) {
    return client.actor.set_portal_members_status(
      portal_id,
      member_id,
      kind,
      reason,
    );
  },
  follow_portal_toggle(portal_id) {
    return client.actor.follow_portal_toggle(portal_id);
  },
  get_randomized_recommended_portals(query) {
    return client.edgeActor.get_randomized_recommended_portals(query);
  },
  search_paginated(query) {
    return client.edgeActor.search_portals(query);
  },
  accept_portal_invite(query) {
    return client.actor.accept_portal_invite(query);
  },
};
