import { computed, type Ref } from 'vue';
import type { UserView } from 'dfx/edge/edge.did';
import { useHead, useSeoMeta } from '@unhead/vue';
import { buildMetaTitle, removeMarkdown } from '@/utils';

export const useMetaUser = (user: Ref<UserView | undefined>) => {
  const title = computed(() =>
    user.value?.username ? buildMetaTitle(user.value.username) : '',
  );
  const description = computed(() =>
    user.value?.bio ? removeMarkdown(user.value.bio).trim() : '',
  );
  const imageUrl = computed(() =>
    user.value
      ? user.value.display_nft[0]?.meta &&
        user.value.display_nft[0]?.meta.length > 0
        ? user.value.display_nft[0]?.meta
        : user.value.icon_url?.length > 0
        ? user.value.icon_url
        : ''
      : '',
  );
  useHead({
    title: () => title.value,
    meta: [
      { name: 'title', content: title },
      { name: 'description', content: description },
    ],
  });

  useSeoMeta({
    title,
    ogDescription: description,
    twitterDescription: description,
    ogType: 'website',
    ogTitle: title,
    twitterTitle: title,
    twitterSite: 'https://dscvr.one',
    twitterCard: 'summary_large_image',
    ogImage: imageUrl,
    twitterImage: imageUrl,
  });
};
