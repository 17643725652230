<script setup lang="ts">
  import { toRefs } from 'vue';
  import { PORTAL_CONTENT } from '../../common';
  import PortalTopInfo from '@/components/portals/PortalTopInfo.vue';
  import type { PortalView } from '../../../dfx/edge/edge.did';

  const props = defineProps<{
    portal: PortalView;
  }>();

  defineEmits(['portal-update']);

  const { portal } = toRefs(props);
</script>

<template>
  <router-link
    v-if="portal"
    class="no-underline not-prose"
    :to="{
      name: PORTAL_CONTENT,
      params: { portal: portal.slug },
    }"
  >
    <portal-top-info :portal="portal" />
  </router-link>
</template>
