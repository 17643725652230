import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import Prando from 'prando';
import { abbreviateNumber } from 'js-abbreviation-number';
import { ActionTypes } from '@/store/action-types';
import { isValidUrl } from '@/utils';
import {
  getPortalsOrdinals,
  sortFavoritePortals,
} from '../../lib/favorite-portals';
import { useFetchPortalQuery } from '../../api/use-fetch-portal.query';
import { useFetchFavoritePortalsQuery } from '../../api/use-fetch-favorite-portals.query';
import { useFetchJoinedPortalsQuery } from '../../api/use-fetch-joined-portals.query';
import { useSetFavoritePortalsMutation } from '../../api/use-set-favorite-portals.mutation';
import { usePortalStore } from '../store/portal-store';
import { COVER_PHOTOS, PHONE_VERIFICATION_REQUIRED_ERROR } from '@/common';
import { getInitialAvatar, fetchMedia } from '@/shared/lib';
import { PermissionFlags, hasPermission } from '@/utils/permissions';
import type {
  PortalView,
  ActionResultPortal,
  UserListItemView,
  ActionResultPortalSearch,
  PortalSearchQuery,
  RoleView,
  UserSelfView,
  PortalInfoView,
} from 'dfx/edge/edge.did';
import type { JoinState } from '../../types';
import type { ContentPreset } from '@/shared/model';
import type { RequestInitCfPropertiesImage } from '@cloudflare/workers-types';

export const usePortal = () => {
  const store = useStore();
  const portalStore = usePortalStore();

  const joinState = ref<JoinState>('join');

  const allPortalsPaginated = computed<PortalView[]>(
    () => store.state.portals.allPortalsPaginated.items,
  );
  const allPortalsPaginatedCurrentPage = computed<bigint>(
    () => store.state.portals.allPortalsPaginated.currentPage,
  );
  const currentPortal = computed<PortalView>(
    () => store.getters['portals/currentPortal'],
  );
  const currentPortalUserAssignableRoles = computed(
    () => store.getters['portals/currentPortalUserAssignableRoles'],
  );
  const isCurrentUserPortalManager = computed(
    () => store.getters['portals/isCurrentUserPortalManager'],
  );
  const portalChainType = computed(() => store.state.portals.portalChainType);

  const currentPortalRoles = computed<RoleView[]>(() => {
    return store.getters['portals/currentPortalRoles'];
  });

  const allPortalsHash = computed(() => portalStore.allPortalsHash);

  const joinedPortals = computed(
    () => portalStore.joinedPortals as PortalView[],
  );

  const favoritePortals = computed(
    () => portalStore.favoritePortals as PortalView[],
  );

  const nonJoinedHighlightedPortals = computed(
    () =>
      portalStore.highlightedPortals.filter(
        (p) => !p.is_following,
      ) as PortalView[],
  );

  const highlightedPortals = computed(() =>
    favoritePortals.value.length > 0
      ? favoritePortals.value
      : (portalStore.highlightedPortals as PortalView[]),
  );

  const joinedNonSelfPortals = computed(() => {
    return joinedPortals.value.filter((portal) => {
      const portalType = portal?.portal_type[0];
      if (!portalType) {
        return false;
      }
      return 'Portal' in portalType;
    });
  });

  const canViewContent = computed(() =>
    currentPortal.value
      ? hasPermission(currentPortal.value.perm, PermissionFlags.VIEW_CONTENT)
      : false,
  );

  const isMemberPortalOwner = (
    portal: PortalView,
    member?: UserListItemView | UserSelfView,
  ) => {
    return portal.owner && member
      ? portal.owner.username === member.username
      : false;
  };

  const getCoverImage = (portal: PortalView) => {
    if (!portal) return;
    const randomGeneratedNumber = new Prando(portal.name);
    const portalCoverImage = portal.info[0]?.cover_photo[0];
    return portalCoverImage
      ? fetchMedia(portalCoverImage)
      : fetchMedia(
          COVER_PHOTOS[
            randomGeneratedNumber.nextInt(0, COVER_PHOTOS.length - 1)
          ],
        );
  };
  const coverImage = computed(() => {
    return getCoverImage(currentPortal.value);
  });
  const membersCount = computed(() =>
    Number(currentPortal.value.member_count / 1n).toLocaleString('en-US'),
  );
  const creationDate = computed(() => {
    return new Date(
      Number(currentPortal.value.created_at / 1000000n),
    ).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  });

  const socialLinks = computed(() => {
    if (
      !currentPortal.value ||
      !currentPortal.value.info ||
      !currentPortal.value.info.length ||
      !currentPortal.value.info[0].links
    ) {
      return [];
    }
    return currentPortal.value.info[0].links.map(({ href }) => href);
  });

  const contentCount = computed(() => {
    return Number(currentPortal.value.content_count).toLocaleString('en-US');
  });

  const getPortalIcon = (
    portal: PortalView | PortalInfoView,
    params?: RequestInitCfPropertiesImage,
  ) => {
    if (!portal) return;
    return typeof portal === 'object' && isValidUrl(portal.icon_url)
      ? fetchMedia(portal.icon_url, {
          fit: 'scale-down',
          width: 148,
          height: 148,
          ...params,
        })
      : getInitialAvatar({
          name: portal.name,
          size: 100,
          initial_bg: '#2564eb',
          initial_fg: '#fff',
          initial_size: 0,
          initial_weight: 400,
          initial_font_family: 'Inter',
        });
  };

  const getPortalDataBySlugs = (
    portalSlugs: string[],
  ): Promise<PortalView[]> => {
    const portalPromises = portalSlugs.map((slug) => {
      return loadPortalBySlug(slug);
    });

    const portalResults = Promise.all(portalPromises).then((results) => {
      return results.filter(
        (result): result is PortalView => result !== undefined,
      );
    });

    return portalResults;
  };

  const loadPortalProfile = (slug: string, hideLoading = false) => {
    return store.dispatch(`portals/${ActionTypes.GET_PORTAL}`, {
      name: slug,
      no_load: hideLoading,
    });
  };

  const getAbbreviatedMemberCount = (portal: PortalView) => {
    return abbreviateNumber(Number(portal?.member_count), 1);
  };

  const getAbbreviatedContentCount = (portal: PortalView) => {
    return abbreviateNumber(Number(portal?.content_count), 1);
  };

  const getPortalChainType = (portal: PortalView | undefined) => {
    if (!portal) return;
    return store.dispatch(
      `portals/${ActionTypes.GET_PORTAL_CHAIN_TYPE}`,
      portal.id,
    );
  };

  const followPortalWithLoader = () => {
    return store.dispatch(
      `portals/${ActionTypes.FOLLOW_PORTAL_TOGGLE_WITH_LOADER}`,
      currentPortal.value.id,
    );
  };

  const togglePortalFollow = (portalId: bigint): Promise<boolean> => {
    return store
      .dispatch(`portals/${ActionTypes.FOLLOW_PORTAL_TOGGLE}`, {
        portal_id: portalId,
      })
      .then((results: ActionResultPortal) => {
        if (results.status === 'sad') {
          if (results.message === PHONE_VERIFICATION_REQUIRED_ERROR) {
            throw new Error(PHONE_VERIFICATION_REQUIRED_ERROR);
          }
        } else if (results.result[0]) {
          portalStore.updatePortal(results.result[0]);
          return results.result[0]?.is_following;
        }
        return false;
      });
  };

  const onMouseLeaveJoin = () => {
    if (joinState.value !== 'join') return;
    joinState.value = 'join';
  };

  const onMouseOverJoin = () => {
    if (joinState.value !== 'leave') return;
    joinState.value = 'leave';
  };

  const fetchAllPortalsPaginated = (
    request: Omit<PortalSearchQuery, 'query'>,
    pagesCount?: bigint,
  ): Promise<ActionResultPortalSearch> => {
    return store.dispatch(`portals/${ActionTypes.GET_ALL_PAGINATED}`, {
      request: { ...request, query: '', pagesCount },
      pagesCount,
    });
  };

  const fetchCurrentPortalRoles = () => {
    return store.dispatch(
      `portals/${ActionTypes.GET_PORTAL_ROLES}`,
      currentPortal.value.id,
    );
  };

  const fetchCurrentPortalUserRoles = (hideLoading = true) => {
    return store.dispatch(`portals/${ActionTypes.GET_PORTAL_USER_ROLES}`, {
      portal_id: currentPortal.value.id,
      user_id: currentPortal.value.owner_id,
      no_load: hideLoading,
      for_owner: true,
    });
  };

  const clearCurrentPortal = () => {
    store.dispatch(`portals/${ActionTypes.SET_CURRENT_PORTAL}`, null);
  };

  const updateCurrentPortal = () => {
    store.dispatch(`portals/${ActionTypes.UPDATE_PORTAL}`, currentPortal.value);
  };

  const loadJoinedAndFavoritePortals = async (contentPreset: ContentPreset) => {
    const response = await useFetchJoinedPortalsQuery();
    portalStore.setJoinedPortals(response);
    await loadFavoritePortals(contentPreset);
    return joinedPortals.value;
  };

  const loadFavoritePortals = async (contentPreset: ContentPreset) => {
    const response = await useFetchFavoritePortalsQuery(contentPreset);
    const favorites = sortFavoritePortals(response);
    portalStore.setSortedFavoritePortals(favorites);
    return favoritePortals.value;
  };

  const setSortedFavoritePortals = async (portals: PortalView[]) => {
    portalStore.setSortedFavoritePortals(portals); // optimistic update
    const ordinals = getPortalsOrdinals(portals);
    const response = await useSetFavoritePortalsMutation(ordinals);
    const favorites = sortFavoritePortals(response);
    portalStore.setSortedFavoritePortals(favorites);
    return favoritePortals.value;
  };

  const toggleFavoritePortal = async (portal: PortalView) => {
    const foundPortal = favoritePortals.value.find(
      (p) => p.slug === portal.slug,
    );
    if (!foundPortal) {
      setSortedFavoritePortals([...favoritePortals.value, portal]);
    } else {
      setSortedFavoritePortals(
        favoritePortals.value.filter((p) => p.slug !== portal.slug),
      );
    }
  };

  const isFavoritePortal = (portal: PortalView) => {
    return favoritePortals.value.some((p) => p.slug === portal.slug);
  };

  const loadPortalBySlug = async (slug: string) => {
    const response = await useFetchPortalQuery(slug);
    if (response) {
      return portalStore.updatePortal(response);
    }
    return undefined;
  };

  return {
    allPortalsHash,
    canViewContent,
    contentCount,
    coverImage,
    creationDate,
    currentPortal,
    currentPortalUserAssignableRoles,
    currentPortalRoles,
    loadPortalBySlug,
    getAbbreviatedContentCount,
    getAbbreviatedMemberCount,
    getPortalDataBySlugs,
    getCoverImage,
    getPortalIcon,
    isMemberPortalOwner,
    joinState,
    membersCount,
    onMouseLeaveJoin,
    onMouseOverJoin,
    followPortalWithLoader,
    togglePortalFollow,
    allPortalsPaginated,
    allPortalsPaginatedCurrentPage,
    socialLinks,
    fetchAllPortalsPaginated,
    getPortalChainType,
    portalChainType,
    loadPortalProfile,
    fetchCurrentPortalRoles,
    fetchCurrentPortalUserRoles,
    clearCurrentPortal,
    isCurrentUserPortalManager,
    joinedPortals,
    joinedNonSelfPortals,
    favoritePortals,
    nonJoinedHighlightedPortals,
    highlightedPortals,
    loadJoinedAndFavoritePortals,
    loadFavoritePortals,
    setSortedFavoritePortals,
    toggleFavoritePortal,
    isFavoritePortal,
    updateCurrentPortal,
  };
};
