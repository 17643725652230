import { defineAsyncComponent } from 'vue';
import type { WalletName } from '@solana/wallet-adapter-base';
import { useDialog } from '@/shared/model';
import { fetchMedia } from '@/shared/lib';
import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
import InstallMetamaskContent from '../../components/dialogs/InstallMetamaskContent.vue';
import SendingToWalletContent from '../../components/dialogs/SendingToWalletContent.vue';
import SuccessfullyPairedContent from '../../components/dialogs/SuccessfullyPairedContent.vue';
import UnsuccessfulPairingContent from '../../components/dialogs/UnsuccessfulPairingContent.vue';
import ConnectWalletContent from '../../components/dialogs/ConnectWalletContent.vue';

export const useWalletDialog = () => {
  const { dialogDefaults, openConfiguredDialog, closeDialog } = useDialog();

  const openInstallMetamaskDialog = () => {
    openConfiguredDialog({
      content: {
        component: InstallMetamaskContent,
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-sm`,
      },
      drawer: {
        customClasses: dialogDefaults.drawer.customClasses,
      },
    });
  };

  const openSendingToWalletDialog = (
    principal: string,
    walletName: WalletName,
  ) => {
    return new Promise<Window | undefined>((resolve) => {
      openConfiguredDialog({
        content: {
          component: SendingToWalletContent,
          props: {
            principal,
            walletName,
          },
          emits: {
            pairing: (deepLinkWindow: Window) => {
              resolve(deepLinkWindow);
              closeDialog();
            },
          },
        },
        header: {
          component: defineAsyncComponent(
            () => import('@/shared/ui/base-dialog/ImageHeader.vue'),
          ),
          slots: {
            media: {
              template: `<div class="flex items-center justify-center bg-gradient-to-br from-[#321575] to-[#8D0B93]"><img src="${fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/sending-to-wallet.gif`,
              )}" alt="Sending to your wallet" /></div>`,
            },
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-md overflow-hidden`,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} overflow-hidden`,
        },
        emit: () => {
          resolve(undefined);
        },
      });
    });
  };

  const openSuccessfullyPairedDialog = (username: string) => {
    openConfiguredDialog({
      content: {
        component: SuccessfullyPairedContent,
        props: {
          username,
        },
      },
      header: {
        component: defineAsyncComponent(
          () => import('@/shared/ui/base-dialog/ImageHeader.vue'),
        ),
        slots: {
          media: {
            template: `<div class="flex items-center justify-center bg-gradient-to-br from-[#AC32E4] to-[#4801FF]"><img src="${fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/orbit-planet.gif`,
            )}" class="w-[291px] h-auto" alt="Successfully paired" /></div>`,
          },
        },
        emits: {
          close: closeDialog,
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-md overflow-hidden`,
      },
      drawer: {
        customClasses: `${dialogDefaults.drawer.customClasses} overflow-hidden`,
      },
    });
  };

  const openUnsuccessfulPairingDialog = (username: string) => {
    return new Promise<boolean>((resolve) => {
      openConfiguredDialog({
        content: {
          component: UnsuccessfulPairingContent,
          props: {
            username,
          },
          emits: {
            pair: () => resolve(true),
          },
        },
        header: {
          component: defineAsyncComponent(
            () => import('@/shared/ui/base-dialog/ImageHeader.vue'),
          ),
          slots: {
            media: {
              template: `<img src="${fetchMedia(
                `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/space-travel.gif`,
              )}" alt="Pairing unsuccessful" class="size-64 mx-auto" />`,
            },
          },
          emits: {
            close: closeDialog,
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[500px] overflow-hidden`,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} overflow-hidden`,
        },
        emit: () => {
          resolve(false);
        },
      });
    });
  };

  const openConnectWalletDialog = () => {
    return new Promise<WalletName | undefined>((resolve) => {
      openConfiguredDialog({
        content: {
          component: ConnectWalletContent,
          emits: {
            connected: (id: WalletName) => {
              resolve(id);
              closeDialog();
            },
            close: () => {
              closeDialog();
            },
          },
        },
        dialog: {
          contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-lg select-none`,
          closeOnClickOutside: false,
        },
        drawer: {
          customClasses: `${dialogDefaults.drawer.customClasses} px-6 pt-8 pb-6  select-none`,
          closeOnClickOutside: false,
        },
        emit: () => {
          resolve(undefined);
        },
      });
    });
  };

  return {
    closeDialog,
    openInstallMetamaskDialog,
    openSendingToWalletDialog,
    openSuccessfullyPairedDialog,
    openUnsuccessfulPairingDialog,
    openConnectWalletDialog,
  };
};
