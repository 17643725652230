<script setup lang="ts">
  import { onMounted, watch } from 'vue';
  import { PORTAL_CONTENT, EXPLORE_PORTALS } from '@/common';
  import { useChainBehavior } from '@/entities/user-setting';
  import { usePortal, usePortalRecommendation } from '@/entities/portal';
  import type { PortalView } from 'dfx/edge/edge.did';

  const props = withDefaults(
    defineProps<{
      count?: number;
    }>(),
    {
      count: 3,
    },
  );

  const { contentPreset } = useChainBehavior();
  const {
    getPortalIcon,
    toggleFavoritePortal,
    isFavoritePortal,
    togglePortalFollow,
  } = usePortal();
  const {
    portalFavoriteRecommendations,
    fetchPortalFavoriteRecommendations,
    replacePortalFavoriteRecommendations,
  } = usePortalRecommendation(contentPreset);

  const addPortalToFavorites = async (portal: PortalView) => {
    await toggleFavoritePortal(portal);
    await replacePortalFavoriteRecommendations(portal.slug);
  };

  watch(
    () => contentPreset.value,
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        fetchPortalFavoriteRecommendations(props.count);
      }
    },
  );

  onMounted(() => {
    if (portalFavoriteRecommendations.value.length === 0) {
      fetchPortalFavoriteRecommendations(props.count);
    }
  });
</script>

<template>
  <h3 class="mt-8 mb-3 font-semibold text-gray-300">
    {{ $t('youMightLike') }}
  </h3>
  <ul class="flex flex-col gap-1 pl-2 pr-2.5">
    <template v-if="!portalFavoriteRecommendations.length">
      <li v-for="i in count" :key="i">
        <div
          class="h-12 p-2 bg-gray-600 bg-opacity-40 animate-pulse rounded-xl"
        ></div>
      </li>
    </template>
    <template v-else>
      <li v-for="portal in portalFavoriteRecommendations" :key="portal.slug">
        <base-button
          variant="link"
          :to="{
            name: PORTAL_CONTENT,
            params: { portal: portal.slug },
          }"
          custom-classes="flex gap-5 justify-between items-center p-2 rounded-xl w-full"
          class="overflow-hidden font-medium text-gray-300 hover:text-white hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785"
        >
          <div class="flex items-center min-w-0 gap-2">
            <img class="w-8 h-8 rounded-lg" :src="getPortalIcon(portal)" />
            <span
              class="text-sm font-medium leading-5 break-words truncate"
              :title="portal.name"
            >
              {{ portal.name }}
            </span>
            <base-icon
              v-if="portal.is_nsfw"
              name="eighteen-plus"
              size="w-4 h-4 min-w-4"
            />
          </div>

          <base-button
            v-if="portal.is_following"
            variant="tertiary"
            size="x-small"
            :title="$t('favoritePortals.favorite')"
            @click.prevent="addPortalToFavorites(portal)"
          >
            <base-icon
              :name="isFavoritePortal(portal) ? 'star' : 'outline-star'"
              size="w-4 h-4"
            />
          </base-button>

          <base-button
            v-else
            variant="tertiary"
            size="x-small"
            :title="$t('join')"
            @click.prevent="togglePortalFollow(portal.id)"
          >
            <base-icon name="plus-circle" size="w-4 h-4" />
          </base-button>
        </base-button>
      </li>
    </template>
  </ul>

  <section class="flex flex-col gap-2.5 mt-4 text-center">
    <h3 class="text-gray-400">{{ $t('favoritePortals.favoritesLegend') }}</h3>
    <base-button
      variant="link"
      class="font-medium text-indigo-300"
      :to="{
        name: EXPLORE_PORTALS,
      }"
    >
      {{ $t('exploreMore') }}
    </base-button>
  </section>
</template>
