import { computed } from 'vue';
import { useUser } from '@/entities/user';
import { usePortal } from '../composables/use-portal';
import { isPortalManager } from '../../lib/permissions';
import { hasPermission, PermissionFlags } from '@/utils/permissions';
import type { Ref } from 'vue';
import type { PortalView } from 'dfx/edge/edge.did';

export const useSinglePortal = (portal: Ref<PortalView | undefined>) => {
  const { currentUser, isLoggedIn } = useUser();
  const {
    isMemberPortalOwner,
    isFavoritePortal,
    getCoverImage,
    getAbbreviatedContentCount,
    getAbbreviatedMemberCount,
  } = usePortal();

  const canInvite = computed(() => {
    if (!portal.value) return false;
    return (
      isLoggedIn.value &&
      hasPermission(portal.value.perm, PermissionFlags.INVITE_MEMBER)
    );
  });

  const isCurrentUserOwner = computed(() =>
    currentUser.value && portal.value?.owner?.username
      ? isMemberPortalOwner(portal.value, currentUser.value)
      : false,
  );

  const isCurrentUserManager = computed(() => {
    if (!portal.value) return false;
    return isPortalManager(portal.value);
  });

  const isCurrentUserOwnerOrManager = computed(
    () => isCurrentUserManager.value || isCurrentUserOwner.value,
  );

  const isJoined = computed(() => portal.value?.is_following);

  const isFavorite = computed(() =>
    portal.value ? isFavoritePortal(portal.value) : false,
  );

  const hasCoverPhoto = computed<boolean>(() => {
    if (!portal.value) return false;
    const portalCoverImage = portal.value.info[0]?.cover_photo[0];
    return !!portalCoverImage;
  });

  const coverPhoto = computed(() =>
    portal.value ? getCoverImage(portal.value) : undefined,
  );

  const abbreviatedMemberCount = computed(() => {
    if (!portal.value) return '';
    return getAbbreviatedMemberCount(portal.value);
  });

  const abbreviatedContentCount = computed(() => {
    if (!portal.value) return '';
    return getAbbreviatedContentCount(portal.value);
  });

  return {
    canInvite,
    isCurrentUserOwner,
    isCurrentUserManager,
    isCurrentUserOwnerOrManager,
    isJoined,
    isFavorite,
    hasCoverPhoto,
    coverPhoto,
    abbreviatedMemberCount,
    abbreviatedContentCount,
  };
};
