<script setup lang="ts" generic="T extends CollectionItem">
  import { computed } from 'vue';
  import {
    type CollectionItem,
    type CollectionType,
    getCollectionItemKey,
  } from '@/shared/lib';
  import { Loader } from '@/shared/ui/loader';

  const props = withDefaults(
    defineProps<{
      items: T[];
      type: CollectionType;
      loading: boolean;
    }>(),
    {
      type: 'grid',
    },
  );

  const classes = computed(() => {
    if (props.type === 'grid') {
      return 'grid grid-cols-1 sm:grid-cols-2 gap-4 py-4 mb-4';
    }
    return 'flex flex-col gap-4 sm:gap-20 md:gap-11';
  });
</script>

<template>
  <ul :class="classes">
    <li v-for="item in items" :key="getCollectionItemKey(item)">
      <slot name="item" :item="item" />
    </li>
  </ul>
  <div v-if="loading" class="relative w-full min-h-48">
    <loader variant="rainbow" border-width="border" size="w-10 h-10" />
  </div>
</template>
