import { computed, reactive } from 'vue';
import { type LiveRoom, dscvrApi, dscvrIcApi } from '@/shared/api';
import type { GetRoomsQuery } from '@/shared/api/dscvr-api/rooms';
import { useUser } from '@/entities/user';
import type { PortalView } from 'dfx/edge/edge.did';

interface LiveRoomState {
  isLoading: boolean;
  selectedRoom: LiveRoom | null;
  liveRooms: LiveRoom[];
}

// Singleton State Shared across all uses of useLiveRooms()
// TODO: move to pinia once we decide to migrate
const state = reactive<LiveRoomState>({
  isLoading: false,
  selectedRoom: null,
  liveRooms: [],
});

export const use = () => {
  const { currentUser } = useUser();
  // Private Methods
  /**
   *
   * @param isLoading
   */
  function _setIsLoading(isLoading: boolean) {
    state.isLoading = isLoading;
  }

  /**
   *
   * @param room
   */
  function setSelectedRoom(room: LiveRoom | null) {
    state.selectedRoom = room;
    if (!room) {
      return;
    }
    const exists = state.liveRooms.findIndex((r) => r.roomId === room.roomId);
    if (exists >= 0) {
      state.liveRooms[exists] = room;
    } else {
      state.liveRooms.push(room);
    }
  }

  // Public Methods
  /**
   *
   * @param roomId
   */
  async function loadAndSelectRoom(roomId: string) {
    _setIsLoading(true);
    try {
      await dscvrApi.verify.verifyUser();
      const room = await dscvrApi.rooms.getRoom(roomId);
      if (room.data.data?.roomId) {
        const resolvedRoom = {
          ...room.data.data,
          portal: await resolvePortal(room.data.data.portal.slug),
        };
        setSelectedRoom(resolvedRoom);
      }
    } catch (error) {
      console.log(error);
    } finally {
      _setIsLoading(false);
    }
  }

  /**
   *
   * @param portalSlug
   */
  async function resolvePortal(portalSlug: string) {
    const result = await dscvrIcApi.portal.getPortal(portalSlug);
    return result.result[0] as PortalView;
  }

  /**
   *
   * @param portalSlug
   * @param loadPortals
   */
  async function fetchPortalRooms(portalSlug = '', loadPortals = false) {
    try {
      if (currentUser.value) {
        await dscvrApi.verify.verifyUser();
        const query: GetRoomsQuery = { enabled: true };
        if (portalSlug !== '') {
          query['portalSlug'] = portalSlug;
        }
        _setIsLoading(true);
        const response = await dscvrApi.rooms.getRooms(query);
        if (!response.data.data) {
          return;
        }
        state.liveRooms = loadPortals
          ? await Promise.all(
              response.data.data.map(async (room: LiveRoom) => {
                if (!room.portal) {
                  return room;
                }
                return {
                  ...room,
                  portal: await resolvePortal(room.portal.slug),
                };
              }),
            )
          : response.data.data;
      }
    } catch (error) {
      console.log(error);
      // display error
    } finally {
      _setIsLoading(false);
    }
  }

  /**
   *
   * @param roomId
   */
  async function decreaseLocalRoomUserCount(roomId: string) {
    const roomIndex = state.liveRooms.findIndex(
      (room) => room.roomId === roomId,
    );
    if (roomIndex < 0) return;
    if (state.liveRooms[roomIndex].userCount !== 0)
      state.liveRooms[roomIndex].userCount -= 1;
  }

  /**
   *
   * @param roomId
   */
  async function increaseLocalRoomUserCount(roomId: string) {
    const roomIndex = state.liveRooms.findIndex(
      (room) => room.roomId === roomId,
    );
    if (roomIndex < 0) return;
    state.liveRooms[roomIndex].userCount += 1;
  }

  /**
   *
   * @param roomId
   */
  function removeLiveRoom(roomId: string) {
    state.liveRooms = state.liveRooms.filter((room) => room.roomId !== roomId);
  }

  // Computed Properties
  const isLoading = computed(() => state.isLoading);
  const selectedRoom = computed(() => state.selectedRoom);
  const liveRooms = computed(() => {
    return state.liveRooms;
  });

  const liveRoomsCount = computed(() => {
    return state.liveRooms.length;
  });

  return {
    // data
    isLoading,
    selectedRoom,
    liveRooms,
    liveRoomsCount,

    // methods
    setSelectedRoom,
    removeLiveRoom,
    loadAndSelectRoom,
    fetchPortalRooms,
    decreaseLocalRoomUserCount,
    increaseLocalRoomUserCount,
  };
};
