<template>
  <div
    class="relative p-4 mb-4 border border-gray-600 rounded-lg sm:mr-4 poll-item"
  >
    <overlay-loader v-if="isVoting" />

    <span class="text-sm text-gray-400">
      <span class="font-bold">votes:</span>
      {{ poll.total_votes }}
    </span>

    <span
      v-if="!isTraditional && !isText && !has_voted && canVote"
      class="block mt-2 mb-1 text-sm text-gray-100"
    >
      <span class="font-bold">Your Votes:</span>
      {{ current_voting_tokens }} / {{ total_voting_tokens }}
    </span>

    <div v-if="has_voted || is_expired">
      <div v-if="isPaged" class="w-60">
        <default-input v-model="search" placeholder="Search" />
      </div>
      <div v-for="choice in choicePages" :key="choice.id" class="mt-4">
        <div
          :class="{ 'opacity-70': !is_winner(choice.id) }"
          class="flex mb-4 overflow-hidden bg-transparent rounded-lg h-7"
        >
          <div
            :style="{
              width: get_vote_percent(choice.total_votes) + '%',
              maxWidth: get_vote_percent(choice.total_votes) + '%',
            }"
            class="justify-center text-white bg-indigo-600 rounded-lg shadow-none whitespace-nowrap"
          >
            <span class="ml-4">
              <span class="font-bold">{{ choice.total_votes }}</span>

              <span class="ml-2">{{ get_choice_text(choice.text) }}</span>

              <span v-if="is_vote_choice(choice.id)" class="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="inline w-5 h-5 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div v-if="isPaged" class="flex justify-center mt-4 space-x-4">
        <div class="w-28">
          <default-button
            variant="secondary"
            text="Prev Page"
            class="mr-2"
            dimensions="xs"
            @click="prevPage()"
          />
        </div>
        <div class="grid items-center w-14">
          <span class="ml-1">{{ current_page }} / {{ totalPages }}</span>
        </div>
        <div class="w-28">
          <default-button
            variant="secondary"
            text="Next Page"
            class="mr-2"
            dimensions="xs"
            @click="nextPage()"
          />
        </div>
      </div>
    </div>

    <div v-if="!has_voted && !is_expired">
      <div v-if="isPaged" class="w-60">
        <default-input v-model="search" placeholder="Search" />
      </div>
      <div v-for="choice in choicePages" :key="choice.id" class="mt-4">
        <label class="break-words">
          <number-increment
            v-if="!isTraditional && !isText && canVote"
            :value="quadratic_token_map[choice.id]"
            class="mr-2"
            @inc="inc_vote(choice.id)"
            @dec="dec_vote(choice.id)"
          />

          <input
            v-if="(isTraditional || isText) && canVote"
            v-model="choice_selected"
            type="radio"
            :name="choice.id"
            :value="choice.id"
          />
          {{ get_choice_text(choice.text) }}
        </label>
      </div>
      <div v-if="isPaged" class="flex justify-center mt-4 space-x-4">
        <div class="w-28">
          <default-button
            variant="secondary"
            text="Prev Page"
            class="mr-2"
            dimensions="xs"
            @click="prevPage()"
          />
        </div>
        <div class="grid items-center w-14">
          <span class="ml-1">{{ current_page }} / {{ totalPages }}</span>
        </div>
        <div class="w-28">
          <default-button
            variant="secondary"
            text="Next Page"
            class="mr-2"
            dimensions="xs"
            @click="nextPage()"
          />
        </div>
      </div>
      <div class="flex justify-center mt-8">
        <div v-if="canVote" class="w-60">
          <default-button
            :disabled="choice_selected == null"
            text="vote"
            class="mr-2"
            @click="vote()"
          />
          <div class="text-sm text-center text-gray-400">
            ends {{ fromNow(poll.expires_at) }}
          </div>
        </div>
        <div v-if="!canVote" class="grid text-center justify-items-center">
          <div>You must be a member of this Portal to vote</div>
          <div
            v-if="currentUser && portalData && !portalData.is_following"
            class="w-40"
          >
            <join-portal-button :portal="portalData" />
          </div>
          <div class="text-sm text-center text-gray-400">
            ends {{ fromNow(poll.expires_at) }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <div v-if="is_expired && has_voted" class="text-sm text-gray-400">
        ended {{ fromNow(poll.expires_at) }}
      </div>

      <div v-if="!is_expired && has_voted" class="text-sm text-gray-400">
        ends {{ fromNow(poll.expires_at) }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { PermissionFlags, hasPermission } from '@/utils/permissions';
  import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
  import NumberIncrement from '@/components/NumberIncrement.vue';
  import DefaultButton from '@/components/buttons/DefaultButton.vue';
  import DefaultInput from '@/components/inputs/DefaultInput.vue';
  import {
    useContentPollVoteMutation,
    useContentPollQuadraticVoteMutation,
  } from '@/entities/post';
  import { usePortal, JoinPortalButton } from '@/entities/portal';
  import { useQueryClient } from '@tanstack/vue-query';
  import { useFeed } from '@/shared/model';
  import { useAuth } from '@/entities/auth';

  export default {
    name: 'poll',
    components: {
      OverlayLoader,
      NumberIncrement,
      DefaultButton,
      DefaultInput,
      JoinPortalButton,
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
      poll: {
        type: Object,
        default: () => null,
      },
      perm: {
        type: BigInt,
        default: BigInt(0),
      },
      portal: {
        type: Object,
        default: () => null,
      },
    },
    emits: ['on-poll-vote'],
    setup() {
      const { showLoginSignUpDialog } = useAuth();
      const { mutateAsync: contentPollVoteMutation, data: newContentPollVote } =
        useContentPollVoteMutation();
      const {
        mutateAsync: contentPollQuadraticVoteMutation,
        data: newContentPollQuadraticVote,
      } = useContentPollQuadraticVoteMutation();
      const { loadPortalBySlug } = usePortal();
      const queryClient = useQueryClient();
      const { cancelAndSetQueryData } = useFeed();

      return {
        cancelAndSetQueryData,
        showLoginSignUpDialog,
        loadPortalBySlug,
        contentPollVoteMutation,
        contentPollQuadraticVoteMutation,
        queryClient,
        newContentPollVote,
        newContentPollQuadraticVote,
      };
    },
    data() {
      return {
        choice_selected: null,
        isVoting: false,
        value: 0,
        total_voting_tokens: 10,
        current_voting_tokens: 10,
        quadratic_token_map: {},
        current_page: 1,
        items_per_page: 15,
        choicePages: [],
        pagedItems: [],
        search: '',
        portalData: null,
      };
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/me',
        currentPortal: 'portals/currentPortal',
      }),
      has_voted() {
        return this.poll.vote_choice_ids.length > 0;
      },
      is_expired() {
        return Number(String(this.poll.expires_at).slice(0, 13)) < Date.now();
      },
      isTraditional() {
        return Object.prototype.hasOwnProperty.call(
          this.poll.kind,
          'Traditional',
        );
      },
      isText() {
        return Object.prototype.hasOwnProperty.call(this.poll.kind, 'Text');
      },
      canVote() {
        return hasPermission(this.perm, PermissionFlags.POLL_VOTE_CONTENT);
      },
      canDownvote() {
        return hasPermission(this.item.perm, PermissionFlags.DISLIKE_CONTENT);
      },
      isPaged() {
        return this.poll.choices.length > this.items_per_page;
      },
      totalPages() {
        return Math.ceil(this.pagedItems.length / this.items_per_page);
      },
      hasNextPage() {
        return this.totalPages > this.current_page;
      },
    },
    watch: {
      search() {
        this.current_page = 1;
        this.pageChoices();
      },
      poll() {
        this.pageChoices();
      },
    },
    mounted() {
      this.init();
    },
    activated() {
      this.init();
    },
    methods: {
      paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice(
          (page_number - 1) * page_size,
          page_number * page_size,
        );
      },
      pageChoices() {
        this.pagedItems = this.poll.choices;
        if (this.search) {
          this.pagedItems = this.pagedItems.filter((x) => {
            console.log(x);

            return x.text.toLowerCase().includes(this.search.toLowerCase());
          });
        }

        this.choicePages = this.paginate(
          this.pagedItems,
          this.items_per_page,
          this.current_page,
        );

        if (this.has_voted || this.is_expired) {
          this.choicePages.sort((a, b) =>
            a.total_votes < b.total_votes ? 1 : -1,
          );
        }
      },
      nextPage() {
        if (this.current_page == this.totalPages) return;
        this.current_page++;
        this.pageChoices();
      },
      prevPage() {
        if (this.current_page <= 1) return;
        this.current_page--;
        this.pageChoices();
      },
      get_choice_text(choice) {
        return choice;
      },
      calc_current_tokens() {
        let current_token_spend = 0;

        for (const id in this.quadratic_token_map) {
          current_token_spend += this.quadratic_token_map[id];
        }

        return current_token_spend;
      },
      inc_vote(id) {
        if (this.calc_current_tokens() < this.total_voting_tokens) {
          this.quadratic_token_map[id] += 1;
          this.current_voting_tokens -= 1;
        }
      },
      dec_vote(id) {
        if (this.quadratic_token_map[id] > 0) {
          this.quadratic_token_map[id] -= 1;
          this.current_voting_tokens += 1;
        }
      },
      is_vote_choice(id) {
        if (this.has_voted) {
          for (let i = 0; i < this.poll.vote_choice_ids.length; i++) {
            if (this.poll.vote_choice_ids[i] == id) return true;
          }
        }

        return false;
      },
      get_vote_percent(votes) {
        const results =
          (parseInt(votes) / parseInt(this.poll.total_votes)) * 100;

        if (!results) return 0;

        return results;
      },
      is_winner(id) {
        let is_winner = false;
        let highest = -1;

        for (let i = 0; i < this.poll.choices.length; i++) {
          const c = this.poll.choices[i];

          if (c.total_votes > highest) highest = c.total_votes;
        }

        for (let i = 0; i < this.poll.choices.length; i++) {
          const c = this.poll.choices[i];

          if (c.total_votes >= highest && c.id == id) is_winner = true;
        }

        return is_winner;
      },
      async vote() {
        if (!this.currentUser) {
          this.showLoginSignUpDialog();
        } else if (this.choice_selected) {
          this.isVoting = true;

          if (this.isTraditional || this.isText) {
            await this.contentPollVoteMutation({
              pollId: this.poll.id,
              choiceId: this.choice_selected,
            });
          } else {
            await this.contentPollQuadraticVoteMutation({
              pollId: this.poll.id,
              choices: this.quadratic_token_map,
            });
          }
          const updatedContent = {
            ...this.content,
            poll: this.newContentPollVote
              ? this.newContentPollVote
              : this.newContentPollQuadraticVote,
          };
          this.$emit('on-poll-vote', updatedContent);
          this.isVoting = false;
        }
      },
      randomNum() {
        return Math.floor(Math.random() * 100) + 1;
      },
      init() {
        if (!this.isTraditional && !this.isText) {
          this.current_voting_tokens = parseInt(this.poll.voting_tokens);
          this.total_voting_tokens = parseInt(this.poll.voting_tokens);
          this.poll.choices.map((x) => {
            this.quadratic_token_map[x.id] = 0;
          });
        }
        this.pageChoices();
        if (!this.portalData && !this.canVote) {
          this.loadPortalBySlug(this.portal.slug).then((data) => {
            this.portalData = data;
          });
        }
      },
    },
  };
</script>
