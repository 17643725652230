<script lang="ts" setup>
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
  import { useHead, useSeoMeta } from '@unhead/vue';
  import { useRoute } from 'vue-router';
  import {
    computed,
    watch,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
  } from 'vue';
  import { AppLayout } from '@/layouts';
  import { storeToRefs } from 'pinia';
  import { useTimeoutFn } from '@vueuse/core';
  import { differenceInDays } from 'date-fns';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import { ToastWidget } from '@/widgets/toast';
  import { DialogWidget } from '@/widgets/dialog';
  import { useStore } from 'vuex';
  import { ActionTypes } from '@/store/action-types';
  import { useUser, useNotificationSettingStore } from '@/entities/user';
  import { usePersistentStore } from '@/store';
  import {
    useDocumentScroll,
    OnboardingStep,
    useOnboardingStore,
  } from '@/shared/model';
  import { trackEvent } from '@/utils';
  import { useAuth } from '@/entities/auth';
  import { DSCVR_STATIC_ASSETS_CDN_URL, HOME } from '@/common';
  import { fetchMedia, config } from '@/shared/lib';
  import { useGetIcpFTsQuery } from '@/entities/token';
  import { useOnboardingStateMachine } from '@/features/onboarding';
  import { useGetTippableTokensQuery } from '@/entities/tip';

  const store = useStore();
  const route = useRoute();

  useGetTippableTokensQuery();

  const notificationSettingStore = useNotificationSettingStore();
  const { isLoggedIn, currentUserPrincipal, currentUser } = useUser();
  const persistentStore = usePersistentStore();
  const { lastTimeSignUpDialogTriggered, isSignUpDialogTriggered } =
    storeToRefs(persistentStore);
  const { y } = useDocumentScroll();
  const { authEntity, showLoginSignUpDialog } = useAuth();
  useGetIcpFTsQuery(currentUserPrincipal, isLoggedIn);
  const onboardingStore = useOnboardingStore();
  const { isUiLocked } = storeToRefs(onboardingStore);
  const { onInit: onInitOnboardingMachine } = useOnboardingStateMachine();

  let timer: ReturnType<typeof setInterval> | undefined;

  const baseTitle = 'DSCVR - Start or join a community today';

  const isLoading = computed(() => store.getters.isLoading);
  const metaTitle = computed(() =>
    route.name && route.name !== HOME
      ? `DSCVR - ${route.name
          .toString()
          .replace('-', ' ')
          .replace(/\b\w/g, (l) => l.toUpperCase())}`
      : baseTitle,
  );

  useHead({
    title: () => metaTitle.value,
  });

  useSeoMeta({
    ogTitle: baseTitle,
    ogDescription: 'Community-first hangout for news, hobbies & discussions.',
    ogImage: fetchMedia(
      `${DSCVR_STATIC_ASSETS_CDN_URL}/common/dscvr-social.png`,
    ),
    ogUrl: 'https://www.dscvr.one',
    ogType: 'website',
    twitterCard: 'summary_large_image',
  });

  const initGlobalLiveDataPoller = () => {
    clearGlobalLiveDataPoller();

    if (!isLoggedIn.value) {
      return;
    }

    store.dispatch(`auth/${ActionTypes.GET_GLOBAL_LIVE_DATA}`);
    timer = setInterval(() => {
      store.dispatch(`auth/${ActionTypes.GET_GLOBAL_LIVE_DATA}`);
    }, 10000);
  };

  const clearGlobalLiveDataPoller = () => {
    if (timer) {
      clearInterval(timer);
      timer = undefined;
    }
  };

  const initTips = async () => {
    setInterval(() => {
      store.dispatch(`nfts/${ActionTypes.TRIGGER_TIP_WATCH}`);
    }, 5000);
  };

  const isAuthManagerLoginFormVisible = computed(
    () =>
      authEntity.value.visible &&
      authEntity.value.form.type === 'login' &&
      authEntity.value.context,
  );

  const onTriggerSignUpDialog = () => {
    if (
      isSignUpDialogTriggered.value ||
      isLoggedIn.value ||
      isAuthManagerLoginFormVisible.value
    ) {
      return;
    }
    trackEvent('user_action', 'signup', 'user_waited_10_seconds');
    showLoginSignUpDialog('signup');
    lastTimeSignUpDialogTriggered.value = Date.now();
    isSignUpDialogTriggered.value = true;
  };
  const { start } = useTimeoutFn(onTriggerSignUpDialog, 10000, {
    immediate: false,
  });

  watch(
    isLoggedIn,
    async (value) => {
      if (value) {
        // if currentUser.onboarding_state is an empty string, user is starting onboarding
        // else we load the onboarding state from the currentUser (onboarding_state is a serialized JSON string)
        // backdoor for dev environment
        if (!config.SKIP_ONBOARDING) {
          const currentUserOnboardingState = currentUser.value.onboarding_state
            ? JSON.parse(currentUser.value.onboarding_state)
            : { currentStep: OnboardingStep.INTRODUCTORY, completedSteps: [] };

          // This is a temporary fix to handle the case where the user has completed the onboarding
          if (!('currentStep' in currentUserOnboardingState)) {
            currentUserOnboardingState.currentStep =
              OnboardingStep.CLAIM_STREAK;
            currentUserOnboardingState.completedSteps = [
              'interests',
              'discover_users',
              'reward_dscvr_points',
              'claim_streak',
            ];
          }
          onInitOnboardingMachine(currentUserOnboardingState);
        }
        // This is required to populate whether the
        // user has enabled notification grouping or not
        await notificationSettingStore.getUserSettings();
      }
      initGlobalLiveDataPoller();
    },
    { immediate: true },
  );

  watch(
    () => y.value,
    (value) => {
      // if user didn't see the signup dialog yet and user has scrolled down 50% of the page
      if (
        !isSignUpDialogTriggered.value &&
        !isLoggedIn.value &&
        !isAuthManagerLoginFormVisible.value &&
        value > document.documentElement.scrollHeight / 2
      ) {
        trackEvent('user_action', 'signup', 'user_scrolled_down_50_percent');
        onTriggerSignUpDialog();
        isSignUpDialogTriggered.value = true;
      }
    },
    { flush: 'post' },
  );

  onMounted(() => {
    // if user is not logged in and 24 hours cooling period has passed
    if (!isLoggedIn.value) {
      if (
        lastTimeSignUpDialogTriggered.value === 0 ||
        differenceInDays(lastTimeSignUpDialogTriggered.value, Date.now()) > 1
      ) {
        isSignUpDialogTriggered.value = false;
        start();
      }
    }
  });

  onBeforeMount(() => {
    initTips();
  });

  onBeforeUnmount(() => {
    clearGlobalLiveDataPoller();
  });
</script>

<template>
  <vue-query-devtools />
  <app-layout :disabled="isUiLocked">
    <router-view v-slot="{ Component }" :key="route.fullPath">
      <component :is="Component" />
    </router-view>
    <loading-overlay :showing="isLoading" />
  </app-layout>
  <toast-widget />
  <dialog-widget />
</template>
