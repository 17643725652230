<script lang="ts" setup>
  import type { IconName } from '@/shared/ui/base-icon';
  import { computed, ref, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import StreakReward from './StreakReward.vue';
  import StreakBar from './StreakBar.vue';
  import { useStreak } from '../../model/composables/use-streak';
  import { ReferralButton, useUser } from '../../../user';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { fetchMedia } from '@/shared/lib';
  import { Countdown } from '@/shared/ui/countdown';

  const { t } = useI18n({ useScope: 'global' });
  const {
    onClaimStreak,
    showClaimButton,
    streakCountdown,
    setStreakCountdown,
    activeStreak,
  } = useStreak();
  const { getReferralLink, currentUser } = useUser();

  const claimButtonState = ref<'base' | 'positive'>('base');
  const referralLink = ref('');

  const claimButton = computed(() => {
    return {
      base: {
        label: t('streak.buttonLabel'),
        iconName: 'dscvr-solid',
      },
      positive: {
        label: t('streak.activeDialog.youAreOnAStreak'),
        image: fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/streak/streak-lightning-blue.gif`,
        ),
      },
    }[claimButtonState.value] as {
      label: string;
      iconName?: IconName;
      image?: string;
    };
  });

  onMounted(() => {
    getReferralLink(currentUser.value, true).then((link) => {
      referralLink.value = link;
    });
  });
</script>

<template>
  <div class="flex flex-col gap-8 max-w-[925px]">
    <streak-reward />
    <streak-bar />
    <div class="self-center">
      <base-button
        v-if="showClaimButton"
        variant="custom"
        custom-classes="flex gap-2 px-5 py-3.5 leading-5 items-center ease-transition-all text-lg font-semibold tracking-[0.18px] h-12 bg-indigo-600 hover:bg-gradient-to-t hover:to-[#00ECBC] hover:from-[#007ADF] rounded-xl"
        @mouseover="claimButtonState = 'positive'"
        @mouseleave="claimButtonState = 'base'"
        @click="onClaimStreak"
      >
        <img
          v-if="claimButton.image"
          :src="claimButton.image"
          alt="Lightning streak"
          class="size-6"
        />
        <base-icon v-else :name="claimButton.iconName" size="size-4" />
        {{ claimButton.label }}
      </base-button>
      <div class="flex flex-col items-center gap-4" v-else>
        <countdown
          :countdown="streakCountdown"
          @running="
            activeStreak?.next_claim_at
              ? setStreakCountdown(activeStreak.next_claim_at)
              : undefined
          "
        >
          <template #prefix>
            <div class="flex gap-1.5 items-center">
              <base-icon name="clock" size="size-4" class="text-white" />
              <span>
                {{ $t('comeBackIn') }}
              </span>
            </div>
          </template>
          <template #suffix>
            <span class="hidden md:block">
              {{ $t('streak.activeDialog.toClaimNextReward') }}
            </span>
          </template>
        </countdown>
        <div class="flex justify-center items-center gap-4 flex-wrap">
          <span class="text-lg">
            {{ $t('streak.activeDialog.wantMorePoints') }}
          </span>
          <referral-button
            variant="custom"
            custom-classes="flex justify-center items-center gap-2 rounded-full border border-white border-opacity-8 py-1 px-2.5 bg-white bg-opacity-8 hover:bg-opacity-10"
            rounded="rounded-full"
            add-referral-code
            :link="referralLink"
          >
            {{ $t('streak.activeDialog.referFriend') }}
          </referral-button>
        </div>
      </div>
    </div>
  </div>
</template>
