<template>
  <div class="mb-4 text-white height">
    <div class="flex items-center justify-between">
      <div class="text-lg font-semibold title">Popular Portals</div>
      <div v-show="trendingPortals.length > pageSize" class="flex gap-2">
        <button
          class="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer hover:bg-gray-785 active:bg-gray-950"
          @click="decrementPage"
        >
          <base-icon name="chevron-left" size="w-5 h-5" />
        </button>
        <button
          class="flex items-center justify-center w-8 h-8 rounded-md cursor-pointer hover:bg-gray-785 active:bg-gray-950"
          @click="incrementPage"
        >
          <base-icon name="chevron-right" size="w-5 h-5" />
        </button>
      </div>
    </div>
    <div class="flex gap-4 mt-3" :class="inRows ? 'flex-row' : 'flex-col'">
      <div
        v-if="!trendingPortalsFiltered || trendingPortalsFiltered.length == 0"
        class="flex items-center justify-center space-x-4"
      >
        <div class="relative pt-2 text-center text-white w-14 h-14">
          <div
            class="transform translate-x-1.5 translate-y-6 spinner-big"
          ></div>
        </div>
      </div>
      <div
        v-for="portal in trendingPortalsFiltered"
        :key="portal.id"
        class="relative h-40 hover:brightness-110 filter"
      >
        <img
          class="object-cover w-full h-full rounded-xl"
          :src="getCoverPhoto(portal)"
        />
        <div class="absolute bottom-0 w-full h-20 p-4 overlay rounded-b-xl">
          <router-link
            :to="{
              name: PORTAL_CONTENT,
              params: { portal: portal.slug },
            }"
            @click="$emit('closeMenu')"
          >
            <div class="flex items-center w-full h-full">
              <img class="rounded-lg size-12" :src="getPortalIcon(portal)" />
              <div class="flex flex-col m-3">
                <div class="text-sm font-semibold break-words lg:text-lg">
                  {{ portal.name }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <router-link
      :to="{ name: EXPLORE_PORTALS }"
      class="flex items-center justify-end mt-4 text-sm font-medium text-gray-400 cursor-pointer gap-x-2 hover:text-white ease-transition-all"
    >
      <base-icon name="compass" class="!h-3.5 !w-3.5" />
      Explore more
    </router-link>
  </div>
</template>

<script>
  import Prando from 'prando';
  import portalService from '@/services/portals';
  import CMS from '@/services/cms';
  import { PORTAL_CONTENT, EXPLORE_PORTALS, COVER_PHOTOS } from '@/common';
  import { usePortal } from '@/entities/portal';

  export default {
    name: 'trending-portals',
    props: {
      inRows: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['closeMenu'],
    setup() {
      const { getPortalIcon } = usePortal();
      return {
        getPortalIcon,
      };
    },
    data() {
      return {
        PORTAL_CONTENT,
        EXPLORE_PORTALS,
        trendingPortalsSlugs: ['dscvr', 'general', 'bugs', 'feature-requests'],
        trendingPortals: [],
        coverPhotos: COVER_PHOTOS,
        startIndex: 0,
        pageSize: this.inRows ? 2 : 3,
      };
    },
    computed: {
      trendingPortalsFiltered() {
        return this.trendingPortals.slice(
          this.startIndex,
          this.startIndex + this.pageSize,
        );
      },
    },
    async mounted() {
      await CMS.getTopPortals().then((result) => {
        if (result?.status == 200) {
          this.trendingPortalsSlugs = [];
          this.trendingPortalsSlugs = [];
          for (let i = 0; i < result.data.length; i++) {
            this.trendingPortalsSlugs.push(
              result.data[i].portal_slug.toString(),
            );
          }
        }
      });
      await portalService
        .getPortals(this.trendingPortalsSlugs)
        .then((result) => {
          if (result?.status == 'happy') {
            this.trendingPortals = result.result[0];
          }
        });
      /*
        this.startIndex = 0;
        let portals = [];
        for(let i=0;i<this.trendingPortalsSlugs.length;i++){
          await portalService.getPortal(this.trendingPortalsSlugs[i]).then((result) => {
            if (result?.status == "happy") {
              portals.push(result.result[0]);
            }
          });
        }
        this.trendingPortals = portals;*/
    },
    methods: {
      incrementPage() {
        this.startIndex = Math.min(
          this.trendingPortals.length - this.pageSize,
          this.startIndex + this.pageSize,
        );
      },
      decrementPage() {
        this.startIndex = Math.max(0, this.startIndex - this.pageSize);
      },
      getCoverPhoto(portal) {
        if (!portal) {
          return '';
        } else if (!portal.info[0]) {
          return this.getRandomCoverPhoto(portal.name);
        }
        if (portal.info[0].cover_photo.length > 0) {
          const cp = portal.info[0].cover_photo[0];
          return cp == '' ? this.getRandomCoverPhoto(portal.name) : cp;
        } else {
          return this.getRandomCoverPhoto(portal.name);
        }
      },
      getRandomCoverPhoto(portalName) {
        const rng = new Prando(portalName);
        return this.coverPhotos[rng.nextInt(0, this.coverPhotos.length - 1)];
      },
    },
  };
</script>
<style scoped>
  .overlay {
    background: rgba(31, 41, 55, 0.64);
    backdrop-filter: blur(40px);
  }
  .title {
    letter-spacing: -0.01117em;
  }
</style>
