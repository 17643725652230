<script setup lang="ts">
  import { onMounted, ref, type Ref } from 'vue';
  import { RoleDisplay } from '@/entities/role';
  import type {
    MultichainCollectionDetailDto,
    MultichainGateDto,
  } from '@/shared/api';
  import type { RoleView } from 'dfx/edge/edge.did';
  import {
    useNFTGatingSetup,
    MultichainCollectionPreview,
  } from '@/features/gating';

  const props = defineProps<{
    item: MultichainGateDto;
    role?: RoleView;
  }>();

  const { getNftCollectionDetailsByAddress } = useNFTGatingSetup();

  const multichainNFTCollection: Ref<
    MultichainCollectionDetailDto[] | undefined
  > = ref();

  onMounted(async () => {
    multichainNFTCollection.value = await getNftCollectionDetailsByAddress(
      props.item.nftCollectionAddress,
    );
  });
</script>

<template>
  <td>
    <div class="flex justify-start">
      <base-icon :name="`chain-${item.network.chainType}`" size="w-5 h-5" />
    </div>
  </td>
  <td>
    <div class="flex gap-4">
      <multichain-collection-preview
        v-if="multichainNFTCollection?.length"
        :item="multichainNFTCollection[0]"
        class="text-gray-400 flex-1"
      />
      <span v-else class="text-gray-400 flex-1 ml-10">{{ item.name }}</span>
    </div>
  </td>
  <td>
    <role-display v-if="role" :role="role" />
  </td>
  <td class="text-green-500">{{ $t('active') }}</td>
</template>
