<script setup lang="ts">
  import { toRefs } from 'vue';
  import ActionButton from '../ActionButton.vue';
  import PortalStats from '../PortalStats.vue';
  import { useSinglePortal } from '../../model/composables/use-single-portal';
  import type { PortalView } from 'dfx/edge/edge.did';
  import { default as TooltipMenu } from './TooltipMenu.vue';
  import { ClampLines } from '@/shared/ui/clamp-lines';
  import { PORTAL_CONTENT } from '@/common';
  import { usePortal } from '../../model/composables/use-portal';

  const props = defineProps<{
    portal: PortalView;
    isDrawer?: boolean;
  }>();

  defineEmits<{
    (e: 'share'): void;
  }>();

  const { portal } = toRefs(props);
  const { hasCoverPhoto, coverPhoto } = useSinglePortal(portal);
  const { getPortalIcon } = usePortal();
</script>

<template>
  <article
    class="relative flex flex-col overflow-hidden pointer-events-auto rounded-xl sm:shadow-light group"
  >
    <header
      class="relative flex justify-end items-start gap-1.5 w-full h-27 p-6"
    >
      <div
        class="absolute inset-0 bg-center bg-cover -z-1"
        :class="{ 'group-hover:hue-rotate-180': !hasCoverPhoto }"
        :style="`background-image:url(${coverPhoto})`"
      />
      <action-button :portal="portal" />
      <tooltip-menu :portal="portal" @share="$emit('share')" />
    </header>
    <section
      class="flex flex-col gap-6 p-8 pt-0 overflow-visible border-opacity-50 bg-gray-975 rounded-b-xl border-gray-785 border-t-none"
    >
      <div class="flex gap-4 overflow-visible">
        <img
          alt="Portal icon"
          v-lazy="getPortalIcon(portal)"
          class="min-w-20.5 w-20.5 h-20.5 rounded-lg relative -mt-4.5"
        />
        <div class="flex flex-col flex-1 gap-2 pt-1 text-white">
          <base-button
            variant="custom"
            custom-classes="text-lg font-semibold"
            :to="{ name: PORTAL_CONTENT, params: { portal: portal.slug } }"
          >
            {{ portal.name }}
          </base-button>
          <portal-stats
            class="text-gray-500"
            :portal="portal"
            :hide-labels="isDrawer"
          />
        </div>
      </div>
      <clamp-lines :text="portal.description" />
    </section>
  </article>
</template>
