<script setup lang="ts" generic="T extends FilterDropDownOptions">
  import { ref, computed, type ComputedRef } from 'vue';
  import { isEqual } from 'lodash-es';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import type { FilterDropdownProps, FilterDropDownOptions } from '../types';
  import { useDocumentScroll } from '@/shared/model';

  const props = defineProps<FilterDropdownProps<T>>();

  const emit = defineEmits<{
    (e: 'update:model-value', filter: T): void;
  }>();

  useDocumentScroll({
    hideOnScroll: () => {
      if (opened.value) opened.value = false;
    },
  });

  const opened = ref(false);

  const onUpdateValue = (filter: T) => {
    opened.value = false;
    emit('update:model-value', filter);
  };

  const customContentClasses: ComputedRef<string> = computed(() =>
    [
      props.extraClasses,
      'border border-[#333B47]/[0.4] overflow-hidden shadow-xl min-w-64',
    ].join(' '),
  );
</script>

<template>
  <base-dropdown
    v-if="modelValue"
    v-model:opened="opened"
    placement="bottom-end"
    :distance="0"
    :custom-content-classes="customContentClasses"
    :disabled="disabled"
  >
    <template #button>
      <base-button
        variant="custom"
        size="x-small"
        class="relative border border-white border-opacity-[0.06] shadow-lg transition-all duration-300 ease-in-out mb-2 drop-shadow-heavy"
        :class="[opened ? 'bg-gray-790' : buttonClasses]"
        :disabled="disabled"
        tabindex="-1"
        @click="opened = !opened"
      >
        <base-icon
          v-if="modelValue.icon"
          :name="modelValue.icon"
          size="w-4 h-4"
          class="mr-1 text-indigo-300"
        />
        <span
          class="font-semibold text-sm capitalize"
          :class="[hideMobileLabel ? 'py-2.5 md:py-0' : '']"
        >
          <span :class="[hideMobileLabel ? 'hidden md:flex' : '']">
            {{ modelValue.title }}
          </span>
        </span>
        <base-icon
          name="chevron-down-filled"
          size="w-4 h-4"
          class="text-gray-500"
        />
      </base-button>
    </template>
    <template #content>
      <ul tabindex="-1">
        <li
          v-for="(filter, index) in filters"
          :key="index"
          class="flex items-center gap-2 transition-all duration-100 ease-in-out p-2 cursor-pointer"
          :class="[
            isEqual(filter, modelValue)
              ? 'bg-gradient-to-r from-indigo-600 via-indigo-800 via-20% to-60% to-gray-725 hover:via-indigo-800 hover:to-gray-700'
              : 'bg-gray-725 hover:bg-gray-700',
            !filter.subtitle ? 'p-4' : 'p-2',
          ]"
          tabindex="0"
          @click="onUpdateValue(filter)"
        >
          <base-icon
            v-if="filter.icon"
            :name="filter.icon"
            class="mx-2"
            :class="
              isEqual(filter, modelValue) ? 'text-white' : 'text-indigo-300'
            "
          />
          <div class="flex flex-col">
            <span class="font-medium capitalize">{{ filter.title }}</span>
            <span v-if="filter.subtitle" class="text-gray-300 text-sm">
              {{ filter.subtitle }}
            </span>
          </div>
        </li>
      </ul>
    </template>
  </base-dropdown>
</template>
