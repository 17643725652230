import type { IconName } from '@/shared/ui/base-icon/types';
import * as routeNames from '@/common/routeNames';
import type { ValueOf } from '@/shared/model';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useMainMenu = () => {
  const route = useRoute();
  const mainMenuLinks = computed<{
    [key: string]: {
      label: string;
      name?: ValueOf<typeof routeNames>;
      icon: IconName;
      isHidden?: boolean;
      isBeta?: boolean;
      isHighlighted?: boolean;
      mobileOnly?: boolean;
    };
  }>(() => {
    const activeRoute = route.name;
    const highlightHome =
      activeRoute === routeNames.HOME ||
      activeRoute === routeNames.FOLLOW ||
      activeRoute === routeNames.ALL;
    const highlightExplorePortals =
      activeRoute === routeNames.EXPLORE_PORTALS ||
      activeRoute === routeNames.EXPLORE_PORTALS_FEATURED;
    const highlightExplorePeople =
      activeRoute === routeNames.EXPLORE_PEOPLE ||
      activeRoute === routeNames.EXPLORE_PEOPLE_FEATURED;
    const highlightArcade = activeRoute === routeNames.ARCADE;

    return {
      home: {
        label: 'Feeds',
        name: routeNames.HOME,
        icon: highlightHome ? 'home' : 'outline-home',
        isHighlighted: highlightHome,
      },
      portals: {
        label: 'Portals',
        name: routeNames.EXPLORE_PORTALS_FEATURED,
        icon: highlightExplorePortals ? 'portal' : 'outline-portal',
        isHighlighted: highlightExplorePortals,
      },
      people: {
        label: 'People',
        name: routeNames.EXPLORE_PEOPLE_FEATURED,
        icon: highlightExplorePeople
          ? 'vuesax-bold-frame'
          : 'vuesax-linear-profile',
        isHighlighted: highlightExplorePeople,
      },
      games: {
        label: 'Games',
        name: routeNames.ARCADE,
        icon: highlightArcade ? 'vuesax-bold-game' : 'vuesax-linear-game',
        isHighlighted: highlightArcade,
        mobileOnly: true,
      },
      spaces: {
        label: 'Spaces',
        icon: 'spaces',
        isHidden: true,
      },
      airdrops: {
        label: 'Collections',
        icon: 'outline-airdrops',
        isHidden: true,
      },
    };
  });

  return {
    mainMenuLinks,
  };
};
