<script lang="ts" setup>
  import { computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useAuthStore, type AccountType } from '@/shared/model';
  import { TERMS } from '@/common';
  import { config } from '@/shared/lib';
  import { Avatar } from '@/shared/ui/avatar';
  import { solanaLogos } from '../../lib/solanaLogos';
  import LastUsed from '../LastUsed.vue';
  import { useDevice, useBreakpoint } from '@/shared/model';
  import { useAuth } from '../../model/composables/use-auth';

  const router = useRouter();
  const authStore = useAuthStore();
  const { authEntity, login, updateAuthManager, closeDialog } = useAuth();
  const { isEmbedded: isEmbeddedBrowser } = useDevice();
  const { t } = useI18n({ useScope: 'global' });
  const { isSmallerThanMd, isSmallerThanLg } = useBreakpoint();

  const title = computed(
    () =>
      `${
        authEntity.value.form.type === 'login' ? 'Sign in' : 'Sign up'
      } to DSCVR`,
  );

  const isLogin = computed(() => authEntity.value.form.type === 'login');

  const buttonLabel = computed(() =>
    authEntity.value.form.type === 'login' ? t('login') : t('signup'),
  );

  const getLastUsedLoginOption = (type: AccountType) => {
    if (isLogin.value) {
      return authStore.getLastUsedLoginOption(type);
    } else {
      return false;
    }
  };

  const onClickPrivacyAndTerms = () => {
    closeDialog();
    router.push({ name: TERMS });
  };

  const onClickLoginWithWallet = () => {
    if (isSmallerThanLg.value && !isEmbeddedBrowser.value) {
      closeDialog();
      updateAuthManager(authEntity.value.context, {
        name: 'use-mobile-wallet-app',
      });
    } else {
      login('solana-wallets');
    }
  };
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-8">
    <div
      class="flex flex-col items-center mt-10"
      :class="{
        'mt-6': isSmallerThanMd,
      }"
    >
      <base-icon name="dscvr" size="w-12 h-12" class="mx-auto mb-3" />
      <span class="text-2xl font-semibold leading-none">
        {{ title }}
      </span>
      <span class="mt-2 text-sm text-center text-gray-400">
        {{ t('auth.subtitle') }}
      </span>
    </div>
    <div class="w-full">
      <div class="flex flex-col items-center w-full gap-4">
        <base-button
          v-if="!isEmbeddedBrowser"
          variant="custom"
          custom-classes="relative flex justify-center items-center gap-2.5 border border-[#747775] box-border bg-white text-[#1F1F1F] text-sm leading-5 p-2.5 font-roboto-medium rounded-full w-full antialiased tracking-normal"
          @click="login('google')"
        >
          <last-used :shown="getLastUsedLoginOption('google')" />
          <base-icon name="colored-google" size="size-5" />
          {{ buttonLabel }} with Google
        </base-button>
        <base-button
          variant="secondary"
          size="medium"
          rounded="rounded-full"
          class="relative w-full text-sm"
          @click="onClickLoginWithWallet"
        >
          <last-used :shown="getLastUsedLoginOption('solana-wallets')" />
          <avatar :avatars="solanaLogos" reverse size="size-5" />
          {{ buttonLabel }} with wallet
        </base-button>
        <base-button
          variant="secondary"
          size="medium"
          rounded="rounded-full"
          class="relative w-full text-sm"
          @click="login('credentials')"
        >
          <last-used :shown="getLastUsedLoginOption('credentials')" />
          <base-icon
            name="profile-circle"
            size="size-5"
            class="text-indigo-300"
          />
          {{ buttonLabel }} with username
        </base-button>
        <base-button
          v-if="!isEmbeddedBrowser && config.WORLDCOIN_AUTH_ENABLED"
          variant="secondary"
          size="medium"
          rounded="rounded-full"
          class="relative w-full text-sm"
          @click="login('worldcoin')"
        >
          <last-used :shown="getLastUsedLoginOption('worldcoin')" />
          <base-icon name="worldcoin" size="size-5" />
          {{ buttonLabel }} with Worldcoin
        </base-button>
      </div>
      <template v-if="isLogin && !isEmbeddedBrowser">
        <div class="w-full h-px my-6 bg-gray-40" />
        <div class="flex flex-col items-center w-full gap-4">
          <base-button
            variant="secondary"
            size="medium"
            rounded="rounded-full"
            class="relative w-full text-sm"
            @click="login('internet identity')"
          >
            <last-used :shown="getLastUsedLoginOption('internet identity')" />
            <base-icon name="colored-internet-computer" size="size-5" />
            {{ buttonLabel }} with Internet Identity
          </base-button>
          <base-button
            variant="secondary"
            size="medium"
            rounded="rounded-full"
            class="relative w-full text-sm"
            @click="login('other')"
          >
            <last-used :shown="getLastUsedLoginOption('other')" />
            <base-icon name="colored-nfid" size="size-5" />
            {{ buttonLabel }} with NFID + Google (Legacy)
          </base-button>
        </div>
      </template>
    </div>
    <div class="flex text-sm">
      {{ isLogin ? 'Do not have an account?' : 'Already have an account?' }}
      <base-button
        variant="link"
        custom-classes="pl-1 text-indigo-300 hover:underline"
        @click.stop="authEntity.form.type = isLogin ? 'signup' : 'login'"
      >
        {{ isLogin ? 'Sign up' : 'Sign in' }}
      </base-button>
    </div>
    <div class="inline-block text-xs text-center text-gray-500">
      {{ t('auth.loginForm.byContinuing') }} <br />
      <base-button
        variant="custom"
        custom-classes="hover:underline"
        @click="onClickPrivacyAndTerms"
      >
        {{ t('auth.loginForm.termOfService') }}
      </base-button>
      {{ t('auth.loginForm.andOur') }}
      <base-button
        variant="custom"
        custom-classes="hover:underline"
        @click="onClickPrivacyAndTerms"
      >
        {{ t('auth.loginForm.privacyPolicy') }}
      </base-button>
    </div>
  </div>
</template>
