<script lang="ts" setup>
  import type { PortalView } from 'dfx/edge/edge.did';
  import { useRoute } from 'vue-router';
  import { PORTAL_CONTENT } from '@/common';
  import { usePortal } from '@/entities/portal';
  import { EmptyFavoritePortals } from '@/widgets/left-sidebar/components';

  defineEmits<{
    (e: 'closeMenu'): void;
  }>();

  const { highlightedPortals, getPortalIcon } = usePortal();

  const route = useRoute();

  const isSelectedPortal = (portal: Pick<PortalView, 'slug'>) =>
    portal.slug === route.params.portal;
</script>

<template>
  <template v-if="highlightedPortals.length > 0">
    <base-button
      v-for="highlighedPortal in highlightedPortals"
      :key="Number(highlighedPortal.id)"
      variant="link"
      :to="{
        name: PORTAL_CONTENT,
        params: { portal: highlighedPortal.slug },
      }"
      custom-classes="flex items-center p-2 hover:text-white rounded-l-xl"
      :class="[
        isSelectedPortal(highlighedPortal)
          ? 'font-semibold bg-gradient-to-r from-indigo-850 via-transparent to-transparent hover:bg-transparent'
          : 'font-medium hover:bg-gradient-to-r hover:via-transparent hover:to-transparent hover:from-gray-785',
      ]"
      @click="$emit('closeMenu')"
    >
      <img
        :src="getPortalIcon(highlighedPortal)"
        class="rounded-lg shadow-lg size-8"
      />
      <span
        class="mx-2 text-sm font-medium leading-5 text-gray-300 truncate"
        :title="highlighedPortal.name"
      >
        {{ highlighedPortal.name }}
      </span>
      <base-icon
        v-if="highlighedPortal.is_nsfw"
        name="eighteen-plus"
        size="w-4 h-4"
      />
    </base-button>
  </template>
  <div v-else class="px-2 mt-2 text-gray-400">
    <empty-favorite-portals />
  </div>
</template>
