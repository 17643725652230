export default {
  about: 'Acerca de',
  add: 'Agregar',
  addLink: 'Añadir enlace',
  addNewNft: 'Agregar nuevo NFT',
  addRule: 'Agregar regla',
  addWallet: 'Agregar billetera',
  active: 'Activo',
  all: 'Todo',
  allPeople: 'Todos',
  allPortals: 'Todos los portales',
  allVolume: 'Todo Vol',
  allowFrames: 'Permitir marcos',
  alsoCommented: 'también comentó',
  and: 'y',
  andOthers: 'y otros',
  access: 'Acceso',
  accessDescription:
    'Las carteras emparejadas se mostrarán aquí. El botín de Solana de las cajas de botín se enviará a tu billetera principal.',
  appsAndSocial: 'Aplicaciones y redes sociales',
  arcade: {
    creatorSpotlight: 'Spotlight de creadores',
    discoverGamingPortals: 'Descubrir portales de juegos',
    enjoyGames: 'Disfruta de juegos gratis o juega para ganar',
    moreGamesComing:
      'Más juegos y tablas de clasificación comunitarias próximamente.',
    newToArcade: 'Nuevo en Arcade',
    owner: 'Dueño',
    slideTitle: '¿Quieres enviar tu juego?',
    slidePrimaryButtonText: 'Aplicar aquí',
    slideSecondaryButtonText: 'Más información',
    welcome: 'Bienvenido a DSCVR ARCADE',
  },
  auth: {
    subtitle: 'Ampliando los límites de las redes sociales Web3',
    detectionForm: {
      accountFound: '¡Lo encontré!',
      yourAccount: 'Su cuenta',
      wasMadeWith: 'fue hecho con',
      useNonWalletBrowser:
        'Utilice un navegador que no sea de billetera en su dispositivo, como Chrome, Firefox o Safari, para iniciar sesión.',
      clickOneOfTheButtons: 'Haga clic en uno de los botones',
      clickTheButton: 'Clic en el botón',
      belowToContinue: 'a continuación para continuar.',
      signInWith: 'Inicia sesión con',
      username: 'Nombre de usuario',
    },
    accountNotFound: {
      notFoundWithNFID:
        'Cuenta no encontrada. Asegúrese de estar utilizando la cuenta NFID correcta',
      notFoundWithII:
        'Cuenta no encontrada. Asegúrese de estar utilizando el ancla de identidad de Internet correcta',
      signInWithUsername: 'Iniciar sesión con el nombre de usuario',
    },
    mobileWalletApp: {
      title: 'Utilice la aplicación de billetera móvil',
      description: 'Inicie sesión en DSCVR en su aplicación de billetera móvil',
    },
    loginForm: {
      byContinuing: 'Al continuar, aceptas nuestros',
      termOfService: 'Términos de servicio',
      andOur: 'Y nuestra',
      privacyPolicy: 'Política de privacidad.',
    },
    registrationForm: {
      createAccount: 'Crear una cuenta',
    },
  },
  back: 'Atrás',
  backToProfile: 'Volver al perfil',
  backToFeeds: 'Volver a los feeds',
  backToPost: 'Volver a la publicación',
  balance: 'Balance',
  ban: 'Banear',
  banMember: 'Banear miembro',
  block: 'Bloquear',
  blocked: 'Obstruido',
  bookmarks: 'Marcadores',
  browseGames: 'Explorar Juegos',
  canisterPrincipalId: 'Identificación principal de canister',
  canisterIdErrorRequired: 'Se requiere una identificación de canister',
  canisterIdErrorInvalid: 'Identificación principal de canister inválida',
  cancel: 'cancelar',
  canvas: {
    errorLoading: 'Error cargando canvas',
    unknownMessageError: 'Canvas: Mensaje desconocido',
  },
  chain: 'Cadena',
  chainErrorRequired: 'Se requiere cadena',
  chooseYourPicture: 'Slecciona to imagen',
  claimed: 'Reclamado',
  claim: 'Reclamar',
  claiming: 'Reclamándolo',
  claimedDescription: 'Has reclamado tu botín',
  claimFive: 'Reclamar 5 puntos',
  claimHundred: 'Reclamar 100 puntos',
  claimLootbox: 'Reclamar caja de botín',
  collab: 'Colab',
  collaborateOnAtlas3: 'Colaborar en Atlas3',
  collectionAddress: 'Dirección de la colección',
  collectionAddressErrorRequired: 'Se requiere la dirección de colección',
  collectionAddressErrorInvalid: 'Dirección de colección no válida',
  collectionAddressLegend:
    'Utilice la dirección de "On-chain Collection" disponible en Magic Eden para cualquier NFT de la colección',
  collectionName: 'Nombre de la colección',
  collectionNameErrorRequired: 'Se requiere el nombre de la colección',
  collectionErrorRequired: 'Se requiere una colección',
  collections: 'Colecciones',
  collectAirdrops: 'Recolectar airdrops gratis',
  comeBackIn: 'Vuelve a entrar',
  comments: 'Comentario',
  connectWallet: 'Conectar billetera',
  continue: 'Continuar',
  copied: 'Copiado!',
  copiedToClipboard: 'Copiado al portapapeles',
  copyPrincipal: 'Copiar ID de principal ',
  create: 'Crear',
  createAirdrop: 'Crear airdrop',
  createdBy: 'Creado por',
  createNewRole: 'Crear un nuevo rol',
  createNftCollectionError: 'Hubo un problema para crear la activación de NFT',
  createNftCollectionSuccess: 'Actualización NFT habilitada',
  createPortal: 'Crear portal',
  createPost: 'Crear publicación',
  createRoleError: 'Hubo un problema para crear el rol',
  createRoleSuccess: 'Rol creado con éxito',
  day: 'Día',
  days: 'días',
  delete: 'borrar',
  deleteConfirmationMessage: '¿Estás seguro que quieres eliminar esto?',
  deleteNftCollectionError:
    'Hubo un problema para eliminar la activación de NFT',
  deleteNftCollectionSuccess: 'Puerta NFT eliminada exitosamente',
  deleteNftGate: 'Eliminar la puerta NFT',
  description: 'Descripción',
  disableComments: 'Desabilitar comentarios',
  dragImage: 'Arrastra y suelta la imagen aquí para cargarla',
  dragMedia: 'Arrastra y suelta las imagenes o videos aquí para cargarlos',
  dropFilesHere: 'Arrastre los archivos aquí',
  dscvrdPoints: 'Parece que has DSCVRed algunos puntos.',
  duration: 'Duración',
  earned: 'ganado',
  edit: 'editar',
  editProfile: 'Editar perfil',
  editProfilePhoto: 'Edit profile photo',
  editRule: 'Editar regla',
  embedImage: 'Imagen de incrustación',
  unpairedWalletNotification: 'Aún no has agregado una billetera',
  error: 'Error',
  errorLoadingImage: 'Error Cargando...',
  explore: 'Explorar',
  exploreAndDiscover: 'Explorar y descubrir',
  exploreMore: 'Explora más',
  exploreSearchPlaceholderLarge:
    'Interiértase en la gente y explore los portales ...',
  exploreSearchPlaceholderSmall: 'Encuentra tu comunidad ...',
  favorites: 'Favoritos',
  favoritePortals: {
    addToFavorites: 'Agregar a Favoritos',
    applyChanges: 'Aplicar cambios',
    favorite: 'Favorito',
    favoritesEmptyLegend:
      'Guarde sus portales favoritos para acceso rápido aquí.Expanda su universo agregando algunos ahora.',
    favoritesLegend: 'Guarde sus portales favoritos para acceso rápido aquí',
    noResults: 'No hay resultados',
    otherFavorites: 'Otros Favoritos',
    owned: 'Adueñado',
    pleaseJoinToFavorite: 'Por favor, únase al favorito',
    removeFromFavorites: 'Quitar de favoritos',
    reorder: 'Reordenar',
    topFavorited: 'Principales Favoritos',
  },
  featured: 'Presentada',
  featuredPortals: 'Portales destacados',
  floorPrice: 'Precio de pisoiso',
  follower: 'Seguidor',
  followers: 'Seguidoras',
  following: 'Siguiente',
  frame: {
    address: 'Dirección',
    buttonIndex: 'Índice de boton',
    commentsMaxReached: 'Los comentarios deben ser menos de 1000 caracteres',
    debug: 'Depurar',
    errorPost: 'Error al realizar la publicación del marco',
    flagFrame: 'Marco de la bandera',
    frameAction: 'Acción de marco',
    frameIntroduction: 'Introducción al marco',
    frameLink: 'Enlace de marco',
    frameNotFoundError: 'Frame no encontrado',
    framesValidator: 'Validador de marcos',
    frameUrl: 'URL de marco',
    frameUrlErrorRequired: 'Se requiere URL de marco',
    inputText: 'Texto de entrada',
    maxResponseTimeLegend:
      'La solicitud tomó más de 5s.Esto puede ser normal: la primera solicitud llevará más tiempo en desarrollo, pero en la producción, los clientes tendrán un tiempo de espera de solicitudes después de 5s',
    openFramesSpec: 'Especificación de marcos abiertos',
    postId: 'ID de publicación',
    postIdErrorInvalid: 'ID de publicación no válido',
    request: 'pedido',
    response: 'respuesta',
    specs: 'Especificaciones',
    state: 'Estado',
    times: 'veces',
    timestamp: 'Marca de tiempo',
    transactionId: 'ID de transacción',
    trustedMessage: 'Mensaje de confianza',
  },
  gallery: 'Galería',
  getNFT: 'Obtener NFT',
  gift: 'regalo',
  gotToSettings: 'Ir a la configuración',
  guest: 'invitado',
  guests: 'invitados',
  hide: 'esconder',
  home: 'inicio',
  hotSpots: 'Puntos calientes',
  howToSetupPhantom: 'Cómo configurar la billetera Phantom',
  installMetamaskTitle: 'No se detectó Metamask',
  installMetamaskLegend: 'Desbloquee o instale Metamask',
  invalidImageUrlError: 'Ingrese una URL de imagen válida',
  invalidUrlError: 'Este no parece ser un url valido',
  invite: 'Invitar',
  inviteToPortal: 'Invitar al portal',
  itemId: 'Identificación del artículo',
  join: 'Unirse',
  joined: 'unido',
  key: 'Llave',
  keyboardC: 'Pestaña de comentarios',
  keyboardDown: 'Desplazarse hacia abajo',
  keyboardG: 'Pestaña de la galería',
  keyboardL: 'Like una publicación',
  keyboardLeft: 'Ir a la publicación anterior',
  keyboardR: 'Abrir reacciones',
  keyboardRight: 'Ir a la próxima publicación',
  keyboardS: 'Menú de acceso directo',
  keyboardShortcuts: 'Atajos de teclado',
  keyboardUp: 'Desplazarse hacia arriba',
  kick: 'Retirar',
  kickMember: 'Sacar miembro',
  lastUsed: 'Usado por última vez',
  learnMore: 'Aprende más',
  leave: 'Dejar',
  leaveConfirm: '¿Estás seguro de que quieres salir?',
  leavePortal: 'Dejar el portal',
  leavePortalLegend: '¿Estás seguro de que quieres dejar este portal?',
  linkWarning: 'Solo usa enlaces "https://"',
  listedSupply: 'Listado / suministro',
  listeningNow: 'Escucha ahora',
  login: 'Loguearse',
  lootbox: {
    creatorLootboxFound: 'Caja de botín del creador encontrada',
    criteria: 'Criterios',
    claimSubtitle: {
      sm: 'Tu tesoro te espera',
      md: 'Tu tesoro te espera. Reclamar el botín restante de su caja abierta.',
    },
    claimTitle: {
      sm: '¡LOOT no reclamado!',
      md: '¡Tienes botín no reclamado!',
    },
    claimYourLoot: {
      sm: 'Afirmar',
      md: 'Reclama tu botín',
    },
    luckyYouCreator: '¡Afortunado! Encontraste una caja de botín de creador',
    loot: 'Botín',
    lootbox: 'Caja botín',
    lootEarned: 'Botín ganado',
    mustBeMemberOf: 'Debe ser miembro de',
    mustFollow: 'Debe seguir',
    pairError:
      'Debe tener una billetera Solana emparejada para abrir este botín.',
  },
  luckyDay: '¡Oye, es tu día de suerte!',
  makeCoHost: 'Hacer Co-host',
  managed: 'Administrada',
  manageWallets: 'Administrar billeteras',
  markAsNSFW: 'Marca como NSFW',
  managePortals: 'Administrar portales',
  marketplace: 'Mercado',
  marketplaceCollectionsLegend:
    'Sumérgete en un tesoro de NFT en juego.Algunas colecciones actúan como su clave dorada para desbloquear este increíble portal. 🔑✨',
  marketplaceCollectionItemLegend: 'Desbloquea este portal. 🔑✨',
  matureContent: 'Contenido para adultos (NSFW)',
  member: 'Miembro',
  members: 'Miembros',
  memberSince: 'Miembro desde',
  more: 'más',
  mustJoinPortalMessage: 'Debe unirse al portal para ver este contenido',
  myProfile: 'Mi perfil',
  newRoleName: 'Nuevo nombre de rol',
  network: 'Red',
  nextPage: 'Siguiente página',
  nftAirdrop: {
    setAsPFP: 'Establecido como PFP',
    viewOnMarketplace: 'Vista sobre Entrepot',
  },
  nftCollection: 'Colección NFT',
  nftGate: 'Puerta NFT',
  nftGates: 'Puertas NFT',
  nftGatesLegend:
    'Añada una puerta NFT a su portal, otorgue a sus miembros roles basados en la propiedad NFT.',
  nftHolderRole: 'Rol del titular de NFT',
  nftTransfer: 'Transferencia NFT',
  nftType: 'Tipo NFT',
  nftTypeErrorRequired: 'Se requiere el tipo NFT',
  noCommentsYet: 'Sin comentarios aún',
  noGalleryItems: 'No hay artículos de la galería todavía',
  noLanguage: '¡No tienes un idioma de traducción establecido todavía!',
  noRecentPortal: 'No hay portales recientes',
  notifications: {
    title: 'Notificaciones',
    portalAdmin: 'Administrador del portal',
    empty: {
      title: 'Permanecer en el bucle',
      excerpt:
        'No te pierdas nada cuando a alguien le gusten, comenten o compartan tus publicaciones.',
    },
    options: {
      title: 'Opciones',
      grouping: {
        title: 'Notificación grupal',
        subtitle:
          'Consolide las notificaciones de alta frecuencia en mensajes agrupados.',
      },
    },
    preferences: {
      title: 'Preferencias',
      commentsAndReplies: 'Comentarios y respuestas',
      newFollowers: 'Nuevos seguidores',
      likesAndReactions: 'Gustos y reacciones',
      mentions: 'Menciones',
      streakReminders: 'Recordatorios de rachas por correo electrónico',
    },
    content: {
      postMention: 'te menciono en una publicacion',
      commentMention: 'te mencioné en un comentario',
      postReply: 'respondió a tu publicación',
      postMediaReply: 'respondió a su publicación en los medios',
      commentReply: 'respondió a tu comentario',
      postReaction: 'respondió a tu publicación',
      postMediaReaction: 'reaccionó a su publicación en los medios',
      commentReaction: 'reaccionó a tu comentario',
    },
    inAppTitle: 'Quiero recibir',
    markAllAsRead: 'Marcar todo como leido',
    settings: {
      streakRemindersErrorTitle: 'Por favor verifique su correo electrónico.',
    },
    viewAllNotifications: 'Ver todas las notificaciones',
  },
  nsfwTooltipContent: 'Solo para audiencias adultas y potencialmente NSFW',
  onboarding: {
    introductory: {
      welcome: 'Bienvenido',
      title: 'Incorporación completa para obtener recompensas',
      description:
        'Sabemos que la incorporación puede ser tediosa. Para compensarlo, queremos regalarte lo siguiente si completas todos los pasos.',
      rewards: {
        title: 'Gana recompensas',
        description:
          'Termine de incorporarse para ganar sus primeros puntos DSCVR, la moneda social de DSCVR que puede gastar para crear su primera comunidad.',
      },
      lootbox: {
        title: 'Recibe una caja de botín gratis',
        description:
          'Las cajas de botín contienen NFT, tokens y otros activos digitales gratuitos de las comunidades y proyectos criptonativos más destacados.',
      },
    },
    interest: {
      title: '¿En que estas interesado?',
      description: 'Elija 3 o más temas para ayudarnos a seleccionar su feed',
      noneOfThese: 'Ninguno de esos',
    },
    discoverUsers: {
      title: 'Descubre los mejores usuarios',
      description:
        'Siga a nuestros usuarios más populares para mantenerse actualizado y unirse a la conversación.',
      follow: 'Seguir',
    },
    joinPortals: {
      title: 'Unirse a portales',
      description:
        'Encontramos algunos portales que coinciden con sus intereses.',
      descriptionNoInterests:
        'Consulte nuestros portales más activos y atractivos.',
      join: 'Unirse',
    },
    missingSteps: {
      title: 'Completar la incorporación para reclamar',
      user: {
        title: 'Sigue al menos a tres personas.',
        description:
          'Conectarse con otros usuarios es una parte fundamental de DSCVR.',
      },
      portal: {
        title: 'Únete al menos a tres portales',
        description:
          'Encontrar comunidades es parte de lo que hace que DSCVR sea divertido y entretenido.',
      },
      completeOnboarding: 'Incorporación completa',
    },
    rewards: {
      title: '¡Obtuviste 50 puntos DSCVR solo por registrarte!',
      description:
        'Acumule puntos DSCVR reclamando su racha diaria, publicando contenido que obtenga votos positivos y recomendando amigos. Los puntos DSCVR pueden calificarlo para cajas de botín y acceso a portales especiales.',
      claimNow: 'Reclama ahora',
      claimed: 'Reivindicar!',
    },
    nft: {
      title: '¡Te ganaste un NFT exclusivo!',
      subtitle: 'Establécela como tu imagen de perfil y gana 50 pts.',
      description:
        'Su NFT está en camino a su billetera. Esto puede tardar hasta una hora si la red está ocupada. Si configura el NFT como su PFP, será recompensado con 50 puntos DSCVR.',
      viewMyWallet: 'Ver mi billetera',
    },
    connectYourWallet: {
      title: 'Conecta tu billetera',
      description:
        'Para reclamar este NFT único, debe tener una billetera conectada.',
      connectWallet: 'Conectar billetera',
      error: 'Hubo un error al conectar tu billetera',
    },
    whatIsInTheBox: '¡¿Qué hay en la caja?!',
    unlock: 'Desbloquear',
    unlockLootbox: 'Desbloquear caja de botín',
    claim: {
      tooltipContent: '¡Reclama tu primera racha para ganar más puntos!',
    },
    newUser: '¡Los nuevos usuarios reciben botín exclusivo!',
    chooseAtLeast3: 'Elige al menos 3',
    skip: 'Pasar',
  },
  open: 'Abierto',
  opened: 'Abrió',
  opening: 'Apertura',
  pair: 'Par',
  paired: 'Emparejada',
  people: 'Gente',
  pickPortal: 'Seleccione un portal',
  play: 'Jugar',
  points: 'Puntos',
  pointsAdded: 'Hemos agregado 5 puntos extra a tu cuenta.',
  pointsReceived: 'Puntos recibidos',
  pollEnptyQuestionError: 'Las preguntas no pueden estar vacías',
  pollMinQuestionsError: 'La encuesta debe tener al menos 2 preguntas',
  pollQuestionMaxCharsError:
    'las preguntas no pueden tener mas de 120 caracteres',
  pollRequiredVotingPeriodError:
    'Debe setear el largo del periodo de votación.',
  popularPortals: 'Portales populares',
  portal: 'Portal',
  portalAdmin: 'Administrador del portal',
  portalDoNotExist: 'Este portal no existe',
  portalError: 'Selecciona un portal antes de publicar',
  portalInvite: {
    buildCommunity:
      'Construye tu comunidad. Copie el enlace de referencia y descargue la imagen para invitar amigos a su portal.',
    buildProfile:
      'Construye tu comunidad. Copie el enlace de referencia y descargue la imagen para invitar a amigos a seguir su perfil.',
    inviteFriends: 'Invita a tus amigos',
    joinPortal: 'Únete a este portal',
    followUser: 'Unete a la communidad',
    invited: '¡Has sido invitado!',
    invitedToJoin: 'Has sido invitado/a a unirte',
    invalidReferralCode: 'Código de referencia no válido',
    embraceFuture:
      '¡Abraza el futuro de las redes sociales! ¡Participe en una comunidad descentralizada creada para usted!',
  },
  portalPermissionError: 'No tienes permisos para publicar en este portal.',
  portalRestrictedSubtitle: 'No tienes acceso a este portal.',
  portalRestrictedNote:
    'Si acaba de vincular o adquirir el NFT, es posible que este Portal tarde unos minutos en desbloquearse.',
  portalRestrictedTitle: 'Adquirir un NFT para unirse',
  portalNSFWRestrictedError:
    'Habilitar NSFW en su configuración para ver este contenido',
  post: 'publicar',
  postButtonLabelGift: 'Regalo',
  postButtonLabelReply: 'Responder',
  postButtonLabelRepost: 'Volver a publicar',
  postButtonLabelShare: 'Compartir',
  postButtonLabelToken: 'Propinas',
  postCreated: 'Tu publicación fue creada exitosamente',
  postDeletedConfirm: 'Tu publicación no guardada se eliminará.',
  postError: 'Rellena el cuerpo de la publicación antes de publicar',
  postFeature: {
    commented: 'Compentaron',
    errorDelete:
      'Se produjo un error al eliminar esta publicación, inténtelo nuevamente',
    errorRemove:
      'Se produjo un error al eliminar esta publicación, inténtalo de nuevo',
    interacted: 'Interactuaron',
    originalPostDoNotExist:
      'La publicación original ya no existe, pero puedes ver otras publicaciones populares.',
    postDoNotExist: 'Esta publicación no existe',
    postRestricted: 'No tiene permisos para ver esta publicación.',
    replied: 'Respondió',
  },
  postUpdated: 'Tu publicación fue actualizada exitosamente',
  posts: 'Publicaciones',
  postTo: 'Publicar en',
  prevPage: 'Pagina anterior',
  primaryChain: 'Cadena primaria',
  primaryWallet: 'Primario',
  principalId: 'ID principal',
  purchased: 'Comprado',
  raisedHands: 'Alzar Manos',
  readMore: 'Leer más',
  reason: 'Razón',
  recent: 'Reciente',
  recommended: 'Recomendado',
  referral: 'Referencia',
  referrals: 'Referencias',
  referralFeature: {
    community: {
      referrals: {
        capacitor: 'el capacitor',
        copyReferralLink: 'Copiar enlace de referencia',
        description:
          '¿Quieres puntos pasivos? Recomiende usuarios y reciba 1 punto de bonificación por cada 10 que ganen. Además, ¡carga tu condensador ganando 100 puntos de bonificación para recibir un multiplicador de Airdrop!',
        empty: 'Vacío',
        title: '¡Tu condensador está vacío!',
      },
    },
    getRewards: '¡Obtener recompensas!',
    inviteYourFriends: 'Invita a tus amigos',
    multipliers: 'Multiplicador',
    multiplier: 'Multiplicadores',
    onboarding: {
      title: 'Conecta a tus amigos, consigue recompensado',
      bonus: {
        title: 'Reciba puntos de bonificación de forma pasiva',
        description:
          'Obtenga 1 punto de bonificación por cada 10 puntos que ganen sus referidos.',
      },
      airdrop: {
        title: 'Maximice sus multiplicadores de lanzamiento aéreo',
        description:
          'Por cada 100 puntos de bonificación que gane a través de referencias, recibirá un multiplicador de Airdrop.',
      },
    },
    tooltipContent: 'Envía a tus amigos un enlace de invitación aquí',
    copyLink: 'Enlace copiado al portapapeles',
  },
  remove: 'Eliminar',
  removeConfirmationMessage: '¿Estás seguro de que quieres remover esto?',
  removeLink: 'Remover enlace',
  removeLinkLegend: '¿Estás seguro de que quieres eliminar este enlace?',
  removeRule: 'Eliminar la regla',
  removeRuleLegend: '¿Estás seguro de que quieres eliminar esta regla?',
  repliedTo: 'responder a',
  reply: 'respuesta',
  report: 'Reportar',
  requiredUrlError: 'necesitas un url para esto',
  requiresFifty: 'Requiere: 50',
  roleErrorRequired: 'Se requiere un rol',
  roleNameErrorDuplicated: 'El nombre de rol ya existe',
  roleNameErrorLength: 'El nombre de rol debe estar entre 1 y 20 caracteres',
  roleNameErrorRequired: 'Se requiere nombre de rol',
  rollTheDice: '¡Tirar los dados!',
  ruleTitle: 'Título de regla',
  ruleDescription: 'Descripción de la regla',
  runtime: 'Runtime',
  scrollAndDiscover: 'Desplázate y descubre',
  search: 'Buscar',
  save: 'guardar',
  searchNftCollection: 'Buscar colección NFT',
  searchPortals: 'Buscar portales',
  selectLanguage: 'Selecciona uno en tus ajustes.',
  selectPrimaryChain: 'Seleccionar cadena primaria',
  selectSiteLanguage: 'Idioma',
  selectSitePortalSorting: 'Clasificación de portales',
  selectSitePrimaryLanguage: 'Seleccionar idioma principal',
  selectSiteTranslatedLanguage: 'Traducción',
  selectSiteTranslatedPrimaryLanguage: 'Seleccionar traducción',
  send: 'Enviar',
  sendGift: '¿Enviar regalo?',
  sendGiftLegend:
    '¿Estás seguro de que quieres regalar este NFT?Esta acción no se puede deshacer.',
  setLanguage: 'Establecer idioma de traducción',
  userSettings: {
    address: 'Dirección',
    avatarRequirements: 'Al menos 280 x 280 PX',
    coverPhotoRequirements: 'al menos 1088x300 PX',
    errorUploadingFile: 'Hubo un error cargar el archivo',
    invalidIcpNftAddressMessage: 'Solo se pueden usar NFT de IC válidos',
    invalidImageFileMessage: 'Sube un archivo de imagen',
    updateProfileError: 'Hubo un error actualizar el perfil',
    username: 'Usuario',
    userProfile: 'Perfil de usuario',
  },
  setupNftWatcher: 'Habilitar compuertas NFT',
  setupNftWatcherSuccess: 'Las compuertas NFT están habilitada',
  share: 'Compartir',
  sharePortal: 'Compartir portal',
  shareProfile: 'Compartir perfil',
  show: 'Espectáculo',
  showcaseNFTs: 'Muestra tu galería NFT de Solana',
  showContent: 'Mostrar contenido',
  showInMarketplace: 'Mostrar en marketplace',
  signup: 'Registrarse',
  signUp: {
    thrilledRegistration:
      '¡Estamos emocionados de que hayas elegido unirte a nuestra comunidad de pensadores y creadores innovadores! Solo queda un último paso.',
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    dontHaveAccount: '¿Aún no tienes una cuenta?',
  },
  sort: 'Ordenar',
  speaker: 'Speaker',
  speakers: 'Speakers',
  spotlight: 'Destacados',
  status: 'Estado',
  streak: {
    airdropMultiplier: 'Multiplicador de lanzamiento aéreo',
    earned: 'Ganados',
    highest: 'Más alto',
    highestStreak: 'Streak más alto',
    streak: 'Streak',
    totalEarned: 'Total ganados',
    informationDialog: {
      title: '¡Multiplica tus posibilidades de obtener un Airdrop exclusivo!',
      lightning: {
        title: 'Aumenta tus posibilidades con Streaks',
        description:
          'Inicie sesión y presione el botón de reclamo para aumentar sus posibilidades de recibir lanzamientos aéreos exclusivos en su feed.',
      },
      plus: {
        title: 'Gana puntos DSCVR cada día que inicies sesión',
        description:
          'Comienza a acumular tus puntos. Cuantas más rachas consigas, más puntos recibirás.',
      },
    },
    shareDialog: {
      title: {
        global:
          '¡Borrar! ¡Has cargado un multiplicador de Airdrop! Te lo ganaste',
      },
      lightning: {
        title: 'Continúe y gane su próximo multiplicador (se acumulan)',
        description:
          'Inicie sesión y presione el botón de reclamo para aumentar sus posibilidades de recibir lanzamientos aéreos exclusivos en su feed.',
      },
      plus: {
        title: 'Gana puntos DSCVR cada día que inicies sesión',
        description:
          'Comienza a acumular tus puntos. Cuantas más rachas consigas, más puntos recibirás.',
      },
      shareYourStreak: 'Comparte tu racha',
      unlockedAn: 'desbloqueado un',
    },
    buttonLabel: 'Reclama tus puntos',
    duringAStreakOf: 'durante una racha de',
    activeButton: {
      tooltipContent: '¡Seleccione este botón para reclamar sus puntos!',
    },
    activeDialog: {
      allPoweredUp: '¡Todo encendido!',
      longStreaksEarnMore: 'Las rachas largas ganan más',
      powerUp: '¡Enciende tu medidor de lanzamiento aéreo!',
      referFriend: 'Recomendar a un amigo',
      toClaimNextReward: 'para reclamar tu próxima recompensa',
      unbrokenStreak: '¡Las rachas ininterrumpidas ganan más puntos!',
      unlockedAn: 'desbloqueado un',
      wantMorePoints: '¿Quieres ganar más puntos?',
      youAreOnAStreak: '¡Estás en una streak!',
      getNotified: 'Ser notificado',
      optInToReceive:
        'Suscríbete para recibir recordatorios por correo electrónico',
      optedIn: 'Optado en',
      toast: {
        title: '¡Serás notificado!',
        description:
          'Has optado por recibir recordatorios de racha por correo electrónico. Visite la configuración para cambiar los permisos.',
        gotoSettings: 'Ir a la configuración',
      },
    },
  },
  stay: 'Permanecer',
  submit: 'Entregar',
  takeMeToTheUnknown: 'Llévame a lo desconocido',
  titleError: 'Rellena el título antes de publicar',
  tokens: 'tokens',
  tokenTransfer: {
    amountErrorMessage: 'La cantidad debe ser mayor que 0',
    balanceErrorMessage: 'No hay suficiente equilibrio.',
    invalidWalletErrorMessage: 'Principal de billetera IC no válido',
    invalidWalletExtErrorMessage:
      'Dirección o director de billetera IC no válida',
    reviewTransfer: 'Revisar transferencia',
    title: 'Transferencia NFT',
    transferNftFailedMessage: 'Falló la transacción de transferencia NFT',
    transferTokenFailedMessage:
      'La transacción de transferencia de token falló',
    transferNftLegend: 'Confirme que está a punto de enviar NFT',
    transferTokenLegend: 'Por favor confirme que está a punto de enviar',
    transferWarning:
      'Todas las transacciones son irreversibles, así que asegúrese de que los detalles anteriores sean correctos antes de continuar.',
    transferNftSuccessMessage: 'La transferencia NFT fue exitosa',
    transferTokenSuccessMessage: 'La transferencia de tokens fue exitosa',
    validationNftLegend:
      'Ingrese la dirección de la billetera informática de Internet a la que desea transferir este NFT.',
    validationTokenLegend:
      'Ingrese la dirección de la billetera informática de Internet que desea transferir este token también.',
    walletPlaceholder: 'Director de billetera IC',
    walletPlaceholderExt: 'Principal o dirección de la billetera de IC',
  },
  tip: {
    contentOwnerHasNoWalletTitle:
      'El propietario del contenido no tiene billetera',
    contentOwnerHasNoWalletDescription:
      'El propietario del contenido no tiene una billetera conectada para recibir propinas',
    connectWallet: 'Conectar billetera',
    signatureFailed: 'Firma fallida',
    preparingTipTitle: 'Preparando propina',
    preparingTipDescription: 'Estamos preparando tu propina',
    noWalletConnectedTitle: 'Sin billetera conectada',
    noWalletConnectedDescription:
      'Conéctese a una billetera para enviar propinas',
    successTitle: 'Propina enviada',
    successDescription: 'Tu propina ha sido enviada',
  },
  top: 'Arriba',
  transfer: 'Transferir',
  translate: 'traducir',
  unblock: 'Desatascar',
  unpair: 'Desemparejar',
  unfollow: 'dejar de seguir',
  unlock: 'desbloquear',
  unsavedChanges: '¿Eliminar cambios no guardados?',
  update: 'Actualizar',
  updateNftCollectionError:
    'Hubo un problema para actualizar las actividades nft',
  updateNftCollectionSuccess: 'Colección NFT actualizada con éxito',
  upload: 'Subir',
  uploadImage: 'Cargar imagen',
  uploadProfilePhoto: 'Subir foto de perfil',
  uploadCoverPhoto: 'Subir foto de portada',
  useNFT: 'Usar NFT',
  user: 'Usuario',
  userBlocked: 'está bloqueado',
  userDoNotExist: 'Este usuario no existe',
  userNoLongerBlocked: 'ya no está bloqueado',
  userNoLongerFollowsYou: 'ya no te sigue',
  verifiedSolanaPFPs: 'Solana PFP verificados',
  verifyPhone: 'Verificar el teléfono',
  verifyPhoneLegend:
    'Debe tener un teléfono verificado para unirse a este portal',
  view: 'Ver',
  viewAll: 'Ver todo',
  viewOnMagicEden: 'Ver en Magic Eden',
  verifyingAtDSCVR: 'Verificación en DSCVR',
  voteCommentPermissionError: 'No tienes permiso para votar este comentario',
  voteCommentError: 'Hubo un error al votar este comentario',
  waiting: 'Esperando',
  wallet: 'Billetera',
  wallets: {
    connectWallet: {
      connectAWallet: 'Conectar una billetera',
      connecting: 'Conectando',
      errorConnectingWallet: 'Error de conectar la billetera',
      installed: 'Instalada',
      invalidWalletConnection: 'Conexión de billetera no válida',
      mobileConnectError: 'La billetera no puede ser conectada en mobile',
      detected: 'Detectada',
      notDetected: 'No detectado',
      popular: 'Popular',
    },
    evm: {
      providersNotFound: 'Proveedor de Ethers no encontrado',
    },
    sendingToWallet: {
      title: 'Te enviamos a tu billetera',
      description:
        'Vuelva a DSCVR cuando haya terminado. Si no recibe un mensaje de confirmación, vuelva a conectar su billetera.',
      takeMeToMyWallet: 'Llévame a mi billetera',
    },
    successfullyPaired: {
      successfullyConnected: '¡Conexión exitosa!',
      takeMeToMyDscvrWallet: 'Llévame a mi billetera DSCVR',
    },
    unsuccessfulPairing: {
      inApp: {
        pairingUnsuccessful: 'Emparejamiento fallido',
        pairThisAccount: 'En su lugar, vincule esta cuenta',
        useDifferentWallet: 'Usa una billetera diferente',
        walletAlreadyPairedTo: 'Esta billetera está emparejada con',
        pairingWillUnpairFrom: 'Al vincular su billetera, se desvinculará de',
      },
      identity: {
        walletAlreadyPaired: 'Cartera ya emparejado',
        walletAlreadyAssociated: 'Esta billetera está asociada con ',
        signinWithThisAccount: 'Iniciar sesión con esta cuenta',
      },
    },
    addAnotherWallet: 'Agregar otra billetera',
    addressCopied: 'Dirección copiada',
    allWallets: 'Todas las billeteras',
    failedToLoad: 'Falló al cargar...',
    mobileWalletPairing: 'Emparejamiento de billetera móvil',
    linkWalletFailed: 'Error en el enlace de billetera',
    linkWalletFailedDescription:
      'Error al vincular la billetera para la dirección: ',
    thanksForPairing:
      'Gracias por realizar el emparejamiento. Ahora puedes volver al navegador de tu móvil.',
    address: 'Dirección',
    refreshWallets: 'Actualizar',
    sharingError: 'Incapaz de compartir token',
    showAllAssets: 'Mostrar todos los elementos',
    verifyNftPfpError: 'Error al verificar NFT',
    matrica: {
      walletsRefreshedTitle:
        'Hemos actualizado y recuperado sus billeteras Matrica.',
      walletsRefreshedDescription:
        'Hemos actualizado y recuperado sus billeteras Matrica.',
      refreshWalletsErrorTitle: 'Error al actualizar billeteras',
      refreshWalletsErrorDescription:
        'No pudimos actualizar y recuperar sus billeteras Matrica.',
      refreshWalletsSuccessDescription:
        'Hemos actualizado y recuperado sus billeteras Matrica.',
      linkWalletLinkErrorTitle: 'Error al vincular la billetera Matrica',
      linkWalletLinkErrorDescription:
        'No pudimos vincular su billetera Matrica.',
      linkWalletUnlinkErrorTitle: 'Error al desvincular la billetera Matrica',
      linkWalletUnlinkErrorDescription:
        'No pudimos desvincular su billetera Matrica.',
      redirectErrorMessage:
        'No pudimos redirigirte a Matrica. Por favor, inténtalo de nuevo.',
      linkedText: 'Desvinculado',
      unlinkedText: 'Vinculado',
    },
  },
  walletTransaction: {
    commentsErrorRequired: 'Debes ingresar un comentario',
    confirm: 'Confirmar',
    confirmTransaction: 'Confirmar transacción',
    great: '¡Excelente!',
    loadingTransactionMessage: 'Confirme la conexión en la extensión.',
    maliciousReports: 'Reportes maliciosos',
    missingConnectedWalletError: 'No se encontró una billetera conectada',
    opening: 'Abriendo',
    proceedAnyway: 'Procede de todas maneras',
    reason: 'Razón',
    reasonFraud: 'Fraude',
    reasonSpam: 'Spam',
    reasonOther: 'Otra',
    reasonErrorRequired: 'Seleccione un motivo de la lista',
    redirectMessageTitle: 'Saliendo de DSCVR',
    redirectMessageDescription:
      'Está a punto de salir de DSCVR, haga clic en continuar para continuar.',
    report: 'Reportar',
    reportTransaction: 'Transacción de informes',
    sendingTransaction: 'Enviando transacción',
    transactionId: 'ID de transacción',
    transactionGenericError: 'Error al procesar la transacción',
    transactionNotFoundError:
      'Transacción exitosa pero blockchain no respondió a tiempo. Consulte la ID de transacción para verificar. Esto puede tomar unos pocos minutos',
    transactions: 'transacciones',
    transactionSuccessMessage: '¿Cómo nos fue?',
    transactionSuccessTitle: '¡Transacción exitosa!',
    transactionReportedTitle: 'Esta transacción se ha reportado',
    transactionReportedMessage:
      'Esta transacción ha sido marcada por otros usuarios como potencialmente maliciosos. DSCVR recomienda cancelar esta transacción.',
    transactionReportTitle: 'Reportar transacción',
    transactionWarning: 'Advertencia de transacción',
  },
  twitter: {
    oauthError:
      '¡Ups! Algo salió mal al vincular su cuenta X. Por favor, inténtelo de nuevo más tarde',
    unlinkError: '¡Ups! Algo salió mal al desvincular su cuenta X',
    unpairedSocialPairingText:
      'Empareje su cuenta X para agregar un nombre de usuario a su perfil',
    oauthSuccess: '¡Éxito! Su cuenta X ha sido vinculada',
    unlinkSuccess: '¡Éxito! Su cuenta X ha sido desvinculada',
  },
  nftMarketplace: {
    buy: 'Comprar',
    buyNow: 'Comprar ahora',
    sold: 'Vendido',
    price: 'Precio',
    royalty: 'Regalía',
    takerFee: 'Tarifa de taker',
    tensor: { viewOnTensor: 'Ver en Tensor', buyOnTensor: 'Comprar en Tensor' },
    magicEden: {
      viewOnMagicEden: 'Ver en Magic Eden',
      buyOnMagicEden: 'Comprar en Magic Eden',
    },
  },
  youMightLike: 'Te podría gustar',
  verification: {
    title: 'Verifícate',
    subtitle:
      'Le enviaremos un correo electrónico de verificación. Haga clic en el enlace del correo electrónico y regrese a DSCVR.',
    resendTitle: '¡Enviado!',
    resendSubtitle:
      'Vuelva a DSCVR después de verificar su correo electrónico. Para optar por recibir notificaciones de rachas, haga clic en el icono de rachas y seleccione "Recibir notificaciones".',
    email: {
      form: {
        errors: {
          invalid:
            'Por favor introduzca una dirección de correo electrónico válida',
          required: 'Se requiere Dirección de correo electrónico',
        },
      },
      buttons: {
        sendEmail: 'Enviar correo electrónico',
        verify: 'Verificar',
        sent: 'Enviado!',
        resend: 'Reenviar',
        verified: 'Verificado',
      },
      resendExcerpt:
        'Puede tomar unos minutos llegar a su correo electrónico. Revisa Spam por si acaso.',
      notifications: {
        successTitle: '¡Has sido verificado!',
        successDescription: 'Gracias por verificar tu e-mail.',
        errorTitle: 'Error al verificar el correo electrónico',
        errorDescription:
          'Asegúrese de utilizar el correo electrónico de verificación más reciente o reenvíe uno nuevo.',
      },
      accountEmailUpdated: 'Correo electrónico actualizado correctamente',
      emailSuccessfullySent: 'El mensaje de verificación ha sido enviado',
      emailNotSent: 'Error al enviar el correo electrónico de verificación: ',
      errors: {
        tooManyRequests: 'Demasiadas solicitudes',
        unknownError: 'Error desconocido',
      },
    },
  },
  redirecting: 'Redirigiendo...',
  welcome: 'Bienvenido',
};
