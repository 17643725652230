import removeMd from 'remove-markdown';
import type { ContentView } from 'dfx/edge/edge.did';

export const buildMetaTitle = (title: string): string => {
  return `DSCVR - ${title}`;
};

export const getTitle = (post: ContentView): string => {
  if (post?.title) return post.title;

  const imgIndex = post?.body.indexOf('<img');
  const videoIndex = post?.body.indexOf('<video');
  const audioIndex = post?.body.indexOf('<audio');
  const iframeIndex = post?.body.indexOf('<iframe');
  const linkIndex = post?.body.indexOf('<link');
  const aIndex = post?.body.indexOf('<a');

  // The title ends, either at the end of the body or the start of the first media element.
  const titleEnd = [
    imgIndex,
    videoIndex,
    audioIndex,
    iframeIndex,
    linkIndex,
    aIndex,
  ].reduce((acc, current) => {
    return current > -1 ? Math.min(acc, current) : acc;
  }, post.body.length);

  const span = document.createElement('span');
  // Note: This will process any HTML in the body and also load any referenced
  // assets. We mitigate this by cutting off the body at the first media element.
  span.innerHTML = post?.body.slice(5, titleEnd);
  const spanTitle = span.textContent || span.innerText || '';
  if (spanTitle === '') return `${post?.owner?.username}'s post`;
  return spanTitle?.length >= 23
    ? spanTitle
        ?.substring(0, 23)
        .replace(/[^\w\s]/gi, '')
        .trim() + '...'
    : spanTitle?.replace(/[^\w\s]/gi, '');
};

export const getUrls = (text: string): string[] => {
  const regex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const str = text;
  let m;
  const results: string[] = [];
  while ((m = regex.exec(str)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    m.forEach((match) => {
      results.push(match);
    });
  }
  return results;
};

export const removeMarkdown = (text: string): string => {
  return removeMd(text, {
    stripListLeaders: true, // strip list leaders (default: true)
    listUnicodeChar: '', // char to insert instead of stripped list leaders (default: '')
    gfm: true, // support GitHub-Flavored Markdown (default: true)
    useImgAltText: true, // replace images with alt-text, if present (default: true)
  });
};
