import portalService from '../../services/portals';
import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';
import { cancellablePromise } from '../../utils/cancellable_promise';
import { trackEvent, trackGAEvent } from '../../utils/tracker';
import { PermissionGroups, hasPermissionGroup } from '@/utils/permissions';

// initial state
const state = () => ({
  isLoading: 0,
  all: [],
  mentionsHash: new Map(),
  currentPortal: null,
  currentPortalRoles: [],
  currentPortalMembers: [],
  currentPortalMembersByStatus: [],
  currentPortalRoleMembers: [],
  currentPortalMemberMemos: [],
  currentPortalUserRoles: [],
  currentPortalHoveredUserRoles: [],
  currentPortalUserMember: null,
  currentPortalUserAssignableRoles: [],
  currentPortalOwnerRoles: [],
  hotspots: [],
  feedRecommendations: [],
  exploreRecommendations: [],
  featuredPortals: [],
  portalChainType: null,
  allPortalsPaginated: {
    items: [],
    currentPage: 0n,
  },
});

// getters
const getters = {
  portals: (state) => {
    return state.all;
  },
  mentionsHash: (state) => {
    return state.mentionsHash;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  currentPortal: (state) => {
    return state.currentPortal;
  },
  currentPortalRoles: (state) => {
    return state.currentPortalRoles;
  },
  currentPortalMembers: (state) => {
    return state.currentPortalMembers;
  },
  currentPortalMembersByStatus: (state) => {
    return state.currentPortalMembersByStatus;
  },
  currentPortalRoleMembers: (state) => {
    return state.currentPortalRoleMembers;
  },
  currentPortalMemberMemos: (state) => {
    return state.currentPortalMemberMemos;
  },
  currentPortalUserRoles: (state) => {
    return state.currentPortalUserRoles;
  },
  currentPortalHoveredUserRoles: (state) => {
    return state.currentPortalHoveredUserRoles;
  },
  currentPortalUserMember: (state) => {
    return state.currentPortalUserMember;
  },
  currentPortalUserAssignableRoles: (state) => {
    return state.currentPortalUserAssignableRoles;
  },
  currentPortalOwnerRoles: (state) => {
    return state.currentPortalOwnerRoles;
  },
  isCurrentUserPortalManager: (_state, getters, _rootState, rootGetters) => {
    return (
      getters.currentPortal &&
      rootGetters['auth/me'] &&
      getters.currentPortal.is_following &&
      hasPermissionGroup(
        getters.currentPortal.perm,
        PermissionGroups.PORTAL_MANAGER,
      )
    );
  },
  hotspots: (state) => {
    return state.hotspots;
  },
  feedRecommendations: (state) => {
    return state.feedRecommendations;
  },
  exploreRecommendations: (state) => {
    return state.exploreRecommendations;
  },
  featuredPortals: (state) => {
    return state.featuredPortals;
  },
  allPortalsPaginated: (state) => {
    return state.allPortalsPaginated;
  },
};

/**
 *
 * @param roles
 */
function sort_roles(roles) {
  return roles.sort((role1, role2) => {
    return Number(role1.ordinal - role2.ordinal);
  });
}

// actions
const actions = {
  [ActionTypes.LIST_PORTALS]({ commit }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .listPortals()
      .then((portal) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        commit(MutationTypes.SET_PORTALS, portal.views);
        return portal.views;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_PORTAL]({ commit }, { name, no_load }) {
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);
    if (name) {
      const promise = portalService.getPortal(name);
      cancellablePromise.registerPromise(ActionTypes.GET_PORTAL, promise);
      return promise
        .then((result) => {
          if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
          if (
            cancellablePromise.isPromiseLatest(ActionTypes.GET_PORTAL, promise)
          ) {
            if (result?.status == 'happy') {
              commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
              commit(MutationTypes.SET_MENTION, result.result[0]);
              return result.result[0];
            }
            if (result?.status == 'sad') {
              return null;
            }
          }
        })
        .catch(() => {
          if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        });
    }
  },
  [ActionTypes.FOLLOW_PORTAL_TOGGLE]({ commit }, payload) {
    return portalService
      .follow_portal_toggle(payload.portal_id)
      .then((result) => {
        if (result?.status == 'happy') {
          commit(MutationTypes.UPDATE_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  [ActionTypes.FOLLOW_PORTAL_TOGGLE_WITH_LOADER]({ commit }, portal_id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .follow_portal_toggle(portal_id)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        console.log(error);
      });
  },
  [ActionTypes.GET_PORTAL_ROLES]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_ROLES, null);
    const promise = portalService.getPortalRoles(id);
    cancellablePromise.registerPromise(ActionTypes.GET_PORTAL_ROLES, promise);
    return promise
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_PORTAL_ROLES,
            promise,
          )
        ) {
          commit(MutationTypes.SET_CURRENT_PORTAL_ROLES, sort_roles(result));
        }
      })
      .catch((error) => {
        console.error(`GET_PORTAL_ROLES ${error}`);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_PORTAL_USER_ASSIGNABLE_ROLES](
    { commit },
    { portal_id, no_load },
  ) {
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);

    // commit(MutationTypes.SET_CURRENT_PORTAL_USER_ASSIGNABLE_ROLES, []);
    const promise = portalService.getPortalUserAssignableRoles(portal_id);
    cancellablePromise.registerPromise(
      ActionTypes.GET_PORTAL_USER_ASSIGNABLE_ROLES,
      promise,
    );
    return promise
      .then((result) => {
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_PORTAL_USER_ASSIGNABLE_ROLES,
            promise,
          ) &&
          result
        ) {
          commit(
            MutationTypes.SET_CURRENT_PORTAL_USER_ASSIGNABLE_ROLES,
            sort_roles(result),
          );
        }
      })
      .catch((error) => {
        console.error(`GET_PORTAL_USER_ASSIGNABLE_ROLES ${error}`);
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_PORTAL_USER_ROLES](
    { commit },
    { portal_id, user_id, no_load, for_owner, hover_user },
  ) {
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);
    if (for_owner != true)
      commit(MutationTypes.SET_CURRENT_PORTAL_HOVERED_USER_ROLES, []);
    else commit(MutationTypes.SET_CURRENT_PORTAL_OWNER_ROLES, []);
    const promise = portalService.getPortalUserRoles(portal_id, user_id);
    cancellablePromise.registerPromise(
      ActionTypes.GET_PORTAL_USER_ROLES + (for_owner ? 'FOR_OWNER' : ''),
      promise,
    );
    return promise
      .then((result) => {
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_PORTAL_USER_ROLES + (for_owner ? 'FOR_OWNER' : ''),
            promise,
          ) &&
          result[0]?.member?.roles
        ) {
          const roles = sort_roles(result[0].member.roles);
          if (hover_user) {
            commit(MutationTypes.SET_CURRENT_PORTAL_HOVERED_USER_ROLES, roles);
          } else if (for_owner != true) {
            commit(MutationTypes.SET_CURRENT_PORTAL_USER_ROLES, roles);
          } else commit(MutationTypes.SET_CURRENT_PORTAL_OWNER_ROLES, roles);
          commit(
            MutationTypes.SET_CURRENT_PORTAL_USER_MEMBER,
            result[0].member,
          );
        }
      })
      .catch((error) => {
        console.error(`GET_PORTAL_USER_ROLES ${error}`);
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  async [ActionTypes.GET_PORTAL_MEMBERS]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_MEMBERS, null);
    const max_page_size = 5000;
    let current_page = 0;
    let current_members = [];

    for (let i = 0; i < 10; i++) {
      const results = await portalService.getPortalMembers(
        id,
        max_page_size,
        current_page,
      );

      current_members = current_members.concat(results);
      current_page += results.length;

      if (results.length == 0) {
        break;
      }
    }

    commit(
      MutationTypes.SET_CURRENT_PORTAL_MEMBERS,
      sort_roles(current_members),
    );

    commit(MutationTypes.SET_IS_LOADING, false);
  },
  [ActionTypes.GET_PORTAL_MEMBERS_QUERY]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_MEMBERS, null);

    const promise = portalService.getPortalMembers(id);
    //cancellablePromise.registerPromise(ActionTypes.GET_PORTAL_MEMBERS, promise);
    return promise
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        commit(MutationTypes.SET_CURRENT_PORTAL_MEMBERS, sort_roles(result));
      })
      .catch((error) => {
        console.log('GET_PORTAL_MEMBERS', error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_PORTAL_MEMBERS_BY_STATUS](
    { commit },
    { portal_id, status },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_MEMBERS_BY_STATUS, null);

    const promise = portalService.getPortalMembersByStatus(portal_id, status);
    cancellablePromise.registerPromise(
      ActionTypes.GET_PORTAL_MEMBERS_BY_STATUS,
      promise,
    );
    return promise
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_PORTAL_MEMBERS_BY_STATUS,
            promise,
          )
        ) {
          commit(
            MutationTypes.SET_CURRENT_PORTAL_MEMBERS_BY_STATUS,
            sort_roles(result),
          );
        }
      })
      .catch((error) => {
        console.error(`GET_PORTAL_MEMBERS_BY_STATUS ${error}`);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_CURRENT_PORTAL_MEMBER_MEMOS](
    { commit },
    { portal_id, member_id },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_MEMBER_MEMOS, null);

    const promise = portalService.getCurrentPortalMemberMemos(
      portal_id,
      member_id,
    );
    cancellablePromise.registerPromise(
      ActionTypes.GET_CURRENT_PORTAL_MEMBER_MEMOS,
      promise,
    );
    return promise
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_CURRENT_PORTAL_MEMBER_MEMOS,
            promise,
          )
        ) {
          commit(
            MutationTypes.SET_CURRENT_PORTAL_MEMBER_MEMOS,
            sort_roles(result),
          );
        }
      })
      .catch((error) => {
        console.error(`GET_CURRENT_PORTAL_MEMBER_MEMOS ${error}`);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_PORTAL_ROLE_MEMBERS]({ commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    commit(MutationTypes.SET_CURRENT_PORTAL_ROLE_MEMBERS, null);

    const promise = portalService.getPortalRoleMembers(id);
    cancellablePromise.registerPromise(
      ActionTypes.GET_PORTAL_ROLE_MEMBERS,
      promise,
    );

    return promise
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (
          cancellablePromise.isPromiseLatest(
            ActionTypes.GET_PORTAL_ROLE_MEMBERS,
            promise,
          )
        ) {
          commit(
            MutationTypes.SET_CURRENT_PORTAL_ROLE_MEMBERS,
            sort_roles(result),
          );
        }
      })
      .catch((error) => {
        console.error(`GET_PORTAL_ROLE_MEMBERS ${error}`);
        commit(MutationTypes.SET_CURRENT_PORTAL_ROLE_MEMBERS, []);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.CREATE_PORTAL]({ commit }, portal) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .createPortal(portal)
      .then((result) => {
        // commit(MutationTypes.ADD_PORTAL, portal)
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          trackGAEvent('create_portal_success', {
            portal_name: portal.name,
            is_nsfw: portal.is_nsfw,
          });
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL]({ commit }, portal) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortal(portal)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_ICON]({ commit }, portalIcon) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalIcon(portalIcon)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_INFO_COVER_PHOTO](
    { commit },
    { portal_id, icon_url },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalInfoCoverPhoto(portal_id, icon_url)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_INFO_RULES]({ commit }, { portal_id, rules }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalInfoRules(portal_id, rules)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_INFO_LINKS]({ commit }, { portal_id, links }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalInfoLinks(portal_id, links)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.SET_PORTAL_PHONE_VERIFY]({ commit }, update) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .requiresPhone(update)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          commit(MutationTypes.SET_CURRENT_PORTAL, result.result[0]);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.ADD_PORTAL_ROLE]({ commit }, { portal_id, role }) {
    commit(MutationTypes.SET_IS_LOADING, true);
    trackEvent('portal_action', 'create_role');
    return portalService
      .addPortalRole(portal_id, role)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_ROLE]({ commit }, role) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalRole(role)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.DELETE_PORTAL_ROLE]({ commit }, role_id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .deletePortalRole(role_id)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.UPDATE_PORTAL_ROLES_ORDINALS](
    { commit },
    { portal_id, ordinals },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .updatePortalRolesOrdinals(portal_id, ordinals)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.ADD_PORTAL_MEMBER_ROLE](
    { commit },
    { portal_id, members, no_load },
  ) {
    trackEvent('portal_action', 'set_member_role');
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);
    const membersList = members.map(({ memberId, roleId }) => [
      memberId,
      roleId,
    ]);
    return portalService
      .addPortalMemberRole(portal_id, membersList)
      .then((result) => {
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.REMOVE_PORTAL_MEMBER_ROLE](
    { commit },
    { portal_id, members, no_load },
  ) {
    if (no_load != true) commit(MutationTypes.SET_IS_LOADING, true);
    const membersList = members.map(({ memberId, roleId }) => [
      memberId,
      roleId,
    ]);
    return portalService
      .removePortalMemberRole(portal_id, membersList)
      .then((result) => {
        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);

        if (no_load != true) commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.SET_PORTAL_MEMBER_STATUS](
    { commit },
    { portal_id, member_id, kind, reason },
  ) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .setPortalMemberStatus(portal_id, member_id, kind, reason)
      .then((result) => {
        commit(MutationTypes.SET_IS_LOADING, false);
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.NSFW_TOGGLE_PORTAL]({ dispatch, commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .nsfwTogglePortal(id)
      .then((result) => {
        // commit(MutationTypes.ADD_PORTAL, portal)
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          dispatch(ActionTypes.LIST_PORTALS, false);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.ROOT_TOGGLE_PORTAL]({ dispatch, commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .rootTogglePortal(id)
      .then((result) => {
        // commit(MutationTypes.ADD_PORTAL, portal)
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          dispatch(ActionTypes.LIST_PORTALS, false);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.REMOVE_PORTAL]({ dispatch, commit }, id) {
    commit(MutationTypes.SET_IS_LOADING, true);
    return portalService
      .removePortal(id)
      .then((result) => {
        // commit(MutationTypes.ADD_PORTAL, portal)
        commit(MutationTypes.SET_IS_LOADING, false);
        if (result?.status == 'happy') {
          dispatch(ActionTypes.LIST_PORTALS, false);
        }
        return result;
      })
      .catch((error) => {
        console.log(error);
        commit(MutationTypes.SET_IS_LOADING, false);
      });
  },
  [ActionTypes.GET_MENTION]({ commit, state }, name) {
    if (state.mentionsHash.has(name)) {
      return;
    }
    portalService.getPortal(name).then((response) => {
      if (
        response &&
        response.status === 'happy' &&
        response.result.length > 0
      ) {
        commit(MutationTypes.SET_MENTION, response.result[0]);
      }
    });
  },
  [ActionTypes.SET_MENTION]({ commit }, portal) {
    commit(MutationTypes.SET_MENTION, portal);
  },
  [ActionTypes.SET_CURRENT_PORTAL]: ({ commit }, portal) =>
    commit(MutationTypes.SET_CURRENT_PORTAL, portal),
  [ActionTypes.GET_HOTSPOTS]({ commit }, query) {
    return portalService
      .get_randomized_recommended_portals(query)
      .then((response) => {
        commit(MutationTypes.SET_HOTSPOTS, response);
        return response;
      });
  },
  [ActionTypes.GET_FEED_RECOMENDATIONS]({ commit }, query) {
    portalService.get_randomized_recommended_portals(query).then((response) => {
      commit(MutationTypes.SET_FEED_RECOMENDATIONS, response);
    });
  },
  [ActionTypes.GET_EXPLORE_RECOMENDATIONS]({ commit }, query) {
    portalService.get_randomized_recommended_portals(query).then((response) => {
      commit(MutationTypes.SET_EXPLORE_RECOMENDATIONS, response);
    });
  },
  [ActionTypes.GET_FEATURED_PORTALS]({ commit }, query) {
    portalService.get_randomized_recommended_portals(query).then((response) => {
      commit(MutationTypes.SET_FEATURED_PORTALS, response);
    });
  },
  [ActionTypes.GET_PORTAL_CHAIN_TYPE]({ commit }, portal_id) {
    return portalService.getPortalChainType(portal_id).then((response) => {
      if (response.Ok) {
        commit(MutationTypes.SET_PORTAL_CHAIN_TYPE, response.Ok);
      }
    });
  },
  [ActionTypes.ACCEPT_PORTAL_INVITE]({}, query) {
    return portalService.accept_portal_invite(query).then((response) => {
      return response;
    });
  },

  async [ActionTypes.GET_ALL_PAGINATED]({ commit }, payload) {
    try {
      const response = await portalService.search_paginated({
        ...payload.request,
        page_size:
          payload.request.page_size * (payload.pagesCount ?? BigInt(1)),
      });
      if (response.status === 'happy' && response.result.length > 0) {
        commit(MutationTypes.SET_ALL_PAGINATED, {
          result: response.result[0],
          pagesCount: payload.pagesCount,
        });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};

// mutations
const mutations = {
  [MutationTypes.SET_PORTALS](state, portals) {
    portals.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    state.all = portals;
  },
  [MutationTypes.UPDATE_PORTAL](state, portal) {
    state.all = state.all.map((item) =>
      item.id === portal.id ? portal : item,
    );
    if (state.currentPortal && state.currentPortal.id == portal.id) {
      state.currentPortal = portal;
    }
    state.hotspots = state.hotspots.map((item) =>
      item.id === portal.id ? portal : item,
    );
    state.feedRecommendations = state.feedRecommendations.map((item) =>
      item.id === portal.id ? portal : item,
    );
    state.exploreRecommendations = state.exploreRecommendations.map((item) =>
      item.id === portal.id ? portal : item,
    );
    state.featuredPortals = state.featuredPortals.map((item) =>
      item.id === portal.id ? portal : item,
    );
    state.allPortalsPaginated.items = state.allPortalsPaginated.items.map(
      (item) => (item.id === portal.id ? portal : item),
    );
  },
  [MutationTypes.SET_IS_LOADING](state, val) {
    if (val == true) state.isLoading++;
    else state.isLoading--;

    if (state.isLoading < 0) state.isLoading = 0;
  },
  [MutationTypes.SET_CURRENT_PORTAL](state, portal) {
    state.currentPortal = portal;
    if (portal) {
      state.all = state.all.map((ele) => (ele.id === portal.id ? portal : ele));
    }
  },
  [MutationTypes.SET_CURRENT_PORTAL_ROLES](state, roles) {
    state.currentPortalRoles = roles;
  },
  [MutationTypes.SET_CURRENT_PORTAL_USER_ROLES](state, roles) {
    state.currentPortalUserRoles = roles;
  },
  [MutationTypes.SET_CURRENT_PORTAL_HOVERED_USER_ROLES](state, roles) {
    state.currentPortalHoveredUserRoles = roles;
  },
  [MutationTypes.SET_CURRENT_PORTAL_USER_MEMBER](state, member) {
    state.currentPortalUserMember = member;
  },
  [MutationTypes.SET_CURRENT_PORTAL_USER_ASSIGNABLE_ROLES](state, roles) {
    state.currentPortalUserAssignableRoles = roles;
  },
  [MutationTypes.SET_CURRENT_PORTAL_OWNER_ROLES](state, roles) {
    state.currentPortalOwnerRoles = roles;
  },
  [MutationTypes.SET_CURRENT_PORTAL_MEMBERS](state, members) {
    if (members == null) state.currentPortalMembers = [];
    else state.currentPortalMembers = members;
  },

  [MutationTypes.SET_CURRENT_PORTAL_MEMBERS_BY_STATUS](state, members) {
    if (members == null) state.currentPortalMembersByStatus = [];
    else state.currentPortalMembersByStatus = members;
  },
  [MutationTypes.SET_CURRENT_PORTAL_MEMBER_MEMOS](state, memos) {
    if (memos == null) state.currentPortalMemberMemos = [];
    else state.currentPortalMemberMemos = memos;
  },
  [MutationTypes.SET_CURRENT_PORTAL_ROLE_MEMBERS](state, members) {
    if (members == null) state.currentPortalRoleMembers = [];
    else state.currentPortalRoleMembers = members;
  },
  [MutationTypes.SET_MENTION](state, portal) {
    const mentionsHash = state.mentionsHash;
    mentionsHash.set(portal.slug, portal);
  },
  [MutationTypes.SET_HOTSPOTS](state, response) {
    state.hotspots = response;
  },
  [MutationTypes.SET_FEED_RECOMENDATIONS](state, response) {
    state.feedRecommendations = response;
  },
  [MutationTypes.SET_EXPLORE_RECOMENDATIONS](state, response) {
    state.exploreRecommendations = response;
  },
  [MutationTypes.SET_FEATURED_PORTALS](state, response) {
    state.featuredPortals = response;
  },
  [MutationTypes.SET_ALL_PAGINATED](state, { result, pagesCount }) {
    if (result.page === 0n) {
      state.allPortalsPaginated.items = result.items;
      state.allPortalsPaginated.currentPage = pagesCount ?? BigInt(0n);
    } else {
      const newPage = result.items.filter(
        (item) =>
          !state.allPortalsPaginated.items.find(
            (portal) => portal.id === item.id,
          ),
      );
      state.allPortalsPaginated.items =
        state.allPortalsPaginated.items.concat(newPage);
      state.allPortalsPaginated.currentPage =
        result.page + (pagesCount ?? BigInt(0n));
    }
  },
  [MutationTypes.SET_PORTAL_CHAIN_TYPE](state, response) {
    state.portalChainType = response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
