<script setup lang="ts">
  import { useRouter } from 'vue-router';
  import { useNotificationStore } from '@/entities/notification';
  import { useUser } from '@/entities/user';
  import { USER_SETTINGS_NOTIFICATIONS } from '@/common/routeNames';

  const router = useRouter();
  const notificationStore = useNotificationStore();
  const { currentUser } = useUser();

  const redirect = () => {
    router.push({
      name: USER_SETTINGS_NOTIFICATIONS,
      params: { username: currentUser.value.username },
    });
    notificationStore.closeDrawer();
  };
</script>

<template>
  <base-button variant="custom" class="p-1" @click="redirect">
    <base-icon name="cog-outlined" size="w-4 h-4" />
  </base-button>
</template>
