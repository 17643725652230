<script lang="ts" setup>
  // TODO: fix FSD
  import { ref, onBeforeMount, computed, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import type { PortalView } from 'dfx/edge/edge.did';
  import type { ButtonSize } from '@/shared/ui/base-button';
  import { useUser } from '@/entities/user';
  import { usePortal } from '../model/composables/use-portal';
  import { usePortalDialog } from '../model/composables/use-portal-dialog';
  import {
    PORTAL_SETTINGS_PAGE_OVERVIEW,
    PHONE_VERIFICATION_REQUIRED_ERROR,
  } from '@/common';
  import { useAuth } from '@/entities/auth';

  const props = withDefaults(
    defineProps<{
      portal: PortalView;
      isPortalList?: boolean;
      classes?: string;
      size?: ButtonSize;
      joinClass?: string;
    }>(),
    {
      isPortalList: false,
      classes: '',
      size: 'small',
      hideLabel: false,
    },
  );

  const emit = defineEmits<{
    (e: 'on-joined'): void;
  }>();

  const router = useRouter();
  const { showLoginSignUpDialog } = useAuth();
  const { currentUser } = useUser();
  const {
    joinState,
    onMouseLeaveJoin,
    onMouseOverJoin,
    isMemberPortalOwner,
    togglePortalFollow,
  } = usePortal();
  const { openLeaveDialog, openVerifyPhoneDialog } = usePortalDialog();

  const isLoading = ref(false);

  const isJoinOrManageState = computed(() =>
    ['join', 'manage'].includes(joinState.value),
  );

  const baseIconName = computed(() => {
    if (joinState.value === 'manage') return 'settings';
    if (['leave', 'joined!'].includes(joinState.value)) return 'portal';
    return 'plus-circle';
  });

  const onManage = () => {
    router.push({
      name: PORTAL_SETTINGS_PAGE_OVERVIEW,
      params: {
        portal: props.portal.slug,
      },
    });
  };

  const onJoin = async () => {
    if (!currentUser.value) {
      showLoginSignUpDialog();
    } else {
      try {
        const res = await togglePortalFollow(props.portal.id);
        if (res) {
          joinState.value = 'joined!';
          emit('on-joined');
          setTimeout(() => {
            joinState.value = 'leave';
          }, 1000);
        }
      } catch (error) {
        if ((error as Error).message === PHONE_VERIFICATION_REQUIRED_ERROR) {
          openVerifyPhoneDialog();
        }
      }
    }
  };

  const onLeave = () => {
    openLeaveDialog(props.portal).then((leaved) => {
      if (leaved) {
        joinState.value = 'join';
      }
    });
  };

  const onClick = () => {
    if (joinState.value === 'manage') {
      return onManage();
    } else if (joinState.value === 'join') {
      return onJoin();
    } else if (joinState.value === 'leave') {
      return onLeave();
    }
  };

  onBeforeMount(() => {
    if (
      currentUser.value &&
      props.portal?.owner?.username &&
      isMemberPortalOwner(props.portal, currentUser.value)
    ) {
      joinState.value = 'manage';
    } else {
      joinState.value = props.portal.is_following ? 'leave' : 'join';
    }
  });

  // TODO: this is a hacky way to update the joinState when the portal is updated
  // (possible race condition somewhere where portal is updated after the button is loaded)
  watch(
    () => props.portal.is_following,
    (isFollowing) => {
      joinState.value = isFollowing ? 'leave' : 'join';
    },
  );
</script>

<template>
  <base-button
    :variant="isJoinOrManageState ? 'primary' : 'glass'"
    :rounded="isPortalList ? 'rounded-lg' : 'rounded-xl'"
    :size="size"
    :disabled="isLoading"
    :class="[classes, 'min-h-8 !gap-0', joinClass]"
    @click.prevent.stop="onClick"
    @mouseover="onMouseOverJoin"
    @mouseleave="onMouseLeaveJoin"
  >
    <base-icon
      v-if="!isPortalList"
      :name="baseIconName"
      :size="size === 'x-small' ? 'w-4 h-4' : 'w-5 h-5'"
      class="flex-none"
    />
    <slot name="label" :join-state="joinState">
      <span
        class="capitalize"
        :class="{
          'ml-2': !isPortalList,
        }"
      >
        {{ joinState }}
      </span>
    </slot>
  </base-button>
</template>
