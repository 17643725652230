<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue';
  import { TextField, FieldWrapper } from '@/shared/ui/fields';
  import { BaseDropdown } from '@/shared/ui/base-dropdown';
  import {
    ICPRowItem,
    MultichainRowItem,
    useNFTGatingSetup,
  } from '@/features/gating';
  import type { RoleView } from 'dfx/edge/edge.did';
  import type { NftGateDto } from '@/shared/api';

  const {
    currentPortalSlug,
    isNftSauronSetup,
    currentPortalRoles,
    allNftCollections,
    fetchNftGatedCollections,
    loadSauronPortalRoles,
    setupNftWatcher,
    openCreateEditDialog,
    openDeleteDialog,
  } = useNFTGatingSetup();

  const searchedNft = ref('');
  const filteredNftCollections = computed(() => {
    return allNftCollections.value.filter((nft) => {
      return nft.name.toLowerCase().includes(searchedNft.value.toLowerCase());
    });
  });

  const getRoleById = (roleId?: string): RoleView | undefined => {
    if (!roleId) {
      return undefined;
    }
    return currentPortalRoles.value?.find((role) => role.id === BigInt(roleId));
  };

  const createNftGate = () => {
    openCreateEditDialog();
  };

  const editNftGate = (item: NftGateDto) => {
    openCreateEditDialog(item);
  };

  const deleteNftGate = (item: NftGateDto) => {
    openDeleteDialog(item);
  };

  onMounted(() => {
    loadSauronPortalRoles();
    fetchNftGatedCollections(currentPortalSlug.value);
  });
</script>

<template>
  <div class="relative flex justify-between items-center">
    <div>
      <div class="font-label-medium font-medium">
        {{ $t('nftGates') }}
        <template v-if="isNftSauronSetup && allNftCollections.length">
          ({{ allNftCollections.length }})
        </template>
      </div>
      <div class="text-neutral-300 font-paragraph-small">
        {{ $t('nftGatesLegend') }}
      </div>
    </div>
    <div>
      <base-button
        v-if="isNftSauronSetup"
        variant="primary"
        size="small"
        @click="createNftGate"
      >
        {{ $t('addNewNft') }}
      </base-button>
      <base-button
        v-else
        variant="primary"
        size="small"
        @click="setupNftWatcher"
      >
        {{ $t('setupNftWatcher') }}
      </base-button>
    </div>
  </div>
  <div v-if="isNftSauronSetup" class="flex flex-col gap-6 mt-6">
    <div>
      <field-wrapper>
        <text-field
          name="search"
          v-model="searchedNft"
          label="Search"
          autocomplete="off"
        />
      </field-wrapper>
    </div>
    <div class="px-2">
      <table
        class="table-fixed w-[calc(100%+1.5rem)] border-separate -mx-3 -mt-3"
        :style="{ borderSpacing: '0.75rem 0.75rem' }"
      >
        <thead>
          <tr class="text-left">
            <th class="font-normal w-20">{{ $t('chain') }}</th>
            <th class="font-normal w-full">{{ $t('collectionName') }}</th>
            <th class="font-normal w-50">{{ $t('nftHolderRole') }}</th>
            <th class="font-normal w-40">{{ $t('status') }}</th>
            <th class="w-20" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="element in filteredNftCollections"
            :key="element.id"
            class="border-b border-gray-500"
          >
            <template v-if="'network' in element">
              <multichain-row-item
                :item="element"
                :role="getRoleById(element.roleId)"
              />
            </template>
            <template v-else>
              <i-c-p-row-item
                :item="element"
                :role="getRoleById(element.roleId)"
              />
            </template>
            <td>
              <div class="flex justify-end">
                <base-dropdown
                  placement="bottom-end"
                  custom-classes="block w-fit"
                  custom-content-classes="bg-gray-975"
                  :distance="5"
                >
                  <template #button="{ toggle }">
                    <base-button
                      variant="secondary"
                      size="small"
                      @click="toggle"
                    >
                      <base-icon name="ellipsis" size="w-4 h-4" />
                    </base-button>
                  </template>
                  <template #content="{ hide }">
                    <base-button
                      variant="custom"
                      rounded="rounded-none"
                      custom-classes="flex justify-start items-center gap-1 w-full px-5 py-3 cursor-pointer hover:bg-gray-800"
                      @click="hide(), editNftGate(element)"
                    >
                      <base-icon name="pencil" size="w-5 h-5" />
                      <span class="ml-2 capitalize">{{ $t('edit') }}</span>
                    </base-button>
                    <base-button
                      variant="custom"
                      rounded="rounded-none"
                      custom-classes="flex justify-start items-center gap-1 w-full px-5 py-3 cursor-pointer hover:bg-gray-800"
                      @click="hide(), deleteNftGate(element)"
                    >
                      <base-icon name="trash" size="w-5 h-5" />
                      <span class="ml-2 capitalize">{{ $t('delete') }}</span>
                    </base-button>
                  </template>
                </base-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
