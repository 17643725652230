import { computed } from 'vue';
import { useHead, useSeoMeta } from '@unhead/vue';
import { fetchMedia } from '@/shared/lib';
import { HTML_PREFIX, DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
import {
  getTitle,
  buildMetaTitle,
  isImageUrl,
  removeMarkdown,
  getUrls,
} from '@/utils';

import type { Ref } from 'vue';
import type { ContentView } from 'dfx/edge/edge.did';

export const useMetaPost = (post: Ref<ContentView | undefined>) => {
  const ogTitle = computed(() => (post.value ? getTitle(post.value) : ''));
  const title = computed(() =>
    ogTitle.value ? buildMetaTitle(ogTitle.value) : '',
  );
  const description = computed(() =>
    post.value ? removeMarkdown(post.value.body.replace(HTML_PREFIX, '')) : '',
  );
  const truncatedDescription = computed(() =>
    description.value.length > 150
      ? description.value.slice(0, 150) + ' ... '
      : description.value,
  );

  const ogDescription = computed(() => {
    if (!post.value) {
      return '';
    }
    return `${truncatedDescription.value} posted by ${post.value.owner.username}`;
  });

  const imageUrl = computed(() => {
    if (post.value) {
      if (isImageUrl(post.value.url)) {
        return post.value.url;
      }
      const results = getUrls(post.value.body).filter((x) => isImageUrl(x));
      if (results.length > 0) {
        return results[0];
      }
    }
    return null;
  });

  const twitterCard = computed(() =>
    imageUrl.value ? 'summary_large_image' : 'summary',
  );

  const ogImage = computed(
    () =>
      imageUrl.value ??
      fetchMedia(
        `${DSCVR_STATIC_ASSETS_CDN_URL}/common/apple-touch-icon-120x120.png`,
      ),
  );

  useHead({
    title: () => title.value,
    meta: [
      { name: 'title', content: title },
      { name: 'description', content: truncatedDescription },
    ],
  });

  useSeoMeta({
    ogDescription,
    ogType: 'website',
    ogTitle,
    ogImage,
    twitterCard,
    twitterImage: ogImage,
    twitterSite: '@DSCVR1',
  });
};
