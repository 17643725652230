<script setup lang="ts">
  import { computed, onMounted } from 'vue';
  import { compact } from 'lodash-es';
  import { useI18n } from 'vue-i18n';
  import { computedAsync } from '@vueuse/core';
  import { DSCVR_STATIC_ASSETS_CDN_URL, PORTAL_MARKETPLACE } from '@/common';
  import { config, fetchMedia } from '@/shared/lib';
  import { useToast } from '@/shared/model';
  import { usePortal } from '@/entities/portal';
  import { useWalletPairing } from '@/entities/wallets';
  import { useNFTGating } from '@/entities/nft-gate';
  import { usePairDialog } from '@/features/wallets';

  const { showToast } = useToast();
  const { currentPortal } = usePortal();
  const {
    marketplaceNftCollections,
    entrepotCollections,
    getMultichainCollectionDetails,
  } = useNFTGating();
  const { firstPairedSolanaWallet, fetchPairedWallets } = useWalletPairing();
  const { openAddWalletDialog } = usePairDialog();
  const { t } = useI18n({ useScope: 'global' });

  const COLLECTIONS_TO_SHOW = 3;

  const collectionsRemainingCount = computed(() => {
    return marketplaceNftCollections.value?.length - COLLECTIONS_TO_SHOW || 0;
  });

  const collectionsDetails = computedAsync(async () => {
    const items = marketplaceNftCollections.value.slice(0, COLLECTIONS_TO_SHOW);
    return compact(
      await Promise.all(
        items.map(async (item) => {
          if ('network' in item) {
            const response = await getMultichainCollectionDetails(item);
            // if a collection is empty we don't show it
            if (!response?.stats?.listedCount) {
              return;
            }
            if (response) {
              return {
                name: item.name,
                avatar: response.detail.image_url,
                detail: response.detail,
                multichain: true,
              };
            }
          } else {
            const entrepotCollection = entrepotCollections.value?.find(
              (collection) => collection.id === item.canisterId,
            );
            if (entrepotCollection) {
              return {
                name: item.name,
                avatar: entrepotCollection.avatar,
                multichain: false,
              };
            }
          }
        }),
      ),
    );
  });

  const checkJoined = () => {
    if (!currentPortal.value.is_following) {
      showToast({
        title: t('mustJoinPortalMessage'),
        type: 'error',
        durationSeconds: 5,
      });

      return false;
    }

    return true;
  };

  const connectWallet = async () => {
    const joined = checkJoined();
    if (joined) {
      if (!firstPairedSolanaWallet.value) {
        openAddWalletDialog();
      }
    }
  };

  onMounted(() => {
    if (
      marketplaceNftCollections.value.length &&
      config.ENABLE_MARKETPLACE_TAB
    ) {
      fetchPairedWallets();
    }
  });
</script>

<template>
  <article
    class="flex flex-col items-center justify-center px-1.25 md:px-6 py-6 gap-6 bg-gray-blue-900 mb-16 md:mb-0"
  >
    <img
      :src="
        fetchMedia(
          `${DSCVR_STATIC_ASSETS_CDN_URL}/portal/restricted-content.png`,
        )
      "
      alt="Portal restricted access"
      class="w-32 h-32"
    />
    <section class="text-center text-lg">
      <p class="font-semibold">{{ $t('portalRestrictedTitle') }}</p>
      <p>{{ $t('portalRestrictedSubtitle') }}</p>
      <p class="text-sm text-opacity-80 py-4 max-w-80 mx-auto">
        <base-icon
          class="inline text-blue-300"
          name="filled-check-circle"
          size="h-4 w-4"
        />
        {{ $t('portalRestrictedNote') }}
      </p>
    </section>

    <template v-if="config.ENABLE_MARKETPLACE_TAB">
      <section
        v-if="collectionsDetails?.length"
        class="flex items-center justify-center flex-wrap gap-2 bg-gray-975 shadow-light"
      >
        <base-button
          v-for="collection in collectionsDetails"
          :key="collection.name"
          variant="custom"
          custom-classes="flex items-center justify-center gap-4 p-4 w-full md:w-auto"
          :to="
            currentPortal.is_following
              ? {
                  name: PORTAL_MARKETPLACE,
                  params: {
                    portal: currentPortal.slug,
                  },
                  query: {
                    name: collection.name,
                  },
                }
              : undefined
          "
          @click="checkJoined"
        >
          <img
            :src="collection.avatar"
            :alt="collection.name"
            class="w-12.5 h-12.5 rounded-xl"
          />
          <span class="whitespace-nowrap flex-1 text-left">{{
            collection.name
          }}</span>
        </base-button>

        <base-button
          v-if="collectionsRemainingCount"
          variant="custom"
          size="x-small"
          class="text-indigo-300 font-semibold m-4 bg-white bg-opacity-5 hover:bg-opacity-8"
          rounded="rounded-full"
          :to="
            currentPortal.is_following
              ? {
                  name: PORTAL_MARKETPLACE,
                  params: {
                    portal: currentPortal.slug,
                  },
                }
              : undefined
          "
          @click="checkJoined"
        >
          <base-icon name="plus" size="w-2 h-2" />
          <span class="capitalize whitespace-nowrap">
            {{ collectionsRemainingCount }} {{ $t('more') }}
          </span>
        </base-button>
      </section>

      <section v-if="collectionsDetails?.length" class="flex gap-3">
        <base-button
          v-if="!firstPairedSolanaWallet"
          variant="secondary"
          size="medium"
          @click="connectWallet"
        >
          <span>{{ $t('connectWallet') }}</span>
        </base-button>
        <base-button
          variant="primary"
          size="medium"
          :to="
            currentPortal.is_following
              ? {
                  name: PORTAL_MARKETPLACE,
                  params: {
                    portal: currentPortal.slug,
                  },
                }
              : undefined
          "
          @click="checkJoined"
        >
          <span>{{ $t('getNFT') }}</span>
        </base-button>
      </section>
    </template>
  </article>
</template>
