<template>
  <div v-if="currentPortal" class="relative">
    <div class="flex flex-col justify-between md:flex-row">
      <div>
        <div class="my-1 text-lg font-bold text-white">
          Members ({{ filteredMembers ? filteredMembers.length : 0 }})
        </div>
        <div class="text-sm text-gray-400">
          You can see all of your members here and assign them roles.
        </div>
      </div>

      <button
        class="px-4 py-3 mt-2 text-sm font-medium text-white rounded-lg btn-tertiary"
        @click="csvExport(filteredMembersIDs)"
      >
        Get Airdrop Principals
      </button>
    </div>

    <error-display
      v-if="errors.length"
      class="mt-3"
      :errors="errors"
      :error-message="errorMessage"
      :local-errors="localErrors"
    />

    <div class="flex flex-col gap-3 mt-6 mb-2 md:flex-row">
      <div class="flex items-center w-full bg-gray-900 rounded-lg flex-nowrap">
        <base-icon
          name="search"
          size="w-5 h-5"
          class="ml-4 text-gray-400 cursor-pointer"
        />
        <input
          v-model="searchedMember"
          type="text"
          class="flex-grow block h-full text-sm font-medium text-white bg-gray-900 border-transparent border-none rounded-lg focus:ring-0"
          placeholder="Search Members..."
        />
      </div>

      <roles-dropdown
        :roles="filteredRoles"
        :selected-roles="selectedRoles"
        @role-click="selectedRolesChange"
      />

      <button
        class="px-4 py-2.5 text-sm font-medium text-white btn-tertiary rounded-lg"
      >
        Filters
      </button>
    </div>

    <div class="my-4 text-white">
      <p v-show="!filteredMembers" class="p-3 mt-3 text-center text-small">
        No added members
      </p>
      <div
        ref="membersPanel"
        class="px-2 my-5 overflow-y-auto thin-scrollbar h-80"
      >
        <member-row
          v-for="(member, index) in lazyfilteredMembers"
          :key="index"
          :member="member"
          @kick-member="kickMember(member)"
          @ban-member="banMember(member)"
          @show-toast="showToastForSeconds"
        />
      </div>
    </div>
    <div
      class="fixed p-2 text-lg text-white transition-all duration-500 transform -translate-x-1/2 bg-blue-600 rounded-lg bg-opacity-80 bottom-5 left-1/2"
      :class="{ 'opacity-0': !shouldShowToast, 'opacity-100': shouldShowToast }"
    >
      Member updated successfully!
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import ErrorDisplay from './ErrorDisplay.vue';
  import { ActionTypes } from '../store/action-types';
  import { numberToColorHex } from '../utils/numberColors';
  import MemberRow from './portalSettings/MemberRow.vue';
  import RolesDropdown from './portalSettings/RolesDropdown.vue';
  import { ROLE_KIND_FIELD_EVERYONE } from '@/common';
  import { usePortalDialog } from '@/entities/portal';

  export default {
    name: 'portal-settings-members',
    components: {
      ErrorDisplay,
      MemberRow,
      RolesDropdown,
    },
    props: {
      portal: {
        type: Object,
        default() {
          return null;
        },
      },
      roles: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    setup() {
      const { openKickBanDialog, closeDialog } = usePortalDialog();

      return { openKickBanDialog, closeDialog };
    },
    data() {
      return {
        newMemberRoles: [],
        removeMemberRoles: [],
        searchedMember: null,
        localErrors: [],
        errorMessage: '',
        errors: [],
        shouldShowToast: false,
        hideToastTimeout: null,
        selectedMember: null,
        selectedRoles: [],
        showUserOptionsMenu: false,
        lazyLoadedResultsCount: 20,
        lazyLoadedResultsStep: 20,
        lazyLoadScrollBottomOffsetInPixels: 50, // how much before the complete scroll to the bottom should we show the next section
      };
    },
    computed: {
      ...mapGetters({
        currentPortal: 'portals/currentPortal',
        currentPortalMembers: 'portals/currentPortalMembers',
        currentPortalUserAssignableRoles:
          'portals/currentPortalUserAssignableRoles',
      }),
      filteredRoles() {
        return this.roles.filter(
          (role) => !(ROLE_KIND_FIELD_EVERYONE in role.kind),
        );
      },
      filteredMembers() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedMember = null;
        if (this.currentPortalMembers == null) return [];
        return this.currentPortalMembers.filter(
          (member) =>
            (!member.status ||
              !Object.prototype.hasOwnProperty.call(member.status, 'Banned')) &&
            (this.selectedRoles.length === 0 ||
              member.roles.findIndex(
                (memberRole) =>
                  !!this.selectedRoles.find(
                    (selectedRole) => memberRole.id === selectedRole.id,
                  ),
              ) != -1) &&
            (!this.searchedMember ||
              member.user.username
                .toLowerCase()
                .includes(this.searchedMember.toLowerCase())),
        );
      },
      lazyfilteredMembers() {
        return this.filteredMembers.slice(0, this.lazyLoadedResultsCount);
      },
      filteredMembersIDs() {
        return this.filteredMembers.map((member) => ({
          id: member.user.id.toString(),
        }));
      },
    },
    async activated() {
      await this.getMembers();
    },
    async mounted() {
      await this.getMembers();
      await this.$store.dispatch(
        `portals/${ActionTypes.GET_PORTAL_USER_ASSIGNABLE_ROLES}`,
        {
          portal_id: this.portal.id,
        },
      );
      this.selectedRoles = this.filteredRoles;

      this.lazyLoadedResultsCount = this.lazyLoadedResultsStep;
      if (this.$refs.membersPanel) {
        this.$refs.membersPanel.addEventListener('scroll', this.onScroll);
      }
    },
    unmounted() {
      if (this.$refs.membersPanel) {
        this.$refs.membersPanel.removeEventListener('scroll', this.onScroll);
      }
    },
    methods: {
      kickMember(member) {
        this.openKickBanDialog((reason) =>
          this.submitKickModal(member, reason),
        );
      },
      banMember(member) {
        this.openKickBanDialog(
          (reason) => this.submitBankModal(member, reason),
          true,
        );
      },
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        const bottomOfWindow =
          scrollHeight - clientHeight - scrollTop <
          this.lazyLoadScrollBottomOffsetInPixels;
        if (bottomOfWindow) {
          if (this.lazyLoadedResultsCount < this.filteredMembers.length)
            this.lazyLoadedResultsCount = Math.min(
              this.filteredMembers.length,
              this.lazyLoadedResultsCount + this.lazyLoadedResultsStep,
            );
        }
      },
      csvExport(arrData) {
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += [
          Object.keys(arrData[0]).join(';'),
          ...arrData.map((item) => Object.values(item).join(';')),
        ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '');

        const data = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', 'export.csv');
        link.click();
      },
      submitBankModal(member, reason) {
        this.$store
          .dispatch(`portals/${ActionTypes.SET_PORTAL_MEMBER_STATUS}`, {
            portal_id: this.portal.id,
            member_id: member.id,
            kind: { Banned: null },
            reason,
          })
          .then(() => {
            // refresh members
            this.$store.dispatch(
              `portals/${ActionTypes.GET_PORTAL_MEMBERS}`,
              this.portal.id,
            );
          })
          .finally(() => {
            this.closeDialog();
          });
      },
      numberToHex(color) {
        return numberToColorHex(color);
      },
      submitKickModal(member, reason) {
        this.$store
          .dispatch(`portals/${ActionTypes.SET_PORTAL_MEMBER_STATUS}`, {
            portal_id: this.portal.id,
            member_id: member.id,
            kind: { Kicked: null },
            reason,
          })
          .then(() => {
            // refresh members
            this.$store.dispatch(
              `portals/${ActionTypes.GET_PORTAL_MEMBERS}`,
              this.portal.id,
            );
          })
          .finally(() => {
            this.closeDialog();
          });
      },
      getAllmembers() {
        this.$store.dispatch(
          `portals/${ActionTypes.GET_PORTAL_MEMBERS}`,
          this.portal.id,
        );
      },
      getMembers() {
        this.$store.dispatch(
          `portals/${ActionTypes.GET_PORTAL_MEMBERS}`,
          this.portal.id,
        );
      },
      selectedRolesChange(roles) {
        this.selectedRoles = roles;
      },
    },
  };
</script>
