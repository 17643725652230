<script setup lang="ts">
  import { computed } from 'vue';
  import { liveRoomsModel } from '@/entities/live';
  import { useCreateRoomDialog } from '..';
  import { usePortal } from '@/entities/portal';

  const { liveRoomsCount } = liveRoomsModel.use();
  const { isCurrentUserPortalManager, currentPortal } = usePortal();
  const { openCreateRoomDialog } = useCreateRoomDialog();

  interface CreateRoomButtonProps {
    portalSlug: string;
  }

  const props = withDefaults(defineProps<CreateRoomButtonProps>(), {
    portalSlug: '',
  });

  const isCurrentPortal = computed(() => {
    return props.portalSlug === currentPortal.value?.slug;
  });

  const noRoomsAreLive = computed(() => {
    return liveRoomsCount.value === 0;
  });

  const isVisible = computed(() => {
    return (
      isCurrentPortal.value &&
      isCurrentUserPortalManager.value &&
      noRoomsAreLive.value
    );
  });

  defineExpose({
    isVisible: isVisible,
  });
</script>
<template>
  <div v-if="isVisible" class="w-full">
    <base-button
      size="small"
      variant="secondary"
      additional-classes="w-full"
      @click="openCreateRoomDialog"
    >
      <base-icon name="sound" size="w-5 h-5" class="mr-1" />
      <span>Live</span>
    </base-button>
  </div>
</template>
