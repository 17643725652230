import { computed, onMounted, type MaybeRef, toValue } from 'vue';
import { usePortal } from '../../model/composables/use-portal';
import type { PortalInfoView, PortalView } from 'dfx/edge/edge.did';

export const useStoredPortal = (
  portal: MaybeRef<PortalView | PortalInfoView>,
) => {
  const { allPortalsHash, loadPortalBySlug } = usePortal();

  const storedPortal = computed(() => {
    const portalValue = toValue(portal);
    return allPortalsHash.value.get(portalValue.id);
  });

  onMounted(() => {
    if (!storedPortal.value) {
      const portalValue = toValue(portal);
      loadPortalBySlug(portalValue.slug);
    }
  });

  return {
    storedPortal,
  };
};
