<script setup lang="ts">
  import { USER_SETTINGS_PORTALS_FAVORITES } from '@/common';
  import { FeaturedPortals } from '@/widgets/featured-portals';
  import { RecommendedPortals } from '.';
  import { useUser } from '@/entities/user';
  import { usePortal } from '@/entities/portal';
  import { EmptyFavoritePortals } from '.';

  const { currentUser } = useUser();
  const { favoritePortals } = usePortal();
</script>

<template>
  <article>
    <template v-if="favoritePortals.length > 0">
      <header
        class="group text-gray-400 mb-3 ml-2 pr-5"
        :class="{
          'hover:text-white': currentUser,
        }"
      >
        <base-button
          variant="link"
          :to="
            currentUser
              ? {
                  name: USER_SETTINGS_PORTALS_FAVORITES,
                  params: { username: currentUser.username },
                }
              : undefined
          "
          :class="{
            'cursor-default': !currentUser,
          }"
          class="flex justify-between items-center"
        >
          <div class="flex gap-2 items-center">
            <base-icon name="outline-portal" size="w-5 h-5" />
            <span>{{ $t('portals') }}</span>
          </div>
          <base-icon
            name="vuesax-linear-edit"
            class="invisible"
            size="w-4 h-4"
            :class="{
              'group-hover:visible': currentUser,
            }"
          />
        </base-button>
      </header>
      <section class="portal-list flex flex-col gap-2">
        <featured-portals />
      </section>
      <section v-if="favoritePortals.length < 5" class="mt-4">
        <recommended-portals />
      </section>
    </template>

    <template v-else>
      <header class="text-gray-400 mb-6 mr-6 pt-1 pl-3">
        <empty-favorite-portals />
      </header>
      <section>
        <recommended-portals />
      </section>
    </template>
  </article>
</template>
