<script setup lang="ts">
  import { computed, onActivated, onMounted, ref } from 'vue';
  import { useRoute, type RouteLocationRaw } from 'vue-router';
  import { useBreakpoint } from '@/shared/model';
  import { useI18n } from 'vue-i18n';
  import {
    USER_SETTINGS_PORTALS,
    USER_SETTINGS_PORTALS_FAVORITES,
    USER_SETTINGS_PORTALS_MANAGED,
  } from '@/common';
  import { useUser } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import { isPortalManager, usePortal } from '@/entities/portal';
  import {
    SearchForm,
    FavoritePortalsManagement,
    JoinedPortalList,
  } from './components';
  import type { PortalView, UserView } from 'dfx/edge/edge.did';

  type TabType = 'joined' | 'favorites' | 'managed';

  interface TabItem {
    key: TabType;
    label: string;
    route: RouteLocationRaw;
  }

  const props = defineProps<{
    username: string;
    user: UserView;
  }>();

  const route = useRoute();
  const { t } = useI18n({ useScope: 'global' });
  const { isSmallerThanSm } = useBreakpoint();
  const { currentUser } = useUser();
  const { contentPreset } = useChainBehavior();
  const {
    joinedNonSelfPortals,
    favoritePortals,
    loadJoinedAndFavoritePortals,
    isMemberPortalOwner,
  } = usePortal();

  const isLoading = ref(false);
  const selectedTab = ref<TabType>('joined');
  const searchFilter = ref('');

  const ownedManagedPortals = computed(() => {
    return joinedNonSelfPortals.value.filter(
      (portal) =>
        isMemberPortalOwner(portal, currentUser.value) ||
        isPortalManager(portal),
    );
  });

  const filteredJoinedPortals = computed(() =>
    joinedNonSelfPortals.value.filter((portal) =>
      isMatch(portal, searchFilter.value),
    ),
  );

  const filteredFavoritePortals = computed(() =>
    favoritePortals.value.filter((portal) =>
      isMatch(portal, searchFilter.value),
    ),
  );

  const filteredManagedPortals = computed(() =>
    ownedManagedPortals.value.filter((portal) =>
      isMatch(portal, searchFilter.value),
    ),
  );

  const filteredPortals = computed(() => {
    if (selectedTab.value === 'managed') {
      return filteredManagedPortals.value;
    }
    if (selectedTab.value === 'favorites') {
      return filteredFavoritePortals.value;
    }
    return filteredJoinedPortals.value;
  });

  const tabs = computed<TabItem[]>(() => {
    return [
      {
        key: 'joined',
        label: `${joinedNonSelfPortals.value.length} ${t('joined')}`,
        route: {
          name: USER_SETTINGS_PORTALS,
          params: {
            username: props.username,
          },
        },
      },
      {
        key: 'favorites',
        label: `${favoritePortals.value.length} ${t('favorites')}`,
        route: {
          name: USER_SETTINGS_PORTALS_FAVORITES,
          params: {
            username: props.username,
          },
        },
      },
      {
        key: 'managed',
        label: `${ownedManagedPortals.value.length} ${t('managed')}`,
        route: {
          name: USER_SETTINGS_PORTALS_MANAGED,
          params: {
            username: props.username,
          },
        },
      },
    ];
  });

  const isMatch = (portal: PortalView, search: string) => {
    return (
      !search ||
      portal.name.toLowerCase().includes(search.toLowerCase()) ||
      portal.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  const loadSelectedTabByRoute = () => {
    if (route.name === USER_SETTINGS_PORTALS) {
      selectedTab.value = 'joined';
    } else if (route.name === USER_SETTINGS_PORTALS_FAVORITES) {
      selectedTab.value = 'favorites';
    } else if (route.name === USER_SETTINGS_PORTALS_MANAGED) {
      selectedTab.value = 'managed';
    }
  };

  onMounted(async () => {
    loadSelectedTabByRoute();
    isLoading.value = true;
    await loadJoinedAndFavoritePortals(contentPreset.value);
    isLoading.value = false;
  });

  onActivated(() => {
    loadSelectedTabByRoute();
  });
</script>

<template>
  <div class="flex justify-between mb-10">
    <span class="text-2xl font-bold">{{ $t('portals') }}</span>
    <search-form v-if="isSmallerThanSm" v-model="searchFilter" />
  </div>

  <div class="flex flex-col gap-y-4">
    <div class="flex justify-between">
      <div class="flex gap-4 text-gray-400 text-sm">
        <base-button
          v-for="tab in tabs"
          :key="tab.key"
          variant="custom"
          rounded="rounded-none"
          class="font-medium py-2.5 border-b-2 border-transparent w-fit min-w-24 hover:text-white transition-all duration-300 capitalize cursor-pointer"
          :class="{ 'text-white border-white': selectedTab === tab.key }"
          :to="tab.route"
          @click="selectedTab = tab.key"
        >
          {{ tab.label }}
        </base-button>
      </div>
      <search-form v-if="!isSmallerThanSm" v-model="searchFilter" />
    </div>

    <div v-if="isLoading" class="spinner-big" />
    <div v-else class="flex flex-col gap-4">
      <section v-if="filteredPortals?.length" class="mt-10">
        <joined-portal-list
          v-if="selectedTab !== 'favorites'"
          :portals="filteredPortals"
        />
        <favorite-portals-management
          v-else
          :portals="filteredPortals"
          :filtered="searchFilter.length > 0"
        />
      </section>
    </div>
  </div>
</template>
