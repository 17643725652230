import { HTML_PREFIX } from '@/common';
import storage from '@/services/storage';
import { type Ref, computed } from 'vue';
import {
  userMentionRegExp,
  lazyImageRegExp,
  imageRegExp,
  iframeRegExp,
  videoRegExp,
  previewRegExp,
  streakPreviewRegExp,
  framePreviewRegExp,
  liteYoutubeRegExp,
} from '@/utils';

/**
 *
 * @param body
 */
function hasHTMLPrefix(body: string): boolean {
  return body.substring(0, 5) === HTML_PREFIX;
}

/**
 *
 * @param body
 */
function stripHTMLPrefix(body: string) {
  return body.replace(new RegExp(HTML_PREFIX, 'g'), '');
}

function isHtmlEmpty(body: string) {
  const textAtStartRegExp = /^\s*(?<plainText>[^<>\s]+?)/;
  const textBetweenTagsRegExp =
    />\s*(?<plainText>[^<>]+?)\s*<\/(?<tag>[a-zA-Z0-9-]+)>/;
  const textAtEndRegExp = />(?<plainText>[^<>\s]+?)\s*$/;

  return !(
    body.match(textAtStartRegExp)?.groups?.plainText?.trim() ||
    body.match(textBetweenTagsRegExp)?.groups?.plainText?.trim() ||
    body.match(textAtEndRegExp)?.groups?.plainText?.trim() ||
    body.match(userMentionRegExp) ||
    body.match(lazyImageRegExp) ||
    body.match(previewRegExp) ||
    body.match(iframeRegExp) ||
    body.match(imageRegExp) ||
    body.match(videoRegExp) ||
    body.match(streakPreviewRegExp) ||
    body.match(framePreviewRegExp) ||
    body.match(liteYoutubeRegExp)
  );
}

/**
 *
 * @param inputBody
 */
export function useFormatHTMLContent(inputBody?: Ref<string>) {
  /**
   *
   * @param body
   */
  function storeMediaSources(body: string): Promise<string> {
    const imageMatchRegex =
      /<img loading="lazy" v-lazy="{ src: '(?<data>data:image\/[a-z]+;base64,[^']*)'[^}]+}"[^>]*\/>/g;
    const videoMatchRegex =
      /<video.*?data-src="(?<data>data:[a-z]*\/[a-z0-9]+;base64,[^"]*)"[^>]*><\/video>/g;

    const matches = [
      ...body.matchAll(imageMatchRegex),
      ...body.matchAll(videoMatchRegex),
    ];

    return Promise.all(
      matches.map((match) =>
        storage.uploadBase64(match.groups?.data).then((url: string) => {
          const result = match.groups?.data.startsWith('data:image')
            ? `<img src="${url}" alt />`
            : `<video src="${url}" controls></video>`;
          body = body.replace(match[0], result);
        }),
      ),
    ).then(() => body);
  }

  const isHTML = computed(() =>
    inputBody ? hasHTMLPrefix(inputBody.value) : false,
  );
  const computedBody = computed(() =>
    inputBody ? stripHTMLPrefix(inputBody.value) : '',
  );

  return {
    storeMediaSources,
    isHTML,
    computedBody,
    isHtmlEmpty,
  };
}
