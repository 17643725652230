import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
  FINISHED_ONBOARDING_STORAGE_KEY,
  ONBOARDING_STEP_STORAGE_KEY,
} from '@/common/constants';
import { ActionTypes } from '@/store/action-types';
import { MutationTypes } from '@/store/mutation-types';
import type { RootState } from './types';

// initial state
const state = (): RootState => ({
  showOnboardingModal: !localStorage.getItem(FINISHED_ONBOARDING_STORAGE_KEY),
  currentOnboardingStep: localStorage.getItem(ONBOARDING_STEP_STORAGE_KEY)
    ? parseInt(localStorage.getItem(ONBOARDING_STEP_STORAGE_KEY)!, 10) % 7
    : 0,
  showReportModal: false,
  currentReportContentId: 0,
  currentInfoPanel: null,
  toasts: [],
  feedRouteBackup: null,
});

// getters
const getters: GetterTree<RootState, unknown> = {
  isLoading: (_state, _getters, _rootState, rootGetters) =>
    rootGetters['reports/isLoading'] ||
    rootGetters['posts/isLoading'] ||
    rootGetters['auth/isLoading'] ||
    rootGetters['users/isLoading'] ||
    rootGetters['portals/isLoading'] ||
    rootGetters['gating/isLoading'] ||
    rootGetters['portalHunt/isLoading'] ||
    rootGetters['nfts/isLoading'],
  showOnboardingModal: (state) => state.showOnboardingModal,
  showReportModal: (state) => state.showReportModal,
  currentReportContentId: (state) => state.currentReportContentId,
  currentInfoPanel: (state) => state.currentInfoPanel,
  currentOnboardingStep: (state) => state.currentOnboardingStep,
  toasts: (state) => state.toasts,
  feedRouteBackup: (state) => state.feedRouteBackup,
};

// actions
const actions: ActionTree<RootState, unknown> = {
  [ActionTypes.SHOW_REPORT_MODAL]({ commit }, val) {
    commit(MutationTypes.SET_REPORT_MODAL, val);
  },
  [ActionTypes.CLOSE_ONBOARDING_MODAL]({ commit }, { finished }) {
    if (finished) {
      localStorage.setItem(FINISHED_ONBOARDING_STORAGE_KEY, 'true');
      localStorage.setItem(ONBOARDING_STEP_STORAGE_KEY, '0');
    }
    commit(MutationTypes.SET_SHOW_ONBOARDING_MODAL, false);
  },
  [ActionTypes.UPDATE_ONBOARDING_STEP]({ commit }, val) {
    localStorage.setItem(ONBOARDING_STEP_STORAGE_KEY, val);
    commit(MutationTypes.SET_ONBOARDING_STEP, val);
  },
  [ActionTypes.ADD_TOAST]: ({ commit }, toast) =>
    commit(MutationTypes.ADD_TOAST, toast),
  [ActionTypes.REMOVE_TOAST]: ({ commit }, toast) => {
    commit(MutationTypes.REMOVE_TOAST, toast);
  },
  [ActionTypes.SET_FEED_ROUTE_BACKUP]: ({ commit }, val) =>
    commit(MutationTypes.SET_FEED_ROUTE_BACKUP, val),
};

// mutations
const mutations: MutationTree<RootState> = {
  [MutationTypes.SET_REPORT_MODAL](state, val) {
    state.showReportModal = val.value;
    state.currentReportContentId = val.contentId;
  },
  [MutationTypes.SET_CURRENT_INFO_PANEL](state, val) {
    state.currentInfoPanel = val;
  },
  [MutationTypes.SET_SHOW_ONBOARDING_MODAL](state, val) {
    state.showOnboardingModal = val;
  },
  [MutationTypes.SET_ONBOARDING_STEP](state, val) {
    state.currentOnboardingStep = val;
  },
  [MutationTypes.ADD_TOAST](state, toast) {
    state.toasts.push(toast);
  },
  [MutationTypes.REMOVE_TOAST](state, toast) {
    state.toasts = state.toasts.filter((t) => t.title !== toast.title);
  },
  [MutationTypes.SET_FEED_ROUTE_BACKUP](state, val) {
    state.feedRouteBackup = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
