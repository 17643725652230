<script setup lang="ts">
  import { computed } from 'vue';
  import { convertLamportToSol, createTensorNftUrl } from '@/entities/token';
  import TensorLogo from '@/features/marketplace/ui/TensorLogo.vue';
  import SolanaNftInfo from '@/features/marketplace/ui/SolanaNftInfo.vue';
  import TensorBuyButton from './TensorBuyButton.vue';
  import OfferButton from '@/features/marketplace/ui/OfferButton.vue';
  import { SizeCapture } from '@/entities/content';
  import type { TensorNft } from '@/shared/api/sol-api/tensor/collection';
  import { fetchMedia } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';

  const props = defineProps<{
    nft: TensorNft;
  }>();

  const tensorShareUrl = computed(() => createTensorNftUrl(props.nft.mint));

  const collectionName = computed(() => props.nft.name.split('#')[0].trim());
</script>

<template>
  <div
    v-if="nft"
    class="flex flex-col bg-[#10141c] border border-gray-820 rounded-xl px-8 md:px-10 lg:px-20 py-8 nft-embed"
  >
    <div class="flex justify-between items-center">
      <tensor-logo />
      <div class="flex items-center gap-x-4">
        <offer-button
          :share-url="tensorShareUrl"
          :is-disabled="!nft.listing?.price"
        />
        <tensor-buy-button :nft="nft" />
      </div>
    </div>
    <a :href="tensorShareUrl" target="_blank" class="py-2">
      <size-capture :id="nft.imageUri" type="nft" v-slot="{ contentReady }">
        <img
          v-lazy="{
            src: nft.imageUri,
            error: fetchMedia(
              `${DSCVR_STATIC_ASSETS_CDN_URL}/solana/missing-nft.jpg`,
            ),
          }"
          class="w-full object-cover rounded-2xl aspect-square"
          :alt="`${nft.name} - ${collectionName}`"
          @load="contentReady"
          @error="contentReady"
        />
      </size-capture>
    </a>
    <solana-nft-info
      :nft-collection-name="collectionName"
      :nft-name="nft.name"
      :nft-rarity="nft.rarityRank"
      :last-sale-price="nft.lastSale?.price"
      :nft-price="convertLamportToSol(BigInt(nft.listing?.price || 0))"
      :nft-share-url="tensorShareUrl"
    />
  </div>
</template>

<style scoped lang="scss">
  .dynamic-content .nft-embed img {
    margin: 1.5rem 0 !important;
    max-height: 60rem !important;
  }

  .slide-item .nft-embed img {
    max-height: 21rem !important;

    @media (max-width: 1023px) {
      max-height: 10rem !important;
    }

    @media (max-width: 642px) {
      max-height: 7.5rem !important;
    }
  }
</style>
