import type { AxiosPromise } from 'axios';
import { apiInstance } from './base';
import type { LiveRoom, ResponseObject } from './models';

const BASE_URL = '/live/rooms';

export const connect = (roomId: string) => {
  return apiInstance.post(`${BASE_URL}/${roomId}/connect`);
};

export interface CreateRoomDto {
  name: string;
  description: string;
  portalSlug?: string;
  startNow: boolean;
  isRecordingEnabled: boolean;
}

export const createRoom = (
  createRoomDto: CreateRoomDto,
): AxiosPromise<ResponseObject<LiveRoom>> => {
  return apiInstance.post(`${BASE_URL}`, createRoomDto);
};

export interface GetRoomsQuery {
  portalSlug?: string;
  enabled: boolean;
}

export const getRooms = (
  query: GetRoomsQuery,
): AxiosPromise<ResponseObject<LiveRoom[]>> => {
  return apiInstance.get(`${BASE_URL}`, { params: query });
};

export const getRoom = (
  roomId: string,
): AxiosPromise<ResponseObject<LiveRoom>> => {
  return apiInstance.get(`${BASE_URL}/${roomId}`);
};

export const joinRoom = (
  roomId: string,
): AxiosPromise<ResponseObject<LiveRoom>> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/join`);
};

export const leaveRoom = (
  roomId: string,
): AxiosPromise<ResponseObject<LiveRoom>> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/leave`);
};

export const startRoom = (
  roomId: string,
): AxiosPromise<ResponseObject<LiveRoom>> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/start`);
};

export const endRoom = (roomId: string): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/end`);
};

export const changePeerRole = (
  roomId: string,
  peerId: string,
  role: string,
): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/peers/${peerId}/change-role`, {
    role,
  });
};

export const banPeer = (roomId: string, peerId: string): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/peers/${peerId}/ban`);
};

export const startRecording = (roomId: string): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/start-recording`);
};

export const endRecording = (roomId: string): AxiosPromise<void> => {
  return apiInstance.post(`${BASE_URL}/${roomId}/end-recording`);
};
