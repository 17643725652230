<script lang="ts" setup>
  import { ref, computed } from 'vue';
  import { fetchMedia, getCurrentDatePlus12Hours } from '@/shared/lib';
  import { DSCVR_STATIC_ASSETS_CDN_URL } from '@/common';
  import { useI18n } from 'vue-i18n';
  import { useTippy } from 'vue-tippy';
  import { useStreak, useStreakStore } from '@/entities/streak';
  import { storeToRefs } from 'pinia';
  import { OnboardingStep, useBreakpoint } from '@/shared/model';
  import { useGtm } from '@gtm-support/vue-gtm';
  import { useOnboardingStateMachine } from '../../model/composables/use-onboarding-state-machine';
  import { useSetClaimedOnboardingStreakMutation } from '../../api/use-set-claimed-onboarding-streak.mutation';
  import { config } from '@/shared/lib';

  const { t } = useI18n({
    useScope: 'global',
  });
  useGtm()?.trackEvent({ event: 'First_Streak_Step_6' });

  const { showDailyStreakDialog } = useStreak();
  const { isSmallerThanMd } = useBreakpoint();
  const { states } = useOnboardingStateMachine();
  const { mutateAsync: setClaimedOnboardingStreakMutation } =
    useSetClaimedOnboardingStreakMutation();

  const streakButton = ref<HTMLElement>();

  const streakStore = useStreakStore();
  const { activeStreakButtonPosition } = storeToRefs(streakStore);

  const buttonOffsetTop = computed(() =>
    // on mobile there is no margin
    // 72 === parent's layer margin-top in pixels
    isSmallerThanMd.value ? 0 : 72,
  );

  const arrowOffsetTop = computed(() =>
    // on mobile there is no margin
    // 44 === streak button height in pixels
    // 42 === tooltip height in pixels
    // 30 === offset from tooltip to arrow in pixels
    // on desktop, 72 === calibrating according to the tooltip height + offset from tooltip to arrow
    isSmallerThanMd.value ? 44 + 42 + 30 : 72,
  );

  useTippy(streakButton, {
    content: t('onboarding.claim.tooltipContent'),
    placement: 'bottom',
    theme: 'blue',
    maxWidth: 190,
    showOnCreate: true,
    appendTo: 'parent',
    hideOnClick: 'toggle',
  });

  const onClickStreakButton = async () => {
    if (config.ENABLE_ONBOARDING_LOOTBOX) {
      await setClaimedOnboardingStreakMutation(getCurrentDatePlus12Hours());
      states.value[OnboardingStep.CLAIM_STREAK].on('continue');
    } else {
      states.value[OnboardingStep.DONE].on('continue');
    }
    showDailyStreakDialog();
  };
</script>

<template>
  <div
    ref="streakButton"
    class="absolute w-8.5 h-11"
    :style="{
      top: `${activeStreakButtonPosition.top - buttonOffsetTop}px`,
      left: `${activeStreakButtonPosition.left}px`,
    }"
  >
    <base-button
      variant="custom"
      custom-classes="size-full"
      @click="onClickStreakButton"
    />
  </div>
  <img
    :src="fetchMedia(`${DSCVR_STATIC_ASSETS_CDN_URL}/onboarding/arrow-up.gif`)"
    class="absolute w-[290px] h-auto"
    :style="{
      top: `${activeStreakButtonPosition.top + arrowOffsetTop}px`,
      // 290 === arrow width in pixels
      left: `${activeStreakButtonPosition.left - 290}px`,
    }"
  />
</template>
