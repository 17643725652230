<script setup lang="ts">
  import { computed, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import type { RoleView } from 'dfx/edge/edge.did';
  import { RoleDisplay } from '@/entities/role';
  import { useNFTGatingSetup, EntrepotItem } from '@/features/gating';
  import type { EntrepotCollectionItem, ICPGateDto } from '@/shared/api';

  const props = defineProps<{
    item: ICPGateDto;
    role?: RoleView;
  }>();

  const { NFT_TYPE_EXT, entrepotCollections, fetchEntrepotCollections } =
    useNFTGatingSetup();
  const { t } = useI18n({ useScope: 'global' });

  const entrepotCollectionItem = computed<EntrepotCollectionItem | undefined>(
    () =>
      props.item.type === NFT_TYPE_EXT
        ? entrepotCollections.value.find(
            (collection) => collection.id === props.item.canisterId,
          )
        : undefined,
  );

  const statusTextFromShouldSyncRegistry = (shouldSyncRegistry?: boolean) => {
    return shouldSyncRegistry ? t('active') : t('verifyingAtDSCVR');
  };

  onMounted(() => {
    fetchEntrepotCollections();
  });
</script>

<template>
  <td>
    <div class="flex justify-start">
      <base-icon name="chain-icp" size="w-5 h-5" />
    </div>
  </td>
  <td>
    <div class="flex gap-4">
      <entrepot-item
        v-if="entrepotCollectionItem"
        :item="entrepotCollectionItem"
        class="text-gray-400 flex-1"
      />
      <span v-else class="text-gray-400 ml-10">{{ item.name }}</span>
    </div>
  </td>
  <td>
    <role-display v-if="role" :role="role" />
  </td>
  <td
    :class="{
      'text-green-500': item.shouldSyncRegistry,
      'text-purple-500': !item.shouldSyncRegistry,
    }"
  >
    {{ statusTextFromShouldSyncRegistry(item.shouldSyncRegistry) }}
  </td>
</template>
