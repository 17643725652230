import { ActionTypes } from '../action-types';
import { MutationTypes } from '../mutation-types';
import portalHuntService from '../../services/portalHunt';

// initial state
const state = () => ({
  isLoading: 0,
});

// getters
const getters = {
  isLoading: (state) => {
    return state.isLoading;
  },
};

// actions
const actions = {
  async [ActionTypes.SUBMIT_PORTAL_HUNT_ANSWER]({ commit }, gameAnswer) {
    commit(MutationTypes.SET_IS_LOADING, true);
    try {
      const results = await portalHuntService.submitAnswer(gameAnswer);
      return results;
    } finally {
      commit(MutationTypes.SET_IS_LOADING, false);
    }
  },
  async [ActionTypes.GET_PORTAL_HUNT_STATUS](_, gameStatus) {
    try {
      const results = await portalHuntService.getStatus(gameStatus);
      return results;
    } catch (ex) {
      console.log('PH Error', ex);
    }
  },
  async [ActionTypes.GET_PORTAL_HUNT_BY_POST](_, postId) {
    try {
      const results = await portalHuntService.getByPostId(postId);
      return results;
    } catch (ex) {
      console.log('PH Error', ex);
    }
  },
};

// mutations
const mutations = {
  [MutationTypes.SET_IS_LOADING](state, val) {
    state.isLoading = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
