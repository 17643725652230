<script setup lang="ts">
  import { watch, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { liveChatModel, liveRoomsModel } from '@/entities/live';
  import { ElapsedRuntime } from '@/features/live/elapsed-runtime';
  import { PORTAL_CONTENT } from '@/common';

  const route = useRoute();

  const { joinRoom } = liveChatModel.use();

  const {
    selectedRoom,
    liveRooms,
    isLoading,
    fetchPortalRooms,
    setSelectedRoom,
  } = liveRoomsModel.use();

  // Only Show Panel is 1 room is live
  const showPanel = computed(() => {
    return liveRooms.value.length && portalSlug.value;
  });

  /**
   *
   */
  function getPortalSlugFromRoute() {
    const portalSlug = route.params['portal'] || '';
    return portalSlug;
  }

  const portalSlug = computed(() => {
    return getPortalSlugFromRoute();
  });

  watch(
    () => route.params.portal,
    async () => {
      if (route.name === PORTAL_CONTENT) {
        const portalSlug = getPortalSlugFromRoute() as string;
        await fetchPortalRooms(portalSlug, true);
        if (liveRooms.value.length) {
          setSelectedRoom(liveRooms.value[0]);
        } else {
          setSelectedRoom(null);
        }
      }
    },
    { immediate: true },
  );
</script>

<template>
  <div v-if="showPanel && selectedRoom && !isLoading" class="p-4 md:p-0">
    <div class="flex items-center mb-4 md:hidden">
      <i class="bg-green-300 w-2.5 h-3 rounded-t-full pr-2"></i>
      <h1 class="pl-2 my-3 text-lg font-semibold">Portal Is Live!</h1>
    </div>
    <div
      class="w-full px-4 py-3 mb-4 text-left text-white bg-gray-950 shadow-lg sm:rounded-xl flex sm:flex-row sm:items-center sm:gap-10 gap-0 items-stretch flex-col md:gap-0 md:items-stretch md:items-stretch md:flex-col md:items-stretch justify-between"
    >
      <div
        class="flex items-center border-b border-gray-785 border-opacity-50 mb-4 hidden md:flex"
      >
        <i class="bg-green-300 w-2.5 h-3 rounded-t-full pr-2"></i>
        <h1 class="pl-2 my-3 text-lg font-semibold">Portal Is Live!</h1>
      </div>
      <div class="flex-1">
        <h2>{{ selectedRoom.name }}</h2>
        <p class="p-0 m-0 my-3 text-sm text-gray-400 break-all">
          {{ selectedRoom.description }}
        </p>
        <div class="flex items-center py-2 space-x-2">
          <elapsed-runtime />
          <span>
            <base-icon
              name="live-users"
              size="w-4 h-4"
              class="inline text-indigo-300"
            />
          </span>
          <span class="text-indigo-300">{{ selectedRoom.userCount }}</span>
        </div>
      </div>

      <!-- Join room button -->
      <base-button
        variant="glass"
        size="small"
        class="w-full sm:w-auto sm:min-w-56 md:w-full"
        @click="joinRoom(selectedRoom)"
      >
        <base-icon name="plus-circle" size="w-5 h-5" class="mr-1" />
        <span>Join Room</span>
      </base-button>
    </div>
  </div>
</template>

<style scoped>
  .overlay {
    background: rgba(31, 41, 55, 0.64);
    backdrop-filter: blur(40px);
  }
  .title {
    letter-spacing: -0.01117em;
  }
</style>
