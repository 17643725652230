<script setup lang="ts">
  import { ref } from 'vue';
  import type { EntrepotCollectionItem } from '@/shared/api';

  defineProps<{
    item: EntrepotCollectionItem;
  }>();

  const imageError = ref(false);
</script>

<template>
  <div class="flex gap-4">
    <img
      v-if="item.avatar && !imageError"
      v-lazy="item.avatar"
      :alt="item.name"
      class="h-6 w-6 flex-shrink-0 rounded-full"
      @error="imageError = true"
    />
    <base-icon v-else name="no-image" class="w-6 h-6 rounded-full" />
    <span>{{ item.name }}</span>
  </div>
</template>
