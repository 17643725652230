<script lang="ts" setup>
  import { computed } from 'vue';
  import type { LoaderProps } from './types';

  const props = withDefaults(defineProps<LoaderProps>(), {
    variant: 'gray',
    size: 'size-4',
    animation: 'animate-spin',
    borderWidth: 'border-4',
  });

  const loaderColors = computed(() => {
    if (props.variant === 'rainbow') {
      return 'border-[#f6f] border-t-[#0e0] border-r-[#0dd] border-b-[#f90]';
    }
    // implicit gray return
    return 'border-white border-t-slate-800 border-opacity-50';
  });
</script>

<template>
  <div
    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  >
    <div
      :class="[
        'rounded-full',
        variant === 'custom' ? customClasses : loaderColors,
        borderWidth,
        size,
        animation,
      ]"
    />
  </div>
</template>
