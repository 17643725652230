import { storeToRefs } from 'pinia';
import { defineAsyncComponent } from 'vue';
import { useDialog } from '@/shared/model';
import { type SocialSphereTab, useReferralStore } from '@/entities/user';

export const useSocialSphere = () => {
  const { openConfiguredDialog, dialogDefaults } = useDialog();
  const referralStore = useReferralStore();
  const { selectedSocialSphereTab } = storeToRefs(referralStore);

  const drawerCommonClasses =
    'w-full px-2 pt-2 pb-10 border border-gray-785 border-opacity-50 rounded-t-2xl streak-modal';

  const showReferralCommunityDialog = (
    tabValue: SocialSphereTab,
    showReferralTab = false,
  ) => {
    selectedSocialSphereTab.value = tabValue;
    return openConfiguredDialog({
      opened: true,
      content: {
        component: defineAsyncComponent(
          () =>
            import(
              '@/features/referral/components/community-dialog/Content.vue'
            ),
        ),
        props: {
          selectedTab: selectedSocialSphereTab,
          showReferralTab,
        },
        emits: {
          'update:selected-tab': (socialSphereTab: SocialSphereTab) => {
            selectedSocialSphereTab.value = socialSphereTab;
          },
        },
      },
      dialog: {
        contentClasses: `${dialogDefaults.dialog.contentClasses} max-w-[573px] pt-6 pb-10 px-6 relative`,
      },
      drawer: {
        customClasses: drawerCommonClasses,
      },
    });
  };

  return {
    showReferralCommunityDialog,
    selectedSocialSphereTab,
  };
};
