<script setup lang="ts">
  import { computed, onMounted, ref, watch, type Ref } from 'vue';
  import { EXPLORE_PORTALS_FEATURED } from '@/common';
  import { useUser } from '@/entities/user';
  import { useChainBehavior } from '@/entities/user-setting';
  import { usePortalRecommendation } from '@/entities/portal';
  import PopularItem from './PopularItem.vue';

  const props = withDefaults(
    defineProps<{
      pageSize?: number;
      pages?: number;
    }>(),
    {
      pageSize: 3,
      pages: 100,
    },
  );

  const { isLoggedIn } = useUser();
  const { contentPreset } = useChainBehavior();
  const { featuredPortals, fetchFeaturedPortals } =
    usePortalRecommendation(contentPreset);

  const currentIndex: Ref<number> = ref(0);
  const currentItems = computed(() =>
    featuredPortals.value.slice(
      currentIndex.value * props.pageSize,
      (currentIndex.value + 1) * props.pageSize,
    ),
  );
  const totalPages = computed(() =>
    Math.ceil(featuredPortals.value.length / props.pageSize),
  );
  const isDecrementDisabled = computed(() => currentIndex.value <= 0);
  const isIncrementDisabled = computed(
    () => currentIndex.value >= totalPages.value - 1,
  );

  const decrementIndex = () => {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1;
    }
  };

  const incrementIndex = () => {
    if (currentIndex.value < totalPages.value - 1) {
      currentIndex.value += 1;
    }
  };

  watch(
    () => contentPreset.value,
    (newValue, oldValue) => {
      if (oldValue !== newValue) {
        currentIndex.value = 0;
        fetchFeaturedPortals(props.pageSize * props.pages);
      }
    },
  );

  onMounted(() => {
    currentIndex.value = 0;
    fetchFeaturedPortals(props.pageSize * props.pages);
  });
</script>

<template>
  <article class="flex flex-col gap-4 pb-4">
    <header class="flex justify-between">
      <h3 class="text-gray-100 text-lg font-semibold">
        {{ isLoggedIn ? $t('featuredPortals') : $t('popularPortals') }}
      </h3>
      <div class="flex">
        <base-button
          variant="link"
          :to="{ name: EXPLORE_PORTALS_FEATURED }"
          class="mr-1.5"
        >
          <span class="text-indigo-300 font-medium">{{ $t('explore') }}</span>
        </base-button>
        <base-button
          v-if="featuredPortals.length > pageSize"
          variant="custom"
          @click="decrementIndex"
          :disabled="isDecrementDisabled"
          class="mr-3"
        >
          <base-icon
            name="chevron-left"
            size="w-6 h-6"
            :class="isDecrementDisabled ? 'text-gray-400' : 'text-white'"
          />
        </base-button>
        <base-button
          v-if="featuredPortals.length > pageSize"
          variant="custom"
          @click="incrementIndex"
          :disabled="isIncrementDisabled"
        >
          <base-icon
            name="chevron-right"
            size="w-6 h-6"
            :class="isIncrementDisabled ? 'text-gray-400' : 'text-white'"
          />
        </base-button>
      </div>
    </header>
    <section v-if="currentItems.length">
      <ul class="flex flex-col gap-4">
        <li v-for="item in currentItems" :key="item.slug">
          <popular-item :portal="item" portrait class="!h-42.5" />
        </li>
      </ul>
    </section>
  </article>
</template>
