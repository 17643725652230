<script setup lang="ts">
  import { toRef, computed } from 'vue';
  import type { ContentView, UserView } from 'dfx/edge/edge.did';
  import { POST_DETAIL } from '@/common';
  import { UserAvatar } from '@/shared/ui/user-avatar';
  import { usePost } from '@/entities/post';
  import { Avatar } from '@/shared/ui/avatar';

  const props = withDefaults(
    defineProps<{
      item: ContentView;
      friends: UserView[];
      maxNamesCount?: number;
      maxIconsCount?: number;
    }>(),
    {
      maxNamesCount: 2,
      maxIconsCount: 3,
    },
  );

  const item = toRef(props, 'item');
  const { postDetailRouteParams } = usePost(item);

  const topFriends = computed(() =>
    props.friends.slice(0, props.maxIconsCount).reverse(),
  );
  const usernames = computed(() =>
    props.friends
      .slice(0, props.maxNamesCount)
      .map((friend) => friend.username)
      .join(', '),
  );
</script>

<template>
  <div class="flex items-center">
    <avatar :avatars="topFriends" reverse size="size-6">
      <template #default="{ avatars }">
        <user-avatar
          v-for="friend in avatars as UserView[]"
          :key="friend.id.toText()"
          :item="friend"
          :username="friend.username"
          size="size-6"
          class="-ml-1.5"
        />
      </template>
    </avatar>

    <base-button
      variant="custom"
      custom-classes="flex items-center gap-1 ml-2 text-sm"
      :to="{
        name: POST_DETAIL,
        params: postDetailRouteParams,
      }"
    >
      {{ usernames }}
      <slot />
    </base-button>
  </div>
</template>
