<script setup lang="ts">
  import { POST_DETAIL } from '@/common';
  import { Post } from '@/features/post';
  import { getUrlTitle } from '@/entities/content';
  import { useGetContentByIdQuery } from '@/entities/post';
  import { computed, toRef } from 'vue';
  import { useRouter } from 'vue-router';

  const props = defineProps<{
    contentId: string;
  }>();

  const router = useRouter();
  const { data } = useGetContentByIdQuery(toRef(props, 'contentId'));
  const content = computed(() => data.value?.result?.[0]);

  const onClickPost = () => {
    if (content.value) {
      router.push({
        name: POST_DETAIL,
        params: {
          id: content.value.id.toString(),
          title: getUrlTitle(content.value),
        },
      });
    }
  };
</script>

<template>
  <div
    v-if="content"
    custom-classes="w-full h-full no-underline"
    @click="onClickPost"
  >
    <post
      variant="compact"
      :post="content"
      is-embedded
      class="bg-gray-800 shadow-lg prose"
    />
  </div>
</template>
