<template>
  <area-loader v-if="isLoading" :rounded="false" class="rounded-full" />
  <div v-if="currentPortal" class="sm:block">
    <h1 class="py-1 text-xl font-bold text-white">Settings</h1>
    <p class="text-sm text-gray-400">
      Personalize the experience of your portal.
    </p>

    <error-display
      class="mt-3"
      :errors="errors"
      :error-message="errorMessage"
      :local-errors="localErrors"
    />

    <div class="w-full my-8">
      <h2 class="mb-4 text-lg font-semibold text-white">Image</h2>

      <div class="overview-imgPreview">
        <div
          class="w-32 h-32 mb-2 bg-gray-900 bg-center bg-cover border border-gray-600 rounded-2xl md:mb-0"
          :style="{ 'background-image': `url(${imageData})` }"
        />
      </div>
      <div class="m:text-left">
        <input
          ref="imageInputField"
          type="file"
          hidden
          @input="pickImageFile($event, false)"
        />
        <div class="flex gap-3 my-4">
          <button
            class="btn-tertiary text-sm font-medium outline-none px-4 py-2.5 rounded-md flex gap-1.5"
            @click="$refs.imageInputField.click()"
          >
            <base-icon name="upload" size="w-4 h-4" />
            Upload
          </button>
          <button
            v-show="imageData"
            class="btn-destructive text-sm font-medium outline-none px-4 py-2.5 rounded-md flex gap-1.5"
            @click="clearImageFileAndData(false)"
          >
            <base-icon name="trash" size="w-4 h-4" />
            Remove
          </button>
        </div>
        <p class="text-sm text-gray-400">
          Portal image should be at least 512x512 px for the best result.
        </p>
      </div>
    </div>

    <div class="coverImg">
      <h2 class="mb-4 text-lg font-semibold text-white">Cover Image</h2>

      <div class="mb-2 rounded-md md:mb-6">
        <!-- used to get the final cropped image resized, before sending -->
        <canvas ref="resizedCanvas" class="hidden" />
        <cropper
          v-show="coverImageData"
          class="cropper rounded-xl"
          :src="coverImageData"
          :stencil-props="{
            handlers: {},
            movable: false,
            scalable: false,
          }"
          :cross-origin="true"
          :stencil-size="{
            width: 1800,
            height: 480,
          }"
          image-restriction="stencil"
          @change="cropCoverImage"
        />
      </div>

      <div class="flex flex-col gap-6 sm:flex-row">
        <div class="flex-grow">
          <h2 class="mb-2 text-lg font-semibold text-white">Gradient Cover</h2>
          <p class="mb-6 text-sm text-gray-400 md:max-w-80">
            Select an option below to set one of the default gradients as your
            cover image.
          </p>
          <div class="flex gap-5">
            <gradient-button
              v-for="photoUrl in COVER_PHOTOS"
              :key="photoUrl"
              :photo-url="photoUrl"
              :checked="photoUrl === coverImageData"
              @click="setGradientCover(photoUrl)"
            />
          </div>
        </div>
        <div class="flex-grow">
          <h2 class="mb-2 text-lg font-semibold text-white">
            Upload Cover Photo
          </h2>
          <p class="mb-4 text-sm text-gray-400 md:max-w-80">
            Portal cover image should be at least 1088x300 px for the best
            result.
          </p>
          <input
            ref="coverImageInputField"
            type="file"
            hidden
            @input="pickImageFile($event, true)"
          />
          <div class="flex gap-3">
            <button
              class="btn-tertiary text-sm font-medium outline-none px-4 py-2.5 rounded-md flex gap-1.5"
              @click="$refs.coverImageInputField.click()"
            >
              <base-icon name="upload" size="w-4 h-4" />
              Upload
            </button>
            <button
              v-show="coverImageData"
              class="btn-destructive text-sm font-medium outline-none px-4 py-2.5 rounded-md flex gap-1.5"
              @click="clearImageFileAndData(true)"
            >
              <base-icon name="trash" size="w-4 h-4" />
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full mt-4 text-white overview-info sm:flex-row sm:items-start"
    >
      <div class="w-full overview-info">
        <span class="mb-2 font-semibold">Name</span>
        <input
          ref="portalName"
          v-model="portalName"
          type="text"
          class="block w-full mt-1 bg-gray-900 border-transparent border-gray-600 rounded-md focus:bg-gray-700 focus:ring-0"
          @keyup="findPortalSlug"
        />
        <span class="text-sm">https://dscvr.one/p/{{ slug }}</span>
        <label class="block">
          <span class="block mt-6 mb-2 font-semibold">Description</span>
          <textarea
            v-model="portalDescription"
            placeholder="Description"
            class="block w-full mt-1 bg-gray-900 border-transparent border-gray-600 rounded-md focus:bg-gray-700 focus:ring-0"
            rows="3"
          ></textarea>
          <span class="text-sm text-gray-400">Limit 256 characters</span>
        </label>
      </div>
    </div>

    <portal-settings-details ref="portalSettingsDetailsRef" :portal="portal" />

    <div class="block w-full mt-4 text-right sm:w-auto">
      <button
        type="button"
        class="text-base leading-tight btn-submit"
        :style="{ padding: '14px 53.5px' }"
        @click="onSubmit"
      >
        <div class="flex items-center justify-center">
          <div>Update</div>
          <div
            v-show="isLoading"
            class="transform translate-y-1 translate-x-7 spinner-big"
          ></div>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { Cropper } from 'vue-advanced-cropper';
  import storage from '@/services/storage';
  import 'vue-advanced-cropper/dist/style.css';

  import ErrorDisplay from './ErrorDisplay.vue';
  import AreaLoader from './AreaLoader.vue';

  import { ActionTypes } from '@/store';
  import { COVER_PHOTOS, DESCRIPTION_MAX_LENGTH } from '@/common';
  import GradientButton from './buttons/GradientButton.vue';
  import PortalSettingsDetails from './PortalSettingsDetails.vue';
  import { usePortal, portalContentKey } from '@/entities/portal';
  import { useChainBehavior } from '@/entities/user-setting';
  import { useQueryClient } from '@tanstack/vue-query';

  export default {
    name: 'portal-settings-overview',
    components: {
      Cropper,
      ErrorDisplay,
      AreaLoader,
      GradientButton,
      PortalSettingsDetails,
    },
    props: {
      portal: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    emits: ['input'],
    data() {
      return {
        img: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
        localErrors: [],
        errorMessage: '',
        errors: [],

        // logo image
        imageData: null,
        imageFileObject: null,
        imageFleekURL: null,
        imageResizedData: null,

        // header image
        coverImageData: null,
        coverImageCroppedData: null,
        coverImageFileObject: null,
        coverImageFleekURL: null,

        portalName: '',
        portalDescription: '',

        isLoading: false,
        COVER_PHOTOS,
      };
    },
    setup() {
      const { loadFavoritePortals } = usePortal();
      const { contentPreset } = useChainBehavior();
      const queryClient = useQueryClient();
      const loadHighlightedPortals = () =>
        loadFavoritePortals(contentPreset.value);

      return {
        loadHighlightedPortals,
        queryClient,
      };
    },
    computed: {
      ...mapGetters({
        currentPortal: 'portals/currentPortal',
      }),
      slug() {
        return this.currentPortal.slug;
      },
    },
    watch: {
      currentPortal: {
        immediate: true,
        handler() {
          if (this.currentPortal) {
            // convert cover_photo to string
            let newImageHeader = '';
            if (this.currentPortal && this.currentPortal.info[0]) {
              newImageHeader =
                this.currentPortal.info[0].cover_photo + '' ?? '';
            }

            if (this.coverImageData != newImageHeader)
              this.coverImageData = newImageHeader;
            if (this.imageFleekURL != newImageHeader)
              this.imageFleekURL = newImageHeader;
            if (this.imageData != this.currentPortal.icon_url)
              this.imageData = this.currentPortal.icon_url;
            if (this.imageFleekURL != this.currentPortal.icon_url)
              this.imageFleekURL = this.currentPortal.icon_url;
          }
        },
      },
    },
    mounted() {
      this.portalName = this.currentPortal.name;
      this.portalDescription = this.currentPortal.description;
    },
    methods: {
      cropCoverImage({ canvas }) {
        // use a hidden canvas to resize the cover image
        const resizedCanvas = this.$refs.resizedCanvas;
        const resizedContext = resizedCanvas.getContext('2d');

        resizedCanvas.width = 1800;
        resizedCanvas.height = 480;

        resizedContext.clearRect(0, 0, 1800, 480);
        resizedContext.drawImage(canvas, 0, 0, 1800, 480);
        this.coverImageCroppedData = resizedCanvas.toDataURL('image/jpeg');
      },
      pickImageFile(event, isCoverImage) {
        const file = event.target.files;
        // check if there are files attached and get first file
        if (file && file[0]) {
          // store image file object for upload to fleek
          if (isCoverImage) this.coverImageFileObject = file[0];
          else this.imageFileObject = file[0];

          const reader = new FileReader();
          // attach callback on after content files are read
          reader.onload = (e) => {
            // store image data for displaying immediately
            if (isCoverImage) this.coverImageData = e.target.result;
            else {
              this.imageData = e.target.result;
              this.resizeImage();
            }
          };
          // read image file contents
          reader.readAsDataURL(file[0]);

          this.$emit('input', file[0]);
        }
      },
      resizeImage() {
        const resizedCanvas = this.$refs.resizedCanvas;
        const context = resizedCanvas.getContext('2d');
        let img = new Image();
        resizedCanvas.width = 168;
        resizedCanvas.height = 168;

        img.onload = () => {
          context.drawImage(img, 0, 0, 168, 168);
          this.imageResizedData = resizedCanvas.toDataURL('image/jpeg');
          img = null;
        };

        img.src = this.imageData;
      },
      setGradientCover(photoUrl) {
        this.coverImageData = photoUrl;
        this.coverImageFileObject = { type: 'image/jpeg' };
      },
      clearImageFileAndData(isCoverImage) {
        if (isCoverImage) {
          this.$refs.coverImageInputField.value = '';
          this.coverImageFileObject = null;
          this.coverImageFleekURL = null;
          this.coverImageData = null;
          this.coverImageCroppedData = null;
        } else {
          this.$refs.imageInputField.value = '';
          this.imageFileObject = null;
          this.imageFleekURL = null;
          this.imageData = null;
        }
      },
      isDataValid() {
        this.localErrors = [];

        this.portalDescription = this.portalDescription.trim();
        if (!this.portalDescription.replace(/\s/g, '').length) {
          this.localErrors.push({
            field: 'Description',
            errors: ['you need to describe it in at least one word'],
          });
        }

        if (this.portalDescription.length >= DESCRIPTION_MAX_LENGTH) {
          this.localErrors.push({
            field: 'Description',
            errors: ['max length is 256 characters'],
          });
        }

        if (this.portalDescription.length < 1) {
          this.localErrors.push({
            field: 'Description',
            errors: ['min length is 1 characters'],
          });
        }

        //TODO: validate images

        return this.localErrors.length == 0;
      },
      async uploadFileToFleek(isCoverImage) {
        // creates unique image name hash and appends file type
        // since we only have a data output of the cropped cover image from the canvas, convert it to a file object for uploading to Fleek
        const imageBase64 = isCoverImage
          ? this.coverImageCroppedData
          : this.imageResizedData;
        const imageUrl = await storage.uploadBase64(imageBase64);

        if (isCoverImage) {
          this.coverImageFleekURL = imageUrl;
          this.coverImageFileObject = null;
        } else {
          this.imageFleekURL = imageUrl;
          this.imageFileObject = null;
        }
      },
      async onSubmit() {
        if (this.isDataValid()) {
          this.isLoading = true;
          await this.$refs.portalSettingsDetailsRef.save();
          this.isLoading = false;

          if (this.imageFileObject) {
            this.isLoading = true;
            await this.uploadFileToFleek(false);
            this.isLoading = false;

            // update icon in db
            await this.$store
              .dispatch(`portals/${ActionTypes.UPDATE_PORTAL_ICON}`, {
                id: this.currentPortal.id,
                icon_url: this.imageFleekURL,
              })
              .then((result) => {
                if (result?.status !== 'happy') {
                  this.errorMessage = result.message;
                  this.errors = result.errors[0];
                }
              });
          }
          // if there is no new file and there is no old image url and the portal has remotely an image - remove it
          else if (!this.imageFleekURL && this.currentPortal.icon_url) {
            // update icon in db
            await this.$store
              .dispatch(`portals/${ActionTypes.UPDATE_PORTAL_ICON}`, {
                id: this.currentPortal.id,
                icon_url: '',
              })
              .then((result) => {
                if (result?.status !== 'happy') {
                  this.errorMessage = result.message;
                  this.errors = result.errors[0];
                }
              });
          }

          if (this.coverImageCroppedData && this.coverImageFileObject) {
            this.isLoading = true;
            await this.uploadFileToFleek(true);
            this.isLoading = false;
            // update icon in db
            await this.$store
              .dispatch(
                `portals/${ActionTypes.UPDATE_PORTAL_INFO_COVER_PHOTO}`,
                {
                  portal_id: this.currentPortal.id,
                  icon_url: [this.coverImageFleekURL],
                },
              )
              .then((result) => {
                if (result?.status !== 'happy') {
                  this.errorMessage = result.message;
                  this.errors = result.errors[0];
                }
              });
          }
          // if there is no new file and there is no old image url and the portal has remotely a cover image - remove it
          else if (
            !this.coverImageCroppedData &&
            this.currentPortal.info[0] &&
            this.currentPortal.info[0].cover_photo
          ) {
            // update icon in db
            await this.$store
              .dispatch(
                `portals/${ActionTypes.UPDATE_PORTAL_INFO_COVER_PHOTO}`,
                {
                  portal_id: this.currentPortal.id,
                  icon_url: [''],
                },
              )
              .then((result) => {
                if (result?.status !== 'happy') {
                  this.errorMessage = result.message;
                  this.errors = result.errors[0];
                }
              });
          }

          this.$store
            .dispatch(`portals/${ActionTypes.UPDATE_PORTAL}`, {
              id: this.currentPortal.id,
              name: this.portalName,
              description: this.portalDescription,
            })
            .then((result) => {
              if (result?.status !== 'happy') {
                this.errorMessage = result.message;
                this.errors = result.errors[0];
              }
            });
          // Refresh list of highlighted portals & portal feed
          this.loadHighlightedPortals();
          this.queryClient.resetQueries({ queryKey: [portalContentKey] });
        } else {
          window.scrollTo(0, 0);
        }
      },
    },
  };
</script>

<style>
  /*
	It may be necessary to set limits on the size of the cropper, otherwise the cropper image will try to fill all the available space.
*/
  .coverImgCropOuter {
    overflow: hidden;
    width: 920px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cropper {
    width: 900px;
    height: 240px;
  }

  .cropper * {
    border: none;
    border-radius: 6px;
  }

  @media screen and (max-width: 1268px) {
    .coverImgCropOuter {
      overflow: hidden;
      width: 460px;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cropper {
      width: 450px;
      height: 120px;
    }
  }
</style>
